import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Dropzone from "react-dropzone";
import Resizer from "react-image-file-resizer";
// import axios from "axios";

import Select from "react-select";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, JENIS_DESA } from "../function/Koneksi";
import { DataToken } from "../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import CKEditors from "react-ckeditor-component";
var HtmlToReactParser = require("html-to-react").Parser;

//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  judul: "",
  konten: "",
  jenis: "",
  routing_mobile: "",
  args_routes: "",
  status: "",
  link_gambar: "",
  logo: "",
};

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Agama",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      konten: false,
      content: "",
      token: DataToken(sessionStorage.getItem("access_token")),
      foto_desa: "",
      link_gambar: "",
      formDisposisi: false,
      stpStat: {label : '', value : ''}
    };
    this.onChange = this.onChange.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.onChange = this.onChange.bind(this);
    this.afterPaste = this.afterPaste.bind(this);
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("konfigurasi-notifikasi" + link, null, dtkat => {
      this.setState({ data: dtkat.results.data, pagination: dtkat.results });
      this.pagination(dtkat.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let addInfo = {
        nama: e.target.value
      };
      Post("info-desa", null, addInfo, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results
        });
      });
    }
  };

  // ========================================================================

  // ubah select value
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jenis-info":
          if (e.value === "info") {
            this.setState({
              konten: true,
            })
          } else {
            this.setState({
              konten: false,
            })
          }
          this.setState({
            stpStat: e,
            dt: { ...this.state.dt, jenis: e.value }
          });
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "jenis-info":
          this.setState({ 
            stpStat: e, 
            dt:{
              ...this.state.dt,
              status_penduduk: 0,
            },
          });
          break;
        default:
          // no default
          break;
      }
    }

    this.setState({
      status:{
          ...this.state.status,
          select: false,
      },
    })
    this.forceUpdate();
  };
  /// upload file

  processGbr = d => {
    // //console.log(d[0].type);
    if (d.length === 1) {
      let type = d[0].type.split("/");
      if (
        type[1] === "jpg" ||
        type[1] === "jpeg" ||
        type[1] === "png" ||
        type[1] === "bmp" ||
        type[1] === "tiff" ||
        type[1] === "webp"
      ) {
        let bodyFormData = new FormData();
        bodyFormData.append("files", d[0]);

        Post("upload-file/notif-desa", null, bodyFormData, data => {
          //console.log(data);
          Resizer.imageFileResizer(
            d[0],
            300,
            300,
            type[1],
            100,
            0,
            uri => {
              this.setState({
                foto_desa: uri,
                foto_info: "",
                link_gambar: data.data.results
              });
            },
            "base64"
          );
        });
        // axios({
        //   method: "post",
        //   url: API_URL + "upload-file/berita",
        //   data: bodyFormData,
        //   headers: {}
        // })
        //   .then(function(response) {
        //     //handle success
        //     //console.log(response);
        //     //console.log(response.status);
        //     if(response.status===200){
        //       this.state.link_gambar=response.data.results;
        //       this.forceUpdate();
        //       //console.log("masuk");
        //     }

        //   })
        //   .catch(function(response) {
        //     //handle error
        //     //console.log(response);
        //   });

        // Post("upload-file/berita", null, bodyFormData, res => {
        //   //console.log(res)
        // });
      } else {
        this.setState({
          foto_desa: "",
          foto_info: (
            <div className="text-danger font-size-10">
              Type file tidak valid
            </div>
          )
        });
      }
    } else {
      this.setState({
        foto_desa: "",
        foto_info: (
          <div className="text-danger font-size-10">
            Silahkan masukan satu gambar
          </div>
        )
      });
    }
  };

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    //console.log(this.state.stpStat)
    //console.log(this.state.dt.judul)

    let judul = document.getElementById("judul").value
		let konten = document.getElementById("konten").value
		let routing = document.getElementById("routing_mobile").value
		let args_routes = document.getElementById("args_routes").value

    if (judul === '' || routing === '' || args_routes === '') {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Data Notifikasi Desa",
        pesanAlert: "Silahkan lengkapi data"
      });
    } else {
        this.setState({
          status:{
              ...this.state.status,
              btnForm: true,
          },
        })
        this.forceUpdate();
  
        let addData = {
          uuid: document.getElementById("uuid").value,
          judul: judul,
          konten: konten,
          routing_mobile: routing,
          args_routes: args_routes,
          gambar: this.state.link_gambar,
					gambar_lama: document.getElementById("logo_lama").value,
        };

        let psn = "";
        let resstat = 204;
        let metode = "create";
        if (addData.uuid === "") {
          psn = "Tambah";
          resstat = 201;
          addData.uuid = null;
					addData.status= '0';
        } else {
          psn = "Ubah";
          resstat = 200;
          metode = "update";

          addData.status= this.state.status_notifikasi.value;
					if (addData.gambar === '') {
						addData.gambar = addData.gambar_lama;
					}
					// else {
					// 	// axios.get(`${addData.gambar_lama}/hapus`).then(resp => {
					// 	// 	// //console.log(resp.data);
					// 	// });
					// 	addData.gambar = addData.gambar;
					// }
        }

        
        //console.log("Data Simpan cuy");
        //console.log(addData);
  
        Post("konfigurasi-notifikasi/" + metode, addData.uuid, addData, res => {
          this.setState({
            status:{
                ...this.state.status,
                btnForm: false,
            },
          })
          // this.state.status.form = false;
          this.tutupForm();
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Info " + JENIS_DESA,
              pesanAlert: "Berhasil " + psn + " Data"
            });
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Info " + JENIS_DESA,
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        });
    }

  };
  // ========================================================================
  // list disposisi

  detailBerita = data => {
    //console.log(data);
    this.setState({
      formDisposisi: true,
      content: data.konten,
      link_gambar: data.link_gambar
    });
  };
  // ============================== Ubah Data ===============================
  ubahData = (data) => {
    this.setState({
      judul: "Ubah Notifikasi Desa ",
      status:{
        ...this.state.status,
        btnAksi: false,
        form: true,
      },
      status_notifikasi: {
				value: data.status, 
				label: (
						data.status === '0' ? 'Aktif'
						: 'Tidak Aktif'
						),
			},
      link_gambar: data.gambar,
      dt:{
        uuid: data.uuid,
				judul: data.judul,
        konten: data.konten,
				routing_mobile: data.routing_mobile,
				args_routes: data.args_routes,
        logo: data.gambar
      }
    })
    this.forceUpdate();
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("konfigurasi-notifikasi/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Info " + JENIS_DESA,
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Info " + JENIS_DESA,
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.setState({
      status:{
          ...this.state.status,
          form: true,
      },
    })
    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      dt:{
        ...this.state.dt,
        uuid: "",
        judul: "",
        konten: "",
        jenis: "",
        routing_mobile: "",
        args_routes: "",
        status: "",
        link_gambar: "",
        logo: "",
      },
      status:{
          ...this.state.status,
          form: false,
      },
      formDisposisi: false,
    })

    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>
        &nbsp;&nbsp;
        {/* //list */}
        {row.jenis === "info" ? (
          <Button
            size="xs"
            color="info"
            className="btn-icon btn-secondary-custom"
            onClick={e => this.detailBerita(row)}
          >
            <i className="fa fa-list"></i>
          </Button>
        ) : (
          <></>
        )}
      </>
    );
  };
  componentDidMount() {
    this.fetch({ page: null, where: [] });

    Get("select-enum/info_desa/jenis", null, data => {
      let frmStp = [];
      data.forEach(dt => {
        frmStp.push({ value: dt, label: dt });
      });
      //console.log(frmStp)
      this.setState({ frmStp });
    });
  }

  //ck editor

  updateContent(newContent) {
    this.setState({
      content: newContent
    });
  }

  onChange(evt) {
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent
    });
  }

  onBlur(evt) {
    //console.log("onBlur event called with event info: ", evt);
  }

  afterPaste(evt) {
    //console.log("afterPaste event called with event info: ", evt);
  }

  
	// Making dynamic function
	onChangeSelect = (e, data) => {
		if (e) {
			switch (data) {
				case 'status_notifikasi':
					this.setState({ 
						status_notifikasi: { value: e.value, label: e.label },
					});
					break;
        default:
          // no default
          break;
			}
		} else {
			switch (data) {
				case 'status_notifikasi':
					this.setState({ 
						status_notifikasi: null,
					});
					break;
        default:
          // no default
          break;
			}
		}
	}


  render() {
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;
    const frmDef = {
      uuid: "",
      judul: "",
      konten: "",
      jenis: "",
      routing_mobile: "",
      args_routes: "",
      status: "",
      link_gambar: "",
      logo: "",
    };
    // const { SearchBar } = Search;
    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.pagination.current_page;
              let total_records_per_page = this.state.pagination.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          },
          headerStyle: (colum, colIndex) => {
              return { width: '15px', textAlign: 'left' };
          }
      },
      {
				dataField: 'judul',
        headerAlign: 'center',
				align: 'center',
				text: 'Judul',
				sort: true
			},
			{
				dataField: 'konten',
        headerAlign: 'center',
				align: 'center',
				text: 'Konten',
				sort: true
			},
			{
				dataField: 'routing_mobile',
        headerAlign: 'center',
				align: 'center',
				text: 'Routing Mobile',
				sort: true
			},
			{
				dataField: "status",
        headerAlign: 'center',
				align: 'center',
				text: "Status",
				sort: true,
				formatter: (cell, obj) => {
				  let isinya = obj.status
				  if(isinya==='0'){
					return ('Aktif');
				  }
				  else{
					return ('Tidak Aktif');
				  }
				},
			  },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];
    // var HtmlToReactParser = require("html-to-react").Parser;
    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-success";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href="#" onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };
    var htmlToReactParser = new HtmlToReactParser();

    return (
      <div>
        {/* <Breadcrumb title="Data Info Desa" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Info " + JENIS_DESA,
                            dt: frmDef,
                            link_gambar: "",
                            content: ""
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          styles={{ modal: { width: "80%" } }}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Agama"
                defaultValue={this.state.dt.uuid}
                value={this.state.dt.uuid}
              />
              
              
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Judul</Label>
								<Col sm='9'>
									<input
										id="judul"
										className="form-control"
										defaultValue={this.state.dt.judul}
										// value={this.state.dt.judul}
										type="text"
										placeholder="Judul"
										required
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Konten</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="konten" 
										type="text" 
										placeholder="Konten" 
										defaultValue={this.state.dt.konten} 
										// required
									/>
								</Col>
							</FormGroup>
							
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Routing Mobile</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="routing_mobile" 
										type="text" 
										placeholder="Routing Mobile" 
										defaultValue={this.state.dt.routing_mobile} 
										required
									/>
								</Col>
							</FormGroup>
							
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Argument Route</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="args_routes" 
										type="text" 
										placeholder="Argument Route" 
										defaultValue={this.state.dt.args_routes} 
										required
									/>
								</Col>
							</FormGroup>

							{
								this.state.dt.uuid?
								<>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status</Label>
										<Col sm='9'>
										<Select
												classNamePrefix="select"
												onChange={(e) => this.onChangeSelect(e, 'status_notifikasi')}
												value={this.state.status_notifikasi}
												name="status_notifikasi"
												options={[
													{ value: '0', label: 'Aktif' },
													{ value: '1', label: 'Tidak Aktif' },
												]}
												placeholder="Pilih Status Notifikasi"
												isClearable
											/>
										</Col>
									</FormGroup>
								</>
								:
								<>
								<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status</Label>
										<Col sm='9'>
											<input 
												className="form-control" 
												id="konten" 
												type="text" 
												placeholder="Konten" 
												defaultValue="Aktif"
												value="Aktif"
												readOnly
												disabled
											/>
										</Col>
									</FormGroup>
								</>
							}

                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Gambar
                    </Label>
                    <Col sm="9">
                      <input className="form-control" placeholder="Logo lama" id="logo_lama" type="hidden" defaultValue={this.state.dt.logo} />

                      <Dropzone
                        onDrop={acceptedFiles => this.processGbr(acceptedFiles)}
                        maxFiles={1}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              style={{
                                minHeight: "100px",
                                paddingTop: "40px",
                                padding: "5px 5px 5px 5px",
                                border: "1px solid #d2d2d2"
                              }}
                            >
                              <input {...getInputProps()} />
                              <div
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                <p className="text-center">
                                  {this.state.link_gambar ? (
                                    <img
                                      alt="gambar"
                                      src={this.state.link_gambar}
                                      style={{
                                        minHeight: "50px",
                                        minWidth: "50px"
                                      }}
                                    />
                                  ) : (
                                    <Label
                                      className="col-form-label text-center"
                                      htmlFor="inputEmail3"
                                    >
                                      Drag 'n' drop some files here, or click to
                                      select files
                                    </Label>
                                  )}
                                  {this.state.foto_info
                                    ? this.state.foto_info
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </Col>
                  </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Detail Info</h5>
            </div>
            <div className="modal-body ">
              <img
                alt="detailgambar"
                src={this.state.link_gambar}
                className="center"
                style={{
                  minHeight: "50px",
                  minWidth: "50px"
                }}
              />
              {htmlToReactParser.parse(this.state.content)}
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default BasicTable;
