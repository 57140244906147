import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../../../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import Datetime from 'react-datetime';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";

import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
// import Datepicker from "react-datepicker";
import NumberFormat from 'react-number-format';

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { ID_DESA, PostEksternal, GetEksternal,  Post, Get, Delete, JENIS_DESA, JENIS_DUSUN, DESA } from "../../../function/Koneksi";
// import { Tanggal, Hari, HariTanggal, HariTanggalJam } from "../../../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";

import "../../../assets/css/site.css";

//json file
// var data = require('../assets/json/company');

// const frmDef = {
// 	id: 0,
// 	idbaru: '',
// 	id_kk: '',
// 	nama: '',
// 	alamat: '',
// 	id_keldes: '',
// 	tempat_lahir: '',
// 	tanggal_lahir: new Date(),
// 	jk: '',
// 	shdk: '',
// 	agama: '',
// 	pendidikan_terakhir: '',
// 	pekerjaan: '',
// 	nama_ibu_kandung: '',
// 	status_penduduk: '',
// 	status_perkawinan: '',
// 	kode_pos: '',
// 	telepon: '',
// 	alasan_pindah: '',
// };

const dateFormat = require('dateformat');

const listFile = {
  surat_pengantar_lokasi_usaha: {
    link: '',
    type: '',
  },
  fotocopy_ktp: {
    link: '',
    type: '',
  },
  fotocopy_kk: {
    link: '',
    type: '',
  },
  pas_foto: {
    link: '',
    type: '',
  },
  npwp: {
    link: '',
    type: '',
  },
}

class KecamatanRekomendasiUsaha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Data table
      data: [],
      dataVerifikasi: [],
      dataDisetujui: [],
      dataDitolak: [],
      dataSelesai: [],
      dataPemohon: null,

      detail: [],

      detailSurat: null,
			dataPelayanan: null,

      // Pagination data table
      pagination: [],
      paginationDitolak: [],
      paginationDiverifikasi: [],
      paginationDisetujui: [],
      paginationSelesai: [],

      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        btnAksiTidakGelar: false,
        select: false,
        formDetail: false,
        fotoPersyaratan: false,
      },
      
      uuid_surat: null,
      dataPenduduk: "none",
      dataPenduduk2: "none",

      // Diajukan
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      // Diverifikasi
      awal2: "",
      sebelum2: "",
      hal2: [],
      setelah2: "",
      akhir2: "",
      // Disetujui
      awal3: "",
      sebelum3: "",
      hal3: [],
      setelah3: "",
      akhir3: "",
      // Ditolak
      awal4: "",
      sebelum4: "",
      hal4: [],
      setelah4: "",
      akhir4: "",
      // Selesai
      awal5: "",
      sebelum5: "",
      hal5: [],
      setelah5: "",
      akhir5: "",
    

      dt: [],
      judul: "",
      // =========== Select 2 ============
      frmPend: [],
			pendStat: null,
      // =================================
      // =========== Alert ============
      alert: null,
      show: false,
      showCetakSurat: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,
      // ==============================
      active_tab_icon: "1",

      // Cari Data Gelar
      dataCariGelar: {
				nama: ''
			},

      // Data pengaju dan pelapor
      frmNik: [],
      nikStat: null,
      nikSelect: true,
      frmNik2: [],
      nik2Stat: null,
      nik2Select: true,

      // sumber_bahan_baku: null,
      // Checkbox
      // sumber_bahan_baku: {
      //   lokal: false,
      //   luar_kota: false,
      //   impor: false
      // },
      pemasaran: {
        lokal: false,
        regional: false,
        nasional: false,
        impor: false
      },
      legalitas_usaha: {
        siup: false,
        situ: false,
        tdp: false,
        npwp: false,
        domisili: false,
        halal: false,
        hak_paten: false,
        p_irt: false
      },
      status_tempat_usaha: {
        milik_sendiri: false,
        sewa: false
      },
      
      // Detail Alamat
      kelStat: {
        value: ID_DESA, label: DESA 
      },
      dusStat: null,
      dusSelect: true,
			frmDus: [],
      frmRw: [],
      frmRt: [],

      showBio: false,
      tanggal_surat_desa: new Date(),
      tgl_melapor: new Date(),

      dokumen_input:"",
      dokumen_output:"",

			linkGambar: listFile,
      isUpdate: false,

      cek_posisi:'Pertama',

			isKelurahan: false,
			id_jenis_pelayanan: this.props.location.state.uuid
    };
		this.changeCheck = this.changeCheck.bind(this);
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      showCetakSurat: false,
      successAlert: false,
      alert: null
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================
// Bootstrap tabs function
  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      if(tab === '1'){
        this.state.cek_posisi = 'Pertama'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '2'){
        this.state.cek_posisi = 'Kedua'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '3'){
        this.state.cek_posisi = 'Ketiga'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '4'){
        this.state.cek_posisi = 'Keempat'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '5'){
        this.state.cek_posisi = 'Kelima'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      
      this.setState({
        active_tab_icon: tab,
      });
    }
    
    this.forceUpdate();
    this.componentDidMount();
  }

  // ================== Ambil data dari db untuk table ==================

  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" +
                Math.ceil(
                  this.state.pagination.total / this.state.pagination.per_page
                );
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDiverifikasi.total /
                  this.state.paginationDiverifikasi.per_page
                );
              break;

            case "Disetujui":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDisetujui.total /
                  this.state.paginationDisetujui.per_page
                );
              break;

            case "Ditolak":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDitolak.total /
                  this.state.paginationDitolak.per_page
                );
              break;
              case "Selesai":
                link =
                  "&page=" +
                  Math.ceil(
                    this.state.paginationSelesai.total /
                    this.state.paginationSelesai.per_page
                  );
                break;
              default:
                // no default
                break;
          }
          break;
        case ">":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) + 1);
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                (parseInt(this.state.paginationDiverifikasi.current_page) +
                  1);
              break;

            case "Disetujui":
              link =
                "&page=" +
                (parseInt(this.state.paginationDisetujui.current_page) + 1);
              break;

            case "Ditolak":
              link =
                "&page=" +
                (parseInt(this.state.paginationDitolak.current_page) + 1);
              break;
            case "Selesai":
              link =
                "&page=" +
                (parseInt(this.state.paginationSelesai.current_page) + 1);
              break;
            default:
              // no default
              break;

          }
          break;
        case "<":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) - 1);
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                (parseInt(this.state.paginationDiverifikasi.current_page) -
                  1);
              break;

            case "Disetujui":
              link =
                "&page=" +
                (parseInt(this.state.paginationDisetujui.current_page) - 1);
              break;

            case "Ditolak":
              link =
                "&page=" +
                (parseInt(this.state.paginationDitolak.current_page) - 1);
              break;
            case "Selesai":
              link =
                "&page=" +
                (parseInt(this.state.paginationSelesai.current_page) - 1);
              break;
            default:
              // no default
              break;
          }
          break;
        case "<<":
          link = "&page=1";
          break;
        default:
          link = "&page=" + params.page;
          break;
      }
    }

    // Get data from db
    if(this.state.cek_posisi === "Pertama"){
      params.tab = "Diajukan"
    }
    else if(this.state.cek_posisi === "Kedua"){
      params.tab = "Diverifikasi"
    }
    else if(this.state.cek_posisi === "Ketiga"){
      params.tab = "Disetujui"
    }
    else if(this.state.cek_posisi === "Keempat"){
      params.tab = "Ditolak"
    }
    else if(this.state.cek_posisi === "Kelima"){
      params.tab = "Selesai"
    }

    // Get("pelayanan-external?jenis_layanan=Permohonan Pindah Datang&status=" + params.tab + link, null, (dtkat) => {
    GetEksternal("pelayanan/layanan-desa/"+ID_DESA+"/"+this.state.id_jenis_pelayanan+"?status=" + params.tab + link, null, (dtkat) => {
      //console.log("COba tampil bos")
      //console.log(dtkat)
      switch (params.tab) {
        case "Diajukan":
          this.setState({
            data: dtkat.results.data,
            pagination: dtkat.results
          });
          break;

        case "Diverifikasi":
          this.setState({
            dataVerifikasi: dtkat.results.data,
            paginationDiverifikasi: dtkat.results
          });
          break;
        
        case "Disetujui":
          this.setState({
            dataDisetujui: dtkat.results.data,
            paginationDisetujui: dtkat.results
          });
          break;
        case "Ditolak":
          this.setState({
            dataDitolak: dtkat.results.data,
            paginationDitolak: dtkat.results
          });
          break;
        case "Selesai":
          this.setState({
            dataSelesai: dtkat.results.data,
            paginationSelesai: dtkat.results
          });
          break;
        default:
          // no default
          break;
      }
      // //console.log(dtkat.results);
      this.pagination(dtkat.results, params.tab);
    });
  };

  // Pagination
  pagination = (data, tab) => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            key={i}
            onClick={() => this.fetch({ page: i, tab: tab })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }

      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }

    switch (tab) {
      case "Diajukan":
        this.setState({ awal, sebelum, hal, setelah, akhir });
        break;
      case "Diverifikasi":
        this.setState({
          awal2: awal,
          sebelum2: sebelum,
          hal2: hal,
          setelah2: setelah,
          akhir2: akhir
        });
        break;
      case "Disetujui":
        this.setState({
          awal3: awal,
          sebelum3: sebelum,
          hal3: hal,
          setelah3: setelah,
          akhir3: akhir
        });
        break;
      case "Ditolak":
        this.setState({
          awal4: awal,
          sebelum4: sebelum,
          hal4: hal,
          setelah4: setelah,
          akhir4: akhir
        });
        break;
      case "Selesai":
        this.setState({
          awal5: awal,
          sebelum5: sebelum,
          hal5: hal,
          setelah5: setelah,
          akhir5: akhir
        });
        break;
      default:
        // no default
        break;
    }
  };

  getChild = (e, self, parent, child) => {
		this.state.status.select = true;
		this.forceUpdate();
    this.changeSelectValue(e, self);
    let formDusun = this.state.frmDus

		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.forEach((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota':
						this.setState({ frmKbkt: frm, frmKec: [] });
						break;
					case 'kecamatan':
						this.setState({ frmKec: frm });
						break;
					case 'keldes':
						this.setState({ frmKel: frm });
						break;
					case 'dusun':
            if (this.state.id_kategori_penduduk.value === 1) {
              this.setState({ frmDus: frm });
            }
            else {
              this.setState({ frmDus: formDusun });
            }
            break;

					case 'rw':
						this.setState({ frmRw: frm });
						break;

					case 'rt':
						this.setState({ frmRt: frm });
						break;
          default:
            // no default
            break;
				}
				this.state.status.select = false;
				this.forceUpdate();
			});
		}
	};

  changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
          case 'keldes':
            let idDus = this.state.dusStat
            this.setState({ kelStat: e });
            if (this.state.id_kategori_penduduk.value === 2) {
              this.setState({ dusStat: idDus });
            }
            else {
              this.setState({ dusStat: null });
            }
  
            break;

				case 'dusun':
					this.setState({ dusStat: e });
					this.setState({ rwStat: null });

					break;

				case 'rw':
					this.setState({ rwStat: e });
					this.setState({ rtStat: null });

					break;

				case 'rt':
					this.state.dt.id_rt = e.value;
					this.setState({ rtStat: e });
					// //console.log(this.state.rtStat)
					break;
				
        // Data Yang DIpakai
        case "nik":
          this.state.dt.nik_pemilik = e.value;
          this.setState({ nikStat: e });
          break;
        case "nik2":
          this.state.dt.nik_pelapor = e.value;
          this.setState({ nik2Stat: e });
          break;
        case "jenis_npwp":
          this.state.dt.jenis_npwp = e;
          this.forceUpdate();
          break;
        case "bentuk_perusahaan":
          this.state.dt.bentuk_perusahaan = e;
          this.forceUpdate();
          break;
        case "jenis_usaha":
          this.state.dt.jenis_usaha = e;
          this.forceUpdate();
          break;
        case "kategori_omset":
          this.state.dt.kategori_omset = e;
          this.forceUpdate();
          break;
        case "kategori_aset":
          this.state.dt.kategori_aset = e
          this.forceUpdate();
          break;
        case "permodalan_sendiri":
          this.state.dt.permodalan_sendiri = e.target.value
          this.forceUpdate();
          break;
        case "permodalan_luar":
          this.state.dt.permodalan_luar =  e.target.value
          this.forceUpdate();
          break;
        case "nominal_omset":
          this.state.dt.nominal_omset =  e.target.value
          this.forceUpdate();
          break;
        case "nominal_aset":
          this.state.dt.nominal_aset =  e.target.value
          this.forceUpdate();
          break;
        case "sumber_bahan_baku":
          this.state.dt.sumber_bahan_baku = e;
          this.forceUpdate();
          break;
        case "pemasaran":
          this.state.dt.pemasaran = e;
          this.forceUpdate();
          break;
        case "status_tempat_usaha":
          this.state.dt.status_tempat_usaha = e;
          this.forceUpdate();
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        
        case 'keldes':
          this.state.dt.id_rt = 0;
          this.setState({
            kelStat: null,
            frmDus: [],
            dusStat: null,
            frmRw: [],
            rwStat: null,
            frmRt: [],
            rtStat: null
          });
					break;
				case 'dusun':
					this.state.dt.id_rt = 0;
					this.setState({ dusStat: null, frmrw: [], rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rw':
					this.state.dt.id_rt = 0;
					this.setState({ rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rt':
					this.state.dt.id_rt = 0;
					this.setState({ rtStat: null });
					break;
          // Data Yang DIpakai
        case "nik":
          this.state.dt.nik_pemilik = 0;
          this.setState({ nikStat: e });
          break;
        case "nik2":
          this.state.dt.nik_pelapor = 0;
          this.setState({ nik2Stat: e });
          break;
        case "jenis_npwp":
          this.state.dt.jenis_npwp = null;
          this.forceUpdate();
          break;
        case "bentuk_perusahaan":
          this.state.dt.bentuk_perusahaan = null;
          this.forceUpdate();
          break;
        case "jenis_usaha":
          this.state.dt.jenis_usaha = null;
          this.forceUpdate();
          break;
        case "kategori_omset":
          this.state.dt.kategori_omset = null;
          this.forceUpdate();
          break;
        case "kategori_aset":
          this.state.dt.kategori_aset = null;
          this.forceUpdate();
          break;
        case "permodalan_sendiri":
          this.state.dt.permodalan_sendiri = null
          this.forceUpdate();
          break;
        case "permodalan_luar":
          this.state.dt.permodalan_luar = null
          this.forceUpdate();
          break;
        case "nominal_omset":
          this.state.dt.nominal_omset =  null
          this.forceUpdate();
          break;
        case "nominal_aset":
          this.state.dt.nominal_aset =  null
          this.forceUpdate();
          break;
        case "sumber_bahan_baku":
          this.state.dt.sumber_bahan_baku = null;
          this.forceUpdate();
          break;
        case "pemasaran":
          this.state.dt.pemasaran = null;
          this.forceUpdate();
          break;
        case "status_tempat_usaha":
          this.state.dt.status_tempat_usaha = null;
          this.forceUpdate();
          break;
        default:
          // no default
          break;
			}
		}
		this.state.status.select = false;
		this.forceUpdate();
	};

  // ========================================================================

  // ====================== Hapus Data =============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("kesukuan/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Suku",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Suku",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ====================== Hapus Data =============================

  bukaForm = jenis => {
    this.state.status.form = true;
    this.state.perihal={
      izin_usaha: false,
      imb: false,
      siup: false,
      situ: false,
      tdp: false,
    };
    
    this.forceUpdate();

    this.setState({
      pendStat: null,
      kkStat: null,
      alasanPindah: null,
      // prvStat: null,
      // kbktStat: null,
      // kecStat: null,
      kldsStat: null,
      klasifikasiPindah: null,
      jenisPindah: null,
      statusTidakPindah: null,
      statusPindah: null,
      detail: []
    })

  };

  tutupForm = () => {
    this.state.status.formTambahVerifikasi = false;
    this.state.status.form = false;
    this.state.status.formDetail = false;

    this.state.dataPenduduk2 = "none";
    this.state.dataPenduduk = "none";
    
    this.state.showBio = false;
    this.state.dt = [];
    this.state.nikStat= null;
    this.state.nik2Stat= null;
    this.state.dusStat= null;
    this.state.rwStat= null;
    this.state.rtStat= null;
    
    this.state.dokumen_input= "";
    this.state.dokumen_output= "";

    this.state.linkGambar= listFile;
    // Checkbox
    this.state.sumber_bahan_baku= {
        lokal: false,
        luar_kota: false,
        impor: false
      };
    this.state.pemasaran= {
        lokal: false,
        regional: false,
        nasional: false,
        impor: false
      };
    this.state.legalitas_usaha= {
        siup: false,
        situ: false,
        tdp: false,
        npwp: false,
        domisili: false,
        halal: false,
        hak_paten: false,
        p_irt: false
      }
      this.state.status_tempat_usaha= {
        milik_sendiri: false,
        sewa: false
      };

    this.forceUpdate();
  };

  ubahData = data => {
    this.state.judul = 'Ubah Data Rekomendasi Izin Usaha Mikro Kecil';
    // console.log("Data Ubah Detail")
    // console.log(data)

    let detail = JSON.parse(data.data_pelayanan)
    // console.log("Detail Ubah Data Pelayanan")
    // console.log(detail)
    Get("rt/rw", detail.rw_usaha.value, dat => {
      let frmRt = [];
      dat.results.data.forEach((dt) => {
        frmRt.push({value:dt.id, label:dt.nama});
      });
      this.setState({frmRt});
    })

    Get("penduduk/find/" + data.nik_pelapor, null, dtl_pelapor => {
      if(dtl_pelapor.results){
        // console.log("Data Set Pelapor ubah")
        // console.log(dtl_pelapor.results)
        
        this.state.isUpdate = true
        this.state.nik2Select = true
        this.state.nik2Stat= {value: dtl_pelapor.results.id, label: dtl_pelapor.results.id}
        this.state.dt.nik_pelapor= dtl_pelapor.results.id;
        this.state.dt.nama_pelapor= dtl_pelapor.results.nama;
        this.state.dt.tempat_lahir_pelapor= dtl_pelapor.results.tempat_lahir;
        this.state.dt.tanggal_lahir_pelapor= dtl_pelapor.results.tanggal_lahir;
        this.state.dt.no_hp_pelapor= data.no_hp_pelapor;
        this.state.dt.alamat_pelapor= dtl_pelapor.results.alamat;
        this.state.dt.pekerjaan_pelapor= dtl_pelapor.results.data_pekerjaan.nama;
        this.state.dt.pendidikan_terakhir= dtl_pelapor.results.data_pendidikan_terakhir.nama;
        this.forceUpdate();
      } else {
        this.setState({ 
          isUpdate: true,
          nik2Select: false,
          nik2Stat: null,
          dt: {...this.state.dt, 
            nik_pelapor: data.nik_pelapor,
            nama_pelapor: data.nama_pelapor,
            alamat_pelapor: data.alamat_pelapor,
            pekerjaan_pelapor: data.pekerjaan_pelapor,
            no_hp_pelapor: data.no_hp_pelapor,
          }
        })
      }
      this.forceUpdate();
      //console.log(this.state.dt);
    });

    // Get("penduduk/find/" + detail.nik.value, null, dtl2 => {
    Get("penduduk/find/" + detail.nik, null, dtl2 => {
      if(dtl2.results){
        // console.log("Data Set Pemilik ubah")
        // console.log(dtl2.results)
  
        this.state.isUpdate = true
        this.state.nikSelect = true
        this.state.nikStat= {value: dtl2.results.id, label: dtl2.results.id}
        this.state.tanggal_lahir_pemilik= dtl2.results.tanggal_lahir
        this.state.dt.nama_pemilik= dtl2.results.nama;
        this.state.dt.tempat_lahir_pemilik= dtl2.results.tempat_lahir;
        this.state.dt.tanggal_lahir_pemilik= new Date(
          dtl2.results.tanggal_lahir
        ).toLocaleDateString("id-ID");
        this.state.dt.tempat_lahir_pemilik= dtl2.results.tempat_lahir;
        this.state.dt.jk_pemilik= dtl2.results.jk;
        this.state.dt.status_perkawinan_pemilik= dtl2.results.status_perkawinan;
        this.state.dt.agama_pemilik= dtl2.results.data_agama.nama;
        this.state.dt.pekerjaan_pemilik= dtl2.results.data_pekerjaan.nama;
        this.state.dt.alamat_pemilik= dtl2.results.alamat;
        this.state.dt.rt_pemilik= dtl2.results.data_kk.data_rt.nama;
        this.state.dt.rw_pemilik= dtl2.results.data_kk.data_rt.data_rw.nama;
        if(this.state.isKelurahan){
          this.state.dt.dusun_pemilik= dtl2.results.data_kk.data_rt.data_rw.nama;
          this.state.dt.kelurahan_pemilik= dtl2.results.data_kk.data_rt.data_rw.data_keldes.nama;
          this.state.dt.kecamatan_pemilik= dtl2.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama;
          this.state.dt.kabkota_pemilik= dtl2.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
        }
        else{
          this.state.dt.dusun_pemilik= dtl2.results.data_kk.data_rt.data_rw.data_dusun.nama;
          this.state.dt.kelurahan_pemilik= dtl2.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama;
          this.state.dt.kecamatan_pemilik= dtl2.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama;
          this.state.dt.kabkota_pemilik= dtl2.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;
        }
        this.state.dt.pendidikan_terakhir_pemilik= dtl2.results.data_pendidikan_terakhir.nama;
        this.state.rwStat = detail.rw_usaha
        this.state.rtStat = detail.rt_usaha
        this.state.dt.email_pemilik = detail.email
        this.state.dt.kode_pos = detail.kode_pos
        this.state.dt.telp_pemilik = detail.telp
      } else {
        this.setState({ 
          isUpdate: true, 
          nikSelect: false, 
          nikStat: null,
          rwStat: detail.rw_usaha,
          rtStat: detail.rt_usaha,
          jenisKelaminStat: {value: detail.jenis_kelamin, label: detail.jenis_kelamin},
          dt: {...this.state.dt, 
            nik_pemilik: detail.nik,
            nama_pemilik: detail.nama_pemilik,
            email_pemilik: detail.email,
            jk_pemilik: detail.jenis_kelamin,
            alamat_pemilik: detail.alamat,
            kabkota_pemilik: detail.kota,
            kecamatan_pemilik: detail.kecamatan,
            kelurahan_pemilik: detail.kelurahan,
            rw_pemilik: detail.rw,
            rt_pemilik: detail.rt,
            kode_pos: detail.kode_pos,
            telp_pemilik: detail.telp,
            pendidikan_terakhir_pemilik: detail.pendidikan_terakhir,
          }
        })
      }
      this.forceUpdate();
    });

    // Wilayah
    Get('keldes/find', ID_DESA , (wil) => {
      if(wil.results){
        //console.log("Data Dusuh Lokal Ubah Data")
        //console.log(wil.results)
        this.state.kecStat = {
          value: wil.results.data_kecamatan.id,
          label: wil.results.data_kecamatan.nama
        }
        this.state.kbktStat = {
          value: wil.results.data_kecamatan.data_kabkota.id,
          label: wil.results.data_kecamatan.data_kabkota.nama,
        }
      }
      this.forceUpdate();
    });

    let dokumen_input = data.dokumen_input
    let dokumen_output = data.dokumen_output
    if(data.dokumen_input==="" || data.dokumen_input===null){
      dokumen_input = "Kosong"
    }
    
    if(data.dokumen_output==="" || data.dokumen_output===null){
      dokumen_output = "Kosong"
    }


    this.state.uuid_surat= data.uuid
    this.state.dokumen_input= dokumen_input;
    this.state.dokumen_output= dokumen_output;
    this.state.dataPenduduk= 'block'
    this.state.dataPenduduk2= 'block'
    // this.state.dusStat= {value: detail.dusun_usaha.value, label: detail.dusun_usaha.label}
    // this.state.rwStat= {value: detail.rw_usaha.value, label: detail.rw_usaha.label}
    // this.state.rtStat= {value: detail.rt_usaha.value, label: detail.rt_usaha.label}
    // this.state.dt={
      // this.state.dt.no_pendaftaran= detail.no_pendaftaran;
      // this.state.dt.kode_pos= detail.kode_pos;
      this.state.dt.telp= detail.telp;
      this.state.dt.email= detail.email;
      this.state.dt.npwp= detail.npwp;
      this.state.dt.jenis_npwp={
        value: detail.jenis_npwp.value,
        label: detail.jenis_npwp.label,
      };
      this.state.dt.nama_usaha= detail.nama_usaha;
      this.state.dt.alamat_usaha= detail.alamat_usaha;
      this.state.dt.kode_pos_usaha= detail.kode_pos_usaha;
      this.state.dt.telp_usaha= detail.telp_usaha;
      this.state.dt.tahun_berdiri= detail.tahun_berdiri;
      this.state.dt.bentuk_perusahaan = detail.bentuk_perusahaan
      this.state.dt.jenis_usaha={
        value: detail.jenis_usaha.value,
        label: detail.jenis_usaha.label,
      };
      this.state.dt.uraian_usaha= detail.uraian_usaha;
      this.state.dt.sarana_usaha= detail.sarana_usaha;
      this.state.dt.jumlah_karyawan= detail.jumlah_karyawan;
      this.state.dt.permodalan_sendiri= detail.permodalan_sendiri.replace(/[^\d]/g, '');
      this.state.dt.permodalan_luar= detail.permodalan_luar.replace(/[^\d]/g, '');
      this.state.dt.kategori_omset={
        value: detail.omset.kategori_omset.value,
        label: detail.omset.kategori_omset.label,
      };
      this.state.dt.nominal_omset= detail.omset.nominal_omset;
      this.state.dt.kategori_aset={
        value: detail.aset.kategori_aset.value,
        label: detail.aset.kategori_aset.label,
      };
      this.state.dt.nominal_aset= detail.aset.nominal_aset;
    // }

    this.state.dt.sumber_bahan_baku= detail.sumber_bahan_baku;
    this.state.dt.pemasaran= detail.pemasaran;
    this.state.dt.status_tempat_usaha= detail.status_tempat_usaha;

    // this.state.dusStat= {value: detail.dusun_usaha.value, label: detail.dusun_usaha.label}
    // this.state.rwStat= {value: detail.rw_usaha.value, label: detail.rw_usaha.label}
    // this.state.rtStat= {value: detail.rt_usaha.value, label: detail.rt_usaha.label}
    
    this.state.dt.dusun_usaha = detail.dusun_usaha
    this.state.dt.rw_usaha = detail.rw_usaha
    this.state.dt.rt_usaha = detail.rt_usaha
    
    let dusStat = detail.dusun_usaha
    let rwStat = detail.rw_usaha
    let rtStat = detail.rt_usaha
    let dusSelect = true

    // Jika input dusun manual
    if (detail.dusun_usaha.value === detail.dusun_usaha.label) {
      dusSelect = false
      dusStat = null
      rwStat = null
      rtStat = null
    }

    this.setState({ dusSelect, dusStat, rwStat, rtStat })

    // this.state.sumber_bahan_baku={
    //   lokal: detail.sumber_bahan_baku.lokal,
    //   luar_kota: detail.sumber_bahan_baku.luar_kota,
    //   impor: detail.sumber_bahan_baku.impor,
    // }
    // this.state.pemasaran= {
    //   lokal: detail.pemasaran.lokal,
    //   regional: detail.pemasaran.regional,
    //   nasional: detail.pemasaran.nasional,
    //   impor: detail.pemasaran.impor,
    // }
    this.state.legalitas_usaha= {
      siup: detail.legalitas_usaha.siup,
      situ: detail.legalitas_usaha.situ,
      tdp: detail.legalitas_usaha.tdp,
      npwp: detail.legalitas_usaha.npwp,
      domisili: detail.legalitas_usaha.domisili,
      halal: detail.legalitas_usaha.halal,
      hak_paten: detail.legalitas_usaha.hak_paten,
      p_irt: detail.legalitas_usaha.p_irt,
    }

    let linkGambar = {}
    Object.keys(detail.linkGambar).forEach((attribute) => {
      let type = detail.linkGambar[attribute].split('.')
      linkGambar[attribute] = {
        link: detail.linkGambar[attribute],
        type: type[type.length - 1],
      }
    })

    // this.state.linkGambar = detail.linkGambar ? detail.linkGambar : listFile
    this.state.linkGambar = linkGambar
    // this.state.status_tempat_usaha= {
    //   milik_sendiri: detail.status_tempat_usaha.milik_sendiri,
    //   sewa: detail.status_tempat_usaha.sewa,
    // }

    this.state.status.form = true;
    this.forceUpdate();
    
};


  detailData = data => {
    this.state.judul = 'Detail Data Rekomendasi Izin Usaha Mikro Kecil';
    //console.log("Data Ubah Detail")
    //console.log(data)

    let detail = JSON.parse(data.data_pelayanan)
    //console.log("Detail Ubah Data Pelayanan")
    //console.log(detail)
    this.setState({ detailSurat: data, dataPelayanan: detail })

    Get("penduduk/find/" + data.nik_pelapor, null, dtl_pelapor => {
      if(dtl_pelapor.results){
        //console.log("Data Set Pelapor ubah")
        //console.log(dtl_pelapor.results)
        
        this.state.isUpdate = false
        this.state.nik2Stat= {value: dtl_pelapor.results.id, label: dtl_pelapor.results.id}
        this.state.dt.nik2Stat= dtl_pelapor.results.id
        this.state.dt.nama_pelapor= dtl_pelapor.results.nama;
        this.state.dt.tempat_lahir_pelapor= dtl_pelapor.results.tempat_lahir;
        this.state.dt.tanggal_lahir_pelapor= dtl_pelapor.results.tanggal_lahir;
        this.state.dt.no_hp_pelapor= data.no_hp_pelapor;
        this.state.dt.alamat_pelapor= dtl_pelapor.results.alamat;
        this.state.dt.pekerjaan_pelapor= dtl_pelapor.results.data_pekerjaan.nama;
        this.state.dt.pendidikan_terakhir= dtl_pelapor.results.data_pendidikan_terakhir.nama;
        this.forceUpdate();
      } else {
        this.setState({ 
          isUpdate: false,
          nik2Select: false,
          nik2Stat: null,
          dt: {...this.state.dt, 
            nik_pelapor: data.nik_pelapor,
            nama_pelapor: data.nama_pelapor,
            alamat_pelapor: data.alamat_pelapor,
            pekerjaan_pelapor: data.pekerjaan_pelapor,
            no_hp_pelapor: data.no_hp_pelapor,
          }
        })
      }
      this.forceUpdate();
      //console.log(this.state.dt);
    });

    Get("penduduk/find/" + detail.nik.value, null, dtl2 => {
      if(dtl2.results){
        //console.log("Data Set Pemilik ubah")
        //console.log(dtl2.results)
  
        this.state.isUpdate = false
        this.state.nikStat= {value: dtl2.results.id, label: dtl2.results.id}
        this.state.dt.nikStat= dtl2.results.id
        this.state.tanggal_lahir_pemilik= dtl2.results.tanggal_lahir
        this.state.dt.nama_pemilik= dtl2.results.nama;
        this.state.dt.tempat_lahir_pemilik= dtl2.results.tempat_lahir;
        this.state.dt.tanggal_lahir_pemilik= new Date(
          dtl2.results.tanggal_lahir
        ).toLocaleDateString("id-ID");
        this.state.dt.tempat_lahir_pemilik= dtl2.results.tempat_lahir;
        this.state.dt.jk_pemilik= dtl2.results.jk;
        this.state.dt.status_perkawinan_pemilik= dtl2.results.status_perkawinan;
        this.state.dt.agama_pemilik= dtl2.results.data_agama.nama;
        this.state.dt.pekerjaan_pemilik= dtl2.results.data_pekerjaan.nama;
        this.state.dt.alamat_pemilik= dtl2;
        this.state.dt.rt_pemilik= dtl2.results.data_kk.data_rt.nama;
        this.state.dt.rw_pemilik= dtl2.results.data_kk.data_rt.data_rw.nama;
        this.state.dt.dusun_pemilik= dtl2.results.data_kk.data_rt.data_rw.data_dusun.nama;
        this.state.dt.kelurahan_pemilik= dtl2.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama;
        this.state.dt.kecamatan_pemilik= dtl2.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama;
        this.state.dt.kabkota_pemilik= dtl2.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;
        this.state.dt.pendidikan_terakhir_pemilik= dtl2.results.data_pendidikan_terakhir.nama;
        // this.state.dt= {
        //     nama_pemilik: dtl2.results.nama,
        //     tempat_lahir_pemilik: dtl2.results.tempat_lahir,
        //     tanggal_lahir_pemilik: new Date(
        //       dtl2.results.tanggal_lahir
        //     ).toLocaleDateString("id-ID"),
        //     tempat_lahir_pemilik: dtl2.results.tempat_lahir,
        //     jk_pemilik: dtl2.results.jk,
        //     status_perkawinan_pemilik: dtl2.results.status_perkawinan,
        //     agama_pemilik: dtl2.results.data_agama.nama,
        //     pekerjaan_pemilik: dtl2.results.data_pekerjaan.nama,
        //     alamat_pemilik: dtl2,
        //     rt_pemilik: dtl2.results.data_kk.data_rt.nama,
        //     rw_pemilik: dtl2.results.data_kk.data_rt.data_rw.nama,
        //     dusun_pemilik: dtl2.results.data_kk.data_rt.data_rw.data_dusun.nama,
        //     kelurahan_pemilik: dtl2.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama,
        //     kecamatan_pemilik: dtl2.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama,
        //     kabkota_pemilik: dtl2.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama,
        //     pendidikan_terakhir_pemilik: dtl2.results.data_pendidikan_terakhir.nama,
        // }
      } else {
        this.setState({ 
          isUpdate: false, 
          nikSelect: false, 
          nikStat: null,
          dt: {...this.state.dt, 
            nik_pemilik: detail.nik,
            nama_pemilik: detail.nama_pemilik,
            email_pemilik: detail.email,
            jk_pemilik: detail.jenis_kelamin,
            alamat_pemilik: detail.alamat,
            kabkota_pemilik: detail.kota,
            kecamatan_pemilik: detail.kecamatan,
            kelurahan_pemilik: detail.kelurahan,
            rw_pemilik: detail.rw,
            rt_pemilik: detail.rt,
            kode_pos: detail.kode_pos,
            telp_pemilik: detail.telp,
            pendidikan_terakhir_pemilik: detail.pendidikan_terakhir,
          }
        })
      }
      this.forceUpdate();
    });

    // Wilayah
    Get('keldes/find', ID_DESA , (wil) => {
      if(wil.results){
        //console.log("Data Dusuh Lokal Ubah Data")
        //console.log(wil.results)
        this.state.kecStat = {
          value: wil.results.data_kecamatan.id,
          label: wil.results.data_kecamatan.nama
        }
        this.state.kbktStat = {
          value: wil.results.data_kecamatan.data_kabkota.id,
          label: wil.results.data_kecamatan.data_kabkota.nama,
        }
        this.state.dt.kecStat = wil.results.data_kecamatan.nama
        this.state.dt.kbktStat = wil.results.data_kecamatan.data_kabkota.nama
      }
      this.forceUpdate();
    });

    let dokumen_input = data.dokumen_input
    let dokumen_output = data.dokumen_output
    if(data.dokumen_input==="" || data.dokumen_input===null){
      dokumen_input = "Kosong"
    }
    
    if(data.dokumen_output==="" || data.dokumen_output===null){
      dokumen_output = "Kosong"
    }


    this.state.uuid_surat= data.uuid
    this.state.dokumen_input= dokumen_input;
    this.state.dokumen_output= dokumen_output;
    this.state.dataPenduduk= 'block'
    this.state.dataPenduduk2= 'block'
    this.state.dusStat= {value: detail.dusun_usaha, label: detail.dusun_usaha}
    this.state.rwStat= {value: detail.rw_usaha, label: detail.rw_usaha}
    this.state.rtStat= {value: detail.rt_usaha, label: detail.rt_usaha}
    this.state.dt.dusun_usaha= detail.dusun_usaha
    this.state.dt.rw_usaha= detail.rw_usaha
    this.state.dt.rt_usaha= detail.rt_usaha
    // this.state.dt={
      // this.state.dt.no_pendaftaran= detail.no_pendaftaran;
      this.state.dt.kode_pos= detail.kode_pos;
      this.state.dt.telp= detail.telp;
      this.state.dt.email= detail.email;
      this.state.dt.npwp= detail.npwp;
      this.state.dt.jenis_npwp={
        value: detail.jenis_npwp.value,
        label: detail.jenis_npwp.label,
      };
      this.state.dt.jenis_npwp2= detail.jenis_npwp.label;
      this.state.dt.nama_usaha= detail.nama_usaha;
      this.state.dt.alamat_usaha= detail.alamat_usaha;
      this.state.dt.kode_pos_usaha= detail.kode_pos_usaha;
      this.state.dt.telp_usaha= detail.telp_usaha;
      this.state.dt.tahun_berdiri= detail.tahun_berdiri;
      this.state.dt.bentuk_perusahaan = detail.bentuk_perusahaan
      this.state.dt.bentuk_perusahaan2 = detail.bentuk_perusahaan.label
      this.state.dt.jenis_usaha={
        value: detail.jenis_usaha.value,
        label: detail.jenis_usaha.label,
      };
      this.state.dt.jenis_usaha2= detail.jenis_usaha.label;
      this.state.dt.uraian_usaha= detail.uraian_usaha;
      this.state.dt.sarana_usaha= detail.sarana_usaha;
      this.state.dt.jumlah_karyawan= detail.jumlah_karyawan;
      this.state.dt.permodalan_sendiri= detail.permodalan_sendiri.replace(/[^\d]/g, '');
      this.state.dt.permodalan_luar= detail.permodalan_luar.replace(/[^\d]/g, '');
      this.state.dt.kategori_omset={
        value: detail.omset.kategori_omset.value,
        label: detail.omset.kategori_omset.label,
      };
      this.state.dt.kategori_omset2= detail.omset.kategori_omset.label;
      this.state.dt.nominal_omset= detail.omset.nominal_omset;
      this.state.dt.kategori_aset={
        value: detail.aset.kategori_aset.value,
        label: detail.aset.kategori_aset.label,
      };
      this.state.dt.kategori_aset2= detail.aset.kategori_aset.label;
      this.state.dt.nominal_aset= detail.aset.nominal_aset;
    // }

    this.state.dt.sumber_bahan_baku = detail.sumber_bahan_baku.label;
    // this.state.sumber_bahan_baku={
    //   lokal: detail.sumber_bahan_baku.lokal,
    //   luar_kota: detail.sumber_bahan_baku.luar_kota,
    //   impor: detail.sumber_bahan_baku.impor,
    // }
    this.state.dt.pemasaran= detail.pemasaran.label;
    // this.state.pemasaran= {
    //   lokal: detail.pemasaran.lokal,
    //   regional: detail.pemasaran.regional,
    //   nasional: detail.pemasaran.nasional,
    //   impor: detail.pemasaran.impor,
    // }
    this.state.legalitas_usaha= {
      siup: detail.legalitas_usaha.siup,
      situ: detail.legalitas_usaha.situ,
      tdp: detail.legalitas_usaha.tdp,
      npwp: detail.legalitas_usaha.npwp,
      domisili: detail.legalitas_usaha.domisili,
      halal: detail.legalitas_usaha.halal,
      hak_paten: detail.legalitas_usaha.hak_paten,
      p_irt: detail.legalitas_usaha.p_irt,
    }
    this.state.dt.status_tempat_usaha= detail.status_tempat_usaha.label
    // this.state.status_tempat_usaha= {
    //   milik_sendiri: detail.status_tempat_usaha.milik_sendiri,
    //   sewa: detail.status_tempat_usaha.sewa,
    // }

    this.state.status.formDetail = true;
    this.forceUpdate();
};

  tombolAksi = (cell, row) => {
    let btnAksi = false
    // if (row.data_status[row.data_status.length - 1].status === 'Selesai') {
    //   btnAksi = true
    // }

    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>
      </>
    )
  }

  tombolAksiVerifikasi = (cell, row) => {
    return (
      <>
      {this.state.cek_posisi==="Pertama"
        ?
        <div>
            <button onClick={() => this.ubahData(row)} color="info" className="btn btn-success"><i className="fa fa-pencil"></i> Ubah</button>{"  "}
            <button onClick={() => this.detailData(row)} color="info" className="btn btn-secondary-custom"><i className="fa fa-eye"></i> Detail</button>
        </div>
        :this.state.cek_posisi==="Kedua"
        ?
        <div>
            <button onClick={() => this.detailData(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i> Detail</button>
        </div>
         :this.state.cek_posisi==="Ketiga"
         ?
         <div>
             <button onClick={() => this.detailData(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i> Detail</button>
         </div>
         :this.state.cek_posisi==="Keempat"
         ?
         <div>
             <button onClick={() => this.detailData(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i>  Detail</button>
         </div>
         
         :this.state.cek_posisi==="Kelima"
         ?
         <div>
             <button onClick={() => this.detailData(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i>  Detail</button>
             {/* <button onClick={() => this.downloadDokumen(row)} color="info" className="btn btn-info"><i className="fa fa-download"></i>  Download</button> */}
         </div>
         :
         <>
         </>
      }
     
    </>
    )
  }

  componentDidMount() {
    if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			this.setState({ isKelurahan: true });

      Get('rw/kel/'+ID_DESA+'/list', null, (data) => {
          // console.log('list rw didmount')
          // console.log(data)
          let frmRw = [];
          data.results.forEach((dt) => {
              frmRw.push({value:dt.id, label:dt.nama});
          });
          this.setState({frmRw});
      })
    }
    else{
      Get('dusun/kel/' + ID_DESA + '/list', null, (data) => {
        if(data.results){
          // //console.log("Data Dusuh Lokal")
          // //console.log(data.results)
          let frm = [];
          data.results.forEach((dt) => {
            frm.push({ value: dt.id, label: dt.nama });
          });
          this.setState({ frmDus: frm });
          this.forceUpdate();
        }
      });
    }
    Get("penduduk", null, data => {
      if(data.results){
        let frmNik = [];
        let frmNik2 = [];
        data.results.data.forEach(dt => {
          frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          frmNik2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
        });
        this.setState({ frmNik, frmNik2, });
      }
    });

    Get('keldes/find', ID_DESA , (wil) => {
      if(wil.results){
        //console.log("Data Dusuh Lokal Ubah Data")
        //console.log(wil.results)
        this.state.kecStat = {
          value: wil.results.data_kecamatan.id,
          label: wil.results.data_kecamatan.nama
        }
        this.state.kbktStat = {
          value: wil.results.data_kecamatan.data_kabkota.id,
          label: wil.results.data_kecamatan.data_kabkota.nama,
        }
      }
      this.forceUpdate();
    });
      
    
    if (typeof this.props.location.tabProps !== "undefined") {
      this.state.active_tab_icon = this.props.location.tabProps;
    }
    if (typeof this.props.location.state !== "undefined") {
      this.state.active_tab_icon = this.props.location.state.tab;

    }

    let tabnya = ""
    if(this.state.cek_posisi==="Pertama"){
      tabnya="Diajukan"
      this.setState({
        active_tab_icon: '1',
      })
    }
    else if(this.state.cek_posisi==="Kedua"){
      tabnya="Diverifikasi"
      this.setState({
        active_tab_icon: '2',
      })
    }
    else if(this.state.cek_posisi==="Ketiga"){
      tabnya="Disetujui"
      this.setState({
        active_tab_icon: '3',
      })
    }
    else if(this.state.cek_posisi==="Keempat"){
      tabnya="Ditolak"
      this.setState({
        active_tab_icon: '4',
      })
    }
    else if(this.state.cek_posisi==="Kelima"){
      tabnya="Selesai"
      this.setState({
        active_tab_icon: '5',
      })
    }
    // Fetching data diajukan
    this.fetch({ page: null, where: [], tab: tabnya });

    
  
  this.forceUpdate();

  }

  simpan = (e) => {
    e.preventDefault()

    // validasi data
    let kode_pos = document.getElementById("kode_pos").value;
    let npwp = document.getElementById("npwp").value;
    let nama_usaha = document.getElementById("nama_usaha").value;
    let alamat_usaha = document.getElementById("alamat_usaha").value;
    let kode_pos_usaha = document.getElementById("kode_pos_usaha").value;
    let telp_usaha = document.getElementById("telp_usaha").value;
    let tahun_berdiri = document.getElementById("tahun_berdiri").value;
    let uraian_usaha = document.getElementById("uraian_usaha").value;
    let sarana_usaha = document.getElementById("sarana_usaha").value;
    let jumlah_karyawan = document.getElementById("jumlah_karyawan").value;
    let dokumen_input = this.state.dokumen_input
    let dokumen_output = this.state.dokumen_output

    let checkNikStat = true
    if (this.state.nikSelect) {
      if (this.state.nikStat) {
        checkNikStat = true
      } else {
        checkNikStat = false
      }
    } 
    
    let checkNik2Stat = true
    if (this.state.nik2Select) {
      if (this.state.nik2Stat) {
        checkNik2Stat = true
      } else {
        checkNik2Stat = false
      }
    }

    let checkDusStat = true
    let checkRwStat = true
    let checkRtStat = true
    if(this.state.isKelurahan){
      checkDusStat = true
      // RW
      if (this.state.rwStat) {
        checkRwStat = true
      } else {
        checkRwStat = false
      }
      // RT
      if (this.state.rtStat) {
        checkRtStat = true
      } else {
        checkRtStat = false
      }
    }
    else{
      if (this.state.dusSelect) {
        // Dusun
        if (this.state.dusStat) {
          checkDusStat = true
        } else {
          checkDusStat = false
        }
        // RW
        if (this.state.rwStat) {
          checkRwStat = true
        } else {
          checkRwStat = false
        }
        // RT
        if (this.state.rtStat) {
          checkRtStat = true
        } else {
          checkRtStat = false
        }
      }
    }

    if (
      checkNikStat &&
      checkNik2Stat &&
      this.state.dt.jenis_npwp&&
      checkDusStat&&
      checkRwStat&&
      checkRtStat&&
      this.state.dt.bentuk_perusahaan&&
      this.state.dt.jenis_usaha&&
      this.state.dt.kategori_omset&&
      this.state.dt.kategori_aset&&
      this.state.dt.permodalan_sendiri&&
      this.state.dt.permodalan_luar&&
      this.state.dt.nominal_omset&&
      this.state.dt.nominal_aset&&
      this.state.dt.sumber_bahan_baku&&
      this.state.dt.pemasaran&&
      this.state.dt.status_tempat_usaha&&
      npwp &&
      nama_usaha &&
      alamat_usaha &&
      kode_pos_usaha &&
      telp_usaha &&
      tahun_berdiri &&
      uraian_usaha &&
      sarana_usaha &&
      jumlah_karyawan
    ) {
      
      // Cek persyaratan
      let emptyGambar = false
			Object.keys(this.state.linkGambar).forEach((attribute) => {
				if(this.state.linkGambar[attribute].link  === ''){
					emptyGambar = true
				}
			})

      if(
        this.state.legalitas_usaha.siup === false &&
        this.state.legalitas_usaha.situ === false &&
        this.state.legalitas_usaha.tdp === false &&
        this.state.legalitas_usaha.npwp === false &&
        this.state.legalitas_usaha.domisili === false &&
        this.state.legalitas_usaha.halal === false &&
        this.state.legalitas_usaha.hak_paten === false &&
        this.state.legalitas_usaha.p_irt === false
      )
        {
          this.setState({
            show: true,
            basicType: 'warning',
            basicTitle: 'Data Rekomendasi Usaha Mikro Kecil',
            pesanAlert: 'Pilih Legalitas Usaha Terlebih Dahulu'
          });
      }
      else if(
        kode_pos.length > 5 ||
        kode_pos_usaha.length > 5
      )
        {
          this.setState({
            show: true,
            basicType: 'warning',
            basicTitle: 'Data Rekomendasi Usaha Mikro Kecil',
            pesanAlert: 'Jumlah digit kode pos tidak boleh lebih dari 5 angka'
          });
      } else if (emptyGambar) {
        this.setState({
          show: true,
          basicType: 'warning',
          basicTitle: 'Data Rekomendasi Usaha Mikro Kecil',
          pesanAlert: 'Upload File Persyaratan Terlebih Dahulu'
        });
      }
      else{
        //console.log("Data Berhasil Disimpan")
        //console.log(this.state.dt)

        let uuid = this.state.uuid_surat

        let legalitas_usaha = {
          siup: this.state.legalitas_usaha.siup,
          situ: this.state.legalitas_usaha.situ,
          tdp: this.state.legalitas_usaha.tdp,
          npwp: this.state.legalitas_usaha.npwp,
          domisili: this.state.legalitas_usaha.domisili,
          halal: this.state.legalitas_usaha.halal,
          hak_paten: this.state.legalitas_usaha.hak_paten,
          p_irt:this.state.legalitas_usaha.p_irt,
        }

        let set_modal_sendiri = this.state.dt.permodalan_sendiri.replace(/[^\d]/g, '')
        let set_modal_luar = this.state.dt.permodalan_luar.replace(/[^\d]/g, '')

        let dusun_usaha = ''
        let rw_usaha = ''
        let rt_usaha = ''

        if(this.state.isKelurahan){
          rw_usaha = this.state.rwStat
          rt_usaha = this.state.rtStat
        }
        else{
          dusun_usaha = this.state.dusStat
          rw_usaha = this.state.rwStat
          rt_usaha = this.state.rtStat
          if (!this.state.dusSelect) {
            dusun_usaha = { 
              value: document.getElementById("dusun_usaha").value,
              label: document.getElementById("dusun_usaha").value,
            }
            rw_usaha = { 
              value: document.getElementById("rw_usaha").value,
              label: document.getElementById("rw_usaha").value,
            }
            rt_usaha = { 
              value: document.getElementById("rt_usaha").value,
              label: document.getElementById("rt_usaha").value,
            }
          }
        }

        // Data Pelapor
        let nik_pelapor = ''
        if (this.state.nik2Select) {
          nik_pelapor = this.state.nik2Stat.value
        } else {
          nik_pelapor = document.getElementById("nik_pelapor").value
        }
        let nama_pelapor = document.getElementById("nama_pelapor").value;
        let pekerjaan_pelapor = document.getElementById("pekerjaan_pelapor").value;
        let alamat_pelapor = document.getElementById("alamat_pelapor").value;
        let no_hp_pelapor = document.getElementById("no_hp_pelapor").value;

        // Data Pemilik
        let nik_pemilik = ''
        if (this.state.nikSelect) {
          nik_pemilik = this.state.nikStat.value
        } else {
          nik_pemilik = document.getElementById("nik_pemilik").value
        }
        let nama_pemilik = document.getElementById("nama_pemilik").value;
        let jk_pemilik = this.state.nikSelect?document.getElementById("jk_pemilik").value:this.state.jenisKelaminStat.label;
        let pendidikan_terakhir_pemilik = document.getElementById("pendidikan_terakhir_pemilik").value;
        let alamat_pemilik = document.getElementById("alamat_pemilik").value;
        let kabkota_pemilik = document.getElementById("kabkota_pemilik").value;
        let kecamatan_pemilik = document.getElementById("kecamatan_pemilik").value;
        let kelurahan_pemilik = document.getElementById("kelurahan_pemilik").value;
        let rw_pemilik = document.getElementById("rw_pemilik").value;
        let rt_pemilik = document.getElementById("rt_pemilik").value;
        // let kode_pos = document.getElementById("kode_pos").value;
        let email = document.getElementById("email_pemilik").value;
        let telp = document.getElementById("telp_pemilik").value;

        let linkGambar = {};
        Object.keys(this.state.linkGambar).forEach((attribute) => {
          linkGambar[attribute] = this.state.linkGambar[attribute].link
        })

        let pelayanan = {
          tgl_surat:"",
          no_surat:"",
          // no_pendaftaran: document.getElementById("no_pendaftaran").value,
          nama_pemohon: nama_pelapor,
          nik: nik_pemilik,
          nama_pemilik,
          jenis_kelamin: jk_pemilik,
          pendidikan_terakhir: pendidikan_terakhir_pemilik,
          alamat: alamat_pemilik,
          kota: kabkota_pemilik,
          kecamatan: kecamatan_pemilik,
          kelurahan: kelurahan_pemilik,
          rw: rw_pemilik,
          rt: rt_pemilik,
          kode_pos,
          email,
          telp,

          npwp: npwp,
          jenis_npwp: this.state.dt.jenis_npwp,
          nama_usaha: nama_usaha,
          alamat_usaha: alamat_usaha,
          // dusun_usaha: this.state.dusStat.label,
          dusun_usaha,
          // rt_usaha: this.state.rtStat.label,
          rt_usaha,
          // rw_usaha: this.state.rwStat.label,
          rw_usaha,
          telp_usaha: telp_usaha,
          kelurahan_usaha: this.state.kelStat.label,
          kecamatan_usaha: this.state.kecStat.label,
          kota_usaha: this.state.kbktStat.label,
          kode_pos_usaha: kode_pos_usaha,
          tahun_berdiri: tahun_berdiri,
          bentuk_perusahaan: this.state.dt.bentuk_perusahaan,
          jenis_usaha: this.state.dt.jenis_usaha,
          uraian_usaha: uraian_usaha,
          sarana_usaha: sarana_usaha,
          jumlah_karyawan: jumlah_karyawan,
          permodalan_sendiri: set_modal_sendiri,
          permodalan_luar: set_modal_luar,
          omset: {
            kategori_omset: this.state.dt.kategori_omset,
            nominal_omset: this.state.dt.nominal_omset
          },
          aset: {
            kategori_aset: this.state.dt.kategori_aset,
            nominal_aset: this.state.dt.nominal_aset
          },
          sumber_bahan_baku: this.state.dt.sumber_bahan_baku,
          pemasaran: this.state.dt.pemasaran,
          legalitas_usaha: legalitas_usaha,
          status_tempat_usaha: this.state.dt.status_tempat_usaha,

          // linkGambar: this.state.linkGambar,
          linkGambar,
          id_desa: {
            value: ID_DESA,
            label: DESA,
          }
        }

        let bodyRaw = { 
          id_desa: ID_DESA,
          nik_pelapor,
          nama_pelapor,
          pekerjaan_pelapor,
          alamat_pelapor,
          no_hp_pelapor,
          tgl_melapor: dateFormat(this.state.tgl_melapor, 'yyyy-mm-dd'),
          data_pelayanan: JSON.stringify(pelayanan),
          dokumen_input: dokumen_input,
          dokumen_output: dokumen_output,
          uuid_surat_desa: uuid,
          jenis_layanan: "IUMK",
          uuid_pelayanan_instansi: this.state.id_jenis_pelayanan,
        }

        //console.log("Data Simpannya berikut:")
        //console.log(bodyRaw)

        let psn = "";
        let resstat = 204;
        let metode = "create";
        if (uuid === "" || uuid === null) {
          psn = "Tambah";
          resstat = 201;
          bodyRaw.uuid = null;
        } else {
          psn = "Ubah";
          resstat = 200;
          metode = "update";
        }
  
        PostEksternal(
          "pelayanan/" + metode,
          uuid,
          bodyRaw,
          res => {
            this.tutupForm();
            this.state.status.btnForm = false;
            this.state.status.form = false;
            this.forceUpdate();

            //console.log("Respon Simpan")
            //console.log(res)
            if (res.status === resstat) {
              this.setState({
                showBio: false,
                show: true,
                basicType: "success",
                basicTitle: "Data Rekomendasi Usaha Mikro Kecil",
                pesanAlert: "Berhasil " + psn + " Rekomendasi Usaha Mikro Kecil",
              });
              this.componentDidMount();
            } else {
              this.setState({
                showBio: false,
                show: true,
                basicType: "danger",
                basicTitle: "Data Rekomendasi Usaha Mikro Kecil",
                pesanAlert: "Gagal " + psn + " Rekomendasi Usaha Mikro Kecil"
              });
            }
            // this.componentDidMount();
          }
        );
      }

    } else {
      this.setState({
        show: true,
        basicType: 'warning',
        basicTitle: 'Data Rekomendasi Usaha Mikro Kecil',
        pesanAlert: 'Isi seluruh data dengan lengkap terlebih dahulu.'
      });
    }

  }

  tambahKeterangan = (e) => {
		e.preventDefault();
      this.forceUpdate();
      let id = this.state.data_detail.uuid;
      let addData = {
          status: "Ditolak",
          keterangan: document.getElementById("keterangan_tolak").value,
      };

          let psn = "Tolak";
          let resstat = 200;

          //console.log("Data Tambah Keterangan")
          //console.log(id)
          //console.log(addData)
          
      Post('pelayanan-external/verifikasi/'+ id, null , addData, (res) => {
          this.forceUpdate();
          //console.log(res.status);
          if (res.status === resstat) {
              this.setState({ show: true, basicType: 'success', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Berhasil ' + psn + ' Verifikasi' });
		          this.state.status.formKeterangan = false;
              this.state.status.formTambahVerifikasi = false;
              document.getElementById("keterangan_tolak").value = ""
              this.forceUpdate()
          }
          else {
              this.setState({ show: true, basicType: 'danger', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Gagal ' + psn + ' Verifikasi' });
          }
          this.componentDidMount();
      });
	};

   // Download File Kelahiran
   downloadDokumen = (data) => {

    // Data Download 
    let dokumen_download = JSON.parse(data.dokumen_output)

    //console.log("Data DOwnload")
    //console.log(dokumen_download)
    
   
      const response = {
        file: dokumen_download.dokumen_output_1,
      };
      // server sent the url to the file!
      // now, let's download:
      window.open(response.file);
      // you could also do:
      // window.location.href = response.file;
  

    this.forceUpdate();
    
  }

  // ambil data penduduk
  
  getPenduduk = (e, sel, jns) => {
    // this.state.status.btnForm = true;
    this.forceUpdate();
    if (e) {
      this.changeSelectValue(e, sel);
      Get("penduduk/find", e.value, data => {
        //console.log("Data Penduduk Yang Diset")
        //console.log(data)
        // const data_rt = data.results.data_kk.data_rt;
        // const data_rw = data_rt.data_rw;
        // const data_dusun = data_rw.data_dusun;
        // const data_desa = data_dusun.data_keldes;
        // const data_kecamatan = data_desa.data_kecamatan;
        // const data_kabkota = data_kecamatan.data_kabkota;
        let alamat = '';
        // if (JENIS_DESA.toLowerCase() === 'kelurahan') {
        //   alamat =
        //   " RT. " +
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // } else {
        //   alamat =
        //   " RT. " + 
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " + JENIS_DUSUN + " " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // }
        if (JENIS_DESA.toLowerCase() === 'kelurahan') {
          alamat =
          " RT. " +
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.data_kabkota.nama; 
        } else {
          if (data.results.data_kk.id_rt === '0') {
            alamat = JENIS_DESA + " " + data.results.data_keldes.nama + 
            ", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
          } else {
            alamat =
            " RT. " + 
            data.results.data_kk.data_rt.nama +
            "/RW. " +
            data.results.data_kk.data_rt.data_rw.nama +
            ", " + JENIS_DUSUN + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.nama +
            ", " + JENIS_DESA + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
            ", Kec. " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.data_kabkota.nama; 
          }
        }

        if (jns === "pelapor") {
          this.state.tanggal_lahir_pelapor = data.results.tanggal_lahir;
          this.state.dt.nama_pelapor = data.results.nama;
          this.state.dt.tanggal_lahir_pelapor = new Date(
            data.results.tanggal_lahir
          ).toLocaleDateString("id-ID");
          this.state.dt.tempat_lahir_pelapor = data.results.tempat_lahir;
          this.state.dt.jk_pelapor = data.results.jk;
          this.state.dt.status_perkawinan_pelapor =
            data.results.status_perkawinan;
          this.state.dt.agama_pelapor = data.results.data_agama.nama;
          this.state.dt.pekerjaan_pelapor = data.results.data_pekerjaan.nama;
          this.state.dt.alamat_pelapor = alamat;
          this.state.dataPenduduk2 = "block";
        } else {
          if (jns === "ibu") {
            this.state.tanggal_lahir_ibu = data.results.tanggal_lahir;
            this.state.dt.nama_ibu = data.results.nama;
            this.state.dt.nik_ibu = data.results.id;

            this.state.dt.tanggal_lahir_ibu = new Date(
              data.results.tanggal_lahir
            )
            // .toLocaleDateString("id-ID");
            this.state.dt.tempat_lahir_ibu = data.results.tempat_lahir;
            // this.state.dt.pekerjaan_ibu = data.results.data_pekerjaan.nama;
            this.state.dt.pekerjaan_ibu = {
              value : data.results.data_pekerjaan.nama,
              label : data.results.data_pekerjaan.nama,
            }
            this.state.dt.alamat_ibu = alamat;
            this.state.status.dataPenduduk3 = "block";
          } else {
            Get("catatan-penduduk/nik", data.results.id, dpddk => {
              // console.log('catatan penduduk');
              // console.log(dpddk);
              let pctt = 0;
              let catatan = "";
              if (dpddk.results.data) {
                dpddk.results.data.forEach(dtp => {
                  if (dtp.status === 0 && dtp.penalti === 1) {
                    pctt = 1;
                    catatan = dtp.catatan;
                  }
                });
              }
              // console.log(pctt);
              // console.log(catatan);
              if (pctt === 0) {
                if(this.state.isKelurahan){
                  // Data Pemilik
                  this.state.tanggal_lahir_pemilik = data.results.tanggal_lahir;
                  this.state.dt.nama_pemilik = data.results.nama;
                  this.state.dt.tanggal_lahir_pemilik = new Date(
                    data.results.tanggal_lahir
                  ).toLocaleDateString("id-ID");
                  this.state.dt.tempat_lahir_pemilik = data.results.tempat_lahir;
                  this.state.dt.jk_pemilik = data.results.jk;
                  this.state.dt.status_perkawinan_pemilik = data.results.status_perkawinan;
                  this.state.dt.agama_pemilik = data.results.data_agama.nama;
                  this.state.dt.pekerjaan_pemilik = data.results.data_pekerjaan.nama;
                  this.state.dt.alamat_pemilik = alamat;
                  this.state.dt.rt_pemilik = data.results.data_kk.data_rt.nama;
                  this.state.dt.rw_pemilik = data.results.data_kk.data_rt.data_rw.nama;
                  this.state.dt.kelurahan_pemilik = data.results.data_kk.data_rt.data_rw.data_keldes.nama
                  this.state.dt.kecamatan_pemilik = data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama
                  this.state.dt.kabkota_pemilik = data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama
                  this.state.dt.pendidikan_terakhir_pemilik = data.results.data_pendidikan_terakhir.nama
  
                  this.state.tanggal_lahir_ayah = data.results.tanggal_lahir;
                  this.state.dt.nama_ayah = data.results.nama;
                  this.state.dt.nik_ayah = data.results.id;
  
                  this.state.dt.tanggal_lahir_ayah = new Date(
                    data.results.tanggal_lahir
                  )
                  // .toLocaleDateString("id-ID");
                  this.state.dt.tempat_lahir_ayah = data.results.tempat_lahir;
                  // this.state.dt.pekerjaan_ayah = data.results.data_pekerjaan.nama;
                  this.state.dt.pekerjaan_ayah = {
                    value : data.results.data_pekerjaan.nama,
                    label : data.results.data_pekerjaan.nama,
                  }
                  this.state.dt.alamat_ayah = alamat;
                  this.state.dataPenduduk = "block";
                  this.state.status.btnForm = false;
                  this.forceUpdate();
                  //console.log
                  (new Date(
                    data.results.tanggal_lahir
                  ).toLocaleDateString("id-ID"));
                }
                else{
                  // Data Pemilik
                  this.state.tanggal_lahir_pemilik = data.results.tanggal_lahir;
                  this.state.dt.nama_pemilik = data.results.nama;
                  this.state.dt.tanggal_lahir_pemilik = new Date(
                    data.results.tanggal_lahir
                  ).toLocaleDateString("id-ID");
                  this.state.dt.tempat_lahir_pemilik = data.results.tempat_lahir;
                  this.state.dt.jk_pemilik = data.results.jk;
                  this.state.dt.status_perkawinan_pemilik =
                    data.results.status_perkawinan;
                  this.state.dt.agama_pemilik = data.results.data_agama.nama;
                  this.state.dt.pekerjaan_pemilik = data.results.data_pekerjaan.nama;
                  this.state.dt.alamat_pemilik = alamat;
                  this.state.dt.rt_pemilik = data.results.data_kk.data_rt.nama;
                  this.state.dt.rw_pemilik = data.results.data_kk.data_rt.data_rw.nama;
                  this.state.dt.dusun_pemilik = data.results.data_kk.data_rt.data_rw.data_dusun.nama
                  this.state.dt.kelurahan_pemilik = data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama
                  this.state.dt.kecamatan_pemilik = data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama
                  this.state.dt.kabkota_pemilik = data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama
                  this.state.dt.pendidikan_terakhir_pemilik = data.results.data_pendidikan_terakhir.nama
  
                  this.state.tanggal_lahir_ayah = data.results.tanggal_lahir;
                  this.state.dt.nama_ayah = data.results.nama;
                  this.state.dt.nik_ayah = data.results.id;
  
                  this.state.dt.tanggal_lahir_ayah = new Date(
                    data.results.tanggal_lahir
                  )
                  // .toLocaleDateString("id-ID");
                  this.state.dt.tempat_lahir_ayah = data.results.tempat_lahir;
                  // this.state.dt.pekerjaan_ayah = data.results.data_pekerjaan.nama;
                  this.state.dt.pekerjaan_ayah = {
                    value : data.results.data_pekerjaan.nama,
                    label : data.results.data_pekerjaan.nama,
                  }
                  this.state.dt.alamat_ayah = alamat;
                  this.state.dataPenduduk = "block";
                  // this.state.status.btnForm = false;
                  this.forceUpdate();
                  //console.log
                  (new Date(
                    data.results.tanggal_lahir
                  ).toLocaleDateString("id-ID"));
                }
              } else {
                this.state.nikStat = null;
                // this.state.frmNik = [];
                this.state.dt.nama_ayah = "";
                // this.state.dt.tanggal_lahir_ayah = "";
                this.state.dt.tanggal_lahir_ayah = null;
                this.state.dt.tempat_lahir_ayah = "";
                this.state.dt.pekerjaan_ayah = "";
                this.state.dt.alamat_ayah = "";
                this.state.dataPenduduk = "none";
                // this.state.status.btnForm = true;
                this.forceUpdate();
                this.setState({
                  show: true,
                  basicType: "danger",
                  basicTitle: "Peringatan Catatan Warga",
                  pesanAlert:
                    "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                    catatan
                });
              }
            });
          }
        }
        // this.state.status.btnForm = true;

        // if (
        //   this.state.nikStat !== null &&
        //   this.state.nik2Stat !== null &&
        //   this.state.nik3Stat !== null
        // ) {
        //   this.state.status.btnForm = false;
        // }
        this.forceUpdate();
      });
    } else {
      if (jns === "pelapor") {
        this.state.nik2Stat = null;
        this.state.dt.nama_pelapor = "";
        this.state.dt.tanggal_lahir_pelapor = "";
        this.state.dt.tempat_lahir_pelapor = "";
        this.state.dt.agama_pelapor = "";
        this.state.dt.pekerjaan_pelapor = "";
        this.state.dt.alamat_pelapor = "";
        this.state.dt.jk_pelapor = "";
        this.state.dt.status_perkawinan_pelapor = "";
        this.state.dataPenduduk2 = "none";
      } else {
        if (jns === "ibu") {
          this.state.nik3Stat = null;
          this.state.dt.nama_ibu = "";
          // this.state.dt.tanggal_lahir_ibu = "";
          this.state.dt.tanggal_lahir_ibu = null;
          this.state.dt.tempat_lahir_ibu = "";
          this.state.dt.pekerjaan_ibu = null;
          this.state.dt.alamat_ibu = "";
          this.state.status.dataPenduduk3 = "none";
        } else {
          this.state.nikStat = null;
          this.state.dt.nama_ayah = "";
          // this.state.dt.tanggal_lahir_ayah = "";
          this.state.dt.tanggal_lahir_ayah = null;
          this.state.dt.tempat_lahir_ayah = "";
          this.state.dt.pekerjaan_ayah = null;
          this.state.dt.alamat_ayah = "";
          this.state.dataPenduduk = "none";
        }
      }
      // this.state.status.btnForm = true;

      this.forceUpdate();
    }
  };

  //change nik
  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        let nikStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik = [];
          datanik.data.results.data.forEach(dt => {
            frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmNik, nikStat });
        });
      } else {
        if (fld === "nik2") {
          let nik2Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            let frmNik2 = [];
            datanik.data.results.data.forEach(dt => {
              frmNik2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
            });
            this.setState({ frmNik2, nik2Stat });
          });
        } else {
          let nik3Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            let frmNik3 = [];
            datanik.data.results.data.forEach(dt => {
              frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
            });
            this.setState({ frmNik3, nik3Stat });
          });
        }
      }
    }
  };
  

  changeCheck = (e, jenis_perihal) => {
			switch (jenis_perihal) {
        // bahan baku
				case 'sumber_bahan_baku_lokal':
          if(this.state.sumber_bahan_baku.lokal === true){
            this.state.sumber_bahan_baku.lokal = false
          }
          else{
            this.state.sumber_bahan_baku.lokal = true
          }
				break;
				case 'sumber_bahan_baku_luar_kota':
          if(this.state.sumber_bahan_baku.luar_kota === true){
            this.state.sumber_bahan_baku.luar_kota = false
          }
          else{
            this.state.sumber_bahan_baku.luar_kota = true
          }
				break;
				case 'sumber_bahan_baku_impor':
          if(this.state.sumber_bahan_baku.impor === true){
            this.state.sumber_bahan_baku.impor = false
          }
          else{
            this.state.sumber_bahan_baku.impor = true
          }
				break;

        // Pemasaran
				case 'pemasaran_lokal':
          if(this.state.pemasaran.lokal === true){
            this.state.pemasaran.lokal = false
          }
          else{
            this.state.pemasaran.lokal = true
          }
				break;
				case 'pemasaran_regional':
          if(this.state.pemasaran.regional === true){
            this.state.pemasaran.regional = false
          }
          else{
            this.state.pemasaran.regional = true
          }
				break;
				case 'pemasaran_nasional':
          if(this.state.pemasaran.nasional === true){
            this.state.pemasaran.nasional = false
          }
          else{
            this.state.pemasaran.nasional = true
          }
				break;
				case 'pemasaran_impor':
          if(this.state.pemasaran.impor === true){
            this.state.pemasaran.impor = false
          }
          else{
            this.state.pemasaran.impor = true
          }
				break;

        // Legalitas
				case 'legalitas_usaha_siup':
          if(this.state.legalitas_usaha.siup === true){
            this.state.legalitas_usaha.siup = false
          }
          else{
            this.state.legalitas_usaha.siup = true
          }
				break;
				case 'legalitas_usaha_situ':
          if(this.state.legalitas_usaha.situ === true){
            this.state.legalitas_usaha.situ = false
          }
          else{
            this.state.legalitas_usaha.situ = true
          }
				break;
				case 'legalitas_usaha_tdp':
          if(this.state.legalitas_usaha.tdp === true){
            this.state.legalitas_usaha.tdp = false
          }
          else{
            this.state.legalitas_usaha.tdp = true
          }
				break;
				case 'legalitas_usaha_npwp':
          if(this.state.legalitas_usaha.npwp === true){
            this.state.legalitas_usaha.npwp = false
          }
          else{
            this.state.legalitas_usaha.npwp = true
          }
				break;
				case 'legalitas_usaha_domisili':
          if(this.state.legalitas_usaha.domisili === true){
            this.state.legalitas_usaha.domisili = false
          }
          else{
            this.state.legalitas_usaha.domisili = true
          }
				break;
				case 'legalitas_usaha_halal':
          if(this.state.legalitas_usaha.halal === true){
            this.state.legalitas_usaha.halal = false
          }
          else{
            this.state.legalitas_usaha.halal = true
          }
				break;
				case 'legalitas_usaha_hak_paten':
          if(this.state.legalitas_usaha.hak_paten === true){
            this.state.legalitas_usaha.hak_paten = false
          }
          else{
            this.state.legalitas_usaha.hak_paten = true
          }
				break;
				case 'legalitas_usaha_p_irt':
          if(this.state.legalitas_usaha.p_irt === true){
            this.state.legalitas_usaha.p_irt = false
          }
          else{
            this.state.legalitas_usaha.p_irt = true
          }
				break;

        // Status Tempat Usaha
				case 'status_tempat_usaha_milik_sendiri':
          if(this.state.status_tempat_usaha.milik_sendiri === true){
            this.state.status_tempat_usaha.milik_sendiri = false
          }
          else{
            this.state.status_tempat_usaha.milik_sendiri= true
          }
				break;
				case 'status_tempat_usaha_sewa':
          if(this.state.status_tempat_usaha.sewa === true){
            this.state.status_tempat_usaha.sewa = false
          }
          else{
            this.state.status_tempat_usaha.sewa= true
          }
				break;
        default:
          // no default
          break;
				
			}
		this.forceUpdate();
	};

   // ================== Tanggal ===================

   gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_lahir_pasangan":
        this.setState({ tanggal_lahir_pasangan: date });
        break;
      case "tanggal_surat_desa":
        if (typeof date._isValid !== 'undefined') {
          this.setState({ tanggal_surat_desa: new Date(date.format('YYYY-MM-DD')) });
        }
        break;
      default:
        // no default
        break;
    }
  };

  // ==============================================

  // ==================== Persyaratan =====================
  onFileChange = (event, attribute) => {
    const file = event.target.files[0];
    const fileType = file.type.split('/')
    const type = fileType[1]

		// Disable tombol selesai sampai file berhasil diupload
		this.setState({ status: {...this.state.status, btnForm: true} })
    
    if (type === "pdf" || type === "jpg" || type === "jpeg" || type === "png") {
      let bodyFormData = new FormData();
      bodyFormData.append("files", file);

      let folder = 'img'
			if (type === "pdf") {
				folder = "pdf"
			}
  
      PostEksternal("upload-file/" + folder, null, bodyFormData, (response) => {
        const link = response.data.results
        let linkGambar = {...this.state.linkGambar};

        // linkGambar[attribute] = link
        linkGambar[attribute] = { link, type: folder }
  
        this.setState({ 
          linkGambar,
					status: {...this.state.status, btnForm: false}
				});
      });
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Upload file",
        pesanAlert: "Format file harus berupa jpg/jpeg/png/pdf",
      });
      document.getElementById("file-upload").value = null
    }

  };

	uploadFiles = (e) => {
    e.preventDefault()

    let isUploadEnable = true
    // Cek upload button
    Object.keys(this.state.linkGambar).forEach((attribute) => {
      isUploadEnable = isUploadEnable && this.state.linkGambar[attribute] !== ''
    })

    if (isUploadEnable) {
      let bodyFormData = {
        status: "Selesai",
        dokumen_output: this.state.linkGambar,
      };

      Post(
        "pelayanan/verifikasi-status",
        this.state.detailSurat.uuid,
        bodyFormData,
        (data) => {
          //console.log(data);
          if (data.status === 200) {
            let status = { ...this.state.status };
            status.alert = true;
            this.setState({
              status,
              basicType: "success",
              basicTitle: "Upload Berkas",
              pesanAlert: "Berhasil upload berkas",
            });
            this.componentDidMount(this.state.active_tab_icon);
          } else {
            let status = { ...this.state.status };
            status.alert = true;
            this.setState({
              status,
              basicType: "danger",
              basicTitle: "Upload Berkas",
              pesanAlert: "Gagal upload berkas",
            });
          }
          this.tutupForm();
        }
      );
    } else {
      let status = { ...this.state.status };
      status.alert = true;

      this.setState({
        status,
        basicType: "warning",
        basicTitle: "Upload berkas",
        pesanAlert: "Pilih berkas terlebih dahulu",
      });
    }
  };
  // ==================== Persyaratan =====================



  render() {
    // console.log("Data Render")
    // console.log(this.state.frmRt)
    // //console.log(this.state.linkGambar)
    // //console.log(this.state.dt.bentuk_perusahaan)
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;
    var dataVerifikasi = this.state.dataVerifikasi;
    var dataDisetujui = this.state.dataDisetujui;
    var dataDitolak = this.state.dataDitolak;
    var dataSelesai = this.state.dataSelesai;

    // var no = 0;
    // const frmDef = {
    //   id: 0,
    //   idbaru: "",
    //   id_keldes: "",
    //   nama: ""
    // };
    // const { SearchBar } = Search;

    // const listKeluargaIn = this.state.detail.forEach((dt, i) => {
    //   no++;
    //   let jk = "L";
    //   if (dt.jenis_kelamin === "Perempuan") {
    //     jk = "P";
    //   }
    //   return (
    //     <tr key={i}>
    //       <td>{no}</td>
    //       <td>{dt.nama}</td>
    //       {/* <td>{jk}</td>
    //       <td>
    //         {dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
    //       </td> */}
    //       {/* <td>{dt.pekerjaan}</td> */}
    //       <td>{dt.nik}</td>
    //       <td>{dt.shdk.label}</td>
    //       <td>
    //         <Button
    //           size="xs"
    //           onClick={() => {
    //             // this.hapus(i)
    //             this.state.detail.splice(i, 1);
    //             this.forceUpdate();
    //           }}
    //           color="danger"
    //           className="btn-icon"
    //         >
    //           <i className="fa fa-trash"></i>
    //         </Button>
    //       </td>
    //     </tr>
    //   );
    // });

    // Data diproses
    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          formatter: (key, obj, index) =>{ 
              let current_pagenum = '';
              let total_records_per_page = '';
              let row_index = '';
              let serial_num = '';

              if(this.state.active_tab_icon ==='1'){
                current_pagenum = this.state.pagination.current_page;
                total_records_per_page = this.state.pagination.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              else if(this.state.active_tab_icon ==='2'){
                current_pagenum = this.state.paginationDiverifikasi.current_page;
                total_records_per_page = this.state.paginationDiverifikasi.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              else if(this.state.active_tab_icon ==='3'){
                current_pagenum = this.state.paginationDisetujui.current_page;
                total_records_per_page = this.state.paginationDisetujui.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              else if(this.state.active_tab_icon ==='4'){
                current_pagenum = this.state.paginationDitolak.current_page;
                total_records_per_page = this.state.paginationDitolak.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              else if(this.state.active_tab_icon ==='5'){
                current_pagenum = this.state.paginationSelesai.current_page;
                total_records_per_page = this.state.paginationSelesai.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              
              return serial_num; 
          }
      },
      {
        // dataField: "data_pelayanan.tanggal_surat",
        dataField: "create_at",
        text: "Tanggal",
        formatter: (cell, obj) => {
          let plainTime = '-'
                    if (obj.create_at) {
                        let date = new Date(obj.create_at)
                        date = date.toLocaleString('id-ID')
                        
                        const datetime = date.split(' ')
      
                        date = datetime[0]
                        let time = datetime[1]
      
                        const splitDate = date.split('/')
                        time = time.replaceAll('.', ':')
      
                        const list_bulan = [
                            'Januari',
                            'Februari',
                            'Maret',
                            'April',
                            'Mei',
                            'Juni',
                            'Juli',
                            'Agustus',
                            'September',
                            'Oktober',
                            'November',
                            'Desember'
                        ];
      
                        plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}  ${time}`;
                    }
      
                    return plainTime
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "data_pelayanan.nomor_surat",
        text: "Nomor surat",
        formatter: (cell, obj) => {
          let isinya = JSON.parse(obj.data_pelayanan)
          return isinya.nomor_surat === "" ? "-" : isinya.nomor_surat;
        },
        sort: true
      },
      {
        dataField: "nik_pelapor",
        text: "NIK Pemohon",
        sort: true
      },
      {
        dataField: "nama_pelapor",
        text: "Nama Pemohon",
        sort: true
      },
      // {
      //   dataField: "tujuan_pembuatan",
      //   text: "Tujuan",
      //   formatter: (cell, obj) => {
      //     let tujuan = obj.tujuan_pembuatan.split(",");
      //     return tujuan[0];
      //   },
      //   isDummyField: true,
      //   sort: true
      // },
      {
        dataField: "data_status_baru.status",
        text: "Status",
        sort: true
      },
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiVerifikasi
      }
    ];

    
		let uploadButton = true
    // Cek upload button
    Object.keys(this.state.linkGambar).forEach((attribute) => {
      uploadButton = uploadButton && this.state.linkGambar[attribute] !== ''
    })

    return (
      <div>
        {/* <Breadcrumb title="Data Surat Keterangan Penghasilan" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.state.showBio= false;
                  this.closeAlert();
                  this.bukaForm();
                  this.forceUpdate();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                color="info"
                // onClick={() => {
                //   this.closeAlert();
                // }}
              >
                <Link
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                {/* <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>Data Disposisi Surat</h5>
                    </div>
                    <div className="col-sm-6 text-right">
                    </div>
                  </div>
                </div> */}
                <div className="card-body datatable-react">
                  <Nav tabs className="tabs-color">
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "1"
                        })}
                        onClick={() => {
                          this.toggle_icon("1");
                        }}
                      >
                        <i className="fa fa-hourglass-half"></i>
                        
                        Diajukan
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "2"
                        })}
                        onClick={() => {
                          this.toggle_icon("2");
                        }}
                      >
                        <i className="fa fa-tasks"></i>

                        Diverifikasi
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "3"
                        })}
                        onClick={() => {
                          this.toggle_icon("3");
                        }}
                      >
                        <i className="fa fa-check"></i>

                        Disetujui
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "4"
                        })}
                        onClick={() => {
                          this.toggle_icon("4");
                        }}
                      >
                        <i className="fa fa-times"></i>

                        Ditolak
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "5"
                        })}
                        onClick={() => {
                          this.toggle_icon("5");
                        }}
                      >
                        <i className="fa fa-check"></i>

                        Selesai
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.active_tab_icon}>
                    <TabPane tabId="1">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                          <div className="row" style={{ marginBottom: 15 }}>
                            <div className="col-sm-4 text-left">
                              {/* <button 
                                color="success" 
                                className="btn btn-secondary"
                                onClick={() => {
                                  this.bukaForm()
                                }}
                              >Tambah Surat</button> */}
                              <Button
                                color="success"
                                className="btn btn-success"
                                onClick={() => {
                                  this.setState({ judul: 'Data Izin Usaha Mikro Kecil' })
                                  this.setState({ 
                                    uuid_surat: null,
                                    dataPemohon: null,
                                    detailKK: null,
                                    frmKbkt: [],
                                    frmKec: [],
                                    frmKel: [],
                                    kepalaKeluarga: '',
                                    dtPindah: {
                                      alasan_pindah: '',
                                      alamat: '',
                                      rt: '',
                                      rw: '',
                                      kode_pos: '',
                                      telepon: '',
                                      tgl_pindah: null,
                                    },
                                    linkGambar: listFile,
                                    nik2Select: true,
                                    nik2Stat: null,
                                    nikSelect: true,
                                    nikStat: null,
                                    dusSelect: true,
                                    dusStat: null,
                                    isUpdate: false,
                                  })
                                  this.bukaForm();
                                }}
                              >
                                Tambah Surat
                              </Button>
                            </div>
                            <div className="col-sm-8 text-right">
                              <button onClick={this.props.history.goBack} color="info" className="btn btn-info"><i className="fa fa-arrow-left"></i> Kembali</button>
                            </div>
                          </div>
                          
                            <BootstrapTable
                              keyField="uuid"
                              data={data}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal}
                              {this.state.sebelum}
                              {this.state.hal.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah}
                              {this.state.akhir}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <div className="row" style={{ marginBottom: 15 }}>
                              <div className="col-sm-4 text-left">
                              </div>
                              <div className="col-sm-8 text-right">
                                <button onClick={this.props.history.goBack} color="info" className="btn btn-info"><i className="fa fa-arrow-left"></i> Kembali</button>
                              </div>
                            </div>
                            
                              <BootstrapTable
                                keyField="uuid"
                                data={dataVerifikasi}
                                columns={columns}
                              />
                              <div className="pull-right text-white">
                                {this.state.awal2}
                                {this.state.sebelum2}
                                {this.state.hal2.map(dt => {
                                  return dt;
                                })}
                                {this.state.setelah2}
                                {this.state.akhir2}
                              </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <div className="row" style={{ marginBottom: 15 }}>
                              <div className="col-sm-4 text-left">
                              </div>
                              <div className="col-sm-8 text-right">
                                <button onClick={this.props.history.goBack} color="info" className="btn btn-info"><i className="fa fa-arrow-left"></i> Kembali</button>
                              </div>
                            </div>
                            
                              <BootstrapTable
                                keyField="uuid"
                                data={dataDisetujui}
                                columns={columns}
                              />
                              <div className="pull-right text-white">
                                {this.state.awal3}
                                {this.state.sebelum3}
                                {this.state.hal3.map(dt => {
                                  return dt;
                                })}
                                {this.state.setelah3}
                                {this.state.akhir3}
                              </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <div className="row" style={{ marginBottom: 15 }}>
                              <div className="col-sm-4 text-left">
                              </div>
                              <div className="col-sm-8 text-right">
                                <button onClick={this.props.history.goBack} color="info" className="btn btn-info"><i className="fa fa-arrow-left"></i> Kembali</button>
                              </div>
                            </div>
                            
                              <BootstrapTable
                                keyField="uuid"
                                data={dataDitolak}
                                columns={columns}
                              />
                              <div className="pull-right text-white">
                                {this.state.awal4}
                                {this.state.sebelum4}
                                {this.state.hal4.map(dt => {
                                  return dt;
                                })}
                                {this.state.setelah4}
                                {this.state.akhir4}
                              </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <div className="row" style={{ marginBottom: 15 }}>
                              <div className="col-sm-4 text-left">
                              </div>
                              <div className="col-sm-8 text-right">
                                <button onClick={this.props.history.goBack} color="info" className="btn btn-info"><i className="fa fa-arrow-left"></i> Kembali</button>
                              </div>
                            </div>
                            
                              <BootstrapTable
                                keyField="uuid"
                                data={dataSelesai}
                                columns={columns}
                              />
                              <div className="pull-right text-white">
                                {this.state.awal5}
                                {this.state.sebelum5}
                                {this.state.hal5.map(dt => {
                                  return dt;
                                })}
                                {this.state.setelah5}
                                {this.state.akhir5}
                              </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: "100%" } }}
				>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header" align="center">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>

            <div className="modal-body">
            {/* <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>No. Pendaftaran</b>
                  </Label>
                  <div className="col-sm-8">
                    <input
                      id="no_pendaftaran"
                      className="form-control"
                      style={{
                        marginBottom: '10px',
                        fontWeight: 400,
                        fontSize: 'inherit'
                      }}
                      defaultValue={this.state.dt.no_pendaftaran}
                      // value={this.state.dt.alamat_usaha}
                      type="text"
                      placeholder="Contoh: 006"
                      required
                    />
                  </div>
              </FormGroup> */}
              {/* Data Pelapor */}
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>DATA PELAPOR</b>
                </Label>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>NIK Pelapor</b>
                </Label>
                <div className="col-sm-8">
                  {this.state.nik2Select ? (
                    <Select
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e, "nik2", "pelapor");
                      }}
                      defaultValue={this.state.nik2Stat}
                      value={this.state.nik2Stat}
                      name="NIK2"
                      options={this.state.frmNik2}
                      placeholder="Pilih NIK Pelapor"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik2")}
                      isClearable
                    />
                  ) : (
                    <input
                      className="form-control"
                      id="nik_pelapor"
                      type="text"
                      placeholder="NIK Pelapor"
                      defaultValue={this.state.dt.nik_pelapor}
                      required
                    />
                  )}
                  <h6 style={{ fontSize: '13px' }}>
                    <a href="/" 
                      onClick={(e) => { 
                        e.preventDefault()
                        this.setState({ 
                          nik2Select: !this.state.nik2Select, 
                          nik2Stat: null,
                          dt: { 
                            nik_pelapor: '',
                            nama_pelapor: '',
                            alamat_pelapor: '',
                            pekerjaan_pelapor: '',
                            no_hp_pelapor: '',
                          }
                        })
                      }} >{this.state.nik2Select ? 'Input NIK Manual' : 'Pilih NIK yang tersedia'}</a>
                  </h6>

                </div>
              </FormGroup>

              <div style={{ display: this.state.nik2Select && this.state.nik2Stat ? 'block' : !this.state.nik2Select ? 'block' : 'none' }}>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Nama
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="nama_pelapor"
                      type="text"
                      placeholder="Nama Pelapor"
                      defaultValue={this.state.dt.nama_pelapor}
                      readOnly={this.state.nik2Select ? true : false}
                      style={{ backgroundColor: this.state.nik2Select ? "#c9d1d8" : "white" }}
                      required
                      // disabled
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Alamat Pelapor
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="alamat_pelapor"
                      type="text"
                      placeholder="Masukkan Alamat Pelapor"
                      defaultValue={this.state.dt.alamat_pelapor}
                      readOnly={this.state.nik2Select ? true : false}
                      style={{ backgroundColor: this.state.nik2Select ? "#c9d1d8" : "white" }}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Pekerjaan Pelapor
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="pekerjaan_pelapor"
                      type="text"
                      placeholder="Masukkan Pekerjaan Pelapor"
                      defaultValue={this.state.dt.pekerjaan_pelapor}
                      readOnly={this.state.nik2Select ? true : false}
                      style={{ backgroundColor: this.state.nik2Select ? "#c9d1d8" : "white" }}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    No. HP Pelapor
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="no_hp_pelapor"
                      type="telp"
                      placeholder="Masukkan No. Telp Pelapor"
                      defaultValue={this.state.dt.no_hp_pelapor}
                      required
                    />
                  </div>
                </FormGroup>
              </div>


              {/* Data Pemilik */}
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>DATA PEMILIK</b>
                </Label>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>NIK Pemilik</b>
                </Label>
                <div className="col-sm-8">
                  {this.state.nikSelect ? (
                    <Select
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e, "nik", "pemohon");
                      }}
                      defaultValue={this.state.nikStat}
                      value={this.state.nikStat}
                      name="NIK"
                      options={this.state.frmNik}
                      placeholder="Pilih NIK"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                      isClearable
                    />
                  ) : (
                    <input
                      className="form-control"
                      id="nik_pemilik"
                      type="text"
                      placeholder="NIK Pemilik"
                      defaultValue={this.state.dt.nik_pemilik}
                      required
                    />
                  )}
                  <h6 style={{ fontSize: '13px' }}>
                    <a href="/" 
                      onClick={(e) => { 
                        e.preventDefault()
                        this.setState({ 
                          nikSelect: !this.state.nikSelect, 
                          nikStat: null,
                          dt: { 
                            nama_pemilik: '',
                            jk_pemilik: '',
                            pendidikan_terakhir_pemilik: '',
                            alamat_pemilik: '',
                            kabkota_pemilik: '',
                            kecamatan_pemilik: '',
                            kelurahan_pemilik: '',
                            rw_pemilik: '',
                            rt_pemilik: '',
                            kode_pos: '',
                            email_pemilik: '',
                            telp_pemilik: '',
                          }
                        })
                      }} >{this.state.nikSelect ? 'Input NIK Manual' : 'Pilih NIK yang tersedia'}</a>
                  </h6>
                </div>
              </FormGroup>
              
              <div style={{ display: this.state.nikSelect && this.state.nikStat ? 'block' : !this.state.nikSelect ? 'block' : 'none' }}>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Nama
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="nama_pemilik"
                      type="text"
                      placeholder="Nama Pemilik"
                      defaultValue={this.state.dt.nama_pemilik}
                      readOnly={this.state.nikSelect ? true : false}
                      style={{ backgroundColor: this.state.nikSelect ? "#c9d1d8" : "white" }}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Jenis Kelamin
                  </Label>
                  {this.state.nikSelect ? (
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        className="form-control"
                        id="jk_pemilik"
                        type="text"
                        placeholder="Jenis Kelamin Pemilik"
                        defaultValue={this.state.dt.jk_pemilik}
                        readOnly={this.state.nikSelect ? true : false}
                        style={{ backgroundColor: this.state.nikSelect ? "#c9d1d8" : "white" }}
                        required
                      />
                    </div>
                  ):(
                    <div className="col-sm-8">
                      <Select
                        classNamePrefix="select"
                        onChange={e => {
                          this.setState({ jenisKelaminStat: e })
                        }}
                        defaultValue={this.state.jenisKelaminStat}
                        // value={this.state.jenisKelaminStat}
                        name="penduduk"
                        options={[
                          {value: 'Laki-Laki', label: 'Laki-Laki'},
                          {value: 'Perempuan', label: 'Perempuan'},
                        ]}
                        placeholder="Pilih Jenis Kelamin Pelapor"
                        isClearable
                      />
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Pendidikan Terakhir
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="pendidikan_terakhir_pemilik"
                      type="text"
                      placeholder="Pendidikan Terakhir Pemilik"
                      defaultValue={this.state.dt.pendidikan_terakhir_pemilik}
                      readOnly={this.state.nikSelect ? true : false}
                      style={{ backgroundColor: this.state.nikSelect ? "#c9d1d8" : "white" }}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Alamat
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="alamat_pemilik"
                      type="text"
                      placeholder="Alamat Pemilik"
                      defaultValue={this.state.dt.alamat_pemilik}
                      readOnly={this.state.nikSelect ? true : false}
                      style={{ backgroundColor: this.state.nikSelect ? "#c9d1d8" : "white" }}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Kabupaten / Kota
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="kabkota_pemilik"
                      type="text"
                      placeholder="Kabupaten / Kota Pemilik"
                      defaultValue={this.state.dt.kabkota_pemilik}
                      readOnly={this.state.nikSelect ? true : false}
                      style={{ backgroundColor: this.state.nikSelect ? "#c9d1d8" : "white" }}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Kecamatan
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="kecamatan_pemilik"
                      type="text"
                      placeholder="Kecamatan Pemilik"
                      defaultValue={this.state.dt.kecamatan_pemilik}
                      readOnly={this.state.nikSelect ? true : false}
                      style={{ backgroundColor: this.state.nikSelect ? "#c9d1d8" : "white" }}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Kelurahan
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="kelurahan_pemilik"
                      type="text"
                      placeholder="Kelurahan Pemilik"
                      defaultValue={this.state.dt.kelurahan_pemilik}
                      readOnly={this.state.nikSelect ? true : false}
                      style={{ backgroundColor: this.state.nikSelect ? "#c9d1d8" : "white" }}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    RW
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="rw_pemilik"
                      type="text"
                      placeholder="RW Pemilik"
                      defaultValue={this.state.dt.rw_pemilik}
                      readOnly={this.state.nikSelect ? true : false}
                      style={{ backgroundColor: this.state.nikSelect ? "#c9d1d8" : "white" }}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    RT
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="rt_pemilik"
                      type="text"
                      placeholder="RT Pemilik"
                      defaultValue={this.state.dt.rt_pemilik}
                      readOnly={this.state.nikSelect ? true : false}
                      style={{ backgroundColor: this.state.nikSelect ? "#c9d1d8" : "white" }}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px'  }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Kode Pos
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="kode_pos"
                      type="number"
                      placeholder="Masukkan kode pos (Contoh : 28285)"
                      defaultValue={this.state.dt.kode_pos}
                      // value={this.state.dt.kode_pos}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Email
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="email_pemilik"
                      type="email"
                      placeholder="Email Pemilik"
                      defaultValue={this.state.dt.email_pemilik}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    No. Telepon
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="telp_pemilik"
                      type="number"
                      placeholder="No. Telepon Pemilik"
                      defaultValue={this.state.dt.telp_pemilik}
                      required
                    />
                  </div>
                </FormGroup>


                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    NPWP
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="npwp"
                      type="text"
                      placeholder="Masukkan NPWP Anda"
                      defaultValue={this.state.dt.npwp}
                      // value={this.state.dt.npwp}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Jenis NPWP
                  </Label>
                  <div className="col-sm-8">
                    <Select
                      // isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.changeSelectValue(e, 'jenis_npwp');
                      }}
                      value={this.state.dt.jenis_npwp}
                      defaultValue={this.state.dt.jenis_npwp}
                      name="jenis_npwp"
                      options={[
                        {value: 'Pribadi', label: 'Pribadi'},
                        {value: 'Perusahaan', label: 'Perusahaan'},
                      ]}
                      placeholder="Pilih Jenis NPWP"
                      isClearable
                    />
                  </div>
                </FormGroup>
              </div>

              {/* Data Usaha */}
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>DATA USAHA</b>
                </Label>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Nama Usaha</b>
                  </Label>
                  <div className="col-sm-8">
                    <input
                      id="nama_usaha"
                      className="form-control"
                      style={{
                        marginBottom: '10px',
                        fontWeight: 400,
                        fontSize: 'inherit'
                      }}
                      defaultValue={this.state.dt.nama_usaha}
                      // value={this.state.dt.nama_usaha}
                      required
                      type="text"
                      placeholder="Contoh: CV. Jaya Bersama Abadi"
                    />
                  </div>
              </FormGroup>
              
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Alamat Usaha</b>
                  </Label>
                  <div className="col-sm-8">
                    <input
                      id="alamat_usaha"
                      className="form-control"
                      style={{
                        marginBottom: '10px',
                        fontWeight: 400,
                        fontSize: 'inherit'
                      }}
                      defaultValue={this.state.dt.alamat_usaha}
                      // value={this.state.dt.alamat_usaha}
                      type="text"
                      placeholder="Contoh: Jl. Purwodadi"
                      required
                    />
                  </div>
              </FormGroup>

              {
                this.state.isKelurahan?
                <>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Rw</b>
                  </Label>
                  <div className="col-sm-8">
                    <Select
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.getChild(e, 'rw', 'rw', 'rt');
                      }}
                      defaultValue={this.state.rwStat}
                      value={this.state.rwStat}
                      name="rw"
                      options={this.state.frmRw}
                      placeholder="Pilih Rw"
                      isClearable
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Rt</b>
                  </Label>
                  <div className="col-sm-8">
                    <Select
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.changeSelectValue(e, 'rt');
                      }}
                      defaultValue={this.state.rtStat}
                      value={this.state.rtStat}
                      name="rt"
                      options={this.state.frmRt}
                      placeholder="Pilih Rt"
                      isClearable
                    />
                  </div>
                </FormGroup>
                </>
                :
                <>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>{JENIS_DUSUN}</b>
                  </Label>
                  <div className="col-sm-8">
                    {this.state.dusSelect ? (
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.getChild(e, 'dusun', 'dus', 'rw');
                        }}
                        defaultValue={this.state.dusStat}
                        // value={this.state.dusStat}
                        name="dusun"
                        options={this.state.frmDus}
                        placeholder={'Pilih ' + JENIS_DUSUN}
                        isClearable
                      />
                    ) : (
                      <input
                        id="dusun_usaha"
                        className="form-control"
                        defaultValue={this.state.dt.dusun_usaha ? this.state.dt.dusun_usaha.value : ''}
                        type="text"
                        placeholder="Contoh : Durian Runtuh"
                        required
                      />
                    )}
                    <h6 style={{ fontSize: '13px' }}>
                      <a href="/" 
                        onClick={(e) => { 
                          e.preventDefault()
                          this.setState({ dusSelect: !this.state.dusSelect })
                        }} >{this.state.dusSelect ? 'Input Dusun Manual' : 'Pilih Dusun yang tersedia'}</a>
                    </h6>
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Rw</b>
                  </Label>
                  <div className="col-sm-8">
                    {this.state.dusSelect ? (
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.getChild(e, 'rw', 'rw', 'rt');
                        }}
                        defaultValue={this.state.rwStat}
                        value={this.state.rwStat}
                        name="rw"
                        options={this.state.frmRw}
                        placeholder="Pilih Rw"
                        isClearable
                      />
                    ) : (
                      <input
                        id="rw_usaha"
                        className="form-control"
                        defaultValue={this.state.dt.rw_usaha ? this.state.dt.rw_usaha.value : ''}
                        type="text"
                        placeholder="Contoh : 007"
                        required
                      />
                    )}

                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Rt</b>
                  </Label>
                  <div className="col-sm-8">
                    {this.state.dusSelect ? (
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.changeSelectValue(e, 'rt');
                        }}
                        defaultValue={this.state.rtStat}
                        value={this.state.rtStat}
                        name="rt"
                        options={this.state.frmRt}
                        placeholder="Pilih Rt"
                        isClearable
                      />
                    ) : (
                      <input
                        id="rt_usaha"
                        className="form-control"
                        defaultValue={this.state.dt.rt_usaha ? this.state.dt.rt_usaha.value : ''}
                        type="text"
                        placeholder="Contoh : 005"
                        required
                      />
                    )}
                  </div>
                </FormGroup>
                </>
              }

                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Kode Pos
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="kode_pos_usaha"
                      type="number"
                      placeholder="Masukkan kode pos (Contoh : 28285)"
                      defaultValue={this.state.dt.kode_pos_usaha}
                      // value={this.state.dt.kode_pos_usaha}
                      required
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    No. Telp Usaha
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="telp_usaha"
                      type="number"
                      placeholder="Masukkan No. Telp Usaha"
                      defaultValue={this.state.dt.telp_usaha}
                      // value={this.state.dt.telp_usaha}
                      required
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Tahun Berdiri
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="tahun_berdiri"
                      type="number"
                      placeholder="Contoh: 2018"
                      defaultValue={this.state.dt.tahun_berdiri}
                      // value={this.state.dt.tahun_berdiri}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Bentuk Perusahaan
                  </Label>
                  <div className="col-sm-8">
                    <Select
                      // isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.changeSelectValue(e, 'bentuk_perusahaan');
                      }}
                      value={this.state.dt.bentuk_perusahaan}
                      defaultValue={this.state.dt.bentuk_perusahaan}
                      name="bentuk_perusahaan"
                      options={[
                        {value: 'CV', label: 'CV'},
                        {value: 'Firma', label: 'Firma'},
                        {value: 'PT', label: 'PT'},
                        {value: 'PD', label: 'PD'},
                        {value: 'Koperasi', label: 'Koperasi'},
                        {value: 'Perseorangan', label: 'Perseorangan'},
                      ]}
                      placeholder="Pilih Bentuk Perusahaan"
                      isClearable
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Jenis Usaha
                  </Label>
                  <div className="col-sm-8">
                    <Select
                      // isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.changeSelectValue(e, 'jenis_usaha');
                      }}
                      value={this.state.dt.jenis_usaha}
                      defaultValue={this.state.dt.jenis_usaha}
                      name="jenis_usaha"
                      options={[
                        {value: 'Perdagangan', label: 'Perdagangan'},
                        {value: 'Makanan', label: 'Makanan'},
                        {value: 'Jasa', label: 'Jasa'},
                        {value: 'Handycraft', label: 'Handycraft'},
                        {value: 'Fashion', label: 'Fashion'},
                        {value: 'Lain-lain', label: 'Lain-lain'},
                      ]}
                      placeholder="Pilih Jenis Usaha"
                      isClearable
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Keterangan Jenis Usaha
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="uraian_usaha"
                      type="text"
                      placeholder="Masukkan Keterangan Jenis Usaha"
                      defaultValue={this.state.dt.uraian_usaha}
                      // value={this.state.dt.uraian_usaha}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Sarana Usaha
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="sarana_usaha"
                      type="text"
                      placeholder="Masukkan Sarana Usaha"
                      defaultValue={this.state.dt.sarana_usaha}
                      // value={this.state.dt.sarana_usaha}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Jumlah Karyawan
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="form-control"
                      id="jumlah_karyawan"
                      type="number"
                      placeholder="Masukkan Jumlah Karyawan"
                      defaultValue={this.state.dt.jumlah_karyawan}
                      // value={this.state.dt.jumlah_karyawan}
                      required
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Modal Sendiri
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <NumberFormat
                      id="permodalan_sendiri"
                      placeholder="Contoh : 10.500.000"
                      thousandSeparator={true}
                      prefix={'Rp. '}
                      className="form-control"
                      defaultValue={this.state.dt.permodalan_sendiri}
                      value={this.state.dt.permodalan_sendiri}
                      onChange={(e) => {
                        this.changeSelectValue(e, 'permodalan_sendiri');
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Modal Luar
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <NumberFormat
                      id="permodalan_luar"
                      placeholder="Contoh : 10.500.000"
                      thousandSeparator={true}
                      prefix={'Rp. '}
                      className="form-control"
                      value={this.state.dt.permodalan_luar}
                      defaultValue={this.state.dt.permodalan_luar}
                      onChange={(e) => {
                        this.changeSelectValue(e, 'permodalan_luar');
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Kategori Omset/Tahun
                  </Label>
                  <div className="col-sm-8">
                    <Select
                      // isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.changeSelectValue(e, 'kategori_omset');
                      }}
                      value={this.state.dt.kategori_omset}
                      defaultValue={this.state.dt.kategori_omset}
                      name="kategori_omset"
                      options={[
                        {value: 'Mikro <= 300 jt', label: 'Mikro <= 300 jt'},
                        {value: 'Kecil 300 jt – 2,5 M', label: 'Kecil 300 jt – 2,5 M'},
                        {value: 'Menengah 2,5 – 50 M', label: 'Menengah 2,5 – 50 M'},
                      ]}
                      placeholder="Pilih Kategori Omset/Tahun"
                      isClearable
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Nominal Omset/Tahun
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <NumberFormat
                      id="nominal_omset"
                      placeholder="Contoh : 10.500.000"
                      thousandSeparator={true}
                      prefix={'Rp. '}
                      className="form-control"
                      value={this.state.dt.nominal_omset}
                      onChange={(e) => {
                        this.changeSelectValue(e, 'nominal_omset');
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Kategori Aset
                  </Label>
                  <div className="col-sm-8">
                    <Select
                      // isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.changeSelectValue(e, 'kategori_aset');
                      }}
                      value={this.state.dt.kategori_aset}
                      defaultValue={this.state.dt.kategori_aset}
                      name="kategori_aset"
                      options={[
                        {value: 'Mikro <  50 jt', label: 'Mikro <  50 jt'},
                        {value: 'Kecil 50-500 jt', label: 'Kecil 50-500 jt'},
                        {value: 'Menengah 500 jt- 10 M', label: 'Menengah 500 jt- 10 M'},
                      ]}
                      placeholder="Pilih Kategori Aset"
                      isClearable
                    />
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Nominal Aset
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    <NumberFormat
                      id="nominal_aset"
                      placeholder="Contoh : 10.500.000"
                      thousandSeparator={true}
                      prefix={'Rp. '}
                      className="form-control"
                      value={this.state.dt.nominal_aset}
                      onChange={(e) => {
                        this.changeSelectValue(e, 'nominal_aset');
                      }}
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Sumber Bahan Baku</b>
                  </Label>
                  <div className="col-sm-8">
                    <Select
                    // isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.changeSelectValue(e, 'sumber_bahan_baku');
                    }}
                    value={this.state.dt.sumber_bahan_baku}
                    defaultValue={this.state.dt.sumber_bahan_baku}
                    name="sumber_bahan_baku"
                    options={[
                      {value: 'Lokal', label: 'Lokal'},
                      {value: 'Luar Kota', label: 'Luar Kota'},
                      {value: 'Impor', label: 'Impor'},
                    ]}
                    placeholder="Pilih Sumber Bahan Baku"
                    isClearable
                  />

                        {/* <input type="checkbox"
                          defaultChecked={this.state.sumber_bahan_baku.lokal}
                          checked={this.state.sumber_bahan_baku.lokal}
                          onChange={e => {
                            this.changeCheck(e, "sumber_bahan_baku_lokal");
                          }}
                        />Lokal
                      </div>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.sumber_bahan_baku.luar_kota}
                          checked={this.state.sumber_bahan_baku.luar_kota}
                          onChange={e => {
                            this.changeCheck(e, "sumber_bahan_baku_luar_kota");
                          }}
                        />Luar Kota
                      </div>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.sumber_bahan_baku.impor}
                          checked={this.state.sumber_bahan_baku.impor}
                          onChange={e => {
                            this.changeCheck(e, "sumber_bahan_baku_impor");
                          }}
                        />Impor */}
                  </div>
                </FormGroup>

              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Daerah Pemasaran</b>
                  </Label>
                  <div className="col-sm-8">
                    <Select
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.changeSelectValue(e, 'pemasaran');
                      }}
                      value={this.state.dt.pemasaran}
                      defaultValue={this.state.dt.pemasaran}
                      name="pemasaran"
                      options={[
                        {value: 'Lokal', label: 'Lokal'},
                        {value: 'Regional', label: 'Regional'},
                        {value: 'Nasional', label: 'Nasional'},
                        {value: 'Impor', label: 'Impor'},
                      ]}
                      placeholder="Pilih Daerah Pemasaran"
                      isClearable
                    />
                  </div>
                  {/* <div className="col-sm-8">
                    <div className="row">
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.pemasaran.lokal}
                          checked={this.state.pemasaran.lokal}
                          onChange={e => {
                            this.changeCheck(e, "pemasaran_lokal");
                          }}
                        />Lokal
                      </div>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.pemasaran.regional}
                          checked={this.state.pemasaran.regional}
                          onChange={e => {
                            this.changeCheck(e, "pemasaran_regional");
                          }}
                        />Regional
                      </div>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.pemasaran.nasional}
                          checked={this.state.pemasaran.nasional}
                          onChange={e => {
                            this.changeCheck(e, "pemasaran_nasional");
                          }}
                        />Nasional
                      </div>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.pemasaran.impor}
                          checked={this.state.pemasaran.impor}
                          onChange={e => {
                            this.changeCheck(e, "pemasaran_impor");
                          }}
                        />Impor
                      </div>
                    </div>
                  </div> */}
              </FormGroup>

              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Legalitas Usaha</b>
                  </Label>
                  <div className="col-sm-8">
                    <div className="row">
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.legalitas_usaha.siup}
                          checked={this.state.legalitas_usaha.siup}
                          onChange={e => {
                            this.changeCheck(e, "legalitas_usaha_siup");
                          }}
                        /> &nbsp;&nbsp; SIUP
                      </div>
                      <br/>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.legalitas_usaha.situ}
                          checked={this.state.legalitas_usaha.situ}
                          onChange={e => {
                            this.changeCheck(e, "legalitas_usaha_situ");
                          }}
                        /> &nbsp;&nbsp; SITU
                      </div>
                      <br/>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.legalitas_usaha.tdp}
                          checked={this.state.legalitas_usaha.tdp}
                          onChange={e => {
                            this.changeCheck(e, "legalitas_usaha_tdp");
                          }}
                        /> &nbsp;&nbsp; TDP
                      </div>
                      <br/>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.legalitas_usaha.npwp}
                          checked={this.state.legalitas_usaha.npwp}
                          onChange={e => {
                            this.changeCheck(e, "legalitas_usaha_npwp");
                          }}
                        /> &nbsp;&nbsp; NPWP
                      </div>
                      <br/>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.legalitas_usaha.domisili}
                          checked={this.state.legalitas_usaha.domisili}
                          onChange={e => {
                            this.changeCheck(e, "legalitas_usaha_domisili");
                          }}
                        /> &nbsp;&nbsp; Domisili
                      </div>
                      <br/>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.legalitas_usaha.halal}
                          checked={this.state.legalitas_usaha.halal}
                          onChange={e => {
                            this.changeCheck(e, "legalitas_usaha_halal");
                          }}
                        /> &nbsp;&nbsp; Halal
                      </div>
                      <br/>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.legalitas_usaha.hak_paten}
                          checked={this.state.legalitas_usaha.hak_paten}
                          onChange={e => {
                            this.changeCheck(e, "legalitas_usaha_hak_paten");
                          }}
                        /> &nbsp;&nbsp; Hak Paten
                      </div>
                      <br/>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.legalitas_usaha.p_irt}
                          checked={this.state.legalitas_usaha.p_irt}
                          onChange={e => {
                            this.changeCheck(e, "legalitas_usaha_p_irt");
                          }}
                        /> &nbsp;&nbsp; P-IRT
                      </div>
                      <br/>
                    </div>
                  </div>
              </FormGroup>

              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Status Tempat Usaha</b>
                  </Label>
                  <div className="col-sm-8">
                    <Select
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.changeSelectValue(e, 'status_tempat_usaha');
                      }}
                      value={this.state.dt.status_tempat_usaha}
                      defaultValue={this.state.dt.status_tempat_usaha}
                      name="status_tempat_usaha"
                      options={[
                        {value: 'Milik Sendiri', label: 'Milik Sendiri'},
                        {value: 'Sewa', label: 'Sewa'},
                      ]}
                      placeholder="Pilih Status Tempat Usaha"
                      isClearable
                    />
                  </div>
                  {/* <div className="col-sm-8">
                    <div className="row">
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.status_tempat_usaha.milik_sendiri}
                          checked={this.state.status_tempat_usaha.milik_sendiri}
                          onChange={e => {
                            this.changeCheck(e, "status_tempat_usaha_milik_sendiri");
                          }}
                        />Milik Sendiri
                      </div>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.status_tempat_usaha.sewa}
                          checked={this.state.status_tempat_usaha.sewa}
                          onChange={e => {
                            this.changeCheck(e, "status_tempat_usaha_sewa");
                          }}
                        />Sewa
                      </div>
                    </div>
                  </div> */}
              </FormGroup>


               {/* Data Usaha */}
               <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>DATA PERSYARATAN</b>
                </Label>
              </FormGroup>
              {Object.keys(this.state.linkGambar).map((attribute) => {
                let titleFile = 'dokumen'
                switch (attribute) {
                  case 'surat_pengantar_lokasi_usaha':
                    titleFile = "Surat Pengantar Lokasi Usaha"
                    break;
                  case 'fotocopy_ktp':
                    titleFile = "Fotocopy KTP"
                    break;
                  case 'fotocopy_kk':
                    titleFile = "Fotocopy KK"
                    break;
                  case 'pas_foto':
                    titleFile = "Pas Foto"
                    break;
                  case 'npwp':
                    titleFile = "NPWP"
                    break;
                  default:
                    // no default
                    break;
                }

                return (
                  <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>File {titleFile} (jpg/pdf)</b>
                    </Label>
                    <div
                      className="col-sm-8"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <input id="file-upload" type="file" onChange={(event) => {
                        this.onFileChange(event, attribute)
                      }} accept="image/png, image/jpg, image/jpeg, application/pdf" required={!this.state.isUpdate} />
                      {this.state.linkGambar[attribute].link !== "" ? (
                          this.state.linkGambar[attribute].type === "pdf" ? (
                            <a href={this.state.linkGambar[attribute].link}>Lihat file</a>
                          ) : (
                            <a 
                              href='/'
                              onClick={(e) => {
                                e.preventDefault()
                                let status = { ...this.state.status };
                                status.fotoPersyaratan = true;
                                this.setState({
                                  status,
                                  fotoPersyaratan: this.state.linkGambar[attribute].link,
                                  titlePersyaratan: titleFile,
                                });
                              }}
                              >
                                Lihat file
                            </a>
                          )

                        ) : (
                          ""
                        )}
                    </div>
                  </FormGroup>
                )
              })}
              

              

            </div>
          
            <div className="modal-footer" align="center">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
                >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
                >
                Selesai
              </button>
            </div>
          </Form>
				</Modal>
				
        <Modal
					open={this.state.status.formDetail}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: "100%" } }}
				>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header" align="center">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>

            <div className="modal-body">
              {/* Data Pelapor */}
              <div style={{ border: '3px outset #e3641b', borderRadius: '25px', padding:'20px', paddingBottom:'20px',}}>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>DATA PELAPOR</b>
                  </Label>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>NIK Pelapor</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                    {/* {this.state.dt.nik2Stat} */}
                    <h6 style={{ margin: 0 }}>
                      {this.state.detailSurat ? this.state.detailSurat.nik_pelapor : ''}
                    </h6>
                  </div>
                </FormGroup>

                <div style={{ display: this.state.dataPenduduk2 }}>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      Nama
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      {/* {this.state.dt.nama_pelapor} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.detailSurat ? this.state.detailSurat.nama_pelapor : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      No. HP Pelapor
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      {/* {this.state.dt.no_hp_pelapor} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.detailSurat ? this.state.detailSurat.no_hp_pelapor : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      Pekerjaan Pelapor
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      <h6 style={{ margin: 0 }}>
                        {this.state.detailSurat ? this.state.detailSurat.pekerjaan_pelapor : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      Alamat Pelapor
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      <h6 style={{ margin: 0 }}>
                        {this.state.detailSurat ? this.state.detailSurat.alamat_pelapor : ''}
                      </h6>
                    </div>
                  </FormGroup>
                </div>
              </div>

              <br/>
              {/* Data Pemilik */}
              <div style={{ border: '3px outset #e3c21b', borderRadius: '25px', padding:'20px',paddingBottom:'20px',}}>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>DATA PEMILIK</b>
                  </Label>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>NIK Pemilik</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                    {/* {this.state.dt.nikStat} */}
                    <h6 style={{ margin: 0 }}>
                      {this.state.dataPelayanan ? this.state.dataPelayanan.nik : ''}
                    </h6>
                  </div>
                </FormGroup>
                
                <div style={{ display: this.state.dataPenduduk }}>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      {/* {this.state.dt.nama_pemilik} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.nama_pemilik : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Jenis Kelamin</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      {/* {this.state.dt.nama_pemilik} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.jenis_kelamin : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>No. Telp</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      {/* {this.state.dt.telp} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.telp : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Email</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      {/* {this.state.dt.email} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.email : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Pendidikan Terakhir</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      {/* {this.state.dt.telp} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.pendidikan_terakhir : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Alamat</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      {/* {this.state.dt.telp} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.alamat : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Kabupaten / Kota</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.kota : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Kecamatan</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.kecamatan : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Kelurahan / {JENIS_DESA}</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.kelurahan : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px'  }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Kode Pos</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      {/* {this.state.dt.kode_pos} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.kode_pos : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>NPWP</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      {/* {this.state.dt.npwp} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.npwp : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Jenis NPWP</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: "flex",  alignItems: "center" }}>
                      {/* {this.state.dt.jenis_npwp2} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.jenis_npwp.value : ''}
                      </h6>
                    </div>
                  </FormGroup>
                </div>
              </div>
              
              <br/>
              {/* Data Usaha */}
              <div style={{ border: '3px outset #b4e31b', borderRadius: '25px', paddingBottom:'20px', padding:'20px'}}>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>DATA USAHA</b>
                  </Label>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama Usaha</b>
                    </Label>
                    <div className="col-sm-8">
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.nama_usaha}
                      </h6>
                    </div>
                </FormGroup>
                
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Alamat Usaha</b>
                    </Label>
                    <div className="col-sm-8">
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.alamat_usaha}
                      </h6>
                    </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>{JENIS_DUSUN}</b>
                    </Label>
                    <div className="col-sm-8">
                      {/* {this.state.dt.dusun_usaha} */}
                      
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.dusun_usaha.label : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Rw</b>
                    </Label>
                    <div className="col-sm-8">
                      {/* {this.state.dt.rw_usaha} */}
                      
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.rw_usaha.label : ''}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Rt</b>
                    </Label>
                    <div className="col-sm-8">
                      {/* {this.state.dt.rt_usaha} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dataPelayanan ? this.state.dataPelayanan.rt_usaha.label : ''}
                      </h6>
                    </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Kode Pos</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* {this.state.dt.kode_pos_usaha} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.kode_pos_usaha}
                      </h6>
                    </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>No. Telp Usaha</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* {this.state.dt.telp_usaha} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.telp_usaha}
                      </h6>
                    </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Tahun Berdiri</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* {this.state.dt.tahun_berdiri} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.tahun_berdiri}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Bentuk Perusahaan</b>
                      
                    </Label>
                    <div className="col-sm-8">
                      {/* {this.state.dt.bentuk_perusahaan2} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.bentuk_perusahaan2}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Jenis Usaha</b>
                      
                    </Label>
                    <div className="col-sm-8">
                      {/* {this.state.dt.jenis_usaha2} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.jenis_usaha2}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Keterangan Jenis Usaha</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* {this.state.dt.uraian_usaha} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.uraian_usaha}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Sarana Usaha</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* {this.state.dt.sarana_usaha} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.sarana_usaha}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Jumlah Karyawan</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* {this.state.dt.jumlah_karyawan} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.jumlah_karyawan}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Modal Sendiri</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* { this.state.dt.permodalan_sendiri} */}
                      <h6 style={{ margin: 0 }}>
                        { this.state.dt.permodalan_sendiri}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Modal Luar</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* {this.state.dt.permodalan_luar} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.permodalan_luar}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Kategori Omset / Tahun</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* {this.state.dt.kategori_omset2} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.kategori_omset2}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nominal Omset / Tahun</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* {this.state.dt.nominal_omset} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.nominal_omset}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Kategori Aset</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* {this.state.dt.kategori_aset2} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.kategori_aset2}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nominal Aset</b>
                      
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* {this.state.dt.nominal_aset} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.nominal_aset}
                      </h6>
                    </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Sumber Bahan Baku</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="row">
                        <div className="col-sm-4">
                          {/* {this.state.dt.sumber_bahan_baku} */}
                          <h6 style={{ margin: 0 }}>
                            {this.state.dt.sumber_bahan_baku}
                          </h6>
                        </div>
                      </div>
                    </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Daerah Pemasaran</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* {this.state.dt.pemasaran} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.pemasaran}
                      </h6>
                    </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Legalitas Usaha</b>
                    </Label>
                    <div className="col-sm-8">
                      <div className="row">
                        <div className="col-sm-4">
                          <input type="checkbox"
                            defaultChecked={this.state.legalitas_usaha.siup}
                            checked={this.state.legalitas_usaha.siup}
                            onChange={e => {
                              this.changeCheck(e, "legalitas_usaha_siup");
                            }}
                            disabled
                          />  SIUP
                        </div>
                        <div className="col-sm-4">
                          <input type="checkbox"
                            defaultChecked={this.state.legalitas_usaha.situ}
                            checked={this.state.legalitas_usaha.situ}
                            onChange={e => {
                              this.changeCheck(e, "legalitas_usaha_situ");
                            }}
                            disabled
                          />  SITU
                        </div>
                        <div className="col-sm-4">
                          <input type="checkbox"
                            defaultChecked={this.state.legalitas_usaha.tdp}
                            checked={this.state.legalitas_usaha.tdp}
                            onChange={e => {
                              this.changeCheck(e, "legalitas_usaha_tdp");
                            }}
                            disabled
                          />  TDP
                        </div>
                        <div className="col-sm-4">
                          <input type="checkbox"
                            defaultChecked={this.state.legalitas_usaha.npwp}
                            checked={this.state.legalitas_usaha.npwp}
                            onChange={e => {
                              this.changeCheck(e, "legalitas_usaha_npwp");
                            }}
                            disabled
                          />  NPWP
                        </div>
                        <div className="col-sm-4">
                          <input type="checkbox"
                            defaultChecked={this.state.legalitas_usaha.domisili}
                            checked={this.state.legalitas_usaha.domisili}
                            onChange={e => {
                              this.changeCheck(e, "legalitas_usaha_domisili");
                            }}
                            disabled
                          />  Domisili
                        </div>
                        <div className="col-sm-4">
                          <input type="checkbox"
                            defaultChecked={this.state.legalitas_usaha.halal}
                            checked={this.state.legalitas_usaha.halal}
                            onChange={e => {
                              this.changeCheck(e, "legalitas_usaha_halal");
                            }}
                            disabled
                          />  Halal
                        </div>
                        <div className="col-sm-4">
                          <input type="checkbox"
                            defaultChecked={this.state.legalitas_usaha.hak_paten}
                            checked={this.state.legalitas_usaha.hak_paten}
                            onChange={e => {
                              this.changeCheck(e, "legalitas_usaha_hak_paten");
                            }}
                            disabled
                          />  Hak Paten
                        </div>
                        <div className="col-sm-4">
                          <input type="checkbox"
                            defaultChecked={this.state.legalitas_usaha.p_irt}
                            checked={this.state.legalitas_usaha.p_irt}
                            onChange={e => {
                              this.changeCheck(e, "legalitas_usaha_p_irt");
                            }}
                            disabled
                          />  P-IRT
                        </div>
                      </div>
                    </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Status Tempat Usaha</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* {this.state.dt.status_tempat_usaha} */}
                      <h6 style={{ margin: 0 }}>
                        {this.state.dt.status_tempat_usaha}
                      </h6>
                    </div>
                </FormGroup>
              </div>
            </div>
          
            <div className="modal-footer" align="center">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
                >
                Tutup
              </button>
            </div>
          </Form>
				</Modal>

        {/* Modal Detail Gambar */}
        <Modal
          open={this.state.status.fotoPersyaratan}
          styles={{
            modal: {
              width: '90%'
            }
          }}
          onClose={() => {
            let status = { ...this.state.status };
            status.fotoPersyaratan = false;
            this.setState({ status });
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title">{this.state.titlePersyaratan}</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
              {this.state.fotoPersyaratan !== '' ? (
                <img
                alt="gambarpersyaratan"
                src={this.state.fotoPersyaratan}
                style={{ width: "100%" }}
              />
              ) : (
                <h6 style={{ textAlign: "center" }}>Tidak ada Foto</h6>
              )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              // disabled={this.state.status.btnForm}
              onClick={() => {
                let status = { ...this.state.status };
                status.fotoPersyaratan = false;
                this.setState({ status });
              }}
              style={{ fontWeight: 600 }}
            >
              Tutup
            </button>
          </div>
        </Modal>
				
      </div>
    );
  }
}

export default KecamatanRekomendasiUsaha;