import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import '../assets/css/kiosk.css';
import { Post, Get, JENIS_DESA, JENIS_DUSUN } from '../function/Koneksi';

import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Datetime from 'react-datetime';
// import Keyboard from 'react-simple-keyboard';
// import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import '../assets/css/site.css';

const frmDef = {
	id: '',
	idbaru: '',
	id_kk: '',
	nama: '',
	alamat: '',
	id_keldes: '',
	data_keldes: '',
	tempat_lahir: '',
	tanggal_lahir: new Date(),
	jk: '',
	shdk: '',
	agama: '',
	pendidikan_terakhir: '',
	pekerjaan: '',
	nama_ibu_kandung: '',
	status_penduduk: '',
	status_perkawinan: ''
};

export default class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			navigate: false,
			data: [],
			data_pars: [],
			pagination: [],
			status: {
				formBerkas: false,
				formExport: false,
				form: false,
				btnForm: false,
				btnAksi: false,
				select: false,
				modalKonfirmasi2: false,
				keyboard: false
			},

			scrollUp: null,

			//keyboard
			layoutName: 'default',
			inputName: 'input1',
			inputName1: 'kk',
			input: {},
			inputPattern: /^\d+$/,
			display: 'none',
			focus: false,
			hasFocus: false,
			style: {},

			// =================================
			// =========== Alert ============
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			// ==============================

			dt: frmDef,

			wilayah: 'inherit',
			// =========== Select 2 ============
			frmPrv: [],
			prvStat: null,
			frmKbkt: [],
			kbktStat: null,
			frmKec: [],
			kecStat: null,
			frmKlds: [],
			kldsStat: null,
			frmShdk: [],
			shdkStat: null,
			frmAgm: [],
			agmStat: null,
			frmPtr: [],
			ptrStat: null,
			frmPkr: [],
			pkrStat: null,
			frmKk: [],
			kkStat: null,
			frmStp: [],
			stpStat: null,
			frmJk: [],
			jkStat: null,
			frmSpk: [],
			spkStat: null,
			frmJbk: [],
			jbkStat: null,
			frmSbk: [],
			sbkStat: null,
			frmSkh: [],
			skhStat: null,
			statusberkas: [],
			// =================================
			// Loading
			loading: true,
		};
		this.showKeyboard = this.showKeyboard.bind(this);
	}

	componentDidMount() {

		let rolenya = this.props.location.state.role
		if (rolenya === "login") {
			let id_pdd = this.props.location.state.data.results.id;

			Get('penduduk/find', id_pdd, (data) => {
				this.setState({
					data_pars: data,
					kkStat: { value: data.results.data_kk.id, label: data.results.data_kk.id },
					shdkStat: { value: data.results.data_shdk.uuid, label: data.results.data_shdk.nama },
					agmStat: { value: data.results.data_agama.uuid, label: data.results.data_agama.nama },
					ptrStat: {
						value: data.results.data_pendidikan_terakhir.uuid,
						label: data.results.data_pendidikan_terakhir.nama
					},
					pkrStat: { value: data.results.data_pekerjaan.uuid, label: data.results.data_pekerjaan.nama },
					jkStat: { value: data.results.jk, label: data.results.jk },
					stpStat: { value: data.results.status_penduduk, label: data.results.status_penduduk },
					dt: data.results,
					startDate: new Date(data.results.tanggal_lahir),
					spkStat: { value: data.results.status_perkawinan, label: data.results.status_perkawinan },
					skhStat: {
						value: data.results.status_kehidupan,
						label: data.results.status_kehidupan === 0 ? 'Meninggal' : 'Hidup'
					},
					tanggal_lahirkk: data.results.tanggal_lahir,

					loading: false,
				});
			});
		}
		else if (rolenya === "tambah") {
			// //console.log("Rolenya bos")
			// //console.log(this.props.location.state.role)

			let id_pdd = this.props.location.state.data.nik;

			Get('temp/penduduk/find', id_pdd, (data) => {
				// //console.log("Data Role Tambah")
				// //console.log(data.results)

				this.setState({
					data_pars: data
				})

				if (data.results.data_kk == null) {
					this.setState({
						kkStat: { value: data.results.data_kk_pend_temp.id, label: data.results.data_kk_pend_temp.id },
						shdkStat: { value: data.results.data_shdk.uuid, label: data.results.data_shdk.nama },
						agmStat: { value: data.results.data_agama.uuid, label: data.results.data_agama.nama },
						ptrStat: {
							value: data.results.data_pendidikan_terakhir.uuid,
							label: data.results.data_pendidikan_terakhir.nama
						},
						pkrStat: { value: data.results.data_pekerjaan.uuid, label: data.results.data_pekerjaan.nama },
						jkStat: { value: data.results.jk, label: data.results.jk },
						stpStat: { value: data.results.status_penduduk, label: data.results.status_penduduk },
						dt: data.results,
						startDate: new Date(data.results.tanggal_lahir),
						spkStat: { value: data.results.status_perkawinan, label: data.results.status_perkawinan },
						skhStat: {
							value: data.results.status_kehidupan,
							label: data.results.status_kehidupan === 0 ? 'Meninggal' : 'Hidup'
						},
						tanggal_lahirkk: data.results.tanggal_lahir,
						loading: false,
					});
				}
				else if (data.results.data_kk_pend_temp == null) {
					this.setState({
						kkStat: { value: data.results.data_kk.id, label: data.results.data_kk.id },
						shdkStat: { value: data.results.data_shdk.uuid, label: data.results.data_shdk.nama },
						agmStat: { value: data.results.data_agama.uuid, label: data.results.data_agama.nama },
						ptrStat: {
							value: data.results.data_pendidikan_terakhir.uuid,
							label: data.results.data_pendidikan_terakhir.nama
						},
						pkrStat: { value: data.results.data_pekerjaan.uuid, label: data.results.data_pekerjaan.nama },
						jkStat: { value: data.results.jk, label: data.results.jk },
						stpStat: { value: data.results.status_penduduk, label: data.results.status_penduduk },
						dt: data.results,
						startDate: new Date(data.results.tanggal_lahir),
						spkStat: { value: data.results.status_perkawinan, label: data.results.status_perkawinan },
						skhStat: {
							value: data.results.status_kehidupan,
							label: data.results.status_kehidupan === 0 ? 'Meninggal' : 'Hidup'
						},
						tanggal_lahirkk: data.results.tanggal_lahir,
						loading: false,
					});
				}
			});
		}



		Get('provinsi/list', null, (data) => {
			let frmPrv = [];
			data.results.forEach((dt) => {
				frmPrv.push({ value: dt.id, label: dt.nama });
			});
			this.setState({
				frmPrv,
				frmTingkat: [
					{ value: 'dusun', label: JENIS_DUSUN },
					{ value: 'rw', label: 'RW' },
					{ value: 'rt', label: 'RT' }
				]
			});
		});

		Get('shdk/list', null, (data) => {
			let frmShdk = [];
			data.results.forEach((dt) => {
				frmShdk.push({ value: dt.uuid, label: dt.nama });
			});
			this.setState({ frmShdk });
		});
		Get('agama/list', null, (data) => {
			let frmAgm = [];
			data.results.forEach((dt) => {
				frmAgm.push({ value: dt.uuid, label: dt.nama });
			});
			this.setState({ frmAgm });
		});
		Get('jenjang-pendidikan/list', null, (data) => {
			let frmPtr = [];
			data.results.forEach((dt) => {
				frmPtr.push({ value: dt.uuid, label: dt.nama });
			});
			this.setState({ frmPtr });
		});
		Get('pekerjaan/list', null, (data) => {
			let frmPkr = [];
			data.results.forEach((dt) => {
				frmPkr.push({ value: dt.uuid, label: dt.nama });
			});
			this.setState({ frmPkr });
		});
		Get('select-enum/penduduk/status_penduduk', null, (data) => {
			let frmStp = [];
			data.forEach((dt) => {
				frmStp.push({ value: dt, label: dt });
			});
			this.setState({ frmStp });
		});
		Get('select-enum/penduduk/jk', null, (data) => {
			let frmJk = [];
			data.forEach((dt) => {
				frmJk.push({ value: dt, label: dt });
			});
			this.setState({ frmJk });
		});
		Get('select-enum/penduduk/status_perkawinan', null, (data) => {
			let frmSpk = [];
			data.forEach((dt) => {
				frmSpk.push({ value: dt, label: dt });
			});
			this.setState({ frmSpk });
		});
		Get('select-enum/berkas_verifikasi/jenis', null, (data) => {
			let frmJbk = [];
			data.forEach((dt) => {
				frmJbk.push({ value: dt, label: dt });
			});
			this.setState({ frmJbk });
		});
		this.setState({
			frmSbk: [{ value: 0, label: 'Belum Verifikasi' }, { value: 1, label: 'Sudah Verifikasi' }],
			statusberkas: ['Belum Verifikasi', 'Sudah Verifikasi'],
			frmSkh: [{ value: 1, label: 'Hidup' }, { value: 0, label: 'Meninggal' }]
		});
	}

	// ==============================================
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ============================== Ubah Data ===============================

	// ubahData = (id) => {
	// 	this.bukaForm();
	// 	this.state.status.btnAksi = true;
	// 	this.state.judul = 'Edit Profil';
	// 	// this.state.kkStat = {value:'a', label:'data.results.data_kk.id'};
	// 	let id_pdd = this.props.location.state.data.results.id;
	// 	Get('penduduk/find', id_pdd, (data) => {
	// 		this.setState({
	// 			kkStat: { value: data.results.data_kk.id, label: data.results.data_kk.id },
	// 			shdkStat: { value: data.results.data_shdk.uuid, label: data.results.data_shdk.nama },
	// 			agmStat: { value: data.results.data_agama.uuid, label: data.results.data_agama.nama },
	// 			ptrStat: {
	// 				value: data.results.data_pendidikan_terakhir.uuid,
	// 				label: data.results.data_pendidikan_terakhir.nama
	// 			},
	// 			pkrStat: { value: data.results.data_pekerjaan.uuid, label: data.results.data_pekerjaan.nama },
	// 			jkStat: { value: data.results.jk, label: data.results.jk },
	// 			stpStat: { value: data.results.status_penduduk, label: data.results.status_penduduk },
	// 			dt: data.results,
	// 			startDate: new Date(data.results.tanggal_lahir),
	// 			spkStat: { value: data.results.status_perkawinan, label: data.results.status_perkawinan },
	// 			skhStat: {
	// 				value: data.results.status_kehidupan,
	// 				label: data.results.status_kehidupan == 0 ? 'Meninggal' : 'Hidup'
	// 			}
	// 		});
	// 	});

	// 	this.forceUpdate();
	// };

	bukaForm = () => {
		this.setState({
			status:{
				...this.state.status,
				form: true,
			}
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			status:{
				...this.state.status,
				form: false,
			}
		})
		this.forceUpdate();
	};

	logOut(e) {
		e.preventDefault();
		// sessionStorage.removeItem('access_token');
		this.setState({ navigate: true });
	}

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		this.setState({
			status:{
				...this.state.status,
				btnForm: true,
			}
		})
		this.forceUpdate();
		let id = this.props.location.state.data.results.id;
		let addPenduduk = {
			id: this.props.location.state.data.results.id,
			id_kk: this.props.location.state.data.results.id_kk,
			id_keldes: this.state.dt.id_keldes,
			nama: document.getElementById('nama').value,
			alamat: document.getElementById('alamat').value,
			tempat_lahir: document.getElementById('tempat_lahir').value,
			tanggal_lahir: this.state.tanggal_lahirkk,
			jk: this.state.jkStat.value,
			shdk: this.state.shdkStat.value,
			agama: this.state.agmStat.value,
			status_perkawinan: this.state.spkStat.value,
			status_kehidupan: this.state.skhStat.value,
			pendidikan_terakhir: this.state.ptrStat.value,
			pekerjaan: this.state.pkrStat.value,
			nama_ibu_kandung: document.getElementById('nama_ibu_kandung').value,
			nama_ayah_kandung: document.getElementById('nama_ayah_kandung').value,
			status_penduduk: this.state.stpStat.value
		};
		let psn = 'Ubah';
		let resstat = 200;
		let metode = 'update';

		Post('penduduk/' + metode, id, addPenduduk, (res) => {
			this.setState({
				status:{
					...this.state.status,
					btnForm: false,
				}
			})
			if (res.status === resstat) {
				this.onCloseModal('Basic');
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Penduduk',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					// show: true,
					basicType: 'danger',
					basicTitle: 'Data Penduduk',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.forceUpdate();
			this.componentDidMount();
		});
	};

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: e, kbktStat: null, kecStat: null, kldsStat: null, frmKk: [] });
					break;
				case 'kab-kota':
					this.setState({ kbktStat: e, kecStat: null, kldsStat: null, frmKk: [] });
					break;
				case 'kecamatan':
					this.setState({ kecStat: e, kldsStat: null, frmKk: [] });
					break;
				case 'keldes':
					this.setState({ 
						kldsStat: e, 
						dt:{
							...this.state.dt,
							id_keldes: e.value,
						}
					});
					// Get('kartu-keluarga/keldes/' + e.value + '/list', null, (data) => {
					//     let frmKk = [];
					//     data.results.forEach((dt) => {
					//         frmKk.push({ value: dt.id, label: dt.id });
					//     });
					//     this.setState({ frmKk });
					// });
					break;
				case 'shdk':
					this.setState({ 
						shdkStat: e, 
						dt:{
							...this.state.dt,
							shdk: e.value,
						}
					});
					break;
				case 'agama':
					this.setState({ 
						agmStat: e, 
						dt:{
							...this.state.dt,
							agama: e.value,
						}
					});
					break;
				case 'pendidikan-terakhir':
					this.setState({ 
						ptrStat: e, 
						dt:{
							...this.state.dt,
							pendidikan_terakhir: e.value,
						}
					});
					break;
				case 'pekerjaan':
					this.setState({ 
						pkrStat: e, 
						dt:{
							...this.state.dt,
							pekerjaan: e.value,
						}
					});
					break;
				case 'kk':
					this.setState({ 
						kkStat: e, 
						dt:{
							...this.state.dt,
							id_kk: e.value,
						}
					});
					break;
				case 'status-penduduk':
					this.setState({ 
						stpStat: e, 
						dt:{
							...this.state.dt,
							status_penduduk: e.value,
						}
					});
					break;
				case 'jk':
					this.setState({ 
						jkStat: e, 
						dt:{
							...this.state.dt,
							jk: e.value,
						}
					});
					break;
				case 'status-perkawinan':
					this.setState({ 
						spkStat: e, 
						dt:{
							...this.state.dt,
							status_perkawinan: e.value,
						}
					});
					break;
				case 'jenis-berkas':
					this.setState({ jbkStat: e });
					break;
				case 'status-berkas':
					this.setState({ sbkStat: e });
					break;
				case 'status-kehidupan':
					this.setState({ skhStat: e });
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case 'provinsi':
					this.setState({
						prvStat: null,
						kbktStat: null,
						kecStat: null,
						kldsStat: null,
						frmKbkt: [],
						frmKec: [],
						frmKlds: []
					});
					break;
				case 'kab-kota':
					this.setState({ kbktStat: null, kecStat: null, kldsStat: null, frmKec: [], frmKlds: [] });
					break;
				case 'kecamatan':
					this.setState({ kecStat: null, kldsStat: null, frmKlds: [] });
					break;
				case 'keldes':
					this.setState({ 
						kldsStat: null, 
						dt:{
							...this.state.dt,
							id_keldes: 0,
						}
					});
					break;
				case 'shdk':
					this.setState({ 
						shdkStat: null, 
						dt:{
							...this.state.dt,
							shdk: 0,
						}
					});
					break;
				case 'agama':
					this.setState({ 
						agmStat: null, 
						dt:{
							...this.state.dt,
							agama: 0,
						}
					});
					break;
				case 'pendidikan-terakhir':
					this.setState({ 
						ptrStat: null, 
						dt:{
							...this.state.dt,
							pendidikan_terakhir: 0,
						}
					});
					break;
				case 'pekerjaan':
					this.setState({ 
						pkrStat: null, 
						dt:{
							...this.state.dt,
							pekerjaan: 0,
						}
					});
					break;
				case 'kk':
					this.setState({ 
						kkStat: null, 
						dt:{
							...this.state.dt,
							id_kk: 0,
						}
					});
					break;
				case 'status-penduduk':
					this.setState({ 
						stpStat: e, 
						dt:{
							...this.state.dt,
							status_penduduk: 0,
						}
					});
					break;
				case 'jk':
					this.setState({ 
						jkStat: e, 
						dt:{
							...this.state.dt,
							jk: 0,
						}
					});
					break;
				case 'status-perkawinan':
					this.setState({ 
						spkStat: e ,
						dt:{
							...this.state.dt,
							status_perkawinan: '',
						}
					});
					break;
				case 'jenis-berkas':
					this.setState({ jbkStat: e });
					break;
				case 'status-berkas':
					this.setState({ sbkStat: e });
					break;
				case 'status-kehidupan':
					this.setState({ skhStat: e });
					break;
				default:
					// no default
					break;
			}
		}
		this.setState({
			status:{
				...this.state.status,
				select: false,
			}
		})
		this.forceUpdate();
	};

	// ================== Tanggal ===================
	gantiTgl = (e, kk) => {
		// e.preventDefault();
		if (kk === 'kk') {
			// //console.log(typeof e.format('YYYY-MM-DD'))
			this.setState({
				tanggal_lahirkk: e.format('YYYY-MM-DD'),
			})

			this.forceUpdate();
			//  this.setState({tanggal_lahirkk:e.format('YYYY-MM-DD')})
			//console.log(this.state.tanggal_lahirkk);
		} else {
			// this.setState({tanggal_lahir:e.format('YYYY-MM-DD')})
			this.setState({
				tanggal_lahir: e.format('YYYY-MM-DD'),
			})
			this.forceUpdate();
		}
	};

	// ==============================================

	// EMBED KEyBOARD

	onOpenModal = (key) => {
		//console.log(key);
		this.setState({
			[key]: true,
			regis: true,
			judul: 'Edit Profil',
			status:{
				...this.state.status,
				btnAksi: true,
			}
		});

		// this.state.kkStat = {value:'a', label:'data.results.data_kk.id'};
		let rolenya = this.props.location.state.role
		if (rolenya === "login") {
			let id_pdd = this.props.location.state.data.results.id;
			Get('penduduk/find', id_pdd, (data) => {
				// //console.log('OPEN DATA');
				// //console.log(data.results);
				this.setState({
					input: {
						nama: data.results.nama,
						alamat: data.results.alamat,
						tempat_lahir: data.results.tempat_lahir,
						nama_ibu_kandung: data.results.nama_ibu_kandung,
						nama_ayah_kandung: data.results.nama_ayah_kandung
					},
					kkStat: { value: data.results.data_kk.id, label: data.results.data_kk.id },
					shdkStat: { value: data.results.data_shdk.uuid, label: data.results.data_shdk.nama },
					agmStat: { value: data.results.data_agama.uuid, label: data.results.data_agama.nama },
					ptrStat: {
						value: data.results.data_pendidikan_terakhir.uuid,
						label: data.results.data_pendidikan_terakhir.nama
					},
					pkrStat: { value: data.results.data_pekerjaan.uuid, label: data.results.data_pekerjaan.nama },
					jkStat: { value: data.results.jk, label: data.results.jk },
					stpStat: { value: data.results.status_penduduk, label: data.results.status_penduduk },
					dt: data.results,
					startDate: new Date(data.results.tanggal_lahir),
					spkStat: { value: data.results.status_perkawinan, label: data.results.status_perkawinan },
					skhStat: {
						value: data.results.status_kehidupan,
						label: data.results.status_kehidupan === 0 ? 'Meninggal' : 'Hidup'
					}
				});
			});
		}
		else if (rolenya === "tambah") {
			// //console.log("Rolenya bos")
			// //console.log(this.props.location.state.role)

			let id_pdd = this.props.location.state.data.nik;

			Get('temp/penduduk/find', id_pdd, (data) => {
				// //console.log("Data Role Tambah")
				// //console.log(data.results)

				if (data.results.data_kk == null) {
					this.setState({
						input: {
							nama: data.results.nama,
							alamat: data.results.alamat,
							tempat_lahir: data.results.tempat_lahir,
							nama_ibu_kandung: data.results.nama_ibu_kandung,
							nama_ayah_kandung: data.results.nama_ayah_kandung
						},
						kkStat: { value: data.results.data_kk_pend_temp.id, label: data.results.data_kk_pend_temp.id },
						shdkStat: { value: data.results.data_shdk.uuid, label: data.results.data_shdk.nama },
						agmStat: { value: data.results.data_agama.uuid, label: data.results.data_agama.nama },
						ptrStat: {
							value: data.results.data_pendidikan_terakhir.uuid,
							label: data.results.data_pendidikan_terakhir.nama
						},
						pkrStat: { value: data.results.data_pekerjaan.uuid, label: data.results.data_pekerjaan.nama },
						jkStat: { value: data.results.jk, label: data.results.jk },
						stpStat: { value: data.results.status_penduduk, label: data.results.status_penduduk },
						dt: data.results,
						startDate: new Date(data.results.tanggal_lahir),
						spkStat: { value: data.results.status_perkawinan, label: data.results.status_perkawinan },
						skhStat: {
							value: data.results.status_kehidupan,
							label: data.results.status_kehidupan === 0 ? 'Meninggal' : 'Hidup'
						}
					});
				}
				else if (data.results.data_kk_pend_temp == null) {
					this.setState({
						input: {
							nama: data.results.nama,
							alamat: data.results.alamat,
							tempat_lahir: data.results.tempat_lahir,
							nama_ibu_kandung: data.results.nama_ibu_kandung,
							nama_ayah_kandung: data.results.nama_ayah_kandung
						},
						kkStat: { value: data.results.data_kk.id, label: data.results.data_kk.id },
						shdkStat: { value: data.results.data_shdk.uuid, label: data.results.data_shdk.nama },
						agmStat: { value: data.results.data_agama.uuid, label: data.results.data_agama.nama },
						ptrStat: {
							value: data.results.data_pendidikan_terakhir.uuid,
							label: data.results.data_pendidikan_terakhir.nama
						},
						pkrStat: { value: data.results.data_pekerjaan.uuid, label: data.results.data_pekerjaan.nama },
						jkStat: { value: data.results.jk, label: data.results.jk },
						stpStat: { value: data.results.status_penduduk, label: data.results.status_penduduk },
						dt: data.results,
						startDate: new Date(data.results.tanggal_lahir),
						spkStat: { value: data.results.status_perkawinan, label: data.results.status_perkawinan },
						skhStat: {
							value: data.results.status_kehidupan,
							label: data.results.status_kehidupan === 0 ? 'Meninggal' : 'Hidup'
						}
					});
				}
			});
		}

		this.forceUpdate();
	};

	onCloseModal = (key) => {
		this.setState({
			[key]: false,
			display: 'none',
			regis: 'false',
			input: {},
			tanggal_lahir_supir: null,
			rencana_berangkat: null,
		});
		this.forceUpdate();
	};

	// on focus
	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				//console.log('Active input', inputName);
			}
		);
	};

	// end on focus

	// ketika Klik Keyboard atau mengisi data
	// onChangeAll = (inputObj) => {

	// 	let inisial = this.state.inputName

	// 	this.setState({
	// 		input: { inisial: inputObj }
	// 	});

	// 	//console.log('Input changed', updatedInputObj);
	// 	//console.log('Input Name All', this.state.inputName);
	// 	//console.log('Input Name All', this.state.input.inisial);
	// };

	// onChangeAll = (inputObj) => {
	// let nama_set = this.state.inputName
	// let inisial = this.state.input[this.state.inputName]
	// let ceka = this.keyboard.setInput();
	// // this.setState({ input: { nama_set: gabung } });
	// // this.state = [...this.state.dataUsaha, data];
	// // let updatedInputObj = {
	// // 	...this.state.input,
	// // 	[this.state.inputName]: inputObj
	// // };
	// // this.setState({
	// // 	input: updatedInputObj
	// // });
	// this.forceUpdate();
	// //console.log("===============")
	// //console.log('Input changed: ', inputObj);
	// //console.log('Inisial: ', inisial);
	// //console.log('Inputname: ', this.state.inputName);
	// //console.log('namaset: ', nama_set);
	// //console.log('ceka: ', ceka);
	// //console.log("===============")
	// };
	onChangeAll = (inputObj) => {
		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputObj[this.state.inputName]
		};
		this.setState({
			// input: inputObj
			input: updatedInputObj
		});
	};

	onKeyPress = (button) => {
		// //console.log('Button pressed', button);

		if (button === '{shift}' || button === '{lock}') {
			this.handleShift();
		}

		// const old = this.state.input[this.state.inputName];

		// let newChar = '';

		// if (typeof old != 'undefined') {
		// 	if (button === '{bksp}') {
		// 		newChar = old.slice(0, -1);
		// 	} else if (button === '{tab}') {
		// 		newChar = old + '\t';
		// 	} else if (button === '{space}') {
		// 		newChar = old + ' ';
		// 	} else if (button !== '{enter}' && button !== '{shift}' && button !== '{lock}') {
		// 		newChar = old + button;
		// 	} else {
		// 		newChar = old;
		// 	}
		// } else {
		// 	if (
		// 		button !== '{bksp}' &&
		// 		button !== '{enter}' &&
		// 		button !== '{tab}' &&
		// 		button !== '{shift}' &&
		// 		button !== '{lock}' &&
		// 		button !== '{space}'
		// 	) {
		// 		newChar = button;
		// 	}
		// }

		// let updatedInputObj = {
		// 	...this.state.input,
		// 	[this.state.inputName]: newChar
		// };

		// this.setState({
		// 	input: updatedInputObj
		// });
	};

	// onKeyPress = (button) => {
	// 	//console.log('Button pressed', button);
	// 	if (button === '{shift}' || button === '{lock}') this.handleShift();
	// 	const old = this.state.input[this.state.inputName];

	// 	let newChar = '';

	// 	if (typeof old != 'undefined') {
	// 		if (button === '{bksp}') {
	// 			newChar = old.slice(0, -1);
	// 		} else if (button === '{tab}') {
	// 			newChar = old + '\t';
	// 		} else if (button === '{space}') {
	// 			newChar = old + ' ';
	// 		} else if (button !== '{enter}' && button !== '{shift}' && button !== '{lock}') {
	// 			newChar = old + button;
	// 		} else {
	// 			newChar = old;
	// 		}
	// 	} else {
	// 		if (
	// 			button !== '{bksp}' &&
	// 			button !== '{enter}' &&
	// 			button !== '{tab}' &&
	// 			button !== '{space}' &&
	// 			button !== '{shift}' &&
	// 			button !== '{lock}'
	// 		) {
	// 			newChar = button;
	// 		}
	// 	}

	// 	let updatedInputObj = {
	// 		...this.state.input,
	// 		[this.state.inputName]: newChar
	// 	};

	// 	this.setState({
	// 		input: updatedInputObj
	// 	});
	// };

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	// End ketika Klik Keyboard atau mengisi data

	// showKeyboard = () => {
	// 	if (!this.state.focus || this.state.display) {
	// 		this.setState({
	// 			focus: true,
	// 			display: ''
	// 		});
	// 	}
	// 	// this.setState({
	// 	// 	display: '',
	// 	// 	// onFocus:true

	// 	//   });
	// 	this.forceUpdate();
	// };

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}
		this.forceUpdate();
	};

	showKeyboard1 = () => {
		this.setState({
			display: 'none',
			regis: 'false'
		});
		this.forceUpdate();
	};

	// Input keyboard
	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		//console.log(inputVal);
		//console.log(updatedInputObj);

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				}
			}
		);
	};

	onChangeInput1 = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				this.keyboard.setInput(inputVal);
			}
		);
	};

	// end input keyboard

	// END EMBED KEYBOARD

	render() {
		if (this.state.navigate || typeof this.props.location.state == 'undefined') {
			return <Redirect to="/" push={true} />;
		}
		//console.log('data parsingnya:', this.props.location.state);

		// const styleHeader = {
		// 	backgroundColor: '#E7FED5',
		// 	width: '425px',
		// 	height: '91px',
		// 	borderRadius: '24px'
		// };

		// const styleHeader1 = {
		// 	width: '120px',
		// 	height: '91px',
		// 	borderRadius: '24px',

		// 	alignItems: 'center',
		// 	marginLeft: 'auto',
		// 	backgroundColor: '#E7FED5',
		// 	borderColor: '#E7FED5'
		// };

		// const styleImg = {
		// 	height: '51px',
		// 	width: '45px'
		// 	// marginTop:"-10px"
		// };

		// const styleText = {
		// 	fontSize: '24px',
		// 	color: '#000000',
		// 	marginTop: '20px',
		// 	marginLeft: '15px'
		// };

		// const styleRow = {
		// 	marginTop: '40px',
		// 	display: 'flex',
		// 	justifyContent: 'center',
		// 	alignItems: 'center'
		// };

		const background = require('../assets/images/auth-layer.png');
		// //console.log(this.props.location.state.data);
		const { Basic } = this.state;

		let logoAplikasi = (
			<img
				src={require('../assets/images/logo/logo_gampande.png')}
				alt="logo"
				style={{
					height: '2.7em'
					// width: '45px',
					// marginTop: '-10px'
				}}
			/>
    )

    switch (localStorage.getItem('JENIS_APLIKASI').toLowerCase()) {
		case 'halaman kampung':
			logoAplikasi = (
						<img
							src={require('../assets/images/hk_icon.png')}
							alt="logo"
							style={{
								height: '2.7em'
								// width: '45px',
								// marginTop: '-10px'
							}}
						/>
			)
			break;
		case 'amanat nagari':
			logoAplikasi = (
						<img
							src={require('../assets/images/amanat_nagari_logo.png')}
							alt="logo"
							style={{
								height: '2.7em'
								// width: '45px',
								// marginTop: '-10px'
							}}
						/>
			)
			break;
		
		default:
			logoAplikasi = (
				<img
					src={require('../assets/images/logo/logo_gampande.png')}
					alt="logo"
					style={{
						height: '2.7em'
						// width: '45px',
						// marginTop: '-10px'
					}}
				/>
			)
			break;
    }

		return (
			<div
				id="bg-kiosk"
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly'
				}}
			>
				{/* {this.state.alert} */}
				<div
					className="row"
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'center',
						position: 'relative',
						zIndex: 1
					}}
				>
					<div
						// className="card-body"
						style={{
							// backgroundColor: '#ffffffc7',
							// background: 'linear-gradient(45deg, #e4e4e4, #a8b6c3)',
							width: 'max-content',
							// width: '33%',
							height: 'max-content',
							borderRadius: '45px',
							padding: '1em 2em',
							boxShadow: '0 4px 8px 0 #0000003d, 0 6px 20px 0 #0000003d',
							zIndex: 2
						}}
					>
						<div
							className="media"
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center'
							}}
						>
							{logoAplikasi}

							<div
								className="media-body align-self-left"
								style={{
									padding: '0px 10px',
									fontWeight: '600'
								}}
							>
								<span
									style={{
										fontSize: '1.7em',
										// color: '#464646',
										color: '#ffffff',
										fontWeight: '600'
									}}
								>
									Kios Pelayanan Masyarakat
								</span>
							</div>
						</div>
					</div>
					<div
						id="logout-kiosk"
						className="logout-kiosk"
						style={{
							// width: '33%',
							position: 'absolute',
							top: 15,
							right: 40,
							width: 'max-content',
							height: 'max-content',
							borderRadius: '45px',
							padding: '0.5em 1em',
							// backgroundColor: '#ffffff',
							backgroundColor: '#ff0000',
							boxShadow: '0 4px 8px 0 #0000003d, 0 6px 20px 0 #0000003d',
							// boxShadow: '0 0 30px 0 #ffffffd1 , 0 0 20px 0 #ffffffd1 ',
							cursor: 'pointer',
							zIndex: 100
						}}
						onClick={() => {
							this.setState({
								status:{
									...this.state.status,
									modalKonfirmasi2: true,
								}
							})
							this.forceUpdate();
						}}
					>
						<h5
							style={{
								fontSize: '1.2em',
								color: '#ffffff',
								fontWeight: '500',
								marginBottom: '0px'
							}}
						>
							Keluar
						</h5>
					</div>
				</div>
{this.state.loading ? (
			<div className="row">
				<div className="col-sm-12" align="center">
					<img 
					alt=""
					src={require("../assets/images/loading-bos.gif")} 
					style={{
						// width: "100%",
						borderRadius: "10px",
						width: '70px'
					}}/> 
				</div>
			</div>
		) : 
		<>
				<Row
					style={{
						// marginTop: '10px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<h3
						style={{
							color: '#ffffff',
							textShadow: '1px 0px 5px #000000'
						}}
					>
						Selamat Datang {this.state.dt.nama}
					</h3>
				</Row>

				<Row
					style={{
						// marginTop: '10px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					{/* <div
						style={{
							borderRadius: '19px',
							// backgroundColor: '#E7FED5',
							width: '740px',
							height: '364px'
						}}
					> */}
					<div
						style={{
							width: '53%',
							height: 'max-content',
							// width: '627px',
							// height: '346px',
							// marginLeft: '65px',
							// marginTop: '18px',
							borderRadius: '19px',
							backgroundSize: 'cover',
							backgroundImage: `url(${background})`
						}}
					>
						<div style={{ padding: '59px 75px 55px 75px' }}>
							<Form>
								<FormGroup>
									<Input
										className="form-control"
										type="text"
										placeholder="Nama lengkap"
										value={this.state.dt.nama}
										disabled
									/>
									<Input
										className="form-control"
										style={{ marginTop: '10px' }}
										type="text"
										value={this.state.dt.id}
										placeholder="NIK"
										disabled
									/>
									<Input
										className="form-control"
										style={{ marginTop: '10px' }}
										value={this.state.dt.id_kk}
										placeholder="No. KK"
										disabled
									/>
									<Input
										className="form-control"
										style={{ marginTop: '10px' }}
										type="text"
										value={this.state.dt.data_keldes.nama}
										placeholder={"Kelurahan / " + JENIS_DESA}
										disabled
									/>
									<Input
										className="form-control"
										style={{ marginTop: '10px' }}
										type="text"
										value={this.state.dt.alamat}
										placeholder="Alamat"
										disabled
									/>
								</FormGroup>
							</Form>

							{this.props.location.state.role === "login"
								?
								(
									<div
										style={{
											color: '#000000',
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between'
										}}
									>
										Terdapat Kesalahan?
										<Button
											style={{
												width: 'max-content',
												// height: '30px',
												// marginLeft: '171px',
												borderRadius: '4px'
											}}
											color="success"
											className="btn-square"
											onClick={() => this.onOpenModal(`Basic`)}
										// {() => this.ubahData()}
										>
											Edit Profil Anda
								</Button>
									</div>
								)
								:
								<>
								</>
							}
						</div>
					</div>
					{/* </div> */}
				</Row>

				<Row
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Link
						to={{
							pathname: '/layanan-masyarakat',
							state: this.state.data_pars
						}}
						// to="/layanan-masyarakat"
						style={{ borderRadius: '46px', width: '193px', backgroundColor: '#6FA16E' }}
					>
						<Button style={{ borderRadius: '46px', width: '193px', backgroundColor: '#6FA16E' }}>
							Lanjut
						</Button>
					</Link>
				</Row>
	</>
}

				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					styles={{ modal: { width: '80%' } }}
				>
					<Form
						onSubmit={this.simpan}
						onMouseDown={() => {
							//console.log('sdfs');
						}}
					>
						<div
							// className="card-scrollable"
							// style={{ marginBottom: '200px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								//console.log(e);
								this.setState({
									isDown: true,
									startY: e.pageY - slider.offsetTop,
									scrollUp: slider.scrollTop,
								})
								this.forceUpdate();
								//console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								//console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title">{this.state.judul}</h5>
							</div>
							<div className="modal-body">
								<p>
									Alamat pada data penduduk bisa jadi berbeda dengan alamat pada KK jika penduduk ybs
									sedang berdomisili di luar kota dalam rangka belajar atau bekerja untuk sementara
									waktu.
								</p>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										NIK
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="id"
											type="text"
											placeholder="NIK"
											defaultValue={this.state.dt.id}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										No. KK
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="no_kk"
											type="text"
											placeholder="NIK"
											defaultValue={this.state.dt.id_kk}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Agama
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'agama');
											}}
											defaultValue={this.state.agmStat}
											value={this.state.agmStat}
											name="Agama"
											options={this.state.frmAgm}
											placeholder="Pilih Agama"
											isClearable
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Pendidikan Terakhir
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'pendidikan-terakhir');
											}}
											defaultValue={this.state.ptrStat}
											value={this.state.ptrStat}
											name="Pendidikan"
											options={this.state.frmPtr}
											placeholder="Pilih Pendidikan Terakhir"
											isClearable
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Pekerjaan
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'pekerjaan');
											}}
											defaultValue={this.state.pkrStat}
											value={this.state.pkrStat}
											name="Pekerjaan"
											options={this.state.frmPkr}
											placeholder="Pilih Pekerjaan"
											isClearable
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nama Lengkap
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama"
											type="text"
											placeholder="Nama Lengkap"
											// defaultValue={this.state.dt.nama}
											required
											onFocus={() => this.setActiveInput('nama')}
											value={this.state.input['nama'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Alamat
									</Label>
									<Col sm="9">
										<textarea
											className="form-control"
											id="alamat"
											placeholder="Alamat"
											// defaultValue={this.state.dt.alamat}
											required
											onFocus={() => this.setActiveInput('alamat')}
											value={this.state.input['alamat'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tempat_lahir"
											type="text"
											placeholder="Tempat Lahir"
											// defaultValue={this.state.dt.tempat_lahir}
											required
											onFocus={() => this.setActiveInput('tempat_lahir')}
											value={this.state.input['tempat_lahir'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tanggal Lahir
									</Label>
									<Col sm="9">
										<Datetime
											locale="id-ID"
											input={true}
											timeFormat={false}
											closeOnTab={true}
											initialValue={new Date(this.state.dt.tanggal_lahir)}
											onChange={(e) => {
												this.gantiTgl(e, 'kk');
											}}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Status Perkawinan
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'status-perkawinan');
											}}
											defaultValue={this.state.spkStat}
											value={this.state.spkStat}
											name="Status Perkawinan"
											options={this.state.frmSpk}
											placeholder="Pilih Status Perkawinan"
											isClearable
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nama Ibu Kandung
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_ibu_kandung"
											type="text"
											placeholder="Nama Ibu Kandung"
											// value={this.state.dt.nama_ibu_kandung}
											required
											onFocus={() => this.setActiveInput('nama_ibu_kandung')}
											value={this.state.input['nama_ibu_kandung'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nama Ayah Kandung
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_ayah_kandung"
											type="text"
											placeholder="Nama Ayah Kandung"
											// value={this.state.dt.nama_ayah_kandung}
											required
											onFocus={() => this.setActiveInput('nama_ayah_kandung')}
											value={this.state.input['nama_ayah_kandung'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-warning"
									disabled={this.state.status.btnForm}
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
									Simpan
								</button>
							</div>
						</div>
					</Form>
					{/* {this.state.regis ? (
						<div style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 4, left: 0 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
							<div />
						)} */}
				</Modal>
				<Modal
					open={this.state.status.modalKonfirmasi2}
					// open={true}
					// open={true}
					onClose={false}
					styles={{ modal: { width: '400px' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div
						className="modal-body"
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<div className="row" style={{ textAlign: 'center' }}>
							<h3>Apakah anda yakin ingin keluar ?</h3>
						</div>
					</div>
					<div
						className="modal-footer"
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<button onClick={this.logOut.bind(this)} className="btn btn-success">
							Ya
						</button>
						<button
							onClick={() => {
								this.setState({
									status:{
										...this.state.status,
										modalKonfirmasi2: false,
									}
								})
								this.forceUpdate();
							}}
							className="btn btn-info"
						>
							Tidak
						</button>
					</div>
				</Modal>
			</div>
		);
	}
}
