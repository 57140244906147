import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datepicker from "react-datepicker";
import { Link, Redirect } from "react-router-dom";

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, Nama_DESA, NAMA_KABUPATEN, KECAMATAN, ALAMAT_KANTOR_DESA, PROVINSI, JENIS_DUSUN } from "../function/Koneksi";
import { Tanggal, HariTanggal, } from "../function/Format";
import { logo } from "./asset/base64img";
// Import the library

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  id_jenis: "",
  nomor: "",
  tanggal_surat: new Date(),
  nik: "",
  nama_pemohon: "",
  tempat_lahir: "",
  tanggal_lahir: null,
  jk: "",
  pekerjaan: "",
  agama: "",
  alamat: "",
  status_perkawinan: "",

  nik_pasangan: "",
  nama_pasangan: "",
  tempat_lahir_pasangan: "",
  tanggal_lahir_pasangan: null,
  jk_pasangan: "",
  pekerjaan_pasangan: null,
  agama_pasangan: null,
  alamat_pasangan: "",
  status_perkawinan_pasangan: "",

  tanggal_menikah: null,
  tempat_menikah: "",
  wali_nikah: "",
  petugas_nikah: "",
  mas_kawin: "",
  saksi_nikah: "",
  nik_pelapor: "",
  nama_pelapor: "",

  tempat_lahir_pelapor: "",
  tanggal_lahir_pelapor: null,
  jk_pelapor: "",
  pekerjaan_pelapor: "",
  agama_pelapor: "",
  alamat_pelapor: "",
  status_perkawinan_pelapor: "",

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: ""
};

const cetak = {
  nama_desa: "",
  nomor_surat: "",
  tanggal_surat: new Date(),
  nik: "",
  nama: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  jk: "",
  pekerjaan: "",
  agama: "",
  alamat: "",
  status_perkawinan: "",
  mas_kawin: 0,
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: ""
};

const dateFormat = require('dateformat');

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: true,
        btnAksi: [],
        dataPenduduk: "none",
        dataPenduduk2: "none",
        dataPenduduk3: "none"
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Surat Pernah Menikah",
      // ============ Select 2 ==============
      frmJam: [],
      jamStat: [],
      frmPkr: [],
      pkrStat: [],
      frmAgm: [],
      agmStat: null,
      frmNik: [],
      nikStat: null,
      frmNik2: [],
      nik2Stat: null,
      frmNik3: [],
      nik3Stat: null,
			frmSpk: [],
			spkStat: null,
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,

      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      tanggal_lahir: new Date(),
      tanggal_menikah: null,
      // ===================================
      // ============ Cetak ==============
      cetak: cetak,
      // ===================================
      // =============== acc ===============
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      datDisposisi: [],
      riwayatDisposisi: [],
      formPasangan: "none"
    };
  }

  // ================== Terbilang ===================

  terbilang = a => {
    // pastikan kita hanya berususan dengan tipe data numeric
    let angka = parseFloat(a);
    let hasil_bagi = 0;
    let hasil_mod = 0;
    // array bilangan
    // sepuluh dan sebelas merupakan special karena awalan 'se'
    const bilangan = [
      "",
      "Satu",
      "Dua",
      "Tiga",
      "Empat",
      "Lima",
      "Enam",
      "Tujuh",
      "Delapan",
      "Sembilan",
      "Sepuluh",
      "Sebelas"
    ];

    // pencocokan dimulai dari satuan angka terkecil
    if (angka < 12) {
      // mapping angka ke index array bilangan
      return bilangan[angka];
    } else if (angka < 20) {
      // bilangan 'belasan'
      // misal 18 maka 18 - 10 = 8
      return bilangan[angka - 10] + " Belas";
    } else if (angka < 100) {
      // bilangan 'puluhan'
      // misal 27 maka 27 / 10 = 2.7 (integer => 2) 'Dua'
      // untuk mendapatkan sisa bagi gunakan modulus
      // 27 mod 10 = 7 'Tujuh'
      hasil_bagi = parseInt(angka / 10);
      hasil_mod = angka % 10;
      return bilangan[hasil_bagi] + " Puluh " + bilangan[hasil_mod];
    } else if (angka < 200) {
      // bilangan 'seratusan' (itulah indonesia knp tidak satu ratus saja? :))
      // misal 151 maka 151 = 100 = 51 (hasil berupa 'puluhan')
      // daripada menulis ulang rutin kode puluhan maka gunakan
      // saja fungsi rekursif dengan memanggil fungsi terbilang(51)
      return "Seratus " + this.terbilang(angka - 100);
    } else if (angka < 1000) {
      // bilangan 'ratusan'
      // misal 467 maka 467 / 100 = 4,67 (integer => 4) 'Empat'
      // sisanya 467 mod 100 = 67 (berupa puluhan jadi gunakan rekursif terbilang(67))
      hasil_bagi = parseInt(angka / 100);
      hasil_mod = angka % 100;
      return bilangan[hasil_bagi] + " Ratus " + this.terbilang(hasil_mod);
    } else if (angka < 2000) {
      // bilangan 'seribuan'
      // misal 1250 maka 1250 - 1000 = 250 (ratusan)
      // gunakan rekursif terbilang(250)
      return "Seribu " + this.terbilang(angka - 100);
    } else if (angka < 1000000) {
      // bilangan 'ribuan' (sampai ratusan ribu
      hasil_bagi = parseInt(angka / 1000); // karena hasilnya bisa ratusan jadi langsung digunakan rekursif
      hasil_mod = angka % 1000;
      return this.terbilang(hasil_bagi) + " Ribu " + this.terbilang(hasil_mod);
    } else if (angka < 1000000000) {
      // bilangan 'jutaan' (sampai ratusan juta)
      // 'satu puluh' => SALAH
      // 'satu ratus' => SALAH
      // 'satu juta' => BENAR
      // @#$%^ WT*

      // hasil bagi bisa satuan, belasan, ratusan jadi langsung kita gunakan rekursif
      hasil_bagi = parseInt(angka / 1000000);
      hasil_mod = angka % 1000000;
      return this.terbilang(hasil_bagi) + " Juta " + this.terbilang(hasil_mod);
    } else if (angka < 1000000000000) {
      // bilangan 'milyaran'
      hasil_bagi = parseInt(angka / 1000000000);
      // karena batas maksimum integer untuk 32bit sistem adalah 2147483647
      // maka kita gunakan fmod agar dapat menghandle angka yang lebih besar
      hasil_mod = angka % 1000000000;
      return (
        this.terbilang(hasil_bagi) + " Milyar " + this.terbilang(hasil_mod)
      );
    } else if (angka < 1000000000000000) {
      // bilangan 'triliun'
      hasil_bagi = angka / 1000000000000;
      hasil_mod = angka % 1000000000000;
      return (
        this.terbilang(hasil_bagi) + " Triliun " + this.terbilang(hasil_mod)
      );
    } else {
      return "Wow...";
    }
  };
  // ===============================================

  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "tanggal_menikah":
        // //console.log(date.format("YYYY-MM-DD"));
        if (typeof date._isValid !== 'undefined') {
          this.setState({ tanggal_menikah: date.format("YYYY-MM-DD") });
        }
        break;
      // case 'tanggal_lahir': this.setState({tanggal_lahir:date});break;
      case "tanggal_lahir_pasangan":
        if (typeof date._isValid !== 'undefined') {
          this.setState({
            dt:{
              ...this.state.dt,
              tanggal_lahir_pasangan: new Date(date.format('YYYY-MM-DD')),
            },
          })
          this.forceUpdate();
        }
        break;
      default:
        // no default
        break;
    }
  };

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("surat-keterangan/pernah-menikah" + link, null, dtkat => {
      if(dtkat.results){
        this.setState({ data: dtkat.results.data, pagination: dtkat.results });
        this.pagination(dtkat.results);

        // For testing
        if (this.props.getListSuratPernahNikah) {
          this.props.getListSuratPernahNikah(dtkat.results.data)
        }
      }
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let addSuratKeteranganPernahMenikah = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/pernah-menikah",
        null,
        addSuratKeteranganPernahMenikah,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
        }
      );
    }
  };

  // ========================================================================
  // ================== change input text ==================
  changeInputText = (e, fld) => {
    switch (fld) {
      case "nik_pasangan":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            nik_pasangan: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "alamat_pasangan":
        //console.log(e.target);
        this.setState({
          dt:{
            ...this.state.dt,
            alamat_pasangan: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "nama_pasangan":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            nama_pasangan: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "tempat_lahir_pasangan":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            tempat_lahir_pasangan: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "pekerjaan_pasangan":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            pekerjaan_pasangan: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "agama_pasangan":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            agama_pasangan: {value : e.target.value, label : e.target.value},
          },
        })
        this.forceUpdate();
        break;
      case "status_perkawinan_pasangan":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            status_perkawinan_pasangan: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "tanggal_lahir_pasangan":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            tanggal_lahir_pasangan: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      default:
        // no default
        break;
    }
  };
  // data ayah
  dataPasangan = () => {
    //console.log(this.props.location.state);
    if (this.state.nik3Stat !== null) {
      this.setState({
        showBio2: true,
      })
    } else {
      this.setState({
        showBio2: this.state.showBio2 === false ? true : false,
      })
    }

    let viewFormPasangan = this.state.formPasangan === "" ? "none" : "";

    this.setState({
      dt:{
        ...this.state.dt,
        nama_nasab_p2: "",
        nama_pasangan: "",
        jenis_kelamin_pasangan: "",
        tempat_lahir_pasangan: "",
        tanggal_lahir_pasangan: null,
        pekerjaan_pasangan: null,
        agama_pasangan: null,
        nik_pasangan: "",
        alamat_pasangan: "",
        status_perkawinan_pasangan: "",
      },
      status:{
        ...this.state.status,
        dataPenduduk3: viewFormPasangan,
      },
      formPasangan: this.state.formPasangan === "" ? "none" : "",
      nik3Stat: null,
    })
    // this.state.status.dataPenduduk3 =
    //   this.state.status.dataPenduduk3 === "" ? "none" : "";
    this.forceUpdate();
  };
  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();

    let validasiPasangan = false;

    if (this.state.formPasangan === "none") {
      if (this.state.nik3Stat) {
        validasiPasangan = true;
      } else {
        validasiPasangan = false;
      }
    } else {
      if (this.state.dt.nik_pasangan !== '' && this.state.dt.pekerjaan_pasangan !== null && this.state.dt.agama_pasangan !== null) {
        validasiPasangan = true;
      } else {
        validasiPasangan = false;
      }
    }

    if (this.state.formPasangan !== "none" && this.state.dt.nik_pasangan.length !== 16) {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Belum Lengkap",
        pesanAlert: "NIK Pasangan tidak terdiri dari 16 angka"
      });
    } else if (
      this.state.nikStat &&
      this.state.nik2Stat &&
      // this.state.dt.nik_pasangan !== "" && 
      validasiPasangan
    ) {
      this.setState({
        status:{
          ...this.state.status,
          btnForm: true,
        },
      })
      this.forceUpdate();

      let addSuratKeteranganPernahMenikah = {
        uuid: document.getElementById("uuid").value,
        id_jenis: this.props.location.state.uuid,
        // nomor : document.getElementById("nomor").value,
        nik: this.state.nikStat.value,
        nama_pemohon: this.state.dt.nama_pemohon,
        tempat_lahir: this.state.dt.tempat_lahir,
        // tanggal_lahir: tanggal_lahir,
        tanggal_lahir: dateFormat(this.state.dt.tanggal_lahir, 'yyyy-mm-dd'),
        jenis_kelamin: this.state.dt.jk,
        status_perkawinan: this.state.dt.status_perkawinan,
        pekerjaan: this.state.dt.pekerjaan,
        agama: this.state.dt.agama,
        alamat: this.state.dt.alamat,

        nik_pasangan: this.state.dt.nik_pasangan,
        nama_pasangan: this.state.dt.nama_pasangan,
        tempat_lahir_pasangan: this.state.dt.tempat_lahir_pasangan,
        tanggal_lahir_pasangan: dateFormat(this.state.dt.tanggal_lahir_pasangan, 'yyyy-mm-dd'),
        jenis_kelamin_pasangan: this.state.dt.jk_pasangan,
        status_perkawinan_pasangan: this.state.dt.status_perkawinan_pasangan,
        pekerjaan_pasangan: this.state.dt.pekerjaan_pasangan.label,
        agama_pasangan: this.state.dt.agama_pasangan.label,
        alamat_pasangan: this.state.dt.alamat_pasangan,

        tanggal_menikah: this.state.tanggal_menikah,
        tempat_menikah: document.getElementById("tempat_menikah").value,
        petugas_nikah: document.getElementById("petugas_nikah").value,
        wali_nikah: document.getElementById("wali_nikah").value,
        mas_kawin: document.getElementById("mas_kawin").value,
        saksi_nikah: document.getElementById("saksi_nikah").value,

        nik_pelapor: this.state.nik2Stat.value,
        nama_pelapor: this.state.dt.nama_pelapor,
        tempat_lahir_pelapor: this.state.dt.tempat_lahir_pelapor,
        // tanggal_lahir_pelapor: tanggal_lahir_pelapor,
        tanggal_lahir_pelapor: dateFormat(this.state.dt.tanggal_lahir_pelapor, 'yyyy-mm-dd'),
        jenis_kelamin_pelapor: this.state.dt.jk_pelapor,
        status_perkawinan_pelapor: this.state.dt.status_perkawinan_pelapor,
        pekerjaan_pelapor: this.state.dt.pekerjaan_pelapor,
        agama_pelapor: this.state.dt.agama_pelapor,
        alamat_pelapor: this.state.dt.alamat_pelapor,
        // hubungan_pelapor : document.getElementById("hubungan_pelapor").value,
        status_ttd: null,
        // jabatan_ttd: "Kepala {Nama_DESA}",
        // nama_ttd: "Rian Adli, S.I.Kom",
        // nip_ttd: "11474242142"
      };

      console.log("Data Simpan Pernah NIkah");
      console.log(addSuratKeteranganPernahMenikah);

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (addSuratKeteranganPernahMenikah.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        addSuratKeteranganPernahMenikah.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      Post(
        "surat-keterangan/pernah-menikah/" + metode,
        addSuratKeteranganPernahMenikah.uuid,
        addSuratKeteranganPernahMenikah,
        res => {

          // For testing
          if (this.props.resSubmit) {
            this.props.resSubmit(res.status)
          }
          
          this.tutupForm();
          this.setState({
            status:{
              ...this.state.status,
              btnForm: false,
              form: false,
            },
          })
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Surat Keterangan Pernah Menikah",
              pesanAlert: "Berhasil " + psn + " Data",
              successAlert: true
            });
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Surat Keterangan Pernah Menikah",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        }
      );
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Belum Lengkap",
        pesanAlert: "Mohon isi seluruh data dengan lengkap"
      });
      // document.getElementsByName("NIK3")[0].focus();
    }
  };
  // ========================================================================
  // list disposisi

  listDisposisi = data => {
    //console.log(data);
    Get("alur-disposisi/" + data.id_jenis, null, dtkat => {
      //console.log(dtkat);
      if (dtkat) {
        //console.log(dtkat.results);

        this.setState({
          idSurat: data.uuid,
          datDisposisi: dtkat.results,
          formDisposisi: true,
          riwayatDisposisi: data.data_status_disposisi
        });
      }
    });
  };
  //disposisi
  disposisi = (label, id) => {
    //console.log(label);
    Get("disposisi/tidak-mampu/" + label + "/" + id, null, dtkat => {
      if (dtkat.code === 201) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Disposisi",
          pesanAlert: "Berhasil  Disposisi Data"
        });
        this.componentDidMount();
        this.tutupForm();
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Disposisi",
          pesanAlert: "Disposisi telah dilakukan"
        });
        this.tutupForm();
      }
    });
    //console.log(id);
  };
  // ============================== Ubah Data ===============================
  tambahData = e => {
    this.forceUpdate();
    this.setState({
      judul: "Tambah Surat Keterangan Pernah Menikah",
      dt: frmDef,
      status:{
        ...this.state.status,
        dataPenduduk: "none",
        dataPenduduk2: "none",
        dataPenduduk3: "none",
      },
      nikStat: null,
      nik2Stat: null,
      nik3Stat: null,
      // showBio2: true,
      formPasangan: "none"
    });
    this.bukaForm();
  };

  ubahData = id => {
    // this.state.status.btnAksi = true;
    this.setState({
      judul: "Ubah Surat Pernah Menikah",
    })
    this.forceUpdate();
    // this.componentDidMount();
    Get("surat-keterangan/pernah-menikah/find", id, data => {
      Get("penduduk/find", data.results.nik_pasangan, data1 => {
        if (data1.results === null) {
          this.setState({
            formPasangan: "",
            showBio2: false,
            nik3Stat: null,
          })
          // this.state.dt.tanggal_lahir_pasangan =
          //   data.results.tanggal_lahir_pasangan;
        } else {
          this.setState({
            nik3Stat: { value: data.results.nik_pasangan, label: data.results.nik_pasangan + ' (' + data.results.nama_pasangan + ')' },
            formPasangan: "none",
            showBio2: true,
          })

          // this.state.dt.tanggal_lahir_pasangan =
          //   data.results.tanggal_lahir_pasangan;
        }

        this.setState({
          dt:{
            ...this.state.dt,
            tanggal_lahir_pasangan: new Date(data.results.tanggal_lahir_pasangan),
            agama: data.results.agama,
            alamat: data.results.alamat,
            id_jenis: data.results.id_jenis,
            jk: data.results.jenis_kelamin,
            nama_pemohon: data.results.nama_pemohon,
            nik: data.results.nik,
            nomor: data.results.nomor,
            pekerjaan: data.results.pekerjaan,
            status_perkawinan: data.results.status_perkawinan,
            // this.state.dt.status_perkawinan = data.results.status_perkawinan;
            tanggal_lahir: new Date(data.results.tanggal_lahir),
            // .toLocaleDateString("id-ID");
            tanggal_surat: data.results.tanggal_surat,
            tempat_lahir: data.results.tempat_lahir,
            uuid: data.results.uuid,
            nik_pasangan: data.results.nik_pasangan,
            nama_pasangan: data.results.nama_pasangan,
            tempat_lahir_pasangan: data.results.tempat_lahir_pasangan,
            jk_pasangan: data.results.jenis_kelamin_pasangan,
            status_perkawinan_pasangan: data.results.status_perkawinan_pasangan,
            agama_pasangan: {
              value: data.results.agama_pasangan, 
              label : data.results.agama_pasangan
            },
            alamat_pasangan: data.results.alamat_pasangan,
            pekerjaan_pasangan: {
              value: data.results.pekerjaan_pasangan,
              label: data.results.pekerjaan_pasangan,
            },
            tanggal_menikah: data.results.tanggal_menikah,
            tempat_menikah: data.results.tempat_menikah,
            wali_nikah: data.results.wali_nikah,
            petugas_nikah: data.results.petugas_nikah,
            mas_kawin: data.results.mas_kawin,
            saksi_nikah: data.results.saksi_nikah,
            nik_pelapor: data.results.nik_pelapor,
            nama_pelapor: data.results.nama_pelapor,
            tanggal_lahir_pelapor: new Date(data.results.tanggal_lahir_pelapor),
            // .toLocaleDateString("id-ID");
            tempat_lahir_pelapor: data.results.tempat_lahir_pelapor,
            jk_pelapor: data.results.jenis_kelamin_pelapor,
            status_perkawinan_pelapor: data.results.status_perkawinan_pelapor,
            agama_pelapor: data.results.agama_pelapor,
            alamat_pelapor: data.results.alamat_pelapor,
            pekerjaan_pelapor: data.results.pekerjaan_pelapor,
            // this.state.dt.hubungan_pelapor = data.results.hubungan_pelapor;
            status_ttd: data.results.status_ttd,
            jabatan_ttd: data.results.jabatan_ttd,
            jabatan_pengganti_ttd: data.results.jabatan_pengganti_ttd,
            nama_ttd: data.results.nama_ttd,
            nip_ttd: data.results.nip_ttd,
            status_ttd2: data.results.status_ttd2,
            jabatan_ttd2: data.results.jabatan_ttd2,
            jabatan_pengganti_ttd2: data.results.jabatan_pengganti_ttd2,
            nama_ttd2: data.results.nama_ttd2,
            nip_ttd2: data.results.nip_ttd2,
          },
          status:{
            ...this.state.status,
            dataPenduduk: "block",
            dataPenduduk2: "block",
            dataPenduduk3: "block",
          },
          tanggal_surat: new Date(data.results.tanggal_surat),
          tanggal_menikah: new Date(data.results.tanggal_menikah),
          nikStat: { value: data.results.nik, label: data.results.nik + ' (' + data.results.nama_pemohon + ')' },
          nik2Stat: { value: data.results.nik_pelapor, label: data.results.nik_pelapor + ' (' + data.results.nama_pelapor + ')' },
        })
        
        
        // this.forceUpdate();
        this.bukaForm();
      });
    });
  };
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/pernah-menikah/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Keterangan Pernah Menikah",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Keterangan Pernah Menikah",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.setState({
      status:{
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({      
			spkStat: null,
      dt:{
        ...this.state.dt,
        nama_pemohon: "",
        tanggal_lahir: null,
        tempat_lahir: "",
        agama: "",
        pekerjaan: "",
        alamat: "",
        jk: "",
        status_perkawinan: "",
        nama_pasangan: "",
        tanggal_lahir_pasangan: null,
        tempat_lahir_pasangan: "",
        agama_pasangan: null,
        pekerjaan_pasangan: null,
        alamat_pasangan: "",
        jk_pasangan: "",
        status_perkawinan_pasangan: "",
        nama_pelapor: "",
        tanggal_lahir_pelapor: null,
        tempat_lahir_pelapor: "",
        agama_pelapor: "",
        pekerjaan_pelapor: "",
        alamat_pelapor: "",
        jk_pelapor: "",
        status_perkawinan_pelapor: "",
        nik_pasangan: "",
        tempat_menikah: "",
        petugas_nikah: "",
        wali_nikah: "",
        mas_kawin: "",
        saksi_nikah: "",
      },
      status:{
        ...this.state.status,
        dataPenduduk: "none",
        dataPenduduk2: "none",
        dataPenduduk3: "none",
        form: false,
        btnForm: true,
      },
      nikStat: null,
      nik2Stat: null,
      nik3Stat: null,
      tanggal_menikah: null,
      formPasangan: "none",
      accStat: [],
      tableAcc: "none",
      formDisposisi: false,
      formAcc: false,
    })


    
    
    // this.state.dt = frmDef;

    this.forceUpdate();
  };

  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get(
      "surat-keterangan/pernah-menikah/persetujuan/" + jawaban + "/" + id,
      null,
      res => {
        if (res.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  tombolAksi = (cell, row) => {
    // let btnApproval = false;
    // let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.forEach(dt => {
      status = dt.status;
    });
    if (status === "Diajukan") {
      // btnCetak = true;
      btnAksi = true;
      // btnApproval = true;
    } else {
      btnAksi = true;
      // btnApproval = true;
      if (status === "Ditolak") {
        // btnCetak = true;
      } else if (status === "Disetujui") {
        btnAksi = false;
        // btnCetak = true;
        // btnApproval = false;
      } else if ((status === "Menunggu Penerbitan") | (status === "Selesai")) {
        // btnAksi = false;
        // btnCetak = false;
        // btnApproval = false;
      } else {
        // btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={() => this.konfirmApprove(row.uuid)}
          disabled={btnApproval}
        >
          <i className="fa fa-check"></i>
        </Button>{" "}
        &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        {/* &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.cetakSurat(e, row)}
          disabled={btnCetak}
        >
          <i className="fa fa-print"></i>
        </Button>

        &nbsp;&nbsp; */}
        {/* //list */}
        {/*         
        <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.listDisposisi(row)}
        >
          <i className="fa fa-list"></i>
        </Button> */}
      </>
    );
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.fetch({ page: null, where: [] });
      let frmJam = [];
      let jamnya = "";
      for (let i = 1; i <= 24; i++) {
        jamnya = i;
        if (i < 10) {
          jamnya = "0" + i;
        }
        jamnya += ":00";
        frmJam.push({ value: jamnya, label: jamnya });
      }
      this.setState({ frmJam });
      Get("pekerjaan/list", null, data => {
        let frmPkr = [];
        data.results.forEach(dt => {
          frmPkr.push({ value: dt.uuid, label: dt.nama });
        });
        this.setState({ frmPkr });
        
        // For testing
        if (this.props.getListPekerjaan) {
          this.props.getListPekerjaan(frmPkr)
        }
      });
      Get("agama/list", null, data => {
        let frmAgm = [];
        data.results.forEach(dt => {
          frmAgm.push({ value: dt.uuid, label: dt.nama });
        });
        this.setState({ frmAgm });
        
        // For testing
        if (this.props.getListAgama) {
          this.props.getListAgama(frmAgm)
        }
      });
      Get("penduduk", null, data => {
        let frmNik = [];
        let frmNik2 = [];
        let frmNik3 = [];
        data.results.data.forEach(dt => {
          frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          frmNik2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
        });
        this.setState({ frmNik, frmNik2, frmNik3 });
         
        // For testing
        if (this.props.getListNIK) {
          this.props.getListNIK(frmNik)
        }
      });
      Get("tanda-tangan/list", null, data => {
        data.results.forEach((dt, i) => {
          if (dt.aktif === 1) {
            let temp = this.state.accButton.forEach(x => 1);
            temp[i] = 0;
            this.setState({
              accButton: temp,
              accuuid: dt.uuid,
            })
          }
        });
        this.forceUpdate();
        this.setState({ frmTTD: data.results });
      });

      // Get List Status Perkawinan
      Get('select-enum/penduduk/status_perkawinan', null, (data) => {
        let frmSpk = [];
        if(data){
          data.forEach((dt) => {
            frmSpk.push({ value: dt, label: dt });
          });
          this.setState({ frmSpk });
        }
      });
    } else {
      this.setState({ redirect: true });
    }
  }

  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        let nikStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik = [];
          datanik.data.results.data.forEach(dt => {
            frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmNik, nikStat });
        });
      } else {
        if (fld === "nik2") {
          let nik2Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            let frmNik2 = [];
            datanik.data.results.data.forEach(dt => {
              frmNik2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
            });
            this.setState({ frmNik2, nik2Stat });
          });
        } else {
          let nik3Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            let frmNik3 = [];
            datanik.data.results.data.forEach(dt => {
              frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
            });
            this.setState({ frmNik3, nik3Stat });
          });
        }
      }
    }
  };

  getPenduduk = (e, sel, jns) => {
    this.forceUpdate();
    if (e) {
      this.changeSelectValue(e, sel);
      Get("penduduk/find", e.value, data => {
        // const data_rt = data.results.data_kk.data_rt;
        // const data_rw = data_rt.data_rw;
        // const data_dusun = data_rw.data_dusun;
        // const data_desa = data_dusun.data_keldes;
        // const data_kecamatan = data_desa.data_kecamatan;
        // const data_kabkota = data_kecamatan.data_kabkota;
        let alamat = '';
        // if (JENIS_DESA.toLowerCase() === 'kelurahan') {
        //   alamat =
        //   " RT. " +
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // } else {
        //   alamat =
        //   " RT. " + 
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " + JENIS_DUSUN + " " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // }
        if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
          alamat =
          " RT. " +
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.data_kabkota.nama; 
        } else {
          if (data.results.data_kk.id_rt === '0') {
            alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
            ", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
          } else {
            alamat =
            " RT. " + 
            data.results.data_kk.data_rt.nama +
            "/RW. " +
            data.results.data_kk.data_rt.data_rw.nama +
            ", " + JENIS_DUSUN + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.nama +
            ", " + localStorage.getItem('JENIS_DESA') + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
            ", Kec. " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.data_kabkota.nama; 
          }
        }

        if (jns === "pelapor") {
          this.setState({
            dt:{
              ...this.state.dt,
              nama_pelapor: data.results.nama,
              tanggal_lahir_pelapor: new Date(
                data.results.tanggal_lahir
              ),
              // .toLocaleDateString("id-ID");
              tempat_lahir_pelapor: data.results.tempat_lahir,
              jk_pelapor: data.results.jk,
              status_perkawinan_pelapor: data.results.status_perkawinan,
              agama_pelapor: data.results.data_agama.nama,
              pekerjaan_pelapor: data.results.data_pekerjaan.nama,
              alamat_pelapor: alamat,
            },
            status:{
              ...this.state.status,
              dataPenduduk2: "block",
            },
            tanggal_lahir_pelapor: data.results.tanggal_lahir,
          })

          // For testing
          if (this.props.selectOnChange2) {
            this.props.selectOnChange2(this.state.dt)
          }
        } else {
          if (jns === "pasangan") {
            this.setState({
              dt:{
                ...this.state.dt,
                nama_pasangan: data.results.nama,
                nik_pasangan: data.results.id,
                // this.state.dt.tanggal_lahir_pasangan = data.results.tanggal_lahir;
                tanggal_lahir_pasangan: new Date(data.results.tanggal_lahir),
                tempat_lahir_pasangan: data.results.tempat_lahir,
                jk_pasangan: data.results.jk,
                status_perkawinan_pasangan: data.results.status_perkawinan,
                agama_pasangan: { 
                  value : data.results.data_agama.nama, 
                  label : data.results.data_agama.nama
                },
                pekerjaan_pasangan: {
                  value : data.results.data_pekerjaan.nama,
                  label : data.results.data_pekerjaan.nama,
                },
                alamat_pasangan: alamat,
              },
              status:{
                ...this.state.status,
                dataPenduduk3: "block",
              },
              tanggal_lahir_pasangan: data.results.tanggal_lahir,
            })

            
            // For testing
            if (this.props.selectOnChange3) {
              this.props.selectOnChange3(this.state.dt)
            }
          } else {
            Get("catatan-penduduk/nik", data.results.id, dpddk => {
              let pctt = 0;
              let catatan = "";
              if (dpddk.results.data) {
                dpddk.results.data.forEach(dtp => {
                  if (dtp.status === 0 && dtp.penalti === 1) {
                    pctt = 1;
                    catatan = dtp.catatan;
                  }
                });
              }
              if (pctt === 0) {
                this.setState({
                  dt:{
                    ...this.state.dt,
                    nama_pemohon: data.results.nama,
                    tanggal_lahir: new Date(
                      data.results.tanggal_lahir),
                    // .toLocaleDateString("id-ID");
                    tempat_lahir: data.results.tempat_lahir,
                    jk: data.results.jk,
                    status_perkawinan: data.results.status_perkawinan,
                    agama: data.results.data_agama.nama,
                    pekerjaan: data.results.data_pekerjaan.nama,
                    alamat: alamat,
                  },
                  status:{
                    ...this.state.status,
                    dataPenduduk: "block",
                    btnForm: false,
                  },
                  tanggal_lahir: data.results.tanggal_lahir,
                })
                this.forceUpdate();
              } else {
                // this.state.frmNik = [];
                this.setState({
                  nikStat: null,
                  dt:{
                    ...this.state.dt,
                    nama_pemohon: "",
                    tanggal_lahir: null,
                    tempat_lahir: "",
                    agama: "",
                    pekerjaan: "",
                    alamat: "",
                    jk: "",
                    status_perkawinan: "",
                  },
                  status:{
                    ...this.state.status,
                    dataPenduduk: "none",
                  },
                  show: true,
                  basicType: "danger",
                  basicTitle: "Peringatan Catatan Warga",
                  pesanAlert:
                    "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                    catatan
                });
                this.forceUpdate();
              }

              // For testing
              if (this.props.selectOnChange) {
                this.props.selectOnChange(this.state.dt)
              }
            });
          }
        }

        if (
          this.state.nikStat !== null &&
          this.state.nik2Stat !== null &&
          this.state.nik3Stat !== null
        ) {
          this.setState({
            status:{
              ...this.state.status,
              btnForm: false,
            },
          })
        }
        this.forceUpdate();
      });
    } else {
      if (jns === "pelapor") {
        this.setState({
          dt:{
            ...this.state.dt,
            nama_pelapor: "",
            tanggal_lahir_pelapor: null,
            tempat_lahir_pelapor: "",
            agama_pelapor: "",
            pekerjaan_pelapor: "",
            alamat_pelapor: "",
            jk_pelapor: "",
            status_perkawinan_pelapor: "",
          },
          status:{
            ...this.state.status,
            dataPenduduk2: "none",
          },
          nik2Stat: null,
        })
      } else {
        if (jns === "pasangan") {
          this.setState({
            dt:{
              ...this.state.dt,
              nik_pasangan: "",
              nama_pasangan: "",
              tanggal_lahir_pasangan: null,
              tempat_lahir_pasangan: "",
              agama_pasangan: null,
              pekerjaan_pasangan: null,
              alamat_pasangan: "",
              jk_pasangan: "",
              status_perkawinan_pasangan: "",
            },
            status:{
              ...this.state.status,
              dataPenduduk3: "none",
            },
            nik3Stat: null,
          })
        } else {
          this.setState({
            dt:{
              ...this.state.dt,
              nama_pemohon: "",
              tanggal_lahir: null,
              tempat_lahir: "",
              agama: "",
              pekerjaan: "",
              alamat: "",
              jk: "",
              status_perkawinan: "",
            },
            status:{
              ...this.state.status,
              dataPenduduk: "none",
            },
            nikStat: null,
          })
        }
      }
      this.setState({
        status:{
          ...this.state.status,
          btnForm: true,
        },
      })

      this.forceUpdate();
    }
  };
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jam":
          this.setState({ 
            jamStat: e, 
            dt:{
              ...this.state.dt,
              jam: e.value,
            },
          });
          break;
        case "pekerjaan":
          this.setState({ 
            pkrStat: e, 
            dt:{
              ...this.state.dt,
              pekerjaan: e.label,
            },
          });
          break;
        case "agama_pasangan":
          this.setState({ 
            agmStat: e, 
            dt:{
              ...this.state.dt,
              agama_pasangan: e,
            },
          });
          // For testing
          if (this.props.selectOnChangeAgamaPasangan) {
            this.props.selectOnChangeAgamaPasangan(e)
          }
          break;
        case "nik":
          this.setState({ 
            nikStat: e, 
            dt:{
              ...this.state.dt,
              nik: e.value,
            },
          });
          break;
        case "nik2":
          this.setState({ 
            nik2Stat: e, 
            dt:{
              ...this.state.dt,
              nik_pelapor: e.value,
            },
          });
          break;
        case "nik3":
          this.setState({ 
            nik3Stat: e, 
            dt:{
              ...this.state.dt,
              nik_pasangan: e.value,
            },
          });
          break;
        case "pekerjaan_pasangan":
          this.setState({ dt : {...this.state.dt, pekerjaan_pasangan : e} })
          // For testing
          if (this.props.selectOnChangePekerjaanPasangan) {
            this.props.selectOnChangePekerjaanPasangan(e)
          }
          break;
        case 'status_perkawinan_pasangan':
					this.setState({ 
						spkStat: e, 
						dt:{
							...this.state.dt,
							status_perkawinan_pasangan: e.label,
						}
					});
					break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "jam":
          this.setState({ 
            jamStat: e, 
            dt:{
              ...this.state.dt,
              jam: 0,
            },
          });
          break;
        case "pekerjaan":
          this.setState({ 
            pkrStat: e, 
            dt:{
              ...this.state.dt,
              pekerjaan: "",
            },
          });
          break;
        case "agama_pasangan":
          this.setState({ 
            agmStat: e, 
            dt:{
              ...this.state.dt,
              agama_pasangan: null,
            },
          });
          break;
        case "nik":
          this.setState({ 
            nikStat: e, 
            dt:{
              ...this.state.dt,
              nik: 0,
            },
          });
          break;
        case "nik2":
          this.setState({ 
            nik2Stat: e, 
            dt:{
              ...this.state.dt,
              nik_pelapor: 0,
            },
          });
          break;
        case "nik3":
          this.setState({ 
            nik3Stat: e, 
            dt:{
              ...this.state.dt,
              nik_pasangan: 0,
            },
          });
          break;
        case "pekerjaan_pasangan":
          this.setState({ dt : {...this.state.dt, pekerjaan_pasangan : null} })
          break;
        case 'status_perkawinan_pasangan':
					this.setState({ 
						spkStat: e, 
						dt:{
							...this.state.dt,
							status_perkawinan_pasangan: '',
						}
					});
					break;
        default:
          // no default
          break;
      }
    }

    this.forceUpdate();
  };
  cetakSurat = (e, data) => {
    e.preventDefault();
    this.setState({
      cetak: {
        nama_desa: Nama_DESA,
        tanggal_surat: data.tanggal_surat,
        nomor_surat: data.nomor,
        nik: data.nik,
        nama_pemohon: data.nama_pemohon.toLowerCase(),
        tanggal_lahir: Tanggal(data.tanggal_lahir),
        tempat_lahir: data.tempat_lahir.toLowerCase(),
        // jk : data.jenis_kelamin.toLowerCase(),
        alamat: data.alamat.toLowerCase(),
        agama: data.agama.toLowerCase(),
        pekerjaan: data.pekerjaan.toLowerCase(),
        status_perkawinan: data.status_perkawinan.toLowerCase(),

        nik_pasangan: data.nik_pasangan,
        nama_pasangan: data.nama_pasangan.toLowerCase(),
        tanggal_lahir_pasangan: Tanggal(data.tanggal_lahir_pasangan),
        tempat_lahir_pasangan: data.tempat_lahir_pasangan.toLowerCase(),
        // jk_pasangan : data.jenis_kelamin_pasangan.toLowerCase(),
        alamat_pasangan: data.alamat_pasangan.toLowerCase(),
        agama_pasangan: data.agama_pasangan.toLowerCase(),
        pekerjaan_pasangan: data.pekerjaan_pasangan.toLowerCase(),
        status_perkawinan_pasangan: data.status_perkawinan_pasangan.toLowerCase(),

        tanggal_menikah: data.tanggal_menikah,
        tempat_menikah: data.tempat_menikah.toLowerCase(),
        petugas_nikah: data.petugas_nikah.toLowerCase(),
        wali_nikah: data.wali_nikah.toLowerCase(),
        mas_kawin: data.mas_kawin.toLowerCase(),
        saksi_nikah: data.saksi_nikah.toLowerCase(),

        nik_pelapor: data.nik_pelapor,
        nama_pelapor: data.nama_pelapor.toLowerCase(),
        tanggal_lahir_pelapor: HariTanggal(data.tanggal_lahir_pelapor),
        tempat_lahir_pelapor: data.tempat_lahir_pelapor.toLowerCase(),
        // jk_pelapor : data.jenis_kelamin_pelapor.toLowerCase(),
        alamat_pelapor: data.alamat_pelapor.toLowerCase(),
        agama_pelapor: data.agama_pelapor.toLowerCase(),
        pekerjaan_pelapor: data.pekerjaan_pelapor.toLowerCase(),
        status_perkawinan_pelapor: data.status_perkawinan_pelapor.toLowerCase(),

        status_ttd: data.status_ttd,
        jabatan_ttd: data.jabatan_ttd,
        jabatan_pengganti_ttd: data.jabatan_pengganti_ttd,
        nama_ttd: data.nama_ttd,
        nip_ttd: data.nip_ttd ? "NIP. " + data.nip_ttd : ""
      }
    });
    setTimeout(function() {
      const content = document.getElementById("cetak-surat").innerHTML;
      const mywindow = window.open("", "Print", "height=600,width=1000");

      mywindow.document.write("<html><head><title>Print</title>");
      mywindow.document.write("</head><body >");
      mywindow.document.write(content);
      mywindow.document.write("</body></html>");

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      setTimeout(function() {
        mywindow.close();
      }, 1000);
    }, 100);
  };

  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  //change select
  changeAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      if (e.value === "Disetujui") {
        this.setState({
          tableAcc: "",
          btnFormAcc: true,
        })
        if (this.state.accButton.length > 0) {
          this.setState({
            btnFormAcc: false,
          })
        }
      } else {
        this.setState({
          tableAcc: "none",
          btnFormAcc: false,
        })
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      this.setState({
        tableAcc: "none",
        btnFormAcc: true,
      })
    }
    this.setState({
      status:{
        ...this.state.status,
        select: false,
      },
    })
    this.forceUpdate();
  };

  simpanAcc = e => {
    e.preventDefault();
    this.setState({
      btnFormAcc: true,
    })

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),
      uuid: this.state.accuuid
    };
    Post(
      "surat-keterangan/pernah-menikah/persetujuan/Selesai/" +
        this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            btnFormAcc: false,
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
    this.tutupForm();
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.map(x => 1);
    temp[id] = 0;
    this.setState({
      accButton: temp,
      accuuid: uuid,
    })
    if (this.state.accStat) {
      this.setState({
        btnFormAcc: false,
      })
    }
    this.forceUpdate();
  };

  render() {
    //console.log("Data Render")
    //console.log("Data Pernah Nikah")
    //console.log("====================")
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;

    // const { SearchBar } = Search;
    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.pagination.current_page;
              let total_records_per_page = this.state.pagination.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
        dataField: "tanggal_surat",
        headerAlign: 'center',
				align: 'center',
        text: "Tanggal",
        formatter: (cell, obj) => {
          //console.log(obj.tanggal_surat);
          return obj.tanggal_surat === null ||
            obj.tanggal_surat === "" ||
            obj.tanggal_surat === "0000-00-00"
            ? "-"
            : new Date(obj.tanggal_surat).toLocaleDateString("id-ID");
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        headerAlign: 'center',
				align: 'center',
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor === "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "nik",
        headerAlign: 'center',
				align: 'center',
        text: "NIK",
        sort: true
      },
      {
        dataField: "nama_pemohon",
        headerAlign: 'center',
				align: 'center',
        text: "Nama",
        sort: true
      },
      {
        dataField: "tanggal_menikah",
        headerAlign: 'center',
				align: 'center',
        text: "Tanggal Menikah",
        align: "right",
        isDummyField: true,
        formatter: (cell, obj) => {
          return HariTanggal(obj.tanggal_menikah);
        },
        sort: true
      },
      {
        dataField: "status",
        headerAlign: 'center',
				align: 'center',
        text: "Status",
        isDummyField: true,
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.forEach(dt => {
            status = dt.status;
          });
          return status;
        },
        sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-primary-custom";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href={() => false} onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };
    let no = 0;
    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] === 0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] === 0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <div data-testid="page-surat keterangan pernah menikah">
        {this.renderRedirect()}
        {/* <Breadcrumb
          title="Data Surat Keterangan Pernah Menikah"
          parent="Admin"
        /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  this.bukaForm();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                // color="info"
                className="btn btn-secondary-custom"
                // onClick={() => {
                //   this.closeAlert();
                // }}
              >
                <Link
                  data-testid="btn-eksekusi_surat"
                  onClick={() => {
                    // For testing
                    if (this.props.clickEksekusiSurat) {
                      this.props.clickEksekusiSurat()
                    }
                  }}
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Keterangan Pernah Menikah</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        data-testid="tblCreateSuratPernahNikah"
                        size="sm"
                        color="success"
                        // className="btn-square"
                        onClick={this.tambahData}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form 
            data-testid="form-create" 
            className="theme-form" 
            onSubmit={this.simpan}
          >
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <p>
                Data identitas akan terisi secara otomatis saat NIK dipilih.
                Jika ada ketidak sesuaian data, silakan ubah di data master
                penduduk.
              </p>
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="nik"
                >
                  NIK
                </Label>
                <Col sm="9">
                  <div data-testid="selectNIK">
                    <Select
                      inputId="nik"
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e, "nik", "pemohon");
                      }}
                      defaultValue={this.state.nikStat}
                      value={this.state.nikStat}
                      name="NIK"
                      options={this.state.frmNik}
                      placeholder="Pilih NIK"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                      isClearable
                    />
                  </div>
                </Col>
              </FormGroup>
              <div style={{ display: this.state.status.dataPenduduk }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pemohon
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama"
                      type="text"
                      placeholder="Nama Pemohon"
                      value={this.state.dt.nama_pemohon}
                      readOnly

                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat Lahir"
                      value={this.state.dt.tempat_lahir}
                      readOnly

                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir"
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={dateFormat(this.state.dt.tanggal_lahir, 'dd/mm/yyyy')}
                      readOnly

                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ display: "none" }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="pekerjaan"
                      type="text"
                      placeholder="Pekerjaan"
                      value={this.state.dt.pekerjaan}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="agama"
                      type="text"
                      placeholder="Agama"
                      value={this.state.dt.agama}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="alamat"
                      type="text"
                      placeholder="Alamat"
                      value={this.state.dt.alamat}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Status Perkawinan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="status_perkawinan"
                      type="text"
                      placeholder="Status Perkawinan"
                      value={this.state.dt.status_perkawinan}
                      readOnly
                    />
                  </Col>
                </FormGroup>
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  NIK Pasangan
                </Label>
                <Col sm="9">
                  {this.state.formPasangan === "none" ? (
                    <div data-testid="selectNIK3">
                      <Select
                        inputId="nik3"
                        classNamePrefix="select"
                        onChange={e => {
                          this.getPenduduk(e, "nik3", "pasangan");
                        }}
                        defaultValue={this.state.nik3Stat}
                        value={this.state.nik3Stat}
                        name="NIK3"
                        options={this.state.frmNik3}
                        placeholder="Pilih NIK Pasangan"
                        onInputChange={e => this.handleInputChangeNIK(e, "nik3")}
                        isClearable
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <input
                    data-testid="nik_pasangan"
                    name="nik_pasangan"
                    className="form-control"
                    id="nik_pasangan"
                    type={
                      this.state.formPasangan === "none" ? "hidden" : "number"
                    }
                    required
                    placeholder="NIK Pasangan"
                    defaultValue={this.state.dt.nik_pasangan}
                    value={this.state.dt.nik_pasangan}
                    onChange={e => this.changeInputText(e, "nik_pasangan")}
                    readOnly={this.state.formPasangan === "" ? false : true}
                  />
                  <small id="emailHelp" className="form-text text-muted">
                    Data NIK {this.state.formPasangan === "none" ? "tidak" : ""}{" "}
                    tersedia?{" "}
                    <Link
                      to={{
                        pathname: this.props.myroute,
                        state: this.props.location.state
                      }}
                      data-testid="btnInputManual"
                      onClick={() => {
                        if(this.state.formPasangan === "none"){
                          if (this.props.getJenisInput) {
                            this.props.getJenisInput("manual")
                          }
                        }
                        else{
                          if (this.props.getJenisInput) {
                            this.props.getJenisInput("auto")
                          }  
                        }
                        this.dataPasangan()
                      }}
                      // onClick={this.dataPasangan}
                    >
                      {" "}
                      Klik disini
                    </Link>
                  </small>
                </Col>
              </FormGroup>
              <div style={{ display: this.state.status.dataPenduduk3 }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      data-testid="nama_pasangan"
                      name="nama_pasangan"
                      className="form-control"
                      id="nama_pasangan"
                      type="text"
                      placeholder="Nama Pasangan"
                      defaultValue={this.state.dt.nama_pasangan}
                      value={this.state.dt.nama_pasangan}
                      onChange={e => this.changeInputText(e, "nama_pasangan")}
                      readOnly={this.state.formPasangan === "" ? false : true}
                      required={this.state.formPasangan === "" ? true : false}
                      style={this.state.formPasangan === "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
                      disabled={this.state.formPasangan === "" ? false : true}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      data-testid="tempat_lahir_pasangan"
                      name="tempat_lahir_pasangan"
                      className="form-control"
                      id="tempat_lahir_pasangan"
                      type=""
                      placeholder="Tempat Lahir Pasangan"
                      defaultValue={this.state.dt.tempat_lahir_pasangan}
                      value={this.state.dt.tempat_lahir_pasangan}
                      onChange={e =>
                        this.changeInputText(e, "tempat_lahir_pasangan")
                      }
                      readOnly={this.state.formPasangan === "" ? false : true}
                      required={this.state.formPasangan === "" ? true : false}
                      style={this.state.formPasangan === "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
                      disabled={this.state.formPasangan === "" ? false : true}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir Pasangan
                  </Label>
                  <Col sm="9">
                    {this.state.formPasangan === ''
                      ?
                      <Datetime
                        inputProps={{ "data-testid": "tanggal_lahir_pasangan", placeholder: 'Tanggal Lahir Pasangan', required: true }}
                        // data-testid="tanggal_lahir_pasangan"
                        inputId="tanggal_lahir_pasangan"
                        name="tanggal_lahir_pasangan"
                        locale="id-ID"
                        input={true}
                        timeFormat={false}
                        closeOnTab={true}
                        closeOnSelect={true}
                        dateFormat="DD/MM/yyyy"
                        initialValue={this.state.dt.tanggal_lahir_pasangan}
                        value={this.state.dt.tanggal_lahir_pasangan}
                        onChange={(e) => {
                          this.gantiTgl(e, 'tanggal_lahir_pasangan');
                        }}
                      />
                      :
                      <input
                        className="form-control"
                        id="tanggal_lahir_pasangan"
                        type="text"
                        placeholder="Tanggal Lahir"
                        value={dateFormat(this.state.dt.tanggal_lahir_pasangan, 'dd/mm/yyyy')}
                        // defaultValue={this.state.dt.tanggal_lahir_pasangan}
                        // onChange={e =>
                        //   this.changeInputText(e, "tanggal_lahir_pasangan")
                        // }
                        // readOnly={this.state.formPasangan === "" ? false : true}
                        readOnly
                        style={this.state.formPasangan === "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
                        // disabled={this.state.formPasangan === "" ? false : true}
                      />
                    }
                  </Col>
                </FormGroup>
              </div>
              <div style={{ display: this.state.formPasangan }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="pekerjaan_pasangan"
                  >
                    Pekerjaan Pasangan
                  </Label>
                  <Col sm="9">
                    <div data-testid="selectPekerjaan">
                      <Select
                        inputId="pekerjaan_pasangan"
                        classNamePrefix="select"
                        onChange={e => {
                          this.changeSelectValue(e, "pekerjaan_pasangan");
                        }}
                        value={this.state.dt.pekerjaan_pasangan}
                        name="pekerjaan_pasangan"
                        options={this.state.frmPkr}
                        placeholder="Pilih Pekerjaan Pasangan"
                        isClearable
                      />
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="agama_pasangan"
                  >
                    Agama Pasangan
                  </Label>
                  <Col sm="9">
                    {/* <input
                      className="form-control"
                      id="agama_pasangan"
                      type="text"
                      placeholder="Agama"
                      value={this.state.dt.agama_pasangan}
                      defaultValue={this.state.dt.agama_pasangan}
                      onChange={e => this.changeInputText(e, "agama_pasangan")}
                      readOnly={this.state.formPasangan === "" ? false : true}
                    /> */}
                    <div data-testid="selectAgama">
                      <Select
                        inputId="agama_pasangan"
                        id="agama_pasangan"
                        isDisabled={this.state.formPasangan === "" ? false : true}
                        classNamePrefix="select"
                        onChange={((e) => { this.changeSelectValue(e, 'agama_pasangan') })}
                        defaultValue={this.state.dt.agama_pasangan}
                        value={this.state.dt.agama_pasangan}
                        name="Agama"
                        options={this.state.frmAgm}
                        placeholder="Pilih Agama"
                        isClearable
                      />
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      data-testid="alamat_pasangan"
                      name="alamat_pasangan"
                      className="form-control"
                      id="alamat_pasangan"
                      type="text"
                      placeholder="Alamat"
                      defaultValue={this.state.dt.alamat_pasangan}
                      value={this.state.dt.alamat_pasangan}
                      onChange={e => this.changeInputText(e, "alamat_pasangan")}
                      readOnly={this.state.formPasangan === "" ? false : true}
                      required={this.state.formPasangan === "" ? true : false}
                    />
                    {/* <textarea className="form-control" id="alamat" placeholder="Alamat" value={this.state.dt.alamat}></textarea> */}
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Status Perkawinan Pasangan
                  </Label>
                  <Col sm="9">
                    {this.state.formPasangan === 'none'
                      ?
                        <input
                          className="form-control"
                          id="status_perkawinan_pasangan"
                          type="text"
                          placeholder="Status Perkawinan Pasangan"
                          required
                          value={this.state.dt.status_perkawinan_pasangan}
                          readOnly
                        />
                      :
                        <Select
                          isDisabled={this.state.status.select}
                          classNamePrefix="select"
                          onChange={(e) => {
                            this.changeSelectValue(e, 'status_perkawinan_pasangan');
                          }}
                          defaultValue={this.state.spkStat}
                          value={this.state.spkStat}
                          name="Status Perkawinan"
                          options={this.state.frmSpk}
                          placeholder="Pilih Status Perkawinan"
                          isClearable
                        />
                    }

                    {/* <input
                      data-testid="status_perkawinan_pasangan"
                      name="status_perkawinan_pasangan"
                      className="form-control"
                      id="status_perkawinan_pasangan"
                      type="text"
                      placeholder="Status Perkawinan Pasangan"
                      defaultValue={this.state.dt.status_perkawinan_pasangan}
                      value={this.state.dt.status_perkawinan_pasangan}
                      onChange={e =>
                        this.changeInputText(e, "status_perkawinan_pasangan")
                      }
                      readOnly={this.state.formPasangan === "" ? false : true}
                      required={this.state.formPasangan === "" ? true : false}
                    /> */}
                  </Col>
                </FormGroup>
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Menikah
                </Label>
                <Col sm="9">
                  <Datetime
                    inputProps={{ "data-testid": "tanggal_menikah", placeholder: 'Tanggal Menikah', required: true }}
                    // data-testid="tanggal_menikah"
                    inputId="tanggal_menikah"
                    name="tanggal_menikah"
                    locale="id-ID"
                    input={true}
                    closeOnTab={true}
                    closeOnSelect={true}
                    timeFormat={false}
                    dateFormat="DD/MM/yyyy"
                    value={this.state.tanggal_menikah}
                    onChange={e => {
                      this.gantiTgl(e, "tanggal_menikah");
                      // this.setState({ tanggal_lahirkk:})
                      // //console.log(e.format('YYYY-MM-DD'))
                    }}
                  />
                  {/* <Datepicker
                    className="form-control"
                    selected={this.state.tanggal_menikah}
                    onChange={e => this.gantiTgl(e, "tanggal_menikah")}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="tanggal"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  /> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Menikah
                </Label>
                <Col sm="9">
                  <input
                    data-testid="tempat menikah"
                    name="tempat_menikah"
                    className="form-control"
                    id="tempat_menikah"
                    type="text"
                    placeholder="Tempat Menikah"
                    defaultValue={this.state.dt.tempat_menikah}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Petugas Nikah
                </Label>
                <Col sm="9">
                  <input
                    data-testid="petugas nikah"
                    name="petugas_nikah"
                    className="form-control"
                    id="petugas_nikah"
                    type="text"
                    placeholder="Petugas Menikah"
                    defaultValue={this.state.dt.petugas_nikah}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Wali Nikah
                </Label>
                <Col sm="9">
                  <input
                    data-testid="wali nikah"
                    name="wali_nikah"
                    className="form-control"
                    id="wali_nikah"
                    type="text"
                    placeholder="Wali Menikah"
                    defaultValue={this.state.dt.wali_nikah}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Mas Kawin
                </Label>
                <Col sm="9">
                  <input
                    data-testid="mas kawin"
                    name="mas_kawin"
                    className="form-control"
                    id="mas_kawin"
                    type="text"
                    placeholder="Mas Kawin"
                    defaultValue={this.state.dt.mas_kawin}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Saksi Nikah
                </Label>
                <Col sm="9">
                  <input
                    data-testid="saksi nikah"
                    name="saksi_nikah"
                    className="form-control"
                    id="saksi_nikah"
                    type="text"
                    placeholder="Saksi Nikah"
                    defaultValue={this.state.dt.saksi_nikah}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="nik2"
                >
                  NIK Pelapor
                </Label>
                <Col sm="9">
                  <div data-testid="selectNIK2">
                    <Select
                      inputId="nik2"
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e, "nik2", "pelapor");
                      }}
                      defaultValue={this.state.nik2Stat}
                      value={this.state.nik2Stat}
                      name="NIK2"
                      options={this.state.frmNik2}
                      placeholder="Pilih NIK Pelapor"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik2")}
                      isClearable
                    />
                  </div>
                </Col>
              </FormGroup>
              <div style={{ display: this.state.status.dataPenduduk2 }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pemohon
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_pelapor"
                      type="text"
                      placeholder="Nama Pemohon"
                      value={this.state.dt.nama_pelapor}
                      readOnly

                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tempat_lahir_pelapor"
                      type="text"
                      placeholder="Tempat Lahir"
                      value={this.state.dt.tempat_lahir_pelapor}
                      readOnly

                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir_pelapor"
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={dateFormat(this.state.dt.tanggal_lahir_pelapor, 'dd/mm/yyyy')}
                      readOnly

                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ display: "none" }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="pekerjaan_pelapor"
                      type="text"
                      placeholder="Pekerjaan"
                      value={this.state.dt.pekerjaan_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="agama_pelapor"
                      type="text"
                      placeholder="Agama"
                      value={this.state.dt.agama_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="alamat_pelapor"
                      type="text"
                      placeholder="Alamat"
                      value={this.state.dt.alamat_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Status Perkawinan Pelapor
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="status_perkawinan_pelapor"
                      type="text"
                      placeholder="Status Perkawinan Pelapor"
                      value={this.state.dt.status_perkawinan_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                data-testid="btnSubmitCreate"
                type="submit"
                className="btn btn-primary-custom"
                // LANJUTKAN BOS
                // disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  />
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary-custom">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            {/* <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Jabatan</th>
                    <th>Level</th>
                    <th>TTD</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.datDisposisi.map((dt,i)=>{
                 return(
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>{dt.data_jabatan.nama}</td>
                    <td>{dt.level}</td>
                    <td>

                    <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => {
                      this.disposisi(dt.uuid,this.state.idSurat)}
                    }
                    disabled={this.state.status.btnForm}
                  >
                    Disposisi
                  </button>
                    </td>
                  </tr>
                   )})}
                </tbody>
              </table>
            </div>
             */}
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.riwayatDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status === 0
                            ? "Menunggu Disposisi"
                            : dt.status === 1
                            ? "Telah di disposisi"
                            : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>

        <div id="cetak-surat" style={{ display: "none" }}>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    "
            }}
          />
          <table width="100%" border="0">
            <tr>
              <td width="100" align="center">
                <img alt="logo" src={logo} width="80px" />
              </td>
              <td align="center">
                <div style={{ fontWeight: "bold" }}>
                  <span
                    style={{ fontSize: "24px", textTransform: "uppercase" }}
                  >
                    pemerintah {NAMA_KABUPATEN}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "22px", textTransform: "uppercase" }}
                  >
                    kecamatan {KECAMATAN}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "28px", textTransform: "uppercase" }}
                  >
                    kantor kepala {Nama_DESA}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "16px", textTransform: "capitalize" }}
                  >
                    {ALAMAT_KANTOR_DESA}
                  </span>
                </div>
              </td>
            </tr>
          </table>
          <hr style={{ border: "2px solid black" }} />
          <table
            border="0"
            width="90%"
            style={{
              marginTop: "30px",
              marginLeft: "auto",
              marginRight: "auto",
              verticalAlign: "top"
            }}
          >
            <tr>
              <td align="center" style={{ paddingBottom: "20px" }}>
                {" "}
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    textDecoration: "underline"
                  }}
                >
                  surat keterangan pernah menikah
                </span>
                <br />
                {this.state.cetak.nomor_surat}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "50px" }}>
                  Yang bertanda tangan dibawah ini Kepala {Nama_DESA},
                  Kecamatan {KECAMATAN}, {NAMA_KABUPATEN}, Provinsi {PROVINSI},
                  dengan ini menerangkan bahwa :{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.nama_pemohon}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat/Tangal Lahir
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.tempat_lahir}
                      </span>
                      , {Tanggal(new Date(this.state.cetak.tanggal_lahir))}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.pekerjaan}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Agama
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.agama}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Status Perkawinan
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.status_perkawinan}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.alamat}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="35%"
                      colspan="2"
                      style={{ paddingLeft: "50px" }}
                    >
                      Telah melaksanakan akad nikah dengan
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.nama_pasangan}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat/Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.tempat_lahir_pasangan},{" "}
                        {Tanggal(
                          new Date(this.state.cetak.tanggal_lahir_pasangan)
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.pekerjaan_pasangan}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Status Perkawinan
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.status_perkawinan_pasangan}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik_pasangan}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat Terakhir
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.alamat_pasangan}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat Menikah
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.tempat_menikah}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pada
                    </td>
                    <td>:</td>
                    <td>
                      {Tanggal(new Date(this.state.cetak.tanggal_menikah))}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Mas Lawin
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.mas_kawin}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Wali Nikah
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.wali_nikah}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Saksi Nikah
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.saksi_nikah}
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <span style={{ paddingLeft: "50px" }}>
                  Demikianlah surat keterangan ini dibuat berdasarkan laporan
                  dari :
                </span>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.nama_pelapor}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat/Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.tempat_lahir_pelapor},{" "}
                        {Tanggal(
                          new Date(this.state.cetak.tanggal_lahir_pelapor)
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik_pelapor}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Agama
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.agama_pelapor}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.pekerjaan_pelapor}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.alamat_pelapor}
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table border="0" width="100%">
            <tr>
              <td style={{ textAlign: "justify" }} width="50%">
                &nbsp;
              </td>
              <td style={{ textAlign: "justify" }} width="50%">
                <table border="0" width="100%" cellpadding="0" cellspacing="0">
                  <tr>
                    <td width="35%">Dikeluarkan di</td>
                    <td>:</td>
                    <td>{this.state.cetak.nama_desa}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ borderBottom: "1px solid black" }}>
                      Dikeluarkan pada
                    </td>
                    <td style={{ borderBottom: "1px solid black" }}>:</td>
                    <td style={{ borderBottom: "1px solid black" }}>
                      {Tanggal(new Date(this.state.cetak.tanggal_surat))}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table border="0" width="100%" style={{ marginTop: "20px" }}>
            <tr>
              <td width="50%">&nbsp;</td>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {this.state.cetak.status_ttd}{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {this.state.cetak.jabatan_ttd}
                  </span>
                  {this.state.cetak.jabatan_pengganti_ttd ? (
                    <>
                      <br />
                      {this.state.cetak.jabatan_pengganti_ttd}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td height="50" colspan="2">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td align="center">&nbsp;</td>
              <td align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama_ttd}
                </span>
                <br />
                {this.state.cetak.nip_ttd}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default BasicTable;
