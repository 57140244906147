import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Nav, 
  NavItem, 
  TabContent, 
  TabPane
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// import Select from "react-select";
// import Datepicker from 'react-datepicker';
import classnames from "classnames";


// koneksi
import { Post, JENIS_DESA} from "../../function/Koneksi";
import { DataToken } from "../../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import { Nav, NavItem, TabContent, TabPane } from "react-bootstrap";
import NavLink from "reactstrap/lib/NavLink";

class Pengajuan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataDiterima: [],
      dataDitolak: [],
      pagination: [],
      status: {
        formBerkas: false,
        formExport: false,
        form: false,
        btnForm: true,
        btnAksi: false,
        select: false
      },
      manualPage: "",
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      judul: "Pengajuan Perubahan Data",
      active_tab_icon: "1",
      // =========== Alert ============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      btnActionText: "Konfirmasi",
      // ===================================
      akun: DataToken(sessionStorage.getItem("access_token")),
      dataPenduduk: {
        nik: ""
      },
      detailData: null,
      dataKK: null,
			isKelurahan: false,
    };
  }

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" +
                Math.ceil(
                  this.state.pagination.total / this.state.pagination.per_page
                );
              break;

            case "Diterima":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDiterima.total /
                  this.state.paginationDiterima.per_page
                );
              break;

            case "Ditolak":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDitolak.total /
                  this.state.paginationDitolak.per_page
                );
              break;
            default:
              // no default
              break;
          }
          break;
        case ">":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) + 1);
              break;

            case "Diterima":
              link =
                "&page=" +
                (parseInt(this.state.paginationDiterima.current_page) + 1);
              break;

            case "Ditolak":
              link =
                "&page=" +
                (parseInt(this.state.paginationDitolak.current_page) + 1);
              break;
            default:
              // no default
              break;

          }
          break;
        case "<":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) - 1);
              break;

            case "Diterima":
              link =
                "&page=" +
                (parseInt(this.state.paginationDiterima.current_page) - 1);
              break;

            case "Ditolak":
              link =
                "&page=" +
                (parseInt(this.state.paginationDitolak.current_page) - 1);
              break;
            default:
              // no default
              break;
          }
          break;
        case "<<":
          link = "&page=1";
          break;
        default:
          link = "&page=" + params.page;
          break;
      }
    }

    Post(
      "penduduk/perubahan-data?status=" + params.tab + link,
      null,
      this.state.dataPenduduk,
      dtkat => {
        console.log('Data Perubahan Data');
        console.log(dtkat);

        switch (params.tab) {
          case 'Diajukan':
            this.setState({
              data: dtkat.data.results.data,
              pagination: dtkat.data.results
            });
            break;
        
          case 'Diterima':
            this.setState({
              dataDiterima: dtkat.data.results.data,
              paginationDiterima: dtkat.data.results
            });
            break;
        
          case 'Ditolak':
            this.setState({
              dataDitolak: dtkat.data.results.data,
              paginationDitolak: dtkat.data.results
            });
            break;
        
          default:
            break;
        }

        
        this.pagination(dtkat.data.results, params.tab);
      }
    );
  };

  // Pagination
  pagination = (data, tab) => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
					start = data.last_page - 4;
				}
        end = data.last_page;
      }

      //console.log(start)
      //console.log(end)

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            key={i}
            onClick={() => this.fetch({ page: i, tab: tab })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };

  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let dataPenduduk = {
        nik: e.target.value
      };
      Post("penduduk/perubahan-data", null, dataPenduduk, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results,
          dataPenduduk
        });
      });
    }
  };

  // Bootstrap tabs function
  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      let btnActionText = ''

      switch (tab) {
        case '1':
          this.fetch({ page: null, where: [], tab: "Diajukan" });
          btnActionText = "Konfirmasi"
          break;
      
        case '2':
          this.fetch({ page: null, where: [], tab: "Diterima" });
          btnActionText = "Detail"
          break;
        
        case '3':
          this.fetch({ page: null, where: [], tab: "Ditolak" });
          btnActionText = "Detail"
          break;
        default:
          // no default
          break;
      }

      this.setState({
        active_tab_icon: tab,
        btnActionText,
      });
    }
  }

  konfirmasiPerubahan = (action) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Ya, saya yakin"
          confirmBtnBsStyle="warning"
          confirmBtnStyle={{ fontWeight: 600 }}
          cancelBtnText="Batal"
          cancelBtnBsStyle="default"
          cancelBtnStyle={{ fontWeight: 600 }}
          type="warning"
          title="Apakah anda yakin ingin menerima perubahan data ini?"
          onCancel={() => {
            this.setState({ alert: null });
          }}
          onConfirm={() => {
            //console.log(this.state.detailData)
            if (this.state.detailData) {
              let bodyFormData
              
              if (action === "terima") {
                bodyFormData = {
                  status_pengajuan: "Diterima",
                };
              } else if (action === "tolak") {
                bodyFormData = {
                  status_pengajuan: "Ditolak",
                };
              }
              
              Post(
                "penduduk/perubahan-data/verifikasi",
                this.state.detailData.uuid,
                bodyFormData,
                (data) => {
                  //console.log(data);
                  if (data.status === 200) {
                    this.setState({
                      alert: null,
                      show: true,
                      basicType: "success",
                      basicTitle: "Konfirmasi Perubahan Data",
                      pesanAlert: "Berhasil melakukan perubahan data",
                    });
                    this.componentDidMount(this.state.active_tab_icon);
                  } else {
                    this.setState({
                      alert: null,
                      show: true,
                      basicType: "danger",
                      basicTitle: "Konfirmasi Perubahan Data",
                      pesanAlert: "Gagal melakukan perubahan data",
                    });
                  }
                  this.tutupForm();
                }
              );
            }
          }}
        >
          Proses ini tidak dapat dibatalkan, pastikan sudah memeriksa seluruh
          data dengan teliti
        </SweetAlert>
      ),
    });
  };

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    this.state.status.btnForm = true;
    this.forceUpdate();
    let id = document.getElementById("oldnik").value;
    let addPenduduk = {
      id_kk: this.state.dataKK.id,
    };
    let resstat = 200;
    //console.log(JSON.stringify(addPenduduk));
    Post("penduduk/update", id, addPenduduk, res => {
      if (res.status === resstat) {
        this.state.status.form = false;
        this.state.status.btnAksi = false;
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Penduduk",
          pesanAlert: "Berhasil  Perbarui Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Gagal Perbarui Data",
          pesanAlert: res.data.message
        });
      }

      this.state.status.btnForm = false;
      this.forceUpdate();

      this.componentDidMount(this.state.active_tab_icon);
    });
  };

  //
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.form = false;
    this.forceUpdate();

    setTimeout(function(){
      this.setState({ detailData: null })
    }.bind(this), 250)

    // this.state.detailData = null
  };

  getDataPenduduk = data => {
    //console.log(data);
    // this.state.status.btnAksi = true;
    this.state.judul = "Konfirmasi Perubahan Data";
    this.state.detailData = data;
    this.state.status.form = true;
    this.state.status.btnForm = true;

    this.state.kkStat=null
    this.state.dataKK=null
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }}>
        <Button
          size="sm"
          color="success"
          className="btn-icon"
          onClick={() => this.getDataPenduduk(row)}
          disabled={this.state.status.btnAksi}
        >
          {this.state.btnActionText}
        </Button>
      </div>
    );
  };

  componentDidMount(tab = '1') {
    switch (tab) {
      case '1':
        this.fetch({ page: null, where: [], tab: "Diajukan" });
        break;
    
      case '2':
        this.fetch({ page: null, where: [], tab: "Diterima" });
        break;
    
      case '3':
        this.fetch({ page: null, where: [], tab: "Ditolak" });
        break;
    
      default:
        break;
    }

    this.setState({
      active_tab_icon: tab
    });

    if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			this.setState({ isKelurahan: true });
    }
  }

  render() {
    var data = this.state.data;

    switch (this.state.active_tab_icon) {
      case "1":
        data = this.state.data
        break;

      case "2":
        data = this.state.dataDiterima
        break;

      case "3":
        data = this.state.dataDitolak
        break;
      default:
        // no default
        break;
    }

    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          formatter: (key, obj, index) =>{ 
              let current_pagenum = '';
              let total_records_per_page = '';
              let row_index = '';
              let serial_num = '';

              if(this.state.active_tab_icon ==='1'){
                current_pagenum = this.state.pagination.current_page;
                total_records_per_page = this.state.pagination.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              else if(this.state.active_tab_icon ==='2'){
                current_pagenum = this.state.paginationDiterima.current_page;
                total_records_per_page = this.state.paginationDiterima.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              } 
              else if(this.state.active_tab_icon ==='3'){
                current_pagenum = this.state.paginationDitolak.current_page;
                total_records_per_page = this.state.paginationDitolak.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              
              return serial_num; 
          }
      },
      {
        dataField: "nik",
        headerAlign: 'center',
				align: 'center',
        text: "NIK",
        sort: false
      },
      // {
      //   dataField: "id_kk",
      //   text: "No KK",
      //   sort: false
      // },
      {
        dataField: "nama",
        headerAlign: 'center',
				align: 'center',
        text: "Nama Lengkap",
        sort: true
      },
      {
        dataField: "status_pengajuan",
        headerAlign: 'center',
				align: 'center',
        text: "Status",
        // sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    let bootstrapTable = (
      <div>
        <BootstrapTable keyField="id" data={data} columns={columns} />
        {data.length !== 0 ? (
          <div className="pull-right text-white">
            {this.state.awal}
            {this.state.sebelum}
            {this.state.hal.map(val => {
              return val;
            })}
            {this.state.setelah}
            {this.state.akhir}
          </div>
        ) : ''}
      </div>
    )

    return (
      <div>
        {/* <Breadcrumb title="Perubahan Data Pindah KK" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                {/* <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>Data Penduduk</h5>
                    </div>
                  </div>
                </div> */}
                <div className="card-body datatable-react">
                  {/* <div className="row">
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        id="cariTable"
                        placeholder="Cari Data berdasarkan nama pemohon, atau nomor surat"
                        onKeyPress={this.cariData}
                      />
                    </div>
                  </div> */}
                  <Nav tabs className="tabs-color">
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "1"
                        })}
                        onClick={() => {
                          this.toggle_icon("1");
                        }}
                      >
                        <i className="icofont icofont-ui-home"></i>
                        Diproses
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "2"
                        })}
                        onClick={() => {
                          this.toggle_icon("2");
                        }}
                      >
                        <i className="icofont icofont-ui-home"></i>
                        Diterima
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "3"
                        })}
                        onClick={() => {
                          this.toggle_icon("3");
                        }}
                      >
                        <i className="icofont icofont-ui-home"></i>
                        Ditolak
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.active_tab_icon}>
                    <TabPane tabId="1">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            {this.state.active_tab_icon === "1" ? bootstrapTable : ''}
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            {this.state.active_tab_icon === "2" ? bootstrapTable : ''}
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            {this.state.active_tab_icon === "3" ? bootstrapTable : ''}
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          styles={{ modal: { width: "80%" } }}
          onClose={this.tutupForm}
          closeOnEsc={false}
          closeOnOverlayClick={true}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              {/* <p>
                Perubahan data hanya terhadap penduduk ini.
              </p> */}

              {this.state.detailData !== null ? (
                <>
                  <input
                    className="form-control"
                    id="oldnik"
                    type="hidden"
                    placeholder="Penduduk"
                    defaultValue={this.state.detailData.id}
                  />
                  <input
                    className="form-control"
                    id="oldkk"
                    type="hidden"
                    placeholder="Penduduk"
                    defaultValue={this.state.detailData.id_kk}
                  />
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Nama Penduduk
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nama"
                        type="text"
                        placeholder="nama"
                        readOnly
                        defaultValue={this.state.detailData.nama}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.nama}</small></h6>
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      NIK
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.nik}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.id}</small></h6>
                    </Col>
                  </FormGroup>
                  {/* <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      No KK{" "}
                    </Label>
                    <Col sm="9">
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={e => {
                          this.changeSelectValue(e, "kk");
                        }}
                        defaultValue={this.state.kkStat}
                        value={this.state.kkStat}
                        name="No KK"
                        options={this.state.frmKk}
                        placeholder="Pilih KK"
                        onInputChange={e => this.handleInputChangeNIK(e, "kk")}
                        isClearable
                      />
                    </Col>
                  </FormGroup> */}
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      SHDK
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.data_shdk.nama}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.data_shdk.nama}</small></h6>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Tempat Lahir
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.tempat_lahir}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.tempat_lahir}</small></h6>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Tanggal Lahir
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.tanggal_lahir}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.tanggal_lahir}</small></h6>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Jenis Kelamin
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.jk}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.jk}</small></h6>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Agama
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.data_agama.nama}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.data_agama.nama}</small></h6>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Nama Ayah
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.nama_ayah_kandung}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.nama_ayah_kandung}</small></h6>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Nama Ibu
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.nama_ibu_kandung}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.nama_ibu_kandung}</small></h6>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Alamat
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.alamat}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.alamat}</small></h6>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Pekerjaan
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.data_pekerjaan.nama}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.data_pekerjaan.nama}</small></h6>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Pendidikan Terakhir
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.data_pendidikan_terakhir.nama}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.data_pendidikan_terakhir.nama}</small></h6>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Status Perkawinan
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.status_perkawinan}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.status_perkawinan}</small></h6>
                    </Col>
                  </FormGroup>
                  
                  {this.state.isKelurahan?
                  <></>
                  :
                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputEmail3"
                      >
                        Dusun
                      </Label>
                      <Col sm="9">
                        <input
                          className="form-control"
                          id="nik"
                          readOnly
                          type="text"
                          placeholder="NIK"
                          defaultValue={this.state.detailData.data_rt.data_rw.data_dusun.nama}
                        />
                        <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.data_kk.data_rt.data_rw.data_dusun.nama}</small></h6>
                      </Col>
                    </FormGroup>
                  }
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      RT
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.data_rt.nama}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.data_kk.data_rt.nama}</small></h6>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      RW
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.data_rt.data_rw.nama}
                      />
                      <h6 style={{ margin: 0 }}><small>Sebelumnya : {this.state.detailData.data_penduduk.data_kk.data_rt.data_rw.nama}</small></h6>
                    </Col>
                  </FormGroup>
                </>
              ) : ''}
            </div>
            {this.state.btnActionText === "Konfirmasi" ? (
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  // disabled={this.state.status.btnForm}
                  onClick={() => {
                    this.konfirmasiPerubahan("tolak")
                  }}
                >
                  Tolak
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  // disabled={this.state.status.btnForm}
                  onClick={() => {
                    this.konfirmasiPerubahan("terima")
                  }}
                >
                  Terima
                </button>
              </div>
            ) : ''}
          </Form>
        </Modal>
      </div>
    );
  }
}

export default Pengajuan;
