import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import { Col, FormGroup, Label, Form } from 'reactstrap';
import Select from 'react-select';
import { Post, Get, JENIS_DUSUN } from '../function/Koneksi';
import Datetime from 'react-datetime';
// import Keyboard from 'react-simple-keyboard';
// import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css';

const frmDef = {
	uuid: '',
	id_jenis: '',
	id_pengaju: '',
	tanggal_surat: new Date(),
	nik: '',
	nama_pemohon: '',
	tempat_lahir: '',
	tanggal_lahir: '',
	pekerjaan: '',
	jenis_kelamin: '',
	status_perkawinan: '',
	kewarganegaraan: 'Indonesia',
	tujuan_pembuatan: '',
	lokasi: '',
	kk: '',
	agama: '',
	status_ttd: '',
	jabatan_ttd: '',
	jabatan_pengganti_ttd: '',
	nama_ttd: '',
	nip_ttd: '',

	//detail
	nama_nasab_p1: '',
	nama_nasab_p2: '',
	nama_pasangan: '',
	jenis_kelamin_pasangan: '',
	tempat_lahir_pasangan: '',
	tanggal_lahir_pasangan: '',
	pekerjaan_pasangan: '',
	agama_pasangan: '',
	nik_pasangan: '',
	alamat_pasangan: '',
	tempat_nikah: ''
};

const dateFormat = require('dateformat');

export default class SuratPindahNikah extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Basic: false,
			formPasangan: 'none',
			status: {
				form: false,
				btnForm: true,
				btnAksi: [],
				keyboard: false
			},
			dt: frmDef,
			showBio: false,
			showBio2: false,
			pendStat: null,
			pendStat2: null,
			frmPend2: [],
			nik2Stat: '',

			scrollUp: null,
			//keyboard
			layoutName: 'default',
			inputName: 'input1',
			inputName1: 'kk',
			input: {},
			inputPattern: /^\d+$/,
			display: 'none',
			focus: false,
			hasFocus: false,
			style: {},
			jkPasangan: null,

			// Dropdown
			frmAgm: [],
			agmStat: null,
			frmPkr: [],
			pkrStat: null,
			
			// Pilih Data Keluarga
			frmNikKeluarga: [],
			nikStatTerlapor: null,
			dt_keluarga:[],
			dataKeluarga: "none",
		};
	}


	handleClick = (event) => {
		alert(event);
	};

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case "nikTerlapor":
					this.setState({ nikStatTerlapor: e });
					break;
				case 'pekerjaan_pasangan':
					this.setState({ 
						pkrStat: e, 
						dt:{
							...this.state.dt,
							pekerjaan_pasangan: e.label,
						}
					});
					break;
				case 'agama':
					this.setState({ 
						agmStat: e, 
						dt:{
							...this.state.dt,
							agama: e.label,
						}
					});
					break;
				case 'agama_pasangan':
					this.setState({ 
						agmStat: e, 
						dt:{
							...this.state.dt,
							agama_pasangan: e.label,
						}
					});
					break;
				case 'status_perkawinan_pasangan':
					this.setState({ 
						spkStat: e, 
						dt:{
							...this.state.dt,
							status_perkawinan_pasangan: e.label,
						}
					});
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case "nikTerlapor":
					this.setState({ nikStatTerlapor: e });
					break;
				case 'pekerjaan_pasangan':
					this.setState({ 
						pkrStat: e, 
						dt:{
							...this.state.dt,
							pekerjaan_pasangan: '',
						}
					});
					break;
				case 'agama':
					this.setState({ 
						agmStat: e,
						dt:{
							...this.state.dt,
							agama: '',
						}
					});
					break;
				case 'agama_pasangan':
					this.setState({ 
						agmStat: e, 
						dt:{
							...this.state.dt,
							agama_pasangan: '',
						}
					});
					break;
				case 'status_perkawinan_pasangan':
					this.setState({ 
						spkStat: e,
						dt:{
							...this.state.dt,
							status_perkawinan_pasangan: '',
						}
					
					});
					break;
				default:
					// no default
					break;
			}
		}

		this.forceUpdate();
	};

	changeInputText = (e, fld) => {
		switch (fld) {
			case 'nama_pasangan':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						nama_pasangan: e.target.value,
					},
				})
				this.forceUpdate();
				break;
			case 'nik_pasangan':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						nik_pasangan: e.target.value,
					},
				})
				this.forceUpdate();
				break;
			case 'nama_nasab_p2':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						nama_nasab_p2: e.target.value,
					},
				})
				this.forceUpdate();
				break;
			case 'jenis_kelamin_pasangan':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						jenis_kelamin_pasangan: e.target.value,
					},
				})
				this.forceUpdate();
				break;
			case 'tempat_lahir_pasangan':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						tempat_lahir_pasangan: e.target.value,
					},
				})
				this.forceUpdate();
				break;
			case 'tanggal_lahir_pasangan':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						tanggal_lahir_pasangan: e.target.value,
					},
				})
				this.forceUpdate();
				break;
			case 'pekerjaan_pasangan':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						pekerjaan_pasangan: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'agama_pasangan':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						agama_pasangan: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'alamat_pasangan':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						alamat_pasangan: e.target.value,
					},
				})
				this.forceUpdate();
				break;
			default:
				// no default
				break;
		}
	};

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld === "nikTerlapor") {
				let newArray = this.state.frmNikKeluarga;
	
				newArray = newArray.filter(day => day !== e);

				this.setState({
					frmNikKeluarga:newArray,
				})
			}
			else if (fld === 'nik') {
				let pendStat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmPend = [];
					datanik.data.results.data.forEach((dt) => {
						frmPend.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmPend, pendStat });
				});
			} else {
				let nik2Stat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmPend2 = [];
					datanik.data.results.data.forEach((dt) => {
						frmPend2.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmPend2, nik2Stat });
				});
			}
		}
	};

	getPenduduk = (e, label) => {
		if (e) {
			this.changeSelectValue(e, label);
			Get('penduduk/find/' + e.value, null, (data) => {
				let alamat = '';
				if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
					alamat =
					" RT. " +
					data.results.data_kk.data_rt.nama +
					"/RW. " +
					data.results.data_kk.data_rt.data_rw.nama +
					", " +
					data.results.data_kk.data_rt.data_rw.data_keldes.nama +
					", Kec. " +
					data.results.data_kk.data_rt.data_rw.data_keldes
						.data_kecamatan.nama +
					", " +
					data.results.data_kk.data_rt.data_rw.data_keldes
						.data_kecamatan.data_kabkota.nama; 
				} else {
					if (data.results.data_kk.id_rt === '0') {
						alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
						", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
						", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
						", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
					} else {
						alamat =
						" RT. " + 
						data.results.data_kk.data_rt.nama +
						"/RW. " +
						data.results.data_kk.data_rt.data_rw.nama +
						", " + JENIS_DUSUN + " " +
						data.results.data_kk.data_rt.data_rw.data_dusun.nama +
						", " + localStorage.getItem('JENIS_DESA') + " " +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
						", Kec. " +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.data_kabkota.nama; 
					}
				}

				if (label === "nikTerlapor") {
					this.setState({
						dt_keluarga:{
							nik: e.value,
							kk: data.results.id_kk,
							nama: data.results.nama,
							agama: data.results.data_agama.nama,
							alamat: alamat,
							jenis_kelamin: data.results.jk,
							pekerjaan: data.results.data_pekerjaan.nama,
							status_perkawinan: data.results.status_perkawinan,
							tanggal_lahir: data.results.tanggal_lahir,
							tempat_lahir: data.results.tempat_lahir,
							pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
						},
						dataKeluarga: 'block',
					})
				}
				else if (label === 'nik') {
					Get('catatan-penduduk/nik', data.results.id, (dpddk) => {
						let pctt = 0;
						let catatan = '';
						if (dpddk.results.data) {
							dpddk.results.data.forEach((dtp) => {
								if (dtp.status === 0 && dtp.penalti === 1) {
									pctt = 1;
									catatan = dtp.catatan;
								}
							});
						}
						if (pctt === 0) {
							this.setState({
								showBio: true,
								pendStat: { value: e.value, label: e.label },
								dt:{
									...this.state.dt,
									nik: data.results.id,
									kk: data.results.id_kk,
									nama_pemohon: data.results.nama,
									tempat_lahir: data.results.tempat_lahir,
									tanggal_lahir: data.results.tanggal_lahir,
									pekerjaan: data.results.data_pekerjaan.nama,
									jenis_kelamin: data.results.jk,
									pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
									nama_nasab_p1: data.results.nama_ayah_kandung,
									agama: data.results.data_agama.nama,
									status_perkawinan: data.results.status_perkawinan,
									alamat: alamat,
								}
							})
							this.forceUpdate();
						} else {
							this.setState({
								showBio: false,
								btnForm: true,
								dt:{
									...this.state.dt,
									nik: '',
									nama_pemohon: '',
									tempat_lahir: '',
									tanggal_lahir: '',
									pekerjaan: '',
									pendidikan_terakhir: '',
									agama: '',
									status_perkawinan: '',
									alamat: '',
								},
								show: true,
								basicType: 'danger',
								basicTitle: 'Peringatan Catatan Warga',
								pesanAlert:
									'Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r' +
									catatan,
								pendStat: null,
								frmPend: []
							});
							this.forceUpdate();
						}
					});
				}
				if (label === 'nik2') {
					this.setState({
						showBio2: true,
						pendStat2: { value: e.value, label: e.label },
						dt:{
							...this.state.dt,
							nik_pasangan: data.results.id,
							kk_pasangan: data.results.id_kk,
							nama_pasangan: data.results.nama,
							tempat_lahir_pasangan: data.results.tempat_lahir,
							tanggal_lahir_pasangan: data.results.tanggal_lahir,
							pekerjaan_pasangan: data.results.data_pekerjaan.nama,
							jenis_kelamin_pasangan: data.results.jk,
							pendidikan_terakhir_pasangan: data.results.data_pendidikan_terakhir.nama,
							agama_pasangan: data.results.data_agama.nama,
							status_perkawinan_pasangan: data.results.status_perkawinan,
							nama_nasab_p2: data.results.nama_ayah_kandung,
							alamat_pasangan: alamat,
						},
					})
				}
				if (this.state.pendStat2 !== null && this.state.pendStat !== null) {
					this.setState({
						status:{
							...this.state.status,
							btnForm: false,
						}
					})
				}

				// this.state.status.btnAksi = false;
				this.forceUpdate();
			});
		} else {
			if (label === "nikTerlapor") {
				this.setState({
					dt_keluarga:{
						nik: '',
						kk: '',
						nama: '',
						agama: '',
						alamat: '',
						jenis_kelamin: '',
						pekerjaan: '',
						status_perkawinan: '',
						tanggal_lahir: '',
						tempat_lahir: '',
						pendidikan_terakhir: '',
					},
					dataKeluarga: 'none',
					nikStatTerlapor:null,
				});
			}
			else if (label === 'nik') {
				this.setState({
					showBio: false,
					pendStat: [],
					dt:{
						...this.state.dt,
						nik: '',
						nama_pemohon: '',
						tempat_lahir: '',
						tanggal_lahir: '',
						pekerjaan: '',
						pendidikan_terakhir: '',
						agama: '',
						status_perkawinan: '',
						alamat: '',
					},
				})
			}
			if (label === 'nik2') {
				this.setState({
					showBio2: false,
					pendStat2: [],
					dt:{
						...this.state.dt,
						nik: '',
						nama_pasangan: '',
						tempat_lahir: '',
						tanggal_lahir: '',
						pekerjaan: '',
						pendidikan_terakhir: '',
						agama: '',
						status_perkawinan: '',
						alamat: '',
					},
				})
			}
			this.setState({
				status:{
					...this.state.status,
					btnForm: true,
				}
			})

			// this.state.status.btnAksi = false;
			this.forceUpdate();
		}
	};

	dataPasangan = () => {
		// //console.log(this.props.location.state);
		let showBio2 = true;
		if (this.state.pendStat2 !== null) {
			showBio2 = true;
		} else {
			showBio2 = this.state.showBio2 === false ? true : false;
		}

		this.setState({
			showBio2: showBio2,
			pendStat2: null,
			formPasangan: this.state.formPasangan === '' ? 'none' : '',
			input:{
				...this.state.input,
				nama_nasab_p2: '',
				nama_pasangan: '',
				jenis_kelamin_pasangan: '',
				tempat_lahir_pasangan: '',
				tanggal_lahir_pasangan: '',
				pekerjaan_pasangan: '',
				agama_pasangan: '',
				nik_pasangan: '',
				alamat_pasangan: '',
			}
		})
		this.forceUpdate();
	};

	simpan = (e) => {
		e.preventDefault();

		// let jkPasangan = true;

		// if (this.state.formPasangan != "none") {
		// 	jkPasangan = this.state.jkPasangan
		// }

		const nikPasangan = document.getElementById("nik_pasangan").value
		
		if (nikPasangan.trim() === '') {
			this.props.getAntrian('Surat Pengantar Pindah Nikah', 'gagal', true);
		}
		else if (nikPasangan.length !== 16) {
			this.props.getAntrian('Surat Pengantar Pindah Nikah', 'cek digit nik');
		}
		else if (this.state.showBio2 !== "none" && this.state.dt.jenis_kelamin_pasangan!=='' && this.state.dt.pekerjaan_pasangan!=='' && this.state.dt.agama_pasangan!== '' && this.state.nikStatTerlapor) {
			// let alamat = '';
			// if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			// 	alamat =
			// 		' RT. ' +
			// 		this.props.data.results.data_kk.data_rt.nama +
			// 		'/RW. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
			// 		', Kec. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
			// } else {
			// 		if (this.props.data.results.data_kk.id_rt === '0') {
			// 			alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
			// 			", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
			// 		} else {
			// 			alamat =
			// 			" RT. " + 
			// 			this.props.data.results.data_kk.data_rt.nama +
			// 			"/RW. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 			", " + JENIS_DUSUN + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 			", " + JENIS_DESA + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 			", Kec. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.nama +
			// 			", " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.data_kabkota.nama; 
			// 		}
			// }
			
			//detail
			let formDetail = [
				{
					nama_item: 'nama_pasangan',
					isi_item: document.getElementById('nama_pasangan').value
				},
				{
					nama_item: 'nama_nasab_p1',
					isi_item: this.props.data.results.nama_ayah_kandung
				},
				{
					nama_item: 'nama_nasab_p2',
					isi_item: document.getElementById('nama_nasab_p2').value
				},
				{
					nama_item: 'jenis_kelamin_pasangan',
					// isi_item: document.getElementById('jenis_kelamin_pasangan').value
					isi_item: this.state.dt.jenis_kelamin_pasangan
				},
				{
					nama_item: 'tempat_lahir_pasangan',
					isi_item: document.getElementById('tempat_lahir_pasangan').value
				},
				{
					nama_item: 'tanggal_lahir_pasangan',
					// isi_item: this.state.dt.tanggal_lahir_pasangan
					isi_item: dateFormat(this.state.dt.tanggal_lahir_pasangan, 'yyyy-mm-dd')
				},
				{
					nama_item: 'pekerjaan_pasangan',
					isi_item: this.state.dt.pekerjaan_pasangan
					// isi_item: document.getElementById('pekerjaan_pasangan').value
				},
				{
					nama_item: 'agama_pasangan',
					// isi_item: document.getElementById('agama_pasangan').value
					isi_item: this.state.dt.agama_pasangan
				},
				{
					nama_item: 'nik_pasangan',
					// isi_item: this.state.input.nik_pasangan
					isi_item: nikPasangan
				},
				{
					nama_item: 'alamat_pasangan',
					isi_item: document.getElementById('alamat_pasangan').value
				},
				{
					nama_item: 'tempat_nikah',
					isi_item: document.getElementById('tempat_nikah').value
				}
			];

			let addData = {
				id_jenis: this.props.id_jenis,
				nik_pelapor: this.props.data.results.id,
				nik: this.state.dt_keluarga.nik,
				kk: this.state.dt_keluarga.kk,
				nama_pemohon: this.state.dt_keluarga.nama,
				tempat_lahir: this.state.dt_keluarga.tempat_lahir,
				tanggal_lahir: this.state.dt_keluarga.tanggal_lahir,
				pekerjaan: this.state.dt_keluarga.pekerjaan,
				agama: this.state.dt_keluarga.agama,
				status_perkawinan: this.state.dt_keluarga.status_perkawinan,
				alamat: this.state.dt_keluarga.alamat,
				jenis_kelamin: this.state.dt_keluarga.jenis_kelamin,
				pendidikan_terakhir: this.state.dt_keluarga.pendidikan_terakhir,

				kewarganegaraan: 'Indonesia',
				tujuan_pembuatan: 'Surat Pengantar Pindah Nikah',
				detail: formDetail
			};

			// let psn = '';
			let resstat = 201;
			let metode = 'create';

			Post('surat-keterangan/grup/' + metode + '/with-detail', null, addData, (res) => {
				this.onCloseModal('Basic');

				let dataAntrian = {
					nama: this.props.data.results.nama,
					nik: this.props.data.results.id,
					keterangan: '',
					uuid_surat:res.data.results,
				};
	
				if (res.status === resstat) {
					setTimeout(function () {
						Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
							//console.log("Hasil Update Status")
							//console.log(res2)
							if (res2.code === 201) {
								//console.log("Berhasil Ganti Status Menjadi Selesai")
	
								this.props.getAntrian('Surat Pengantar Pindah Nikah', 'berhasil', false, dataAntrian);
							}
							else{
								//console.log("Gagal Ganti Status Menjadi Selesai")
	
								this.props.getAntrian('Surat Pengantar Pindah Nikah', 'gagal');
							}
						});
						this.setState({
							pkrStat: null,
							agmStat: null,
						})
					}.bind(this),1000)
					// this.props.getAntrian('Surat Pengantar Pindah Nikah', 'berhasil', false, dataAntrian);
					this.forceUpdate();
				} else {
					this.props.getAntrian('Surat Pengantar Pindah Nikah', 'gagal');
				}
				this.componentDidMount();
			});
		} else {
			this.props.getAntrian('Surat Pengantar Pindah Nikah', 'gagal', true);
		}
		
	};

	resetData = () => {
		this.setState({
			formPasangan: 'none',
			dt: frmDef,
			pendStat2: null,
			nik2Stat: '',
		})
		this.forceUpdate();
	};

	componentDidMount() {
		this.resetData();
		// Get list anggota keluarga from parent
		if (this.props.anggotaKeluarga) {
			this.setState({ frmNikKeluarga: this.props.anggotaKeluarga })
		}
		// Get("penduduk/kk/" + this.props.data.results.id_kk, null, data => {
		// 	console.log("Data Keluarga")
		// 	console.log(data)
		// 	if(data.results){
		// 	  let frmNikKeluarga = [];
		// 	  data.results.forEach(dt => {
		// 		frmNikKeluarga.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
		// 	  });
		// 	  this.setState({ frmNikKeluarga });
			  
		// 	  // For testing
		// 	  if (this.props.getListNIKTerlapor) {
		// 		this.props.getListNIKTerlapor(frmNikKeluarga)
		// 	  }
		// 	}
		// });

		// Get('penduduk', null, (data) => {
		// 	// let frmPend = [];
		// 	let frmPend2 = [];
		// 	if(data.results){
		// 		data.results.data.forEach((dt) => {
		// 			// frmPend.push({ value: dt.id, label: dt.id });
		// 			frmPend2.push({ value: dt.id, label: dt.id });
		// 		});
		// 		this.setState({ frmPend2 });
		// 	}
		// });

		// Get list agama from parent
		if (this.props.listAgama) {
			this.setState({ frmAgm: this.props.listAgama })
		}
		// Get list agama
		// Get('agama/list', null, (data) => {
		// 	let frmAgm = [];
		// 	if(data.results){
		// 		data.results.forEach((dt) => {
		// 			frmAgm.push({ value: dt.uuid, label: dt.nama });
		// 		});
		// 		this.setState({ frmAgm });
		// 	}
		// });

		// Get list pekerjaan from parent
		if (this.props.listPekerjaan) {
			this.setState({ frmPkr: this.props.listPekerjaan })
		}
		// Get List Pekerjaan
		// Get('pekerjaan/list', null, (data) => {
		// 	let frmPkr = [];
		// 	if(data.results){
		// 		data.results.forEach((dt) => {
		// 			frmPkr.push({ value: dt.uuid, label: dt.nama });
		// 		});
		// 		this.setState({ frmPkr });
		// 	}
		// });


	}

	// Cari NIK
	handleChange = (e, cek) => {
		switch (cek) {
			case 'cari_nik1':
				this.setState({
					pendStat2: e.target.value
				});
				this.forceUpdate();
				break;
			default:
				// no default
				break;
		}
	};

	cariNIK = (e, sel) => {
		e.preventDefault();

		let id_cari = '';
		if (sel === 'cari_nik1') {
			id_cari = this.state.input.cari_nik1;
		}

		//console.log("ID CARI BOSSSSS")
		//console.log(id_cari)

		if (id_cari.length !== 16) {
			// this.state.input.nik_pasangan = ''
			// this.state.input.kk_pasangan = ''
			// this.state.input.nama_pasangan = ''
			// this.state.input.tempat_lahir_pasangan = ''
			// this.state.input.tanggal_lahir_pasangan = ''
			// this.state.input.pekerjaan_pasangan = ''
			// this.state.input.jenis_kelamin_pasangan = ''
			// this.state.input.pendidikan_terakhir_pasangan = ''
			// this.state.input.agama_pasangan = ''
			// this.state.input.status_perkawinan_pasangan = ''
			// this.state.input.nama_nasab_p2 = ''
			// this.state.input.alamat_pasangan = ''
			this.setState({
				showBio2: false,
				pendStat2: [],
				dt:{
					...this.state.dt,
					nik_pasangan: '',
					kk_pasangan: '',
					nama_pasangan: '',
					tempat_lahir_pasangan: '',
					tanggal_lahir_pasangan: '',
					pekerjaan_pasangan: '',
					jenis_kelamin_pasangan: '',
					pendidikan_terakhir_pasangan: '',
					agama_pasangan: '',
					status_perkawinan_pasangan: '',
					nama_nasab_p2: '',
					alamat_pasangan: '',
				},
			})
			this.forceUpdate();
			//console.log('Panjang NIK adalah 16 digit');
			this.props.getAntrian('Surat Pengantar Pindah Nikah', 'cek digit nik');
		} else {

			Get('penduduk/find', id_cari, (data) => {
				if (data.results) {
					//console.log('Berhasil Tampilkan Penduduk');
					//console.log(data.results);

					let alamat = '';
					if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
						alamat =
						" RT. " +
						data.results.data_kk.data_rt.nama +
						"/RW. " +
						data.results.data_kk.data_rt.data_rw.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_keldes.nama +
						", Kec. " +
						data.results.data_kk.data_rt.data_rw.data_keldes
							.data_kecamatan.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_keldes
							.data_kecamatan.data_kabkota.nama; 
					} else {
						if (data.results.data_kk.id_rt === '0') {
							alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
							", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
						} else {
							alamat =
							" RT. " + 
							data.results.data_kk.data_rt.nama +
							"/RW. " +
							data.results.data_kk.data_rt.data_rw.nama +
							", " + JENIS_DUSUN + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.nama +
							", " + localStorage.getItem('JENIS_DESA') + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
							", Kec. " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
							.data_kecamatan.nama +
							", " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
							.data_kecamatan.data_kabkota.nama; 
						}
					}
						this.setState({
							showBio2: true,
							pkrStat: { value: data.results.data_pekerjaan.uuid, label: data.results.data_pekerjaan.nama },
							agmStat: { value: data.results.data_agama.uuid, label: data.results.data_agama.nama },
							pendStat2: id_cari,
							dt:{
								...this.state.dt,
								nik_pasangan: data.results.id,
								kk_pasangan: data.results.id_kk,
								nama_pasangan: data.results.nama,
								tempat_lahir_pasangan: data.results.tempat_lahir,
								// this.state.dt.tanggal_lahir_pasangan = data.results.tanggal_lahir;
								tanggal_lahir_pasangan: new Date(data.results.tanggal_lahir),
								pekerjaan_pasangan: data.results.data_pekerjaan.nama,
								jenis_kelamin_pasangan: data.results.jk,
								pendidikan_terakhir_pasangan: data.results.data_pendidikan_terakhir.nama,
								agama_pasangan: data.results.data_agama.nama,
								status_perkawinan_pasangan: data.results.status_perkawinan,
								nama_nasab_p2: data.results.nama_ayah_kandung,
								alamat_pasangan: alamat,
							},
						})

					// this.state.input.nik_pasangan = data.results.id;
					// this.state.input.kk_pasangan = data.results.id_kk;
					// this.state.input.nama_pasangan = data.results.nama;
					// this.state.input.tempat_lahir_pasangan = data.results.tempat_lahir;
					// this.state.input.tanggal_lahir_pasangan = data.results.tanggal_lahir;
					// this.state.input.pekerjaan_pasangan = data.results.data_pekerjaan.nama;
					// this.state.input.jenis_kelamin_pasangan = data.results.jk;
					// this.state.input.pendidikan_terakhir_pasangan = data.results.data_pendidikan_terakhir.nama;
					// this.state.input.agama_pasangan = data.results.data_agama.nama;
					// this.state.input.status_perkawinan_pasangan = data.results.status_perkawinan;
					// this.state.input.nama_nasab_p2 = data.results.nama_ayah_kandung;
					// this.state.input.alamat_pasangan = alamat;
					// this.state.pendStat2 = id_cari;

					this.forceUpdate();
				} else {
					// this.state.input.nik_pasangan = ''
					// this.state.input.kk_pasangan = ''
					// this.state.input.nama_pasangan = ''
					// this.state.input.tempat_lahir_pasangan = ''
					// this.state.input.tanggal_lahir_pasangan = ''
					// this.state.input.pekerjaan_pasangan = ''
					// this.state.input.jenis_kelamin_pasangan = ''
					// this.state.input.pendidikan_terakhir_pasangan = ''
					// this.state.input.agama_pasangan = ''
					// this.state.input.status_perkawinan_pasangan = ''
					// this.state.input.nama_nasab_p2 = ''
					// this.state.input.alamat_pasangan = ''
					this.setState({
						showBio2: false,
						pendStat2: [],
						dt:{
							...this.state.dt,
							nik_pasangan: '',
							kk_pasangan: '',
							nama_pasangan: '',
							tempat_lahir_pasangan: '',
							tanggal_lahir_pasangan: '',
							pekerjaan_pasangan: '',
							jenis_kelamin_pasangan: '',
							pendidikan_terakhir_pasangan: '',
							agama_pasangan: '',
							status_perkawinan_pasangan: '',
							nama_nasab_p2: '',
							alamat_pasangan: '',
						}
					})
					this.forceUpdate();
					this.props.getAntrian('Surat Pengantar Pindah Nikah', 'cari nik');
				}
			});
		}
	};

	gantiTgl = (date, fld) => {
		switch (fld) {
			case 'tanggal_lahir_pasangan':
        		if (typeof date._isValid != 'undefined') {
					this.setState({
						dt:{
							...this.state.dt,
							tanggal_lahir_pasangan: new Date(date.format('YYYY-MM-DD')),
						}
					})
					this.forceUpdate();
				}
				break;
			default:
				// no default
				break;
		}
	};

	// EMBED KEyBOARD

	onOpenModal = (key) => {
		//console.log(key);
		//console.log(this.props.data.results);
		this.setState({
			[key]: true,
			regis: true
		});
	};

	onCloseModal = (key) => {
		this.setState({
			[key]: false,
			display: 'none',
			regis: 'false',
			input: {},
			showBio2: false,
			jkPasangan: null,
			agmStat: null,
			pkrStat: null,
			formPasangan: 'none',
			dt:{
				...this.state.dt,
				tanggal_lahir_pasangan: null,
			},
			dt_keluarga:{
				nik: '',
				kk: '',
				nama: '',
				agama: '',
				alamat: '',
				jenis_kelamin: '',
				pekerjaan: '',
				status_perkawinan: '',
				tanggal_lahir: '',
				tempat_lahir: '',
				pendidikan_terakhir: '',
			},
			dataKeluarga:'none',
			nikStatTerlapor:null,
		});
		this.forceUpdate();
		// this.setState({ [key]: false, showBio2: false });
	};

	// on focus
	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				//console.log('Active input', inputName);
			}
		);
	};

	// end on focus

	// ketika Klik Keyboard atau mengisi data
	onChangeAll = (inputObj) => {
		this.setState({
			input: inputObj
		});

		//console.log('Input changed', inputObj);
	};

	onKeyPress = (button) => {
		//console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	// End ketika Klik Keyboard atau mengisi data

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}
		// this.setState({
		// 	display: '',
		// 	// onFocus:true

		//   });
		this.forceUpdate();
	};

	showKeyboard1 = () => {
		this.setState({
			display: 'none',
			regis: 'false'
		});
		this.forceUpdate();
	};

	// Input keyboard 
	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				}
				else {
				}
			}
		);
	};

	onChangeInput1 = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				this.keyboard.setInput(inputVal);
			}
		);
	};

	// end input keyboard

	// END EMBED KEYBOARD

	render() {

		const footerStyle = {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		};

		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const { Basic } = this.state;

		return (
			<div>
				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					// styles={{ modal: { marginBottom: '200px', width: '80%' } }}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}
						onMouseDown={() => {
							//console.log('sdfs');
						}}
					>

						<div
							// className="card-scrollable"
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								//console.log(e);
								this.setState({
									isDown: true,
									startY: e.pageY - slider.offsetTop,
									scrollUp: slider.scrollTop,
								})
								this.forceUpdate();
								//console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								//console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title" align="center">
									Surat Pengantar Pindah Nikah
							</h5>
							</div>

							<div className="modal-body">
								{/* <FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik"
											disabled
											type="text"
											placeholder="NIK"
											value={this.props.data.results.id}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Lengkap Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pemohon"
											disabled
											type="text"
											placeholder=" Nama Lengkap"
											value={this.props.data.results.nama}
										/>
									</Col>
								</FormGroup> */}

								<FormGroup className="row">
									<Label
									className="col-sm-3 col-form-label"
									htmlFor="nik"
									>
									NIK Terlapor
									</Label>
									<Col sm="9">
									<div data-testid="selectNIKTerlapor">
										<Select
										inputId="nikTerlapor"
										classNamePrefix="select"
										onChange={e => {
											this.getPenduduk(e, "nikTerlapor");
										}}
										defaultValue={this.state.nikStatTerlapor}
										value={this.state.nikStatTerlapor}
										name="NIK"
										options={this.state.frmNikKeluarga}
										placeholder="Pilih NIK Terlapor"
										onInputChange={e => this.handleInputChangeNIK(e, "nikTerlapor")}
										isClearable
										/>
									</div>
									</Col>
								</FormGroup>

								<div style={{ display: this.state.dataKeluarga }}>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Nama Terlapor
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Nama Terlapor"
										value={this.state.dt_keluarga.nama}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tempat_lahir"
										type="text"
										placeholder="Tempat Lahir"
										value={this.state.dt_keluarga.tempat_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tanggal Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tanggal_lahir"
										type="text"
										placeholder="Tanggal Lahir"
										value={this.state.dt_keluarga.tanggal_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
								</div>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										NIK Pasangan{' '}
									</Label>

									{this.state.formPasangan === 'none' ? (
										<>
											<div className="col-sm-7">
												<input
													id="nik_pasangan"
													name="pendStat2"
													type="number"
													// value={this.state.pendStat2}
													// onChange={(e) => this.handleChange(e, 'cari_nik1')}
													style={{ marginBottom: '10px' }}
													className="form-control"
													placeholder="Masukkan NIK Terlapor"
													minLength="16"
													onFocus={() => this.setActiveInput('cari_nik1')}
													value={this.state.input['cari_nik1'] || ''}
													onChange={(e) => this.onChangeInput(e)}
												/>
											</div>
											<div className="col-sm-2" style={{ textAlign: 'center' }}>
												<button
													type="button"
													className="btn btn-warning"
													onClick={(e) => this.cariNIK(e, 'cari_nik1')}
												>
													Cari
											</button>
											</div>
										</>
									) : (
											// <Select
											// 	isDisabled={this.state.status.select}
											// 	classNamePrefix="select"
											// 	onChange={(e) => {
											// 		this.getPenduduk(e, 'nik2');
											// 	}}
											// 	// defaultValue={this.state.pendStat2}
											// 	// value={this.state.pendStat2}
											// 	name="penduduk"
											// 	onInputChange={(e) => this.handleInputChangeNIK(e, 'nik2')}
											// 	options={this.state.frmPend2}
											// 	placeholder="Pilih NIK "
											// 	isClearable
											// />
											// <div />
											<Col sm="9">
												<input
													className="form-control"
													id="nik_pasangan"
													type={this.state.formPasangan === 'none' ? 'hidden' : 'number'}
													required
													placeholder="NIK Pasangan"
													// defaultValue={this.state.dt.nik_pasangan}
													// value={this.state.dt.nik_pasangan}
													onFocus={() => this.setActiveInput('nik_pasangan')}
													value={this.state.input['nik_pasangan'] || ''}
													onChange={(e) => this.onChangeInput(e)}
													// onChange={(e) => this.changeInputText(e, 'nik_pasangan')}
													minLength="16"
												/>
											</Col>
										)}
									<Col sm="3"> &nbsp; </Col>
									<Col sm="6" align="left">
										<small id="emailHelp" className="form-text text-muted">
											Data NIK {this.state.formPasangan === 'none' ? 'tidak' : ''} tersedia?{' '}
											<Link
												to={{
													pathname: this.props.myroute,
													state: this.props.data
												}}
												onClick={this.dataPasangan}
											>
												{' '}
											Klik disini
										</Link>
										</small>
									</Col>
								</FormGroup>
								<div style={{ display: this.state.showBio2 === false ? 'none' : '' }}>
									<hr />

									<h5>Detail Data</h5>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Nama Pasangan
									</Label>
										<Col sm="9">
											{this.state.formPasangan === 'none' ? (
												<input
													className="form-control"
													id="nama_pasangan"
													type="text"
													required
													readOnly={this.state.formPasangan === '' ? false : true}
													placeholder=" Nama Pasangan"
													defaultValue={this.state.dt.nama_pasangan}
													value={this.state.dt.nama_pasangan}
													onChange={(e) => this.changeInputText(e, 'nama_pasangan')}
												/>)
												: (
													<input
														className="form-control"
														id="nama_pasangan"
														type="text"
														required
														readOnly={this.state.formPasangan === '' ? false : true}
														placeholder=" Nama Pasangan"
														// defaultValue={this.state.dt.nama_pasangan}
														// value={this.state.dt.nama_pasangan}
														// onChange={(e) => this.changeInputText(e, 'nama_pasangan')}
														onFocus={() => this.setActiveInput('nama_pasangan')}
														value={this.state.input['nama_pasangan'] || ''}
														onChange={(e) => this.onChangeInput(e)}
													/>
												)
											}
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Nama Nasab
									</Label>
										<Col sm="9">
											{this.state.formPasangan === 'none' ? (
												<input
													className="form-control"
													id="nama_nasab_p2"
													type="text"
													required
													readOnly={this.state.formPasangan === '' ? false : true}
													placeholder="Nama Nasab"
													defaultValue={this.state.dt.nama_nasab_p2}
													value={this.state.dt.nama_nasab_p2}
													onChange={(e) => this.changeInputText(e, 'nama_nasab_p2')}
												/>
											)
												:
												(
													<input
														className="form-control"
														id="nama_nasab_p2"
														type="text"
														required
														readOnly={this.state.formPasangan === '' ? false : true}
														placeholder="Nama Nasab"
														// defaultValue={this.state.dt.nama_nasab_p2}
														// value={this.state.dt.nama_nasab_p2}
														// onChange={(e) => this.changeInputText(e, 'nama_nasab_p2')}
														onFocus={() => this.setActiveInput('nama_nasab_p2')}
														value={this.state.input['nama_nasab_p2'] || ''}
														onChange={(e) => this.onChangeInput(e)}
													/>
												)
											}
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Jenis Kelamin Pasangan
									</Label>
										<Col sm="9">
											{this.state.formPasangan === 'none' ? (
												<input
													className="form-control"
													id="jenis_kelamin_pasangan"
													type="text"
													readOnly={this.state.formPasangan === '' ? false : true}
													placeholder="Jenis Kelamin"
													value={this.state.dt.jenis_kelamin_pasangan}
													// defaultValue={this.state.dt.jenis_kelamin_pasangan}
													onChange={(e) => this.changeInputText(e, 'jenis_kelamin_pasangan')}
													required
												/>
											)
												:
												(
													<Select
														classNamePrefix="select"
														onChange={(e) => {
															this.setState({ 
																jkPasangan : e,
																dt:{
																	...this.state.dt,
																	jenis_kelamin_pasangan: e.value,
																}
															})
															this.forceUpdate()
															//console.log(this.state.dt.jenis_kelamin_pasangan)
														}}
														value={this.state.jkPasangan}
														name="jk_pasangan"
														options={[
															{value: 'Laki-Laki', label: 'Laki-Laki'},
															{value: 'Perempuan', label: 'Perempuan'},
														]}
														placeholder="Pilih Jenis Kelamin Pasangan"
														isClearable
													/>
												)
											}
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tempat Lahir Pasangan
									</Label>
										<Col sm="9">
											{this.state.formPasangan === 'none' ? (
												<input
													className="form-control"
													id="tempat_lahir_pasangan"
													type="text"
													required
													readOnly={this.state.formPasangan === '' ? false : true}
													placeholder="Tempat Lahir Pasangan"
													defaultValue={this.state.dt.tempat_lahir_pasangan}
													value={this.state.dt.tempat_lahir_pasangan}
													onChange={(e) => this.changeInputText(e, 'tempat_lahir_pasangan')}
												/>
											)
												:
												(
													<input
														className="form-control"
														id="tempat_lahir_pasangan"
														type="text"
														required
														readOnly={this.state.formPasangan === '' ? false : true}
														placeholder="Tempat Lahir Pasangan"
														// defaultValue={this.state.dt.tempat_lahir_pasangan}
														// value={this.state.dt.tempat_lahir_pasangan}
														// onChange={(e) => this.changeInputText(e, 'tempat_lahir_pasangan')}
														onFocus={() => this.setActiveInput('tempat_lahir_pasangan')}
														value={this.state.input['tempat_lahir_pasangan'] || ''}
														onChange={(e) => this.onChangeInput(e)}
													/>
												)
											}
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tanggal Lahir Pasangan
									</Label>
										<Col sm="9">
											{this.state.formPasangan === 'none' ? (
												<input
													className="form-control"
													id="tanggal_lahir_pasangan"
													type="text"
													readOnly
													required
													placeholder="Tanggal Lahir"
													value={dateFormat(this.state.dt.tanggal_lahir_pasangan, 'dd/mm/yyyy') }
												/>
											) : (
													<Datetime
														locale="id-ID"
														dateFormat="DD/MM/yyyy"
														closeOnSelect={true}
														input={true}
														inputProps={{ required: true, placeholder: 'Tanggal Lahir Pasangan' }}
														closeOnTab={true}
														timeFormat={false}
														initialValue={this.state.dt.tanggal_lahir_pasangan}
														value={this.state.dt.tanggal_lahir_pasangan}
														onChange={(e) => {
															this.gantiTgl(e, 'tanggal_lahir_pasangan');
														}}
													/>
												)}
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Pekerjaan Pasangan
									</Label>
										<Col sm="9">
											{this.state.formPasangan === 'none'
												?
													<input
														className="form-control"
														id="pekerjaan_pasangan"
														type="text"
														placeholder="Pekerjaan"
														required
														value={this.state.dt.pekerjaan_pasangan}
														readOnly
													/>
												:
													<Select
														isDisabled={this.state.status.select}
														classNamePrefix="select"
														onChange={(e) => {
															this.changeSelectValue(e, 'pekerjaan_pasangan');
														}}
														defaultValue={this.state.pkrStat}
														value={this.state.pkrStat}
														name="Pekerjaan"
														options={this.state.frmPkr}
														placeholder="Pilih Pekerjaan"
														isClearable
													/>
											}
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Agama Pasangan
									</Label>
										<Col sm="9">
											{this.state.formPasangan === 'none'
												?
													<input
														className="form-control"
														id="agama_pasangan"
														type="text"
														placeholder="Agama"
														value={this.state.dt.agama_pasangan}
														required
														readOnly
													/>
												:
													<Select
														isDisabled={this.state.status.select}
														classNamePrefix="select"
														onChange={(e) => {
															this.changeSelectValue(e, 'agama_pasangan');
														}}
														defaultValue={this.state.agmStat}
														value={this.state.agmStat}
														name="Agama"
														options={this.state.frmAgm}
														placeholder="Pilih Agama"
														isClearable
													/>
													
											}
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Alamat Pasangan
									</Label>
										<Col sm="9">
											{this.state.formPasangan === 'none' ? (
												<input
													className="form-control"
													id="alamat_pasangan"
													type="text"
													readOnly={this.state.formPasangan === '' ? false : true}
													required
													placeholder=" Alamat Pasangan "
													defaultValue={this.state.dt.alamat_pasangan}
													value={this.state.dt.alamat_pasangan}
													onChange={(e) => this.changeInputText(e, 'alamat_pasangan')}
												/>
											)
												:
												(
													<input
														className="form-control"
														id="alamat_pasangan"
														type="text"
														readOnly={this.state.formPasangan === '' ? false : true}
														required
														placeholder=" Alamat Pasangan "
														// defaultValue={this.state.dt.alamat_pasangan}
														// value={this.state.dt.alamat_pasangan}
														// onChange={(e) => this.changeInputText(e, 'alamat_pasangan')}
														onFocus={() => this.setActiveInput('alamat_pasangan')}
														value={this.state.input['alamat_pasangan'] || ''}
														onChange={(e) => this.onChangeInput(e)}
													/>
												)
											}
										</Col>
									</FormGroup>
								</div>
								<hr />

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tempat Nikah
								</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tempat_nikah"
											type="text"
											required
											placeholder="Tempat Nikah"
											// defaultValue={this.state.dt.tempat_nikah}
											onFocus={() => this.setActiveInput('tempat_nikah')}
											value={this.state.input['tempat_nikah'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>
							</div>

							<div className="modal-footer" style={footerStyle}>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
              				</button>
								<button type="submit" className="btn btn-success">
									Buat Surat
							</button>
							</div>
						</div>
					</Form>

					
					{/* {this.state.regis ? (
						<div style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 4, left: 0 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
							<div />
						)} */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
					}}
				>


					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/pindah-nikah.png')}
								alt="logo"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p
								className="mb-0"
								style={{
									fontSize: '20px',
									fontWeight: '600',
									color: '#000000'
								}}
							>
								Pengantar Pindah Nikah
							</p>
						</div>
					</div>

				</div>
			</div>
		);
	}
}
