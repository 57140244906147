import React, { Component } from 'react';

// import Custom Componenets
// import Breadcrumb from '../components/common/breadcrumb.component';
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
// 	PaginationProvider,
// 	PaginationTotalStandalone,
// 	PaginationListStandalone
// } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';

// koneksi
import { Post, Get, ID_DESA, JENIS_DESA } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');

// const frmDef = {
// 	uuid: '',
// 	nama: ''
// };
class BasicTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data_detail:[],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			foto_desa: '',
			foto_info: '',
			input: 'none',
			view: 'inherit',
			judul: 'Tambah Profile',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: ''
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = () => {
		Get('profile-desa', null, (dtkat) => {
			console.log("data profil desa bos");
			console.log(dtkat);
			if (dtkat.results) {
				this.setState({ data: dtkat.results });
			} else {
				this.setState({
					input: 'none',
				})
				this.forceUpdate();
			}
		});
	};

	// ========================================================================

	tutupForm = (e) => {
		this.setState({ 
			input: 'none', 
			view: 'inherit', 
			status:{
				...this.state.status,
				form: false,
			},
		});
		this.forceUpdate()
	};

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		this.setState({
			status:{
				...this.state.status,
				btnForm: true,
			},
		})
		this.forceUpdate();
		let uuid = this.state.data.uuid;
		let addProfile = {
			id_keldes: ID_DESA,
			nama_desa: document.getElementById('nama_desa').value,
			// kode_desa: document.getElementById('kode_desa').value,
			kode_desa: ID_DESA+document.getElementById('nama_desa').value,
			alamat_desa: document.getElementById('alamat_desa').value,
			kode_pos: document.getElementById('kode_pos').value,
			notelp: document.getElementById('notelp').value,
			email: document.getElementById('email').value,
			fax: document.getElementById('fax').value,
			website: document.getElementById('website').value,
			batas_utara: document.getElementById('batas_utara').value,
			batas_timur: document.getElementById('batas_timur').value,
			batas_selatan: document.getElementById('batas_selatan').value,
			batas_barat: document.getElementById('batas_barat').value
		};
		
		let psn = '';
        let resstat = 204;
        let metode = "create";
        if (uuid === '' || uuid === 'undefined' || uuid === null)
        {
            psn = "Tambah"; 
            resstat = 201;
            addProfile.uuid = null;
			addProfile.foto_desa= this.state.foto_desa;
            
        } 
        else 
        {
            psn = "Ubah"; 
            resstat = 200;
            metode = "update";
			addProfile.uuid = uuid

			if(this.state.foto_desa){
				addProfile.foto_desa= this.state.foto_desa;
			}
			else{
				addProfile.foto_desa= this.state.data.foto_desa;
			}
        }

		//console.log("Data Simpan")
		//console.log(addProfile)
		//console.log(uuid)
		//console.log(psn+ '/'+resstat+'/'+metode)
		
		Post('profile-desa/' + metode, addProfile.uuid, addProfile, (res) => {
			if (res.status === resstat) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Profile',
					pesanAlert: 'Berhasil ' + psn + ' Data',
					status:{
						...this.state.status,
						form: false,
					},
				});
				this.tutupForm()
				this.forceUpdate()
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Profile',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			// this.setState({ input: 'none', view: 'inherit' });
			this.fetch();
		});
	};
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (status, uuid) => {
		this.setState({
			status:{
				...this.state.status,
				form: true,
			},
			judul: 'Ubah Profil Desa',
		})
		// this.setState({ view: 'none', input: 'inherit' });
		this.forceUpdate();
	};
	// ========================================================================

	componentDidMount() {
		this.fetch({ page: null, where: [] });
	}

	processGbr = (d) => {
		// //console.log(d[0].type);
		let type = d[0].type.split('/');
		if (
			type[1] === 'jpg' ||
			type[1] === 'jpeg' ||
			type[1] === 'png' ||
			type[1] === 'bmp' ||
			type[1] === 'tiff' ||
			type[1] === 'webp'
		) {
			Resizer.imageFileResizer(
				d[0],
				300,
				300,
				type[1],
				100,
				0,
				(uri) => {
					this.setState({ foto_desa: uri, foto_info: '' });
				},
				'base64'
			);
		} else {
			this.setState({
				foto_desa: '',
				foto_info: <div className="text-danger font-size-10">Type file tidak valid</div>
			});
		}
	};

	render() {
		// console.log("UUID BOSKU")
		// console.log(this.state.foto_desa)
		return (
			<div>
				{/* <Breadcrumb title="Data Profile" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div
										className="row"
										style={{ border: '3px outset black', borderRadius: '25px', padding: '20px' }}
									>
											<div className="col-md-12 text-right">
											{
												this.state.data.uuid
												?
													<Button  size="md" color="success" className="btn-icon" onClick={() => this.ubahData('update',this.state.data.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-edit"></i> Ubah Data Profil</Button> 
												:
													<Button  size="md" color="success" className="btn-icon" onClick={() => this.ubahData('create','')} disabled={this.state.status.btnAksi}><i className="fa fa-plus"></i> Tambah Data Profil</Button> 
											}
											</div>
											<div>
												<br/>&nbsp;<br/>
											</div>
										{
										this.state.data.uuid
										?
											<>
												<div className="col-md-12" align="center">
													<img alt="fotodesa" src={this.state.data.foto_desa} style={{ width: '350px' }} />
													<br/><br/><br/><br/>
												</div>

												<div className="col-6">

													<div className="col-md-12">
														<b>ID {JENIS_DESA}</b>
													</div>
													<div className="col-md-12">
														{this.state.data.id_keldes}
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
														&nbsp;
														</div>

													<div className="col-md-12">
														<b>Nama {JENIS_DESA}</b>
													</div>
													<div className="col-md-12">
														{this.state.data.nama_desa}
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
														&nbsp;
													</div>

													{/* <div className="col-md-12">
														<b>Kode {JENIS_DESA}</b>
													</div>
													<div className="col-md-12">
														{this.state.data.kode_desa}
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
														&nbsp;
													</div> */}

													<div className="col-md-12">
														<b>Batas Sebelah Utara {JENIS_DESA}</b>
													</div>
													<div className="col-md-12">
														{this.state.data.batas_utara}
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
														&nbsp;
													</div>

													<div className="col-md-12">
														<b>Batas Sebelah Timur {JENIS_DESA}</b>
													</div>
													<div className="col-md-12">
														{this.state.data.batas_timur}
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
														&nbsp;
													</div>

													<div className="col-md-12">
														<b>Batas Sebelah Selatan {JENIS_DESA}</b>
													</div>
													<div className="col-md-12">
														{this.state.data.batas_selatan}
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
														&nbsp;
													</div>

													<div className="col-md-12">
														<b>Batas Sebelah Barat {JENIS_DESA}</b>
													</div>
													<div className="col-md-12">
														{this.state.data.batas_barat}
													</div>
												</div>

												<div className="col-6">
													<div className="col-md-12">
														<b>Alamat</b>
													</div>
													<div className="col-md-12">
														{this.state.data.alamat_desa}
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
														&nbsp;
														</div>

													<div className="col-md-12">
														<b>Kode Pos</b>
													</div>
													<div className="col-md-12">
														{this.state.data.kode_pos}
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
														&nbsp;
														</div>

													<div className="col-md-12">
														<b>Telp</b>
													</div>
													<div className="col-md-12">
														{this.state.data.notelp}
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
														&nbsp;
														</div>

													<div className="col-md-12">
														<b>Email</b>
													</div>
													<div className="col-md-12">
														{this.state.data.email}
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
														&nbsp;
														</div>

													<div className="col-md-12">
														<b>Fax</b>
													</div>
													<div className="col-md-12">
														{this.state.data.fax}
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
														&nbsp;
														</div>

													<div className="col-md-12">
														<b>Website</b>
													</div>
													<div className="col-md-12">
														{this.state.data.website}
													</div>

												</div>
											</>
										:
										(
											<div className="col-md-12 text-center">
												<h4 align="center">Data Tidak Dapat Ditemukan</h4>
											</div>
										)
										}
									</div>
								</div>

								<Modal
									open={this.state.status.form}
									onClose={this.tutupForm}
									styles={{ modal: { width: '80%' } }}
									closeOnEsc={false}
									closeOnOverlayClick={false}
								>
									<Form className="theme-form" onSubmit={this.simpan}>
										<div className="modal-header">
											<h4 className="modal-title">{this.state.judul}</h4>
										</div>

										<div className="modal-body">
											<input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.data.uuid}/>

											<div className="row">
												<div className="col-lg-6 pr-3">
													<FormGroup className="row">
														<Label className="col-form-label" htmlFor="inputEmail3">
															Nama {JENIS_DESA}
														</Label>
														<input
															className="form-control"
															id="nama_desa"
															type="text"
															placeholder={'Nama ' + JENIS_DESA}
															defaultValue={this.state.data.nama_desa}
															required
														/>
													</FormGroup>
												</div>
												{/* <div className="col-lg-6 pl-3">
													<FormGroup className="row">
														<Label className="col-form-label" htmlFor="inputEmail3">
															Kode {JENIS_DESA}
														</Label>
														<input
															className="form-control"
															id="kode_desa"
															type="text"
															placeholder={'Kode ' + JENIS_DESA}
															defaultValue={this.state.data.kode_desa}
															required
														/>
													</FormGroup>
												</div> */}
												<div className="col-lg-12">
													<FormGroup className="row">
														<Label className="col-form-label" htmlFor="inputEmail3">
															Alamat
														</Label>
														<textarea
															className="form-control"
															id="alamat_desa"
															placeholder={'Alamat ' + JENIS_DESA}
															defaultValue={this.state.data.alamat_desa}
															required
														/>
													</FormGroup>
												</div>
												<div className="col-lg-4 pr-3">
													<FormGroup className="row">
														<Label className="col-form-label" htmlFor="inputEmail3">
															Kode Pos
														</Label>
														<input
															className="form-control"
															id="kode_pos"
															type="text"
															placeholder="Kode Pos"
															defaultValue={this.state.data.kode_pos}
															required
														/>
													</FormGroup>
												</div>
												<div className="col-lg-4 pl-3">
													<FormGroup className="row">
														<Label className="col-form-label" htmlFor="inputEmail3">
															Telp
														</Label>
														<input
															className="form-control"
															id="notelp"
															type="text"
															placeholder={'Telp ' + JENIS_DESA}
															defaultValue={this.state.data.notelp}
															required
														/>
													</FormGroup>
												</div>
												<div className="col-lg-4 pl-3">
													<FormGroup className="row">
														<Label className="col-form-label" htmlFor="inputEmail3">
															Foto {JENIS_DESA}
														</Label>
														<Dropzone
															onDrop={(acceptedFiles) => this.processGbr(acceptedFiles)}
															maxFiles={1}
														>
															{({ getRootProps, getInputProps }) => (
																<section>
																	<div
																		{...getRootProps()}
																		style={{
																			minHeight: '100px',
																			paddingTop: '40px',
																			padding: '5px 5px 5px 5px',
																			border: '1px solid #d2d2d2'
																		}}
																	>
																		<input {...getInputProps()} />
																		<p>
																			{this.state.foto_desa ? (
																				<img
																					alt="fotodesa2"
																					src={this.state.foto_desa}
																					style={{
																						minHeight: '50px',
																						minWidth: '50px'
																					}}
																				/>
																			) : (
																				"Drag 'n' drop some files here, or click to select files"
																			)}
																			{this.state.foto_info ? (
																				this.state.foto_info
																			) : (
																				''
																			)}
																		</p>
																	</div>
																</section>
															)}
														</Dropzone>
													</FormGroup>
												</div>
												<div className="col-lg-4 pr-3">
													<FormGroup className="row">
														<Label className="col-form-label" htmlFor="inputEmail3">
															Email
														</Label>
														<input
															className="form-control"
															id="email"
															type="text"
															placeholder="Email"
															defaultValue={this.state.data.email}
															required
														/>
													</FormGroup>
												</div>
												<div className="col-lg-4 pl-3 pr-3">
													<FormGroup className="row">
														<Label className="col-form-label" htmlFor="inputEmail3">
															Fax
														</Label>
														<input
															className="form-control"
															id="fax"
															type="text"
															placeholder="Fax"
															defaultValue={this.state.data.fax}
															required
														/>
													</FormGroup>
												</div>
												<div className="col-lg-4 pl-3">
													<FormGroup className="row">
														<Label className="col-form-label" htmlFor="inputEmail3">
															Website
														</Label>
														<input
															className="form-control"
															id="website"
															type="text"
															placeholder="Website"
															defaultValue={this.state.data.website}
															required
														/>
													</FormGroup>
												</div>
												<div className="col-lg-12">
													<FormGroup className="row">
														<Label className="col-form-label" htmlFor="inputEmail3">
															Batas Sebelah Utara
														</Label>
														<textarea
															className="form-control"
															id="batas_utara"
															placeholder={'Batas Sebelah Utara ' + JENIS_DESA}
															defaultValue={this.state.data.batas_utara}
															required
														/>
													</FormGroup>
												</div>
												<div className="col-lg-12">
													<FormGroup className="row">
														<Label className="col-form-label" htmlFor="inputEmail3">
															Batas Sebelah Timur
														</Label>
														<textarea
															className="form-control"
															id="batas_timur"
															placeholder={'Batas Sebelah Timur ' + JENIS_DESA}
															defaultValue={this.state.data.batas_timur}
															required
														/>
													</FormGroup>
												</div>
												<div className="col-lg-12">
													<FormGroup className="row">
														<Label className="col-form-label" htmlFor="inputEmail3">
															Batas Sebelah Selatan
														</Label>
														<textarea
															className="form-control"
															id="batas_selatan"
															placeholder={'Batas Sebelah Selatan ' + JENIS_DESA}
															defaultValue={this.state.data.batas_selatan}
															required
														/>
													</FormGroup>
												</div>
												<div className="col-lg-12">
													<FormGroup className="row">
														<Label className="col-form-label" htmlFor="inputEmail3">
															Batas Sebelah Barat
														</Label>
														<textarea
															className="form-control"
															id="batas_barat"
															placeholder={'Batas Sebelah Barat ' + JENIS_DESA}
															defaultValue={this.state.data.batas_barat}
															required
														/>
													</FormGroup>
												</div>
											</div>
										</div>

										<div className="modal-footer">
											<button type="button" className="btn btn-warning" onClick={this.tutupForm}>
												Batal
											</button>
											<button
												type="submit"
												className="btn btn-success"
												// disabled={this.state.status.btnFormAcc}
											>
												Simpan
											</button>
										</div>
									</Form>
								</Modal>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default BasicTable;