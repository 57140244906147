import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datepicker from "react-datepicker";
import { Link, Redirect } from "react-router-dom";
import Datetime from "react-datetime";

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete ,Nama_DESA, NAMA_KABUPATEN, KECAMATAN, ALAMAT_KANTOR_DESA, PROVINSI, JENIS_DUSUN} from "../function/Koneksi";
import { Tanggal, HariTanggal, HariTanggalJam } from "../function/Format";
import { logo } from "./asset/base64img";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  id_jenis: "",
  nomor: "",
  tanggal_surat: new Date(),
  nik: "",
  nama: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  jk: "",
  pekerjaan: "",
  agama: "",
  alamat: "",
  status_perkawinan: "",
  waktu_meninggal: new Date(),
  tempat_meninggal: "",
  sebab_meninggal: "",
  tempat_makam: "",
  nik_pelapor: "",
  nama_pelapor: "",
  tempat_lahir_pelapor: "",
  tanggal_lahir_pelapor: "",
  jk_pelapor: "",
  pekerjaan_pelapor: "",
  agama_pelapor: "",
  alamat_pelapor: "",
  hubungan_pelapor: "",
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",
  status_ttd2: "",
  jabatan_ttd2: "",
  jabatan_pengganti_ttd2: "",
  nama_ttd2: "",
  nip_ttd2: ""
};

const cetak = {
  nama_desa: "",
  nomor_surat: "",
  tanggal_surat: new Date(),
  nik: "",
  nama: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  jk: "",
  pekerjaan: "",
  agama: "",
  alamat: "",
  status_perkawinan: "",
  kematian: 0,
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: ""
};

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: true,
        btnAksi: [],
        dataPenduduk: "none",
        dataPenduduk2: "none"
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Surat Kematian",
      // ============ Select 2 ==============
      frmJam: [],
      jamStat: [],
      frmPkr: [],
      pkrStat: [],
      frmAgm: [],
      agmStat: [],
      frmNik: [],
      nikStat: null,
      frmNik2: [],
      nik2Stat: null,
      shdk: null,
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,

      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      tanggal_lahir: new Date(),
      waktu_meninggal: new Date(),
      // ===================================
      // ============ Cetak ==============
      cetak: cetak,
      // ===================================
      // =============== acc ===============
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      datDisposisi: [],
      riwayatDisposisi: []
    };
  }

  // ================== Terbilang ===================

  terbilang = a => {
    // pastikan kita hanya berususan dengan tipe data numeric
    let angka = parseFloat(a);
    let hasil_bagi = 0;
    let hasil_mod = 0;
    // array bilangan
    // sepuluh dan sebelas merupakan special karena awalan 'se'
    const bilangan = [
      "",
      "Satu",
      "Dua",
      "Tiga",
      "Empat",
      "Lima",
      "Enam",
      "Tujuh",
      "Delapan",
      "Sembilan",
      "Sepuluh",
      "Sebelas"
    ];

    // pencocokan dimulai dari satuan angka terkecil
    if (angka < 12) {
      // mapping angka ke index array bilangan
      return bilangan[angka];
    } else if (angka < 20) {
      // bilangan 'belasan'
      // misal 18 maka 18 - 10 = 8
      return bilangan[angka - 10] + " Belas";
    } else if (angka < 100) {
      // bilangan 'puluhan'
      // misal 27 maka 27 / 10 = 2.7 (integer => 2) 'Dua'
      // untuk mendapatkan sisa bagi gunakan modulus
      // 27 mod 10 = 7 'Tujuh'
      hasil_bagi = parseInt(angka / 10);
      hasil_mod = angka % 10;
      return bilangan[hasil_bagi] + " Puluh " + bilangan[hasil_mod];
    } else if (angka < 200) {
      // bilangan 'seratusan' (itulah indonesia knp tidak satu ratus saja? :))
      // misal 151 maka 151 = 100 = 51 (hasil berupa 'puluhan')
      // daripada menulis ulang rutin kode puluhan maka gunakan
      // saja fungsi rekursif dengan memanggil fungsi terbilang(51)
      return "Seratus " + this.terbilang(angka - 100);
    } else if (angka < 1000) {
      // bilangan 'ratusan'
      // misal 467 maka 467 / 100 = 4,67 (integer => 4) 'Empat'
      // sisanya 467 mod 100 = 67 (berupa puluhan jadi gunakan rekursif terbilang(67))
      hasil_bagi = parseInt(angka / 100);
      hasil_mod = angka % 100;
      return bilangan[hasil_bagi] + " Ratus " + this.terbilang(hasil_mod);
    } else if (angka < 2000) {
      // bilangan 'seribuan'
      // misal 1250 maka 1250 - 1000 = 250 (ratusan)
      // gunakan rekursif terbilang(250)
      return "Seribu " + this.terbilang(angka - 100);
    } else if (angka < 1000000) {
      // bilangan 'ribuan' (sampai ratusan ribu
      hasil_bagi = parseInt(angka / 1000); // karena hasilnya bisa ratusan jadi langsung digunakan rekursif
      hasil_mod = angka % 1000;
      return this.terbilang(hasil_bagi) + " Ribu " + this.terbilang(hasil_mod);
    } else if (angka < 1000000000) {
      // bilangan 'jutaan' (sampai ratusan juta)
      // 'satu puluh' => SALAH
      // 'satu ratus' => SALAH
      // 'satu juta' => BENAR
      // @#$%^ WT*

      // hasil bagi bisa satuan, belasan, ratusan jadi langsung kita gunakan rekursif
      hasil_bagi = parseInt(angka / 1000000);
      hasil_mod = angka % 1000000;
      return this.terbilang(hasil_bagi) + " Juta " + this.terbilang(hasil_mod);
    } else if (angka < 1000000000000) {
      // bilangan 'milyaran'
      hasil_bagi = parseInt(angka / 1000000000);
      // karena batas maksimum integer untuk 32bit sistem adalah 2147483647
      // maka kita gunakan fmod agar dapat menghandle angka yang lebih besar
      hasil_mod = angka % 1000000000;
      return (
        this.terbilang(hasil_bagi) + " Milyar " + this.terbilang(hasil_mod)
      );
    } else if (angka < 1000000000000000) {
      // bilangan 'triliun'
      hasil_bagi = angka / 1000000000000;
      hasil_mod = angka % 1000000000000;
      return (
        this.terbilang(hasil_bagi) + " Triliun " + this.terbilang(hasil_mod)
      );
    } else {
      return "Wow...";
    }
  };
  // ===============================================

  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "waktu_meninggal":
        if (typeof date._isValid !== 'undefined') {
          this.setState({ waktu_meninggal: date });
        }
        break;
      default:
        // no default
        break;
      // case 'tanggal_lahir': this.setState({tanggal_lahir:date});break;
    }
  };

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("surat-keterangan/kematian" + link, null, dtkat => {
      if(dtkat.results){
        this.setState({ data: dtkat.results.data, pagination: dtkat.results });
        this.pagination(dtkat.results);
 
        // For testing
        if (this.props.getListSuratKeteranganKematian) {
          this.props.getListSuratKeteranganKematian(dtkat.results.data)
        }
      }
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let addSuratKeteranganKematian = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/kematian",
        null,
        addSuratKeteranganKematian,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
        }
      );
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    //console.log("NIK STAT SIMPAN")
    //console.log(this.state.nikStat)
    //console.log("NIK STAT 2 SIMPAN")
    //console.log(this.state.nik2Stat)
    //console.log("SHDK")
    //console.log(this.state.shdk)
    if (this.state.nikStat && this.state.nik2Stat && this.state.shdk !== null) {
      //console.log("MASUK KONDISI IF")
      this.setState({
        status:{
          ...this.state.status,
          btnForm: true,
        },
      })
      this.forceUpdate();
      let tgl_lhr = this.state.dt.tanggal_lahir.split("/");
      let tanggal_lahir = tgl_lhr[2] + "-" + tgl_lhr[1] + "-" + tgl_lhr[0];
      let tgl_lhr_pl = this.state.dt.tanggal_lahir_pelapor.split("/");
      let tanggal_lahir_pelapor =
        tgl_lhr_pl[2] + "-" + tgl_lhr_pl[1] + "-" + tgl_lhr_pl[0];
      let addSuratKeteranganKematian = {
        uuid: document.getElementById("uuid").value,
        id_jenis: this.props.location.state.uuid,
        // nomor : document.getElementById("nomor").value,
        nik: this.state.nikStat.value,
        nama: this.state.dt.nama,
        tempat_lahir: this.state.dt.tempat_lahir,
        tanggal_lahir: tanggal_lahir,
        jenis_kelamin: this.state.dt.jk,
        status_perkawinan: this.state.dt.status_perkawinan,
        pekerjaan: this.state.dt.pekerjaan,
        agama: this.state.dt.agama,
        alamat: this.state.dt.alamat,
        waktu_meninggal:
          new Date(this.state.waktu_meninggal).toISOString().slice(0, 10) +
          " " +
          new Date(this.state.waktu_meninggal).toLocaleTimeString("id-ID"),
        tempat_meninggal: document.getElementById("tempat_meninggal").value,
        sebab_meninggal: document.getElementById("sebab_meninggal").value,
        tempat_makam: document.getElementById("tempat_makam").value,
        nik_pelapor: this.state.nik2Stat.value,
        nama_pelapor: this.state.dt.nama_pelapor,
        tempat_lahir_pelapor: this.state.dt.tempat_lahir_pelapor,
        tanggal_lahir_pelapor: tanggal_lahir_pelapor,
        jenis_kelamin_pelapor: this.state.dt.jk_pelapor,
        status_perkawinan_pelapor: this.state.dt.status_perkawinan_pelapor,
        pekerjaan_pelapor: this.state.dt.pekerjaan_pelapor,
        agama_pelapor: this.state.dt.agama_pelapor,
        alamat_pelapor: this.state.dt.alamat_pelapor,
        // hubungan_pelapor: document.getElementById("hubungan_pelapor").value
        hubungan_pelapor: this.state.shdk.value
      };

      //console.log("Data Simpan Keterangan Kematian")
      //console.log(addSuratKeteranganKematian)

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (addSuratKeteranganKematian.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        addSuratKeteranganKematian.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      Post(
        "surat-keterangan/kematian/" + metode,
        addSuratKeteranganKematian.uuid,
        addSuratKeteranganKematian,
        res => {
          // For testing
          if (this.props.resSubmit) {
            this.props.resSubmit(res.status)
          }

          this.tutupForm();
          this.setState({
            status:{
              ...this.state.status,
              btnForm: false,
              form: false,
            },
          })
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Surat Keterangan Kematian",
              pesanAlert: "Berhasil " + psn + " Data",
              successAlert: true
            });
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Surat Keterangan Kematian",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        }
      );
    } else {
      //console.log("MASUK KONDISI ELSE")
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Belum Lengkap",
        pesanAlert: "Mohon pilih data NIK penduduk yang meninggal, NIK pelapor, dan hubungan pelapor"
      });
      if (!this.state.nikStat) {
        document.getElementById("nikStat").focus();
      } else {
        document.getElementById("nik2Stat").focus();
      }
    }
  };
  // ========================================================================
  // list disposisi

  listDisposisi = data => {
    //console.log(data);
    Get("alur-disposisi/" + data.id_jenis, null, dtkat => {
      //console.log(dtkat);
      if (dtkat) {
        //console.log(dtkat.results);

        this.setState({
          idSurat: data.uuid,
          datDisposisi: dtkat.results,
          formDisposisi: true,
          riwayatDisposisi: data.data_status_disposisi
        });
      }
    });
  };
  //disposisi
  disposisi = (label, id) => {
    //console.log(label);
    Get("disposisi/tidak-mampu/" + label + "/" + id, null, dtkat => {
      if (dtkat.code === 201) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Disposisi",
          pesanAlert: "Berhasil  Disposisi Data"
        });
        this.componentDidMount();
        this.tutupForm();
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Disposisi",
          pesanAlert: "Disposisi telah dilakukan"
        });
        this.tutupForm();
      }
    });
    //console.log(id);
  };
  // ============================== Ubah Data ===============================

  ubahData = id => {
    // this.state.status.btnAksi = true;
    this.setState({
      judul: "Ubah Surat Kematian",
    })
    this.forceUpdate();
    // this.componentDidMount();
    Get("surat-keterangan/kematian/find", id, data => {
      this.setState({
        dt:{
          ...this.state.dt,
          uuid: data.results.uuid,
          id_jenis: data.results.id_jenis,
          nomor: data.results.nomor,
          nik: data.results.nik,
          tanggal_surat: data.results.tanggal_surat,
          nama: data.results.nama,
          tanggal_lahir: new Date(
            data.results.tanggal_lahir
          ).toLocaleDateString("id-ID"),
          tempat_lahir: data.results.tempat_lahir,
          jk: data.results.jenis_kelamin,
          status_perkawinan: data.results.status_perkawinan,
          agama: data.results.agama,
          alamat: data.results.alamat,
          pekerjaan: data.results.pekerjaan,
          waktu_meninggal: data.results.waktu_meninggal,
          tempat_meninggal: data.results.tempat_meninggal,
          sebab_meninggal: data.results.sebab_meninggal,
          tempat_makam: data.results.tempat_makam,
          nik_pelapor: data.results.nik_pelapor,
          nama_pelapor: data.results.nama_pelapor,
          tanggal_lahir_pelapor: new Date(
            data.results.tanggal_lahir_pelapor
          ).toLocaleDateString("id-ID"),
          tempat_lahir_pelapor: data.results.tempat_lahir_pelapor,
          jk_pelapor: data.results.jenis_kelamin_pelapor,
          status_perkawinan_pelapor: data.results.status_perkawinan_pelapor,
          agama_pelapor: data.results.agama_pelapor,
          alamat_pelapor: data.results.alamat_pelapor,
          pekerjaan_pelapor: data.results.pekerjaan_pelapor,
          hubungan_pelapor: data.results.hubungan_pelapor,
          status_ttd: data.results.status_ttd,
          jabatan_ttd: data.results.jabatan_ttd,
          jabatan_pengganti_ttd: data.results.jabatan_pengganti_ttd,
          nama_ttd: data.results.nama_ttd,
          nip_ttd: data.results.nip_ttd,
          status_ttd2: data.results.status_ttd2,
          jabatan_ttd2: data.results.jabatan_ttd2,
          jabatan_pengganti_ttd2: data.results.jabatan_pengganti_ttd2,
          nama_ttd2: data.results.nama_ttd2,
          nip_ttd2: data.results.nip_ttd2,
        },
        status:{
          ...this.state.status,
          dataPenduduk: "block",
          dataPenduduk2: "block",
        },
        tanggal_surat: new Date(data.results.tanggal_surat),
        waktu_meninggal: new Date(data.results.waktu_meninggal),
        shdk: {
          value: data.results.hubungan_pelapor,
          label: data.results.hubungan_pelapor,
        },
        nikStat: { value: data.results.nik, label: data.results.nik + ' (' + data.results.nama + ')' },
        nik2Stat: { value: data.results.nik_pelapor, label: data.results.nik_pelapor + ' (' + data.results.nama_pelapor + ')' },
      })




      // this.forceUpdate();
      this.bukaForm();
    });
  };
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/kematian/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Keterangan Kematian",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Keterangan Kematian",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.setState({
      status:{
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      dt:{
        ...this.state.dt,
        nama: "",
        tanggal_lahir: "",
        tempat_lahir: "",
        agama: "",
        pekerjaan: "",
        alamat: "",
        jk: "",
        status_perkawinan: "",
        nama_pelapor: "",
        tanggal_lahir_pelapor: "",
        tempat_lahir_pelapor: "",
        agama_pelapor: "",
        pekerjaan_pelapor: "",
        alamat_pelapor: "",
        jk_pelapor: "",
        status_perkawinan_pelapor: "",
      },
      status:{
        ...this.state.status,
        dataPenduduk: "none",
        dataPenduduk2: "none",
        form: false,
      },
      nikStat: null,
      nik2Stat: [],
      shdk: null,
      waktu_meninggal: new Date(),
      accStat: [],
      tableAcc: "none",
      formDisposisi: false,
      formAcc: false,
    })
    this.forceUpdate();
  };

  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get(
      "surat-keterangan/kematian/persetujuan/" + jawaban + "/" + id,
      null,
      res => {
        if (res.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  tombolAksi = (cell, row) => {
    // let btnApproval = false;
    // let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.forEach(dt => {
      status = dt.status;
    });
    if (status === "Diajukan") {
      // btnCetak = true;
      btnAksi = true;
      // btnApproval = true;
    } else {
      btnAksi = true;
      // btnApproval = true;
      if (status === "Ditolak") {
        // btnCetak = true;
      } else if (status === "Disetujui") {
        btnAksi = false;
        // btnCetak = true;
        // btnApproval = false;
      } else if ((status === "Menunggu Penerbitan") | (status === "Selesai")) {
        // btnAksi = false;
        // btnCetak = false;
        // btnApproval = false;
      } else {
        // btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button size="xs" color="info" className="btn-icon" onClick={() => this.konfirmApprove(row.uuid)} disabled={btnApproval}><i className="fa fa-check"></i></Button> &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        {/* <Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={btnAksi}><i className="fa fa-trash"></i></Button> &nbsp;&nbsp; */}
        {/* <Button size="xs" color="info" className="btn-icon" onClick={(e) => this.cetakSurat(e, row)} disabled={btnCetak}><i className="fa fa-print"></i></Button> */}
        {/* &nbsp;&nbsp; */}
        {/* //list */}
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.listDisposisi(row)}
        >
          <i className="fa fa-list"></i>
        </Button> */}
      </>
    );
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.fetch({ page: null, where: [] });
      let frmJam = [];
      let jamnya = "";
      for (let i = 1; i <= 24; i++) {
        jamnya = i;
        if (i < 10) {
          jamnya = "0" + i;
        }
        jamnya += ":00";
        frmJam.push({ value: jamnya, label: jamnya });
      }
      this.setState({ frmJam });
      Get("pekerjaan/list", null, data => {
        let frmPkr = [];
        data.results.forEach(dt => {
          frmPkr.push({ value: dt.uuid, label: dt.nama });
        });
        this.setState({ frmPkr });
      });
      Get("agama/list", null, data => {
        let frmAgm = [];
        data.results.forEach(dt => {
          frmAgm.push({ value: dt.uuid, label: dt.nama });
        });
        this.setState({ frmAgm });
      });
      Get("penduduk", null, data => {
        let frmNik = [];
        let frmNik2 = [];
        data.results.data.forEach(dt => {
          frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          frmNik2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
        });
        this.setState({ frmNik, frmNik2 });

        // For testing
        if (this.props.getListNIK) {
          this.props.getListNIK(frmNik)
        }
      });
      Get("tanda-tangan/list", null, data => {
        data.results.forEach((dt, i) => {
          if (dt.aktif === 1) {
            let temp = this.state.accButton.forEach(x => 1);
            temp[i] = 0;
            this.setState({
              accButton: temp,
              accuuid: dt.uuid,
            })
          }
        });
        this.forceUpdate();
        this.setState({ frmTTD: data.results });
      });
    } else {
      this.setState({ redirect: true });
    }
  }

  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        let nikStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik = [];
          datanik.data.results.data.forEach(dt => {
            frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmNik, nikStat });
        });
      } else {
        let nik2Stat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik2 = [];
          datanik.data.results.data.forEach(dt => {
            frmNik2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmNik2, nik2Stat });
        });
      }
    }
  };

  getPenduduk = (e, sel, jns) => {
    this.forceUpdate();
    if (e) {
      this.changeSelectValue(e, sel);
      Get("penduduk/find", e.value, data => {
        // const data_rt = data.results.data_kk.data_rt;
        // const data_rw = data_rt.data_rw;
        // const data_dusun = data_rw.data_dusun;
        // const data_desa = data_dusun.data_keldes;
        // const data_kecamatan = data_desa.data_kecamatan;
        // const data_kabkota = data_kecamatan.data_kabkota;
        let alamat = '';
        if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
          alamat =
          " RT. " +
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.data_kabkota.nama; 
        } else {
          alamat =
          " RT. " + 
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", " + JENIS_DUSUN + " " +
          data.results.data_kk.data_rt.data_rw.data_dusun.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
            .data_kecamatan.data_kabkota.nama; 
        }

        if (jns === "pelapor") {
          this.setState({
            dt:{
              ...this.state.dt,
              nama_pelapor: data.results.nama,
              tanggal_lahir_pelapor: new Date(
                data.results.tanggal_lahir
              ).toLocaleDateString("id-ID"),
              tempat_lahir_pelapor: data.results.tempat_lahir,
              jk_pelapor: data.results.jk,
              status_perkawinan_pelapor: data.results.status_perkawinan,
              agama_pelapor: data.results.data_agama.nama,
              pekerjaan_pelapor: data.results.data_pekerjaan.nama,
              alamat_pelapor: alamat,
            },
            status:{
              ...this.state.status,
              dataPenduduk2: "block",
            },
            tanggal_lahir_pelapor: data.results.tanggal_lahir,
          })

          // For testing
          if (this.props.selectOnChange2) {
            this.props.selectOnChange2(this.state.dt)
          }
        } else {
          Get("catatan-penduduk/nik", data.results.id, dpddk => {
            let pctt = 0;
            let catatan = "";
            if (dpddk.results.data) {
              dpddk.results.data.forEach(dtp => {
                if (dtp.status === 0 && dtp.penalti === 1) {
                  pctt = 1;
                  catatan = dtp.catatan;
                }
              });
            }
            if (pctt === 0) {
              this.setState({
                dt:{
                  ...this.state.dt,
                  nama: data.results.nama,
                  tanggal_lahir: new Date(
                    data.results.tanggal_lahir
                  ).toLocaleDateString("id-ID"),
                  tempat_lahir: data.results.tempat_lahir,
                  jk: data.results.jk,
                  status_perkawinan: data.results.status_perkawinan,
                  agama: data.results.data_agama.nama,
                  pekerjaan: data.results.data_pekerjaan.nama,
                  alamat: alamat,
                },
                status:{
                  ...this.state.status,
                  dataPenduduk: "block",
                },
                tanggal_lahir: data.results.tanggal_lahir,
              })
              this.forceUpdate();
            } else {
              // this.state.frmNik = [];
              this.setState({
                nikStat: null,
                dt:{
                  ...this.state.dt,
                  nama: "",
                  tanggal_lahir: "",
                  tempat_lahir: "",
                  agama: "",
                  pekerjaan: "",
                  alamat: "",
                  jk: "",
                  status_perkawinan: "",
                },
                status:{
                  ...this.state.status,
                  dataPenduduk: "none",
                  btnForm: true,
                },
                show: true,
                basicType: "danger",
                basicTitle: "Peringatan Catatan Warga",
                pesanAlert:
                "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                catatan
              });
              this.forceUpdate();
            }

            // For testing
            if (this.props.selectOnChange) {
              this.props.selectOnChange(this.state.dt)
            }
          });
        }
        if (this.state.nikStat !== null && this.state.nik2Stat !== null) {
          this.setState({
            status:{
              ...this.state.status,
              btnForm: false,
            },
          })
        }
        this.forceUpdate();
      });
    } else {
      if (jns === "pelapor") {
        this.setState({
          dt:{
            ...this.state.dt,
            nama_pelapor: "",
            tanggal_lahir_pelapor: "",
            tempat_lahir_pelapor: "",
            agama_pelapor: "",
            pekerjaan_pelapor: "",
            alamat_pelapor: "",
            jk_pelapor: "",
            status_perkawinan_pelapor: "",
          },
          status:{
            ...this.state.status,
            dataPenduduk2: "none",
          },
          nik2Stat: [],
        })
      } else {
        this.setState({
          dt:{
            ...this.state.dt,
            nama: "",
            tanggal_lahir: "",
            tempat_lahir: "",
            agama: "",
            pekerjaan: "",
            alamat: "",
            jk: "",
            status_perkawinan: "",
          },
          status:{
            ...this.state.status,
            dataPenduduk: "none",
          },
          nikStat: null,
        })
      }
      this.setState({
        status:{
          ...this.state.status,
          btnForm: true,
        }
      })

      this.forceUpdate();
    }
  };
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jam":
          this.setState({ 
            jamStat: e, 
            dt:{
              ...this.state.dt,
              jam: e.value,
            }
          });
          break;
        case "pekerjaan":
          this.setState({ 
            pkrStat: e, 
            dt:{
              ...this.state.dt,
              pekerjaan: e.label,
            }
          });
          break;
        case "agama":
          this.setState({ 
            agmStat: e, 
            dt:{
              ...this.state.dt,
              agama: e.label,
            }
          });
          break;
        case "nik":
          this.setState({ 
            nikStat: e,
            dt:{
              ...this.state.dt,
              nik: e.value,
            }
          });
          break;
        case "nik2":
          this.setState({ 
            nik2Stat: e, 
            dt:{
              ...this.state.dt,
              nik_pelapor: e.value,
            }
          });
          break;
        case "shdk":
          this.setState({ shdk: e });

          // For testing
          if (this.props.selectOnChangeShdk) {
            this.props.selectOnChangeShdk(e.value)
          }
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "jam":
          this.setState({ 
            jamStat: e, 
            dt:{
              ...this.state.dt,
              jam: 0,
            }
          });
          break;
        case "pekerjaan":
          this.setState({ 
            pkrStat: e, 
            dt:{
              ...this.state.dt,
              pekerjaan: "",
            }
          });
          break;
        case "agama":
          this.setState({ 
            agmStat: e, 
            dt:{
              ...this.state.dt,
              agama: "",
            }
          });
          break;
        case "nik":
          this.setState({ 
            nikStat: e, 
            dt:{
              ...this.state.dt,
              nik: 0,
            }
          });
          break;
        case "nik2":
          this.setState({ 
            nik2Stat: e, 
            dt:{
              ...this.state.dt,
              nik_pelapor: 0,
            },
          });
          break;
        case "shdk":
          this.setState({ shdk: null });
          break;
        default:
          // no default
          break;
      }
    }

    this.forceUpdate();
  };
  cetakSurat = (e, data) => {
    e.preventDefault();
    this.setState({
      cetak: {
        nama_desa: Nama_DESA,
        tanggal_surat: data.tanggal_surat,
        nomor_surat: data.nomor,
        nik: data.nik,
        nama: data.nama.toLowerCase(),
        tanggal_lahir: HariTanggal(data.tanggal_lahir),
        tempat_lahir: data.tempat_lahir.toLowerCase(),
        jk: data.jenis_kelamin,
        alamat: data.alamat.toLowerCase(),
        agama: data.agama.toLowerCase(),
        pekerjaan: data.pekerjaan.toLowerCase(),
        status_perkawinan: data.status_perkawinan,
        waktu_meninggal: data.waktu_meninggal,
        tempat_meninggal: data.tempat_meninggal.toLowerCase(),
        sebab_meninggal: data.sebab_meninggal.toLowerCase(),
        tempat_makam: data.tempat_makam.toLowerCase(),
        nik_pelapor: data.nik_pelapor,
        nama_pelapor: data.nama_pelapor.toLowerCase(),
        tanggal_lahir_pelapor: HariTanggal(data.tanggal_lahir_pelapor),
        tempat_lahir_pelapor: data.tempat_lahir_pelapor.toLowerCase(),
        jk_pelapor: data.jenis_kelamin_pelapor,
        alamat_pelapor: data.alamat_pelapor.toLowerCase(),
        agama_pelapor: data.agama_pelapor.toLowerCase(),
        pekerjaan_pelapor: data.pekerjaan_pelapor.toLowerCase(),
        status_perkawinan_pelapor: data.status_perkawinan_pelapor,
        hubungan_pelapor: data.hubungan_pelapor.toLowerCase(),
        status_ttd: data.status_ttd,
        jabatan_ttd: data.jabatan_ttd,
        jabatan_pengganti_ttd: data.jabatan_pengganti_ttd,
        nama_ttd: data.nama_ttd,
        nip_ttd: data.nip_ttd ? "NIP. " + data.nip_ttd : "",
        status_ttd2: data.status_ttd2,
        jabatan_ttd2: data.jabatan_ttd2,
        jabatan_pengganti_ttd2: data.jabatan_pengganti_ttd2,
        nama_ttd2: data.nama_ttd2,
        nip_ttd2: data.nip_ttd2 ? "NIP. " + data.nip_ttd2 : ""
      }
    });
    setTimeout(function() {
      const content = document.getElementById("cetak-surat").innerHTML;
      const mywindow = window.open("", "Print", "height=600,width=1000");

      mywindow.document.write("<html><head><title>Print</title>");
      mywindow.document.write("</head><body >");
      mywindow.document.write(content);
      mywindow.document.write("</body></html>");

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      setTimeout(function() {
        mywindow.close();
      }, 1000);
    }, 100);
  };

  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  //change select
  changeAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      if (e.value === "Disetujui") {
        this.setState({
          tableAcc: "",
          btnFormAcc: true,
        })
        if (this.state.accButton.length > 0) {
          this.setState({
            btnFormAcc: false,
          })
        }
      } else {
        this.setState({
          tableAcc: "none",
          btnFormAcc: false,
        })
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      this.setState({
        tableAcc: "none",
        btnFormAcc: true,
      })
    }
    this.setState({
      status:{
        ...this.state.status,
        select: false,
      }
    })
    this.forceUpdate();
  };

  simpanAcc = e => {
    e.preventDefault();
    this.setState({
      btnFormAcc: true,
    })

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),
      uuid: this.state.accuuid
    };
    Post(
      "surat-keterangan/kematian/persetujuan/Selesai/" + this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            btnFormAcc: false,
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
    this.tutupForm();
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.map(x => 1);
    temp[id] = 0;
    this.setState({
      accButton: temp,
      accuuid: uuid,
    })
    if (this.state.accStat) {
      this.setState({
        btnFormAcc: false,
      })
    }
    this.forceUpdate();
  };

  render() {
    
    //console.log("Data Render")
    //console.log("Data Kematian")
    //console.log("====================")
    // //console.log("DATA RENDER Surat Kematian")
    // //console.log(this.state.nikStat)
    // //console.log(this.state.nik2Stat)
    // //console.log(this.state.shdk)
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;

    const frmDef = {
      uuid: "",
      id_jenis: "",
      nomor: "",
      tanggal_surat: new Date(),
      nik: "",
      nama: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      jk: "",
      pekerjaan: "",
      agama: "",
      alamat: "",
      status_perkawinan: "",
      waktu_meninggal: new Date(),
      tempat_meninggal: "",
      sebab_meninggal: "",
      tempat_makam: "",
      nik_pelapor: "",
      nama_pelapor: "",
      tempat_lahir_pelapor: "",
      tanggal_lahir_pelapor: "",
      jk_pelapor: "",
      pekerjaan_pelapor: "",
      agama_pelapor: "",
      alamat_pelapor: "",
      hubungan_pelapor: "",
      status_ttd: "",
      jabatan_ttd: "",
      jabatan_pengganti_ttd: "",
      nama_ttd: "",
      nip_ttd: "",
      status_ttd2: "",
      jabatan_ttd2: "",
      jabatan_pengganti_ttd2: "",
      nama_ttd2: "",
      nip_ttd2: ""
    };

    // const { SearchBar } = Search;
    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.pagination.current_page;
              let total_records_per_page = this.state.pagination.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
        dataField: "tanggal_surat",
        headerAlign: 'center',
				align: 'center',
        text: "Tanggal",
        formatter: (cell, obj) => {
          return obj.tanggal_surat === null || obj.tanggal_surat === "0000-00-00"
            ? "-"
            : new Date(obj.tanggal_surat).toLocaleDateString("id-ID");
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        headerAlign: 'center',
				align: 'center',
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor === "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "nik",
        headerAlign: 'center',
				align: 'center',
        text: "NIK",
        sort: true
      },
      {
        dataField: "nama",
        headerAlign: 'center',
				align: 'center',
        text: "Nama",
        sort: true
      },
      {
        dataField: "waktu_meninggal",
        headerAlign: 'center',
				align: 'center',
        text: "Waktu Meninggal",
        align: "right",
        isDummyField: true,
        formatter: (cell, obj) => {
          return HariTanggalJam(obj.waktu_meninggal);
        },
        sort: true
      },
      {
        dataField: "status",
        headerAlign: 'center',
				align: 'center',
        text: "Status",
        isDummyField: true,
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.forEach(dt => {
            status = dt.status;
          });
          return status;
        },
        sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-primary-custom";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href="#" onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };
    let no = 0;
    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] === 0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] === 0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <div data-testid="page-surat keterangan kematian">
        {this.renderRedirect()}
        {/* <Breadcrumb title="Data Surat Keterangan Kematian" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  this.bukaForm();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                // color="info"
                className="btn btn-secondary-custom"
                // onClick={() => {
                //   this.closeAlert();
                // }}
              >
                <Link
                  data-testid="btn-eksekusi_surat"
                  onClick={() => {
                    // For testing
                    if (this.props.clickEksekusiSurat) {
                      this.props.clickEksekusiSurat()
                    }
                  }}
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Keterangan Kematian</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        data-testid="tblCreateSuratKeteranganKematian"
                        size="sm"
                        color="success"
                        // className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Surat Keterangan Kematian",
                            dt: frmDef,
                            nikStat: null,
                            nik2Stat: null
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
           <Form 
            data-testid="form-create" 
            className="theme-form" 
            onSubmit={this.simpan}
          >
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <p>
                Data identitas akan terisi secara otomatis saat NIK dipilih.
                Jika ada ketidak sesuaian data, silakan ubah di data master
                penduduk.
              </p>
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              {/* <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nomor</Label>
                                <Col sm='9'>
                                    <input className="form-control" id="nomor" type="text" placeholder="Nomor Surat" defaultValue={this.state.dt.nomor} required/>
                                </Col>
                            </FormGroup> */}
              <FormGroup className="row">
                <Label
                  id="nikStat"
                  className="col-sm-3 col-form-label"
                  htmlFor="nik"
                >
                  NIK
                </Label>
                <Col sm="9">
                  <div data-testid="selectNIK">
                    <Select
                      inputId="nik"
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e, "nik", "pemohon");
                      }}
                      defaultValue={this.state.nikStat}
                      value={this.state.nikStat}
                      name="NIK"
                      options={this.state.frmNik}
                      placeholder="Pilih NIK"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                      isClearable
                    />
                  </div>
                </Col>
              </FormGroup>
              <div style={{ display: this.state.status.dataPenduduk }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pemohon
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama"
                      type="text"
                      placeholder="Nama Pemohon"
                      value={this.state.dt.nama}
                      readOnly

                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat Lahir"
                      value={this.state.dt.tempat_lahir}
                      readOnly

                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir"
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir}
                      readOnly

                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ display: "none" }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="pekerjaan"
                      type="text"
                      placeholder="Pekerjaan"
                      value={this.state.dt.pekerjaan}
                      readOnly
                    />
                    {/* <Select
                                            classNamePrefix="select"
                                            onChange={((e)=>{ this.changeSelectValue(e,'pekerjaan')})}
                                            value={this.state.pkrStat} 
                                            value={this.state.pkrStat} 
                                            name="pekerjaan"
                                            options={this.state.frmPkr}
                                            placeholder="Pilih Pekerjaan"
                                            isClearable
                                        /> */}
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="agama"
                      type="text"
                      placeholder="Agama"
                      value={this.state.dt.agama}
                      readOnly
                    />
                    {/* <Select
                                            classNamePrefix="select"
                                            onChange={((e)=>{ this.changeSelectValue(e,'agama')})}
                                            value={this.state.agmStat} 
                                            value={this.state.agmStat} 
                                            name="agama"
                                            options={this.state.frmAgm}
                                            placeholder="Pilih Agama"
                                            isClearable
                                            /> */}
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="alamat"
                      type="text"
                      placeholder="Alamat"
                      value={this.state.dt.alamat}
                      readOnly
                    />
                    {/* <textarea className="form-control" id="alamat" placeholder="Alamat" value={this.state.dt.alamat}></textarea> */}
                  </Col>
                </FormGroup>
                {/* <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status Perkawinan</Label>
                                <Col sm='9'>
                                    <input className="form-control" id="status_perkawinan" type="text" placeholder="Status Perkawinan" value={this.state.dt.status_perkawinan} readOnly/>
                                </Col>
                            </FormGroup> */}
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="waktu_meninggal"
                >
                  Waktu Meninggal
                </Label>
                <Col sm="9">
                  {/* <Datepicker
                    className="form-control"
                    selected={this.state.waktu_meninggal}
                    onChange={e => this.gantiTgl(e, "waktu_meninggal")}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="waktu"
                    dateFormat="dd/MM/yyyy HH:mm"
                  /> */}
                  <Datetime
                    inputProps={{ "data-testid": "waktu_meninggal", placeholder: 'Waktu Meninggal', required: true }}
                    // data-testid="tanggal_mulai"
                    inputId="waktu_meninggal"
                    name="waktu_meninggal"
                    locale="id-ID"
                    timeFormat="HH:mm"
                    dateFormat="DD/MM/yyyy"
                    input={true}
                    closeOnTab={true}
                    initialValue={this.state.waktu_meninggal}
                    onChange={e => {
                      this.gantiTgl(e, "waktu_meninggal");
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Meninggal
                </Label>
                <Col sm="9">
                  <input
                    data-testid="tempat meninggal"
                    name="tempat_meninggal"
                    className="form-control"
                    id="tempat_meninggal"
                    type="text"
                    placeholder="Tempat Meninggal"
                    defaultValue={this.state.dt.tempat_meninggal}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Sebab Meninggal
                </Label>
                <Col sm="9">
                  <input
                    data-testid="sebab meninggal"
                    name="sebab_meninggal"
                    className="form-control"
                    id="sebab_meninggal"
                    type="text"
                    placeholder="Sebab Meninggal"
                    defaultValue={this.state.dt.sebab_meninggal}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Dimakamkan di
                </Label>
                <Col sm="9">
                  <input
                    data-testid="tempat makam"
                    name="tempat_makam"
                    className="form-control"
                    id="tempat_makam"
                    type="text"
                    placeholder="Tempat Makam"
                    defaultValue={this.state.dt.tempat_makam}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  id="nik2Stat"
                  className="col-sm-3 col-form-label"
                  htmlFor="nik2"
                >
                  NIK Pelapor
                </Label>
                <Col sm="9">
                  <div data-testid="selectNIK2">
                    <Select
                      inputId="nik2"
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e, "nik2", "pelapor");
                      }}
                      defaultValue={this.state.nik2Stat}
                      value={this.state.nik2Stat}
                      name="NIK Pelapor"
                      options={this.state.frmNik2}
                      placeholder="Pilih NIK Pelapor"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik2")}
                      isClearable
                    />
                  </div>
                </Col>
              </FormGroup>
              <div style={{ display: this.state.status.dataPenduduk2 }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pelapor
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama"
                      type="text"
                      placeholder="Nama Pemohon"
                      value={this.state.dt.nama_pelapor}
                      readOnly

                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat Lahir"
                      value={this.state.dt.tempat_lahir_pelapor}
                      readOnly

                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir"
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir_pelapor}
                      readOnly

                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ display: "none" }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="pekerjaan"
                      type="text"
                      placeholder="Pekerjaan"
                      value={this.state.dt.pekerjaan_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="agama"
                      type="text"
                      placeholder="Agama"
                      value={this.state.dt.agama_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="alamat"
                      type="text"
                      placeholder="Alamat"
                      value={this.state.dt.alamat_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="shdk"
                >
                  Hubungan Pelapor
                </Label>
                <Col sm="9">
                  {/* <input
                    className="form-control"
                    id="hubungan_pelapor"
                    type="text"
                    placeholder="Hubungan Pelapor"
                    defaultValue={this.state.dt.hubungan_pelapor}
                    required
                  /> */}
                  <div data-testid="selectShdk">
                    <Select
                      inputId="shdk"
                      name="shdk"
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.changeSelectValue(e, 'shdk');
                      }}
                      value={this.state.shdk}
                      id="shdk"
                      options={[
                        { value: 'Suami', label: 'Suami' },
                        { value: 'Isteri', label: 'Isteri' },
                        { value: 'Orang Tua', label: 'Orang Tua' },
                        { value: 'Anak', label: 'Anak' },
                        { value: 'Kakak', label: 'Kakak' },
                        { value: 'Adik', label: 'Adik' },
                        { value: 'Mertua', label: 'Mertua' },
                        { value: 'Menantu', label: 'Menantu' },
                        { value: 'Cucu', label: 'Cucu' },
                        { value: 'Pembantu', label: 'Pembantu' },
                        { value: 'Famili Lain', label: 'Famili Lain' }
                      ]}
                      placeholder="Pilih Hubungan Keluarga"
                    />
                  </div>
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                data-testid="btnSubmitCreate"
                type="submit"
                className="btn btn-primary-custom"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  />
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary-custom">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            {/* <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Jabatan</th>
                    <th>Level</th>
                    <th>TTD</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.datDisposisi.map((dt,i)=>{
                 return(
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>{dt.data_jabatan.nama}</td>
                    <td>{dt.level}</td>
                    <td>

                    <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => {
                      this.disposisi(dt.uuid,this.state.idSurat)}
                    }
                    disabled={this.state.status.btnForm}
                  >
                    Disposisi
                  </button>
                    </td>
                  </tr>
                   )})}
                </tbody>
              </table>
            </div>
             */}
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.riwayatDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status === 0
                            ? "Menunggu Disposisi"
                            : dt.status === 1
                            ? "Telah di disposisi"
                            : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <div id="cetak-surat" style={{ display: "none" }}>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    "
            }}
          />
          <table width="100%" border="0">
            <tr>
              <td width="100" align="center">
                <img alt="logo" src={logo} width="80px" />
              </td>
              <td align="center">
                <div style={{ fontWeight: "bold" }}>
                  <span
                    style={{ fontSize: "24px", textTransform: "uppercase" }}
                  >
                    pemerintah {NAMA_KABUPATEN}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "22px", textTransform: "uppercase" }}
                  >
                    kecamatan {KECAMATAN}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "28px", textTransform: "uppercase" }}
                  >
                    kantor kepala {Nama_DESA}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "16px", textTransform: "capitalize" }}
                  >
                    {ALAMAT_KANTOR_DESA}
                  </span>
                </div>
              </td>
            </tr>
          </table>
          <hr style={{ border: "2px solid black" }} />
          <table
            border="0"
            width="90%"
            style={{
              marginTop: "30px",
              marginLeft: "auto",
              marginRight: "auto",
              verticalAlign: "top"
            }}
          >
            <tr>
              <td align="center" style={{ paddingBottom: "20px" }}>
                {" "}
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    textDecoration: "underline"
                  }}
                >
                  surat keterangan kematian
                </span>
                <br />
                {this.state.cetak.nomor_surat}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "50px" }}>
                  Yang bertanda tangan dibawah ini Kepala {Nama_DESA},
                  Kecamatan {KECAMATAN}, {NAMA_KABUPATEN}, Provinsi {PROVINSI},
                  dengan ini menerangkan bahwa :{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nama}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat/Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td>
                      {this.state.cetak.tempat_lahir},{" "}
                      {Tanggal(new Date(this.state.cetak.tanggal_lahir))}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.alamat}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td colspan="3" width="35%" style={{ paddingLeft: "50px" }}>
                      Telah meninggal dunia pada
                    </td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Hari/Tanggal
                    </td>
                    <td>:</td>
                    <td>
                      {HariTanggal(new Date(this.state.cetak.waktu_meninggal))}
                    </td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Jam
                    </td>
                    <td>:</td>
                    <td>
                      {new Date(
                        this.state.cetak.waktu_meninggal
                      ).toLocaleTimeString("id-ID")}
                    </td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Disebabkan karena
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.sebab_meninggal}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Dimakamkan di
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.tempat_makam}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" width="35%" style={{ paddingLeft: "50px" }}>
                      Demikianlah surat keterangan ini dibuat berdasarkan
                      laporan dari :
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.nama_pelapor}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat/Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.tempat_lahir_pelapor},{" "}
                      {Tanggal(
                        new Date(this.state.cetak.tanggal_lahir_pelapor)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik_pelapor}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Agama
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.agama_pelapor}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.pekerjaan_pelapor}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.alamat_pelapor}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Hubungan Pelapor
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.hubungan_pelapor}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table border="0" width="100%">
            <tr>
              <td style={{ textAlign: "justify" }} width="50%">
                &nbsp;
              </td>
              <td style={{ textAlign: "justify" }} width="50%">
                <table border="0" width="100%" cellpadding="0" cellspacing="0">
                  <tr>
                    <td width="35%">Dikeluarkan di</td>
                    <td>:</td>
                    <td>{this.state.cetak.nama_desa}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ borderBottom: "1px solid black" }}>
                      Dikeluarkan pada
                    </td>
                    <td style={{ borderBottom: "1px solid black" }}>:</td>
                    <td style={{ borderBottom: "1px solid black" }}>
                      {Tanggal(new Date(this.state.cetak.tanggal_surat))}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table border="0" width="100%" style={{ marginTop: "20px" }}>
            <tr>
              <td width="50%">&nbsp;</td>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {this.state.cetak.status_ttd}{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {this.state.cetak.jabatan_ttd}
                  </span>
                  {this.state.cetak.jabatan_pengganti_ttd ? (
                    <>
                      <br />
                      {this.state.cetak.jabatan_pengganti_ttd}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td height="50" colspan="2">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td align="center">&nbsp;</td>
              <td align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama_ttd}
                </span>
                <br />
                {this.state.cetak.nip_ttd}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default BasicTable;
