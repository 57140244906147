import React, { Component, } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
import Datetime from 'react-datetime';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
// import classnames from "classnames";

import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
// import Datepicker from "react-datepicker";

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import  { Post, Get, Delete, JENIS_DESA} from "../function/Koneksi";
// import { Tanggal, Hari, HariTanggal, HariTanggalJam } from "../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import ReactDatePicker from "react-datepicker";
import { Link, } from "react-router-dom";

//json file
// var data = require('../assets/json/company');

const frmDef = {
	id: 0,
	idbaru: '',
	id_kk: '',
	nama: '',
	alamat: '',
	id_keldes: '',
	tempat_lahir: '',
	tanggal_lahir: new Date(),
	jk: '',
	shdk: '',
	agama: '',
	pendidikan_terakhir: '',
	pekerjaan: '',
	nama_ibu_kandung: '',
	status_penduduk: '',
	status_perkawinan: '',
	kode_pos: '',
	telepon: '',
	alasan_pindah: '',
};

const dateFormat = require('dateformat');

class KecamatanPindahDatang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Data table
      data: [],
      dataPemohon: null,

      detail: [],
      detailKK: null,
      kepalaKeluarga: '',

      // Pagination data table
      pagination: [],

      status: {
        form: false,
        formTambahVerifikasi: false,
        formKeterangan: false,
        formDetailGambar: false,
        formDetailKeluarga: false,
        btnForm: false,
        btnAksi: false,
        btnAksiTidakGelar: false,
        select: false,
      },

      uuid_surat: null,

      // Diajukan
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",

      dt: frmDef,
      dtPindah: {
        alasan_pindah: '',
        alamat: '',
        rt: '',
        rw: '',
        kode_pos: '',
        telepon: '',
        tgl_pindah: null,
      },
      judul: "",
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: null,
      frmKbkt: [],
      kbktStat: null,
      frmKec: [],
      kecStat: null,
      frmKel: [],
      kldsStat: null,

      frmAgm: [],
      frmKk: [],
      frmNik3: [],
      nikStat: null,
      nik3Stat: null,
      shdk_aw: null,
			kkStat: null,
			alasanPindah: null,
			klasifikasiPindah: null,
			jenisPindah: null,
			statusTidakPindah: null,
			statusPindah: null,
      // =================================
      // =========== Alert ============
      alert: null,
      show: false,
      showCetakSurat: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,
      // ==============================
      active_tab_icon: "1",

      // Cari Data Gelar
      dataCariGelar: {
				nama: ''
			},

      uuid_jenis_surat: '8a3ee8e1-d1fb-43ad-8073-6dc126bb72cd',

      // Detail Layanan
      detailLayanan: [],
      // Detail Keluarga
      detail_keluarga: [],

      cek_posisi:'Pertama',

			isKelurahan: false,
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      showCetakSurat: false,
      successAlert: false,
      alert: null
    });
  };

  // Bootstrap tabs function
  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      if(tab === 1){
        this.setState({
          cek_posisi: 'Pertama',
          dataCariGelar: {
            nama: ''
          }
        })
      }
      else if(tab === 2){
        this.setState({
          cek_posisi: 'Kedua',
          dataCariGelar: {
            nama: ''
          }
        })
      }
      else if(tab === 3){
        this.setState({
          cek_posisi: 'Ketiga',
          dataCariGelar: {
            nama: ''
          }
        })
      }
      else if(tab === 4){
        this.setState({
          cek_posisi: 'Keempat',
          dataCariGelar: {
            nama: ''
          }
        })
      }
      else if(tab === 5){
        this.setState({
          cek_posisi: 'Kelima',
          dataCariGelar: {
            nama: ''
          },
        })
      }
      
      this.setState({
        active_tab_icon: tab,
      });
    }
    
    this.forceUpdate();
    this.componentDidMount();
  }

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" +
                Math.ceil(
                  this.state.pagination.total / this.state.pagination.per_page
                );
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDiverifikasi.total /
                  this.state.paginationDiverifikasi.per_page
                );
              break;

            case "Disetujui":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDisetujui.total /
                  this.state.paginationDisetujui.per_page
                );
              break;

            case "Ditolak":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDitolak.total /
                  this.state.paginationDitolak.per_page
                );
              break;
              case "Selesai":
                link =
                  "&page=" +
                  Math.ceil(
                    this.state.paginationSelesai.total /
                    this.state.paginationSelesai.per_page
                  );
                break;
              default:
                // no default
                break;
          }
          break;
        case ">":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) + 1);
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                (parseInt(this.state.paginationDiverifikasi.current_page) +
                  1);
              break;

            case "Disetujui":
              link =
                "&page=" +
                (parseInt(this.state.paginationDisetujui.current_page) + 1);
              break;

            case "Ditolak":
              link =
                "&page=" +
                (parseInt(this.state.paginationDitolak.current_page) + 1);
              break;
            case "Selesai":
              link =
                "&page=" +
                (parseInt(this.state.paginationSelesai.current_page) + 1);
              break;
            default:
              // no default
              break;

          }
          break;
        case "<":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) - 1);
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                (parseInt(this.state.paginationDiverifikasi.current_page) -
                  1);
              break;

            case "Disetujui":
              link =
                "&page=" +
                (parseInt(this.state.paginationDisetujui.current_page) - 1);
              break;

            case "Ditolak":
              link =
                "&page=" +
                (parseInt(this.state.paginationDitolak.current_page) - 1);
              break;
            case "Selesai":
              link =
                "&page=" +
                (parseInt(this.state.paginationSelesai.current_page) - 1);
              break;
            default:
              // no default
              break;
          }
          break;
        case "<<":
          link = "&page=1";
          break;
        default:
          link = "&page=" + params.page;
          break;
      }
    }

    Get("surat-keterangan/grup/" + this.state.uuid_jenis_surat + link, null, (dtkat) => {
      if(dtkat.results){
        this.setState({ data: dtkat.results.data, pagination: dtkat.results });
        this.pagination(dtkat.results); 
        
        // For testing
        if (this.props.getListSuratPindahWNI) {
          this.props.getListSuratPindahWNI(dtkat.results.data)
        }
      }
    });
  };

  // Pagination
  pagination = (data) => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            key={i}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }

      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }

    this.setState({ awal, sebelum, hal, setelah, akhir });
    
  };

  getChild = (e, self, parent, child) => {
    this.setState({
      status:{
        ...this.state.status,
        select: true,
      },
    })
		this.forceUpdate();
    this.changeSelectValue(e, self);
    let formDusun = this.state.frmDus

		if (e) {
      if(this.state.isKelurahan){
        Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
          let frm = [];
          data.results.forEach((dt) => {
            frm.push({ value: dt.id, label: dt.nama });
          });
          switch (child) {
            case 'kab-kota':
              this.setState({ frmKbkt: frm, frmKec: [] });
              // For testing
              if (this.props.getListKab) {
                this.props.getListKab(frm)
              }
  
              break;
            case 'kecamatan':
              this.setState({ frmKec: frm });
              
              // For testing
              if (this.props.getListKec) {
                this.props.getListKec(frm)
              }
              break;
            case 'keldes':
              this.setState({ frmKel: frm });
     
              // For testing
              if (this.props.getListKeldes) {
                this.props.getListKeldes(frm)
              }
              break;
            case 'rw':
              this.setState({ frmRw: frm });
              break;
  
            case 'rt':
              this.setState({ frmRt: frm });
              break;
            default:
              // no default
              break;
          }
          this.setState({
            status:{
              ...this.state.status,
              select: false,
            },
          })
          this.forceUpdate();
        });
      }
      else{
        Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
          let frm = [];
          data.results.forEach((dt) => {
            frm.push({ value: dt.id, label: dt.nama });
          });
          switch (child) {
            case 'kab-kota':
              this.setState({ frmKbkt: frm, frmKec: [] });
              // For testing
              if (this.props.getListKab) {
                this.props.getListKab(frm)
              }
  
              break;
            case 'kecamatan':
              this.setState({ frmKec: frm });
              
              // For testing
              if (this.props.getListKec) {
                this.props.getListKec(frm)
              }
              break;
            case 'keldes':
              this.setState({ frmKel: frm });
     
              // For testing
              if (this.props.getListKeldes) {
                this.props.getListKeldes(frm)
              }
              break;
            case 'dusun':
              if (this.state.id_kategori_penduduk.value === 1) {
                this.setState({ frmDus: frm });
  
                // For testing
                if (this.props.getListDusun) {
                  this.props.getListDusun(frm)
                }
              }
              else {
                this.setState({ frmDus: formDusun });
                
                // For testing
                if (this.props.getListDusun) {
                  this.props.getListDusun(formDusun)
                }
              }
              break;
  
            case 'rw':
              this.setState({ frmRw: frm });
              break;
  
            case 'rt':
              this.setState({ frmRt: frm });
              break;
            default:
              // no default
              break;
          }
          this.setState({
            status:{
              ...this.state.status,
              select: false,
            },
          })
          this.forceUpdate();
        });
      }
		}
	};

  changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'kk':
          this.setState({ 
            kkStat: e, 
            dt:{
              ...this.state.dt,
              id_kk: e.value,
            },
          });

          Get('kartu-keluarga/find', e.value, (data) => {
            // console.log('data find pdd');
            // console.log(data);
            if (data.results) {
              this.setState({ detailKK: data.results })
              let kepalaKeluarga = ''
              // #continuethis
              // coba pke while break
              data.results.data_keluarga.forEach((detail, index) => {
                if (detail.data_shdk.nama === 'Kepala Keluarga') {
                  kepalaKeluarga = detail.nama
                } else if (index === data.results.data_keluarga.length - 1 && kepalaKeluarga === '') {
                  kepalaKeluarga = detail.nama
                }
                this.setState({ kepalaKeluarga })
              })

              Get("penduduk/kk/"+data.results.id, null, dtkk => {
                // console.log('data get pdd next');
                // console.log(dtkk);
                let frmNik3 = [];
                dtkk.results.forEach(dt => {
                  frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
                });
                this.setState({ frmNik3 })

                 // For testing
                if (this.props.getListNIK3) {
                  this.props.getListNIK3(frmNik3)
                }
               
              })
 
              // For testing
              if (this.props.selectOnChangeKK) {
                this.props.selectOnChangeKK(data.results)
              }
              
            }
          })
					break;
        case 'nik': 
          this.setState({ nikStat: e })
          Get("penduduk/find", e.value, data => {
            if (data.results) {
              //console.log(data);
              this.setState({
                dataPemohon: {
                  nik: data.results.id,
                  nama_pemohon: data.results.nama,
                  tempat_lahir: data.results.tempat_lahir,
                  tanggal_lahir: data.results.tanggal_lahir,
                  pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
                  pekerjaan: data.results.data_pekerjaan.nama,
                  agama: data.results.data_agama.nama,
                  status_perkawinan: data.results.status_perkawinan,
                  alamat: data.results.alamat,
                  jenis_kelamin: data.results.jk,
                  kewarganegaraan: "WNI",
                  tujuan_pembuatan: 'Surat Keterangan Pindah Datang WNI',
                  kk: data.results.id_kk,
                }
              })
              
              // For testing
              if (this.props.selectOnChange) {
                this.props.selectOnChange(this.state.dataPemohon)
              }
            }
          });
          break;
        case 'nik3': 
          this.setState({ nik3Stat: e }) 
          
          // For testing
          if (this.props.selectOnChange3) {
            this.props.selectOnChange3(e)
          }
          break;
        case 'shdk_aw': 
          this.setState({ shdk_aw: e })
          
          // For testing
          if (this.props.selectOnChangeShdk) {
            this.props.selectOnChangeShdk(e)
          }
          break;
        case 'alasan pindah': 
          this.setState({ alasanPindah: e })
          if (e.value === '7') {
            this.setState({ dtPindah: {...this.state.dtPindah, alasan_pindah: ''} })
          }
          // For testing
          if (this.props.selectOnChangeAlasanPindah) {
            this.props.selectOnChangeAlasanPindah(e)
          }
          break;
        case 'klasifikasi pindah': 
          this.setState({ klasifikasiPindah: e })
          
          // For testing
          if (this.props.selectOnChangeKlasifikasiPindah) {
            this.props.selectOnChangeKlasifikasiPindah(e)
          }
          break;
        case 'jenis pindah': 
          this.setState({ jenisPindah: e })

          // For testing
          if (this.props.selectOnChangeJenisPindah) {
            this.props.selectOnChangeJenisPindah(e)
          }
          break;
        case 'status tidak pindah': 
          this.setState({ statusTidakPindah: e })

          // For testing
          if (this.props.selectOnChangeStatusTidakPindah) {
            this.props.selectOnChangeStatusTidakPindah(e)
          }
          break;
        case 'status pindah': 
          this.setState({ statusPindah: e })
          
          // For testing
          if (this.props.selectOnChangeStatusPindah) {
            this.props.selectOnChangeStatusPindah(e)
          }
          break;
        case 'provinsi':
          this.setState({ prvStat: e });
          this.setState({ kbktStat: null });
          this.setState({ kecStat: null });
          
          // For testing
          if (this.props.selectOnChangeProvinsi) {
            this.props.selectOnChangeProvinsi(e)
          }
          break;
        case 'kab-kota':
          this.setState({ kbktStat: e });
          this.setState({ kecStat: null });
          
          // For testing
          if (this.props.selectOnChangeKab) {
            this.props.selectOnChangeKab(e)
          }
          break;
        case 'kecamatan':
          this.setState({ kecStat: e });
          this.setState({ kldsStat: null });
          
          // For testing
          if (this.props.selectOnChangeKec) {
            this.props.selectOnChangeKec(e)
          }
          break;
        case 'keldes':
          this.setState({ kldsStat: e });
          
          // For testing
          if (this.props.selectOnChangeKeldes) {
            this.props.selectOnChangeKeldes(e)
          }
          break;
        default:
          // no default
          break;
			}
		} else {
			switch (sel) {
				case 'kk':
          this.setState({ 
            dt:{
              ...this.state.dt,
              id_kk: 0,
            },
            detailKK: [],
            kepalaKeluarga: [],
            kkStat: null, 
            detailkk: null, 
            frmNik3: [] 
          });
					break;
				case 'nik':
          this.setState({ 
            nikStat: null, 
            dataPemohon: null,
          });
					break;
				case 'nik3':
					this.setState({ nik3Stat: null });
					break;
				case 'shdk_aw':
					this.setState({ shdk_aw: null });
					break;
				case 'alasan pindah':
					this.setState({ alasanPindah: null });
					break;
				case 'klasifikasi pindah':
					this.setState({ klasifikasiPindah: null });
					break;
				case 'jenis pindah':
					this.setState({ jenisPindah: null });
					break;
				case 'status tidak pindah':
					this.setState({ statusTidakPindah: null });
					break;
				case 'status pindah':
					this.setState({ statusPindah: null });
					break;
        case 'provinsi':
          this.setState({
            prvStat: null,
            kbktStat: null, 
            frmKbkt: [],
            kecStat: null, 
            frmKec: [],
            kldsStat: null,
            frmKel: [],
          });
          break;
        case 'kab-kota':
          this.setState({
            kbktStat: null, 
            frmKbkt: [],
            kecStat: null, 
            frmKec: [],
            kldsStat: null,
            frmKel: [],
          });
          break;
        case 'kecamatan':
          this.setState({
            kecStat: null, 
            frmKec: [],
            kldsStat: null,
            frmKel: [],
          });
          break;
        case 'keldes':
          this.setState({
            kldsStat: null
          });
          break;
        default:
          // no default
          break;
			}
		}

    this.setState({
      status:{
        ...this.state.status,
        select: false,
      },
    })
		this.forceUpdate();
	};

  handleInputChangeNIK = (e, fld) => {
    // //console.log(e);
		if (e) {
      if (fld === 'kk') {
				// let kkStat = { value: e, label: e };
				let param = {
					id: e
				};
				Post('kartu-keluarga/select', null, param, (datakk) => {
					let frmKk = [];
					if (datakk.data.results.data.length > 0) {
						datakk.data.results.data.forEach((dt) => {
							frmKk.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
						});
						// this.setState({ frmKk, kkStat });
						this.setState({ frmKk });
					} else {
						// Get('kartu-keluarga/list', null, (data) => {
						Get('kartu-keluarga', null, (data) => {
							data.results.data.forEach((dt) => {
									frmKk.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
							});

							// if (this.state.kkStat === null) {
								this.setState({
									show: true,
									basicType: 'info',
									basicTitle: 'Data Penduduk',
									pesanAlert: 'Nomor KK tidak tersedia, silahkan pilih Nomor KK yang tersedia',
									frmKk
								});
							// }
						});
					}
				});
			}
		} else {
			if (fld === "kk") {
				Get('kartu-keluarga', null, (data) => {
					let frmKk = [];
					data.results.data.forEach((dt) => {
							frmKk.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
					});
					this.setState({ frmKk });
				});
			}
		}
	};
 
  // ========================================================================

  // ====================== Hapus Data =============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("kesukuan/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Suku",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Suku",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ====================== Hapus Data =============================

  bukaForm = jenis => {
    
    this.setState({
      detailKK: null,
      dtPindah:{
        ...this.state.dtPindah,
        tgl_pindah: null
      },
      status:{
        ...this.state.status,
        form: true,
      },
      kkStat: null,
      alasanPindah: null,
      prvStat: null,
      kbktStat: null,
      kecStat: null,
      kldsStat: null,
      klasifikasiPindah: null,
      jenisPindah: null,
      statusTidakPindah: null,
      statusPindah: null,
      detail: []
    })

    this.forceUpdate();

  };

  tutupForm = () => {
    this.setState({
      detail:[],
      status:{
        ...this.state.status,
        formTambahVerifikasi: false,
        form: false,
        accStat: false,
        tableAcc: "none",
      },
      id_suku: null,
      id_datuak: null,
    })

    this.forceUpdate();
  };

  ubahData = id => {
    // this.state.status.btnAksi = true;
    // this.componentDidMount();
    Get("surat-keterangan/grup/find", id, data => {
      // console.log("Data Ubah Detail")
      // console.log(data.results)
      if (data.results) {
        if (data.results.detail_surat) {
          let detail_surat = JSON.parse(data.results.detail_surat)
          // console.log('detail_surat');
          // console.log(detail_surat);

          Get('kartu-keluarga/find', detail_surat.data_daerah_asal.no_kk, (data2) => {
            //console.log(data2);
            if (data2.results) {
              let frmNik3 = [];
              data2.results.data_keluarga.forEach(dt => {
                frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
              });

              let dataPemohon = {
                nik: data.results.nik,
                nama_pemohon: data.results.nama_pemohon,
                tempat_lahir: data.results.tempat_lahir,
                tanggal_lahir: data.results.tanggal_lahir,
                pendidikan_terakhir: data.results.pendidikan_terakhir,
                pekerjaan: data.results.pekerjaan,
                agama: data.results.agama,
                status_perkawinan: data.results.status_perkawinan,
                alamat: data.results.alamat,
                jenis_kelamin: data.results.jenis_kelamin,
                kewarganegaraan: "WNI",
                tujuan_pembuatan: 'Surat Keterangan Pindah Datang WNI',
                kk: data.results.kk,
              }

              // get list wilayah
              this.getChild(detail_surat.data_kepindahan.provinsi, 'provinsi', 'prov', 'kab-kota');
              this.getChild(detail_surat.data_kepindahan.kabkota, 'kab-kota', 'kab', 'kecamatan');
              this.getChild(detail_surat.data_kepindahan.kecamatan, 'kecamatan', 'kec', 'keldes');

              this.setState({
                uuid_surat: id,
                kkStat: {
                  value: detail_surat.data_daerah_asal.no_kk, 
                  label: detail_surat.data_daerah_asal.no_kk
                },
                nikStat: { value: data.results.nik, label: data.results.nik + ' (' + data.results.nama_pemohon + ')' },
                dataPemohon,
                frmNik3,
                detailKK: data2.results,
                kepalaKeluarga: detail_surat.data_daerah_asal.nama_kepala_keluarga,
                // alasanPindah: detail_surat.data_kepindahan.alasan_pindah,
                prvStat: detail_surat.data_kepindahan.provinsi,
                kbktStat: detail_surat.data_kepindahan.kabkota,
                kecStat: detail_surat.data_kepindahan.kecamatan,
                kldsStat: detail_surat.data_kepindahan.deskel,
                klasifikasiPindah: detail_surat.data_kepindahan.klasifikasi_pindah,
                jenisPindah: detail_surat.data_kepindahan.jenis_pindah,
                statusTidakPindah: detail_surat.data_kepindahan.status_nomor_kk_bagi_tidak_pindah,
                statusPindah: detail_surat.data_kepindahan.status_nomor_kk_bagi_pindah,
                dtPindah: {
                  alasan_pindah: detail_surat.data_kepindahan.alasan_pindah.label,
                  alamat: detail_surat.data_kepindahan.alamat,
                  rt: detail_surat.data_kepindahan.rt,
                  rw: detail_surat.data_kepindahan.rw,
                  kode_pos: detail_surat.data_kepindahan.kode_pos,
                  telepon: detail_surat.data_kepindahan.telp,
                  tgl_pindah: new Date(detail_surat.data_kepindahan.tanggal_pindah)
                },
                detail: detail_surat.keluarga_yang_pindah,
                status: {...this.state.status, form: true}
              })

              if (detail_surat.data_kepindahan.alasan_pindah.value === '7') {
                this.setState({ alasanPindah: {
                  value: '7',
                  label: 'Lainnya'
                } })
              } else {
                this.setState({ alasanPindah: detail_surat.data_kepindahan.alasan_pindah })
              }

              document.getElementById("kode_pos").value = detail_surat.data_daerah_asal.kode_pos
              document.getElementById("telepon").value = detail_surat.data_daerah_asal.telp

              // this.state.status.form = true;
              // this.forceUpdate()

            }
          })

        }
      }

      this.bukaForm();
    });
  };

  tombolAksi = (cell, row) => {
    let btnAksi = false
    // if (row.data_status[row.data_status.length - 1].status == 'Selesai') {
    //   btnAksi = true
    // }

    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>
      </>
    )
  }


  tombolAksiVerifikasi = (cell, row) => {
    return (
      <>
      {this.state.cek_posisi==="Pertama"
        ?
        <div>
            <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn btn-primary-custom"><i className="fa fa-check"></i> Verifikasi</button>
        </div>
        :this.state.cek_posisi==="Kedua"
        ?
        <div>
            <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn btn-primary-custom"><i className="fa fa-eye"></i> Detail</button>
        </div>
         :this.state.cek_posisi==="Ketiga"
         ?
         <div>
             <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn btn-primary-custom"><i className="fa fa-eye"></i> Detail</button>
         </div>
         :this.state.cek_posisi==="Keempat"
         ?
         <div>
             <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn btn-primary-custom"><i className="fa fa-eye"></i>  Detail</button>
         </div>
         
         :this.state.cek_posisi==="Kelima"
         ?
         <div>
             <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn btn-primary-custom"><i className="fa fa-eye"></i>  Detail</button>
             <button onClick={() => this.downloadDokumen(row)} color="info" className="btn btn-primary-custom"><i className="fa fa-download"></i>  Download</button>
         </div>
         :
         <>
         </>
      }
     
    </>
    )
}

  componentDidMount() {
    if (this.props.location.state) {
      if (JENIS_DESA.toLowerCase() === 'kelurahan') {
        this.setState({ isKelurahan: true });
      }

      if (typeof this.props.location.tabProps !== "undefined") {
        this.setState({
          active_tab_icon: this.props.location.tabProps,
        })
      }
      if (typeof this.props.location.state !== "undefined") {
        this.setState({
          active_tab_icon: this.props.location.state.tab,
        })

      }

      let tabnya = ""
      if(this.state.cek_posisi==="Pertama"){
        tabnya="Diajukan"
      }
      // Fetching data diajukan
      this.fetch({ page: null, where: [], tab: tabnya });
      this.forceUpdate();
    } 

      // Get list kk
      Get('kartu-keluarga', null, (data) => {
        //console.log(data.results)
          let frmKk = [];
          data.results.data.forEach((dt) => {
              frmKk.push({ value: dt.id, label: dt.id });
          });
          this.setState({frmKk});
          
          // For testing
          if (this.props.getListKK) {
            this.props.getListKK(frmKk)
          }
      });
      // Get list provinsi
      Get('provinsi/list', null, (data) => {
        let frmPrv = [];
        data.results.forEach((dt) => {
          frmPrv.push({ value: dt.id, label: dt.nama });
        });
        this.setState({ frmPrv });

        // For testing
        if (this.props.getListProv) {
          this.props.getListProv(frmPrv)
        }
      });
    // else {
    //   this.setState({ redirect: true });
    // }
    this.fetch({ page: null, where: [] });
  }


  // Cari Data penduduk gelar
	cariDataGelar = (e) => {
    
    //console.log("Tab Keberapa")
    //console.log(this.state.active_tab_icon)

    if(this.state.active_tab_icon===1){
      this.setState({
        cek_posisi: "Pertama",
      })
    }
    else{
      this.setState({
        cek_posisi: "Non-Gelar",
      })
    }

		if (e.key === 'Enter') {
      //console.log("Search")
			//console.log(e.target.value)
			let dataCariGelar = {
        q: e.target.value
			};
			this.setState({ dataCariGelar });
			setTimeout(
        function() {
          // mywindow.close();
					this.fetch();
				}.bind(this),
				500
        );
      }
      else{
        this.setState({
          dataCariGelar: {
            nama: ''
          },
        })
      }
      this.forceUpdate();
	};


  // ============================== Ubah Data ===============================
    
//   ubahData = (id) => {
//     this.state.status.btnAksi = true;
//     this.state.status.formTambahVerifikasi = true;
//     this.state.judul = "Ubah Suku";
//     this.forceUpdate();
//     Get('kesukuan/find',id, (data) => {
//       //console.log("Data Ubah")
//       //console.log(data)
//       this.state.data_detail.uuid = data.results.uuid;
//       this.state.data_detail.nik = data.results.nik;
//       this.state.data_detail.nama = data.results.data_penduduk.nama;
//       this.state.id_suku= { value: data.results.nama_suku, label: data.results.nama_suku }
//       this.state.id_datuak= { value: data.results.nama_datuak, label: data.results.nama_datuak }
//       this.state.data_detail.nama_gelar = data.results.nama_gelar;

//           this.forceUpdate();
//         this.state.status.btnAksi = false;
//         this.forceUpdate();
//         this.bukaForm();
//     });
// }


  formKeterangan = (e) => {
		e.preventDefault();
    this.setState({
      data_detail:{
        ...this.state.data_detail,
        keterangan_layanan: "",
      },
      status:{
        ...this.state.status,
        formKeterangan: true,
      },
    })
			this.forceUpdate();
	};

  detailGambar = (id, judul) => {
		// e.preventDefault();
    this.setState({
      data_detail:{
        ...this.state.data_detail,
        show_gambar: id,
        judul_gambar: judul,
      },
      status:{
        ...this.state.status,
        formDetailGambar: true,
      },
    })
      //console.log("Detail Link Gambar")
      //console.log(id)
			this.forceUpdate();
	};

  detailKeluarga = (data) => {
		// e.preventDefault();
    this.setState({
      detail_keluarga:{
        ...this.state.detail_keluarga,
        judul: "Detail Data Keluarga",
        agama: data.agama,
        akta_cerai: data.akta_cerai,
        akte_lahir: data.akte_lahir,
        akte_perkawinan: data.akte_perkawinan,
        alamat_sebelum: data.alamat_sebelum,
        gelar: data.gelar,
        golongan_darah: data.golongan_darah,
        jenis_kelamin: data.jenis_kelamin,
        kelainan_fisik: data.kelainan_fisik,
        kepercayaan_kpd_tuhan: data.kepercayaan_kpd_tuhan,
        nama: data.nama,
        nama_ayah: data.nama_ayah,
        nama_ibu: data.nama_ibu,
        nama_ketua_rt: data.nama_ketua_rt,
        nama_ketua_rw: data.nama_ketua_rw,
        nik_ayah: data.nik_ayah,
        nik_ibu: data.nik_ibu,
        no_akta_cerai: data.no_akta_cerai,
        no_akta_kelahiran: data.no_akta_kelahiran,
        no_akta_perkawinan: data.no_akta_perkawinan,
        no_ktp: data.no_ktp,
        no_paspor: data.no_paspor,
        no_urut: data.no_urut,
        pekerjaan: data.pekerjaan,
        pendidikan_terakhir: data.pendidikan_terakhir,
        penyandang_cacat: data.penyandang_cacat,
        status_hub_keluarga: data.status_hub_keluarga,
        status_perkawinan: data.status_perkawinan,
        tgl_berakhir_paspor: data.tgl_berakhir_paspor,
        tgl_cerai: data.tgl_cerai,
        tgl_lahir: data.tgl_lahir,
        tgl_perkawinan: data.tgl_perkawinan,
        tmpt_lahir: data.tmpt_lahir,
        umur: data.umur,
      },
      status:{
        ...this.state.status,
        formDetailKeluarga: true,
      },
    })
      //console.log("Detail Keluarganya")
      //console.log(data)
			this.forceUpdate();
	};


  tutupKeterangan = (e) => {
		e.preventDefault();
    this.setState({
      status:{
        ...this.state.status,
        formKeterangan: false,
      },
    })
    document.getElementById("keterangan_tolak").value = ""
		this.forceUpdate();
	};

  simpan = (e) => {
    e.preventDefault()

    // validasi data
    if (
      this.state.kkStat &&
      this.state.nikStat &&
      this.state.dataPemohon &&
      this.state.detailKK &&
      this.state.alasanPindah &&
      this.state.prvStat &&
      this.state.kbktStat &&
      this.state.kecStat &&
      this.state.kldsStat &&
      this.state.klasifikasiPindah &&
      this.state.jenisPindah &&
      this.state.statusTidakPindah &&
      this.state.statusPindah
    ) {

      let uuid = this.state.uuid_surat

      // data daerah asal
      let kode_pos = document.getElementById("kode_pos").value
      let telepon = document.getElementById("telepon").value

      // data kepindahan
      let alasan_pindah = '' 
      if (this.state.alasanPindah.value === '7') {
        alasan_pindah = {
          value: this.state.alasanPindah.value,
          label: document.getElementById("alasan_pindah").value,
        }
      } else {
        alasan_pindah = this.state.alasanPindah
      }
      let kode_pos_pindah = document.getElementById("kode_pos_pindah").value
      let telepon_pindah = document.getElementById("telepon_pindah").value
      let alamat_pindah = document.getElementById("alamat_pindah").value
      let rt_pindah = document.getElementById("rt_pindah").value
      let rw_pindah = document.getElementById("rw_pindah").value

      // Check validation
      if (kode_pos.length > 5 || kode_pos_pindah.length > 5) {
        this.setState({
          show: true,
          basicType: 'warning',
          basicTitle: 'Data Surat Pindah',
          pesanAlert: 'Jumlah digit kode pos tidak boleh lebih dari 5 angka'
        });
      } else if (telepon.length > 10 || telepon_pindah.length > 10) {
        this.setState({
          show: true,
          basicType: 'warning',
          basicTitle: 'Data Surat Pindah',
          pesanAlert: 'Jumlah digit nomor telepon tidak boleh lebih dari 10 angka'
        });
      } else if (rt_pindah.length !== 3) {
        this.setState({
          show: true,
          basicType: 'warning',
          basicTitle: 'Data Surat Pindah',
          pesanAlert: 'Jumlah digit kode RT harus 3 angka'
        });
      } else if (rw_pindah.length !== 3) {
        this.setState({
          show: true,
          basicType: 'warning',
          basicTitle: 'Data Surat Pindah',
          pesanAlert: 'Jumlah digit kode RW harus 3 angka'
        });
      } else {
        this.setState({
          status:{
            ...this.state.status,
            form: false,
          },
        })
        this.forceUpdate()

        let keluarga_yang_pindah = []
  
        this.state.detail.forEach((data, index) => {
          keluarga_yang_pindah.push({
            nik: data.nik,
            nama: data.nama,
            shdk: data.shdk,
          })
        })

        let set_tanggal_pindah = dateFormat(this.state.dtPindah.tgl_pindah, 'yyyy-mm-dd');
        // let set_tanggal_pindah = dateFormat(this.state.dtPindah.tgl_pindah, 'yyyy-mm-dd')
  
        let detail = {
          data_daerah_asal: {
            no_kk: this.state.detailKK.id,
            nama_kepala_keluarga: this.state.kepalaKeluarga,
            alamat: this.state.detailKK.alamat,
            rt: this.state.detailKK.data_rt.nama,
            rw: this.state.detailKK.data_rt.data_rw.nama,
            provinsi: this.state.detailKK.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama,
            kabkota: this.state.detailKK.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama,
            kecamatan: this.state.detailKK.data_rt.data_rw.data_keldes.data_kecamatan.nama,
            deskel: this.state.detailKK.data_rt.data_rw.data_keldes.nama,
            kode_pos: kode_pos,
            telp: telepon 
          },
          data_kepindahan: {
            alasan_pindah: alasan_pindah,
            alamat: alamat_pindah,
            rt: rt_pindah,
            rw: rw_pindah,
            provinsi: this.state.prvStat,
            kabkota: this.state.kbktStat,
            kecamatan: this.state.kecStat,
            deskel: this.state.kldsStat,
            kode_pos: kode_pos_pindah,
            telp: telepon_pindah,
            klasifikasi_pindah: this.state.klasifikasiPindah,
            jenis_pindah: this.state.jenisPindah,
            status_nomor_kk_bagi_tidak_pindah: this.state.statusTidakPindah,
            status_nomor_kk_bagi_pindah: this.state.statusPindah,
            tanggal_pindah: set_tanggal_pindah
          },
          keluarga_yang_pindah: keluarga_yang_pindah
        }
  
        let bodyRaw = { 
          id_jenis: this.state.uuid_jenis_surat,
  
          nik_pelapor: this.state.dataPemohon.nik,
          nik: this.state.dataPemohon.nik,
          nama_pemohon: this.state.dataPemohon.nama_pemohon,
          tempat_lahir: this.state.dataPemohon.tempat_lahir,
          tanggal_lahir: this.state.dataPemohon.tanggal_lahir,
          pendidikan_terakhir: this.state.dataPemohon.pendidikan_terakhir,
          pekerjaan: this.state.dataPemohon.pekerjaan,
          agama: this.state.dataPemohon.agama,
          status_perkawinan: this.state.dataPemohon.status_perkawinan,
          alamat: this.state.dataPemohon.alamat,
          jenis_kelamin: this.state.dataPemohon.jenis_kelamin,
          kewarganegaraan: 'WNI',
          tujuan_pembuatan: 'Surat Keterangan Pindah Datang WNI',
          kk: this.state.dataPemohon.kk,
  
          tanggal_surat: dateFormat(new Date(), 'yyyy-mm-dd'),
  
          detail_surat: JSON.stringify(detail)
        }
  
        //console.log("Data Simpan Pindah Datang WNI")
        //console.log(bodyRaw)
  
        let metode = "create"
        let resstat = 201
        let psn = "Tambah"

        if (uuid) {
          metode = "update"
          resstat = 200
          psn = "Ubah"
        }
  
        Post(
          "surat-keterangan/grup/" + metode,
          uuid,
          bodyRaw,
          res => {
            // For testing
            if (this.props.resSubmit) {
              this.props.resSubmit(res.status)
            }
            this.tutupForm();
            this.setState({
              status:{
                ...this.state.status,
                btnForm: false,
                form: false,
              },
            })
            this.forceUpdate();
            if (res.status === resstat) {
              this.setState({
                showBio: false,
                show: true,
                basicType: "success",
                basicTitle: "Data Surat Pindah",
                pesanAlert: "Berhasil " + psn + " Surat Pindah Datang WNI",
                successAlert: true,
                dtPindah: {
                  alasan_pindah: '',
                  alamat: '',
                  rt: '',
                  rw: '',
                  kode_pos: '',
                  telepon: '',
                  tgl_pindah: null,
                },
              });
              this.componentDidMount();
            } else {
              this.setState({
                showBio: false,
                show: true,
                basicType: "danger",
                basicTitle: "Data Surat Pindah",
                pesanAlert: "Gagal " + psn + " Surat Pindah Datang WNI"
              });
            }
            // this.componentDidMount();
          }
        );
      }

    } else {
      this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Data Surat Pindah',
				pesanAlert: 'Isi seluruh data dengan lengkap terlebih dahulu.'
			});
    }

  }

  tambahKeterangan = (e) => {
		e.preventDefault();
      this.forceUpdate();
      let id = this.state.data_detail.uuid;
      let addData = {
          status: "Ditolak",
          keterangan: document.getElementById("keterangan_tolak").value,
      };

          let psn = "Tolak";
          let resstat = 200;

          //console.log("Data Tambah Keterangan")
          //console.log(id)
          //console.log(addData)
          
      Post('pelayanan-external/verifikasi/'+ id, null , addData, (res) => {
          this.forceUpdate();
          //console.log(res.status);
          if (res.status === resstat) {
              this.setState({ 
                show: true, 
                basicType: 'success', 
                basicTitle: 'Verifikasi Pendaftaran Layanan', 
                pesanAlert: 'Berhasil ' + psn + ' Verifikasi',
                status:{
                  ...this.state.status,
                  formKeterangan: false,
                  formTambahVerifikasi: false,
                } 
              });
              document.getElementById("keterangan_tolak").value = ""
              this.forceUpdate()
          }
          else {
              this.setState({ show: true, basicType: 'danger', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Gagal ' + psn + ' Verifikasi' });
          }
          this.componentDidMount();
      });
	};

   // Download File Kelahiran
   downloadDokumen = (data) => {

    // Data Download 
    let dokumen_download = JSON.parse(data.dokumen_output)

    //console.log("Data DOwnload")
    //console.log(dokumen_download)
    
   
      const response = {
        file: dokumen_download.dokumen_output_1,
      };
      // server sent the url to the file!
      // now, let's download:
      window.open(response.file);
      // you could also do:
      // window.location.href = response.file;
  

    this.forceUpdate();
    
  }

  tambahAw = e => {
    if (this.state.nik3Stat !== null && this.state.shdk_aw) {
      let detail = this.state.detail;
      Get("penduduk/find", this.state.nik3Stat.value, data => {
        if (data.results) {
          detail.push({
            nik: this.state.nik3Stat.value,
            nama: data.results.nama,
            jenis_kelamin: data.results.jk,
            tanggal_lahir: data.results.tanggal_lahir,
            tempat_lahir: data.results.tempat_lahir,
            pekerjaan: data.results.data_pekerjaan.nama,
            pendidikan: data.results.data_pendidikan_terakhir.nama,
            shdk: this.state.shdk_aw,
            keterangan: "",
          });
          this.setState({ detail, nik3Stat: null, shdk_aw: null });
          document.getElementById("shdk").value = "";

          
          // For testing
          if (this.props.getDataKeluarga) {
            this.props.getDataKeluarga(detail)
          }
        }
      });
    } else {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Surat Keterangan Pindah Datang WNI",
        pesanAlert: "Pilih NIK dan hubungan keluarga terlebih dahulu"
      });
    }
  };
  
   // ================== Tanggal ===================

   gantiTgl = (date, fld) => {
    //console.log("Tanggal Di klik")
    //console.log(date)
    switch (fld) {
      case "tgl_pindah":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.setState({ dtPindah: {...this.state.dtPindah, tgl_pindah: new Date(tgl)} })
          //console.log("Tanggal Select")
          //console.log(tgl)
          //console.log(new Date(tgl))
        }
        break;
      default:
        // no default
        break;
    }
  };

  // ==============================================

  render() {
    
    var data = this.state.data;

    var no = 0;
    // const frmDef = {
    //   id: 0,
    //   idbaru: "",
    //   id_keldes: "",
    //   nama: ""
    // };
    // const { SearchBar } = Search;

    const listKeluargaIn = this.state.detail.map((dt, i) => {
      no++;
      // let jk = "L";
      // if (dt.jenis_kelamin === "Perempuan") {
      //   jk = "P";
      // }
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.nama}</td>
          {/* <td>{jk}</td>
          <td>
            {dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
          </td> */}
          {/* <td>{dt.pekerjaan}</td> */}
          <td>{dt.nik}</td>
          <td>{dt.shdk.label}</td>
          <td>
            <Button
              size="xs"
              onClick={() => {
                // this.hapus(i)
                this.state.detail.splice(i, 1);
                this.forceUpdate();
              }}
              color="danger"
              className="btn-icon"
            >
              <i className="fa fa-trash"></i>
            </Button>
          </td>
        </tr>
      );
    });

    // Data diproses
    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.pagination.current_page;
              let total_records_per_page = this.state.pagination.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
        dataField: "tanggal_surat",
        headerAlign: 'center',
				align: 'center',
        text: "Tanggal",
        formatter: (cell, obj) => {
          return obj.tanggal_surat === null
            ? "-"
            : new Date(obj.tanggal_surat).toLocaleDateString("id-ID");
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        headerAlign: 'center',
				align: 'center',
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor === "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "nama_pemohon",
        headerAlign: 'center',
				align: 'center',
        text: "Nama Pemohon",
        sort: true
      },
      {
        dataField: "tujuan_pembuatan",
        headerAlign: 'center',
				align: 'center',
        text: "Tujuan",
        formatter: (cell, obj) => {
          let tujuan = obj.tujuan_pembuatan.split(",");
          return tujuan[0];
        },
        isDummyField: true,
        sort: true
      },
      // {
      //   dataField: "status",
      //   text: "Status",
      //   formatter: (cell, obj) => {
      //     let status = "-";
      //     obj.data_status.map(dt => {
      //       status = dt.status;
      //     });
      //     if (status === "Diajukan") {
      //       this.state.status.btnAksi[cell] = true;
      //     } else {
      //       this.state.status.btnAksi[cell] = false;
      //     }
      //     this.forceUpdate();
      //     return status;
      //   },
      //   isDummyField: true,
      //   sort: true
      // },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    return (
      <div data-testid="page-surat keterangan pindah datang wni">
        {/* <Breadcrumb title="Data Surat Keterangan Penghasilan" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  this.bukaForm();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                color="info"
                className="btn btn-secondary-custom"
                // onClick={() => {
                //   this.closeAlert();
                // }}
              >
                <Link
                  data-testid="btn-eksekusi_surat"
                  onClick={() => {
                    // For testing
                    if (this.props.clickEksekusiSurat) {
                      this.props.clickEksekusiSurat()
                    }
                  }}
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                {/* <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>Data Disposisi Surat</h5>
                    </div>
                    <div className="col-sm-6 text-right">
                    </div>
                  </div>
                </div> */}
                <div className="card-body datatable-react">
                  <div className="row" style={{ marginBottom: 15 }}>
										<div className="col-sm-4 text-left">
                      {/* <button 
                        color="success" 
                        className="btn btn-secondary"
                        onClick={() => {
                          this.bukaForm()
                        }}
                      >Tambah Surat</button> */}
                      <Button
                        data-testid="tblCreateSuratPindahWNI"
                        color="success"
                        className="btn btn-primary-custom"
                        onClick={() => {
                          this.setState({ judul: 'Tambah Surat Keterangan Pindah Datang WNI' })
                          this.setState({ 
                            uuid_surat: null,
                            dataPemohon: null,
                            detailKK: null,
                            frmKbkt: [],
                            frmKec: [],
                            frmKel: [],
                            kepalaKeluarga: '',
                            dtPindah: {
                              alasan_pindah: '',
                              alamat: '',
                              rt: '',
                              rw: '',
                              kode_pos: '',
                              telepon: '',
                              tgl_pindah: null,
                            },
                           })
                          this.bukaForm();
                        }}
                      >
                        Tambah Surat
                      </Button>
										</div>
										{/* <div className="col-sm-8 text-right">
											<button onClick={this.props.history.goBack} color="info" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>
										</div> */}
									</div>
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: "100%" } }}
				>
          <Form 
            data-testid="form-create" 
            className="theme-form" 
            onSubmit={this.simpan}
          >
            <div className="modal-header" align="center">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>

            <div className="modal-body">
              {/* Data Daerah Asal */}
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>DATA DAERAH ASAL</b>
                </Label>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Nomor KK</b>
                </Label>
                <div className="col-sm-8">
                  <div data-testid="selectKK">
                    <Select
                      inputId="kk"
                      id="noKKPenduduk"
                      // isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.changeSelectValue(e, 'kk');
                      }}
                      defaultValue={this.state.kkStat}
                      value={this.state.kkStat}
                      name="No KK"
                      options={this.state.frmKk}
                      placeholder="Pilih KK"
                      onInputChange={(e) => this.handleInputChangeNIK(e, 'kk')}
                      isClearable
                    />
                  </div>
                </div>
              </FormGroup>
              {this.state.detailKK && this.state.kepalaKeluarga !== '' ? (
                <>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama Kepala Keluarga</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={this.state.kepalaKeluarga}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Alamat</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 500,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.detailKK.alamat}
                      </h6> */}
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={this.state.detailKK.alamat}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>RT</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 500,
                          fontSize: 'inherit'
                        }}
                      >
                        {this.state.detailKK.data_rt.nama}
                      </h6> */}
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={this.state.detailKK.data_rt.nama}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>RW</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 500,
                          fontSize: 'inherit'
                        }}
                      >
                        {this.state.detailKK.data_rt.data_rw.nama}
                      </h6> */}
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={this.state.detailKK.data_rt.data_rw.nama}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>{localStorage.getItem('JENIS_DESA')} / Kelurahan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 500,
                          fontSize: 'inherit'
                        }}
                      >
                        {this.state.detailKK.data_rt.data_rw.data_keldes.nama}
                      </h6> */}
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={this.state.detailKK.data_rt.data_rw.data_keldes.nama}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>Kecamatan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 500,
                          fontSize: 'inherit'
                        }}
                      >
                        {this.state.detailKK.data_rt.data_rw.data_keldes.data_kecamatan.nama}
                      </h6> */}
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={this.state.detailKK.data_rt.data_rw.data_keldes.data_kecamatan.nama}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>Kab/Kota</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 500,
                          fontSize: 'inherit'
                        }}
                      >
                        {this.state.detailKK.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama}
                      </h6> */}
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={this.state.detailKK.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>Provinsi</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 500,
                          fontSize: 'inherit'
                        }}
                      >
                        {this.state.detailKK.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama}
                      </h6> */}
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={this.state.detailKK.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>Kode POS</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        data-testid="kode_pos"
                        name="kode_pos"
                        id="kode_pos"
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                        defaultValue={this.state.dt.kode_pos}
                        required
                        type="number"
                        placeholder="Masukkan kode pos (Contoh : 28285)"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>Telepon</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        data-testid="telepon"
                        name="telepon"
                        id="telepon"
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                        defaultValue={this.state.dt.telepon}
                        // required
                        type="text"
                        placeholder="Masukkan nomor telepon rumah jika ada (Contoh : 0761252532)"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Pemohon</b>
                    </Label>
                    <div className="col-sm-8" data-testid="selectNIK">
                      <Select
                        inputId="nik"
                        classNamePrefix="select"
                        onChange={e => {
                          this.changeSelectValue(e, "nik");
                        }}
                        defaultValue={this.state.nikStat}
                        value={this.state.nikStat}
                        name="NIK3"
                        options={this.state.frmNik3}
                        placeholder="Pilih NIK Pemohon"
                        isClearable
                      />
                    </div>
                  </FormGroup>
                  {this.state.dataPemohon ? (
                    <>
                      <FormGroup className="row" style={{ marginBottom: '10px' }}>
                        <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                          <b>Nama Pemohon</b>
                        </Label>
                        <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          {/* <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 500,
                              fontSize: 'inherit'
                            }}
                          >
                            {this.state.dataPemohon.nama_pemohon}
                          </h6> */}
                          <input
                            className="form-control"
                            style={{
                              marginBottom: '10px',
                              fontWeight: 400,
                              fontSize: 'inherit',
                              backgroundColor: '#c9d1d8'
                            }}
                            defaultValue={this.state.dataPemohon.nama_pemohon}
                            required
                            type="text"
                            disabled
                            readOnly
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="row" style={{ marginBottom: '10px' }}>
                        <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                          <b>NIK Pemohon</b>
                        </Label>
                        <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          {/* <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 500,
                              fontSize: 'inherit'
                            }}
                          >
                            {this.state.dataPemohon.nik}
                          </h6> */}
                          <input
                            className="form-control"
                            style={{
                              marginBottom: '10px',
                              fontWeight: 400,
                              fontSize: 'inherit',
                              backgroundColor: '#c9d1d8'
                            }}
                            defaultValue={this.state.dataPemohon.nik}
                            required
                            type="text"
                            disabled
                            readOnly
                          />
                        </div>
                      </FormGroup>
                    </>
                  ) : ''}
                </>
              ) : ''}

              {/* Data Kepindahan */}
              <FormGroup className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>DATA KEPINDAHAN</b>
                </Label>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Alasan Pindah</b>
                </Label>
                <div className="col-sm-8" data-testid="selectAlasanPindah">
                  <Select
                    inputId="alasan_pindah"
                    // isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.changeSelectValue(e, 'alasan pindah');
                    }}
                    defaultValue={this.state.alasanPindah}
                    value={this.state.alasanPindah}
                    name="Alasan Pindah"
                    options={[
                      {value: '1', label: 'Pekerjaan'},
                      {value: '2', label: 'Pendidikan'},
                      {value: '3', label: 'Keamanan'},
                      {value: '4', label: 'Kesehatan'},
                      {value: '5', label: 'Perumahan'},
                      {value: '6', label: 'Keluarga'},
                      {value: '7', label: 'Lainnya'},
                    ]}
                    placeholder="Pilih Alasan Pindah"
                    isClearable
                  />
                </div>
              </FormGroup>
              {this.state.alasanPindah && this.state.alasanPindah.label === 'Lainnya' ? (
                <FormGroup className="row" style={{ marginBottom: '0px', marginTop: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Keterangan Alasan Pindah</b>
                  </Label>
                  <div className="col-sm-8">
                    <input
                      data-testid="keterangan_alasan_pindah"
                      name="keterangan_alasan_pindah"
                      id="alasan_pindah"
                      className="form-control"
                      style={{
                        marginBottom: '10px',
                        fontWeight: 400,
                        fontSize: 'inherit'
                      }}
                      defaultValue={this.state.dtPindah.alasan_pindah}
                      required
                      type="text"
                      placeholder="Ketikkan alasan pindah (Contoh : Pindah Tugas)"
                    />
                  </div>
                </FormGroup>
              ) : ''}
              <FormGroup className="row" style={{ marginBottom: '0px', marginTop: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Alamat</b>
                </Label>
                <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    data-testid="alamat_pindah"
                    name="alamat_pindah"
                    id="alamat_pindah"
                    className="form-control"
                    style={{
                      marginBottom: '10px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                    defaultValue={this.state.dtPindah.alamat}
                    required
                    type="text"
                    placeholder="Masukkan alamat pindah (Contoh : Jl. Sudirman No. 17A)"
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>RT</b>
                </Label>
                <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    data-testid="rt_pindah"
                    name="rt_pindah"
                    id="rt_pindah"
                    className="form-control"
                    style={{
                      marginBottom: '10px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                    defaultValue={this.state.dtPindah.rt}
                    required
                    type="text"
                    placeholder="Masukkan RT (Contoh : 002)"
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>RW</b>
                </Label>
                <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    data-testid="rw_pindah"
                    name="rw_pindah"
                    id="rw_pindah"
                    className="form-control"
                    style={{
                      marginBottom: '10px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                    defaultValue={this.state.dtPindah.rw}
                    required
                    type="text"
                    placeholder="Masukkan RW (Contoh : 007)"
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>Provinsi</b>
                </Label>
                <div className="col-sm-8" data-testid="selectProv">
                  <Select
                    inputId="provinsi"
                    // isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, 'provinsi', 'prov', 'kab-kota');
                    }}
                    defaultValue={this.state.prvStat}
                    value={this.state.prvStat}
                    name="provinsi"
                    options={this.state.frmPrv}
                    placeholder="Pilih Provinsi"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>Kab/Kota</b>
                </Label>
                <div className="col-sm-8" data-testid="selectKab">
                  <Select
                    inputId="kabupaten"
                    // isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, 'kab-kota', 'kab', 'kecamatan');
                    }}
                    defaultValue={this.state.kbktStat}
                    value={this.state.kbktStat}
                    name="kabkota"
                    options={this.state.frmKbkt}
                    placeholder="Pilih Kabupaten/Kota"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>Kecamatan</b>
                </Label>
                <div className="col-sm-8" data-testid="selectKec">
                  <Select
                    inputId="kecamatan"
                    // isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, 'kecamatan', 'kec', 'keldes');
                    }}
                    defaultValue={this.state.kecStat}
                    value={this.state.kecStat}
                    name="kecamatan"
                    options={this.state.frmKec}
                    placeholder="Pilih Kecamatan"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>
                    {localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan' ? (
                      'Kelurahan'
                    ) : (
                      'Kelurahan / ' + localStorage.getItem('JENIS_DESA')
                    )}
                  </b>
                </Label>
                <div className="col-sm-8" data-testid="selectKeldes">
                  <Select
                    inputId="keldes"
                    // isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      if (this.state.isKelurahan) {
                        this.getChild(e, 'keldes', 'kel', 'rw');
                      } else {
                        this.getChild(e, 'keldes', 'kel', 'dusun');
                      }
                    }}
                    defaultValue={this.state.kldsStat}
                    value={this.state.kldsStat}
                    name="keldes"
                    options={this.state.frmKel}
                    placeholder={
                      localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan' ? (
                        'Pilih Kelurahan'
                      ) : (
                          'Pilih Kelurahan / ' + localStorage.getItem('JENIS_DESA')
                        )
                    }
                    isClearable
                    required
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>Kode POS</b>
                </Label>
                <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    data-testid="kode_pos_pindah"
                    name="kode_pos_pindah"
                    id="kode_pos_pindah"
                    className="form-control"
                    style={{
                      marginBottom: '10px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                    defaultValue={this.state.dtPindah.kode_pos}
                    required
                    type="number"
                    placeholder="Masukkan kode pos (Contoh : 28285)"
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>Telepon</b>
                </Label>
                <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    data-testid="telepon_pindah"
                    name="telepon_pindah"
                    id="telepon_pindah"
                    className="form-control"
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                    defaultValue={this.state.dtPindah.telepon}
                    // required
                    type="text"
                    placeholder="Masukkan nomor telepon rumah jika ada (Contoh : 0761252532)"
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Klasifikasi Pindah</b>
                </Label>
                <div className="col-sm-8" data-testid="selectKlasifikasiPindah">
                  <Select
                    inputId="klasifikasi_pindah"
                    // isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.changeSelectValue(e, 'klasifikasi pindah');
                    }}
                    defaultValue={this.state.klasifikasiPindah}
                    value={this.state.klasifikasiPindah}
                    name="Klasifikasi Pindah"
                    options={[
                      {value: '1', label: 'Dalam satu ' + localStorage.getItem('JENIS_DESA') + ' / Kelurahan'},
                      {value: '2', label: 'Antar ' + localStorage.getItem('JENIS_DESA') + ' / Kelurahan'},
                      {value: '3', label: 'Antar Kecamatan'},
                      {value: '4', label: 'Antar Kab/Kota'},
                      {value: '5', label: 'Antar Provinsi'},
                    ]}
                    placeholder="Pilih Klasifikasi Pindah"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Jenis Kepindahan</b>
                </Label>
                <div className="col-sm-8" data-testid="selectJenisPindah">
                  <Select
                    inputId="jenis_pindah"
                    // isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.changeSelectValue(e, 'jenis pindah');
                    }}
                    defaultValue={this.state.jenisPindah}
                    value={this.state.jenisPindah}
                    name="Jenis Kepindahan"
                    options={[
                      {value: '1', label: 'Kep. Keluarga'},
                      {value: '2', label: 'Kep. Keluarga dan Seluruh Anggota Keluarga'},
                      {value: '3', label: 'Kep. Keluarga dan Sbg. Angg. keluarga'},
                      {value: '4', label: 'Angg. Keluarga'},
                    ]}
                    placeholder="Pilih Jenis Kepindahan"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Status Nomor KK Bagi Yang Tidak Pindah</b>
                </Label>
                <div className="col-sm-8" data-testid="selectStatusTidakPindah">
                  <Select
                    inputId="status_tidak_pindah"
                    // isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.changeSelectValue(e, 'status tidak pindah');
                    }}
                    defaultValue={this.state.statusTidakPindah}
                    value={this.state.statusTidakPindah}
                    name="Status Tidak Pindah"
                    options={[
                      {value: '1', label: 'Numpang KK'},
                      {value: '2', label: 'Membuat KK Baru'},
                      {value: '3', label: 'Tidak Ada Angg. Keluarga Yang Ditinggal'},
                      {value: '4', label: 'Nomor KK Tetap'},
                    ]}
                    placeholder="Pilih Status Nomor KK yang Tidak Pindah"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Status Nomor KK Bagi Yang Pindah</b>
                </Label>
                <div className="col-sm-8" data-testid="selectStatusPindah">
                  <Select
                    inputId="status_pindah"
                    // isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.changeSelectValue(e, 'status pindah');
                    }}
                    defaultValue={this.state.statusPindah}
                    value={this.state.statusPindah}
                    name="Status Tidak Pindah"
                    options={[
                      {value: '1', label: 'Numpang KK'},
                      {value: '2', label: 'Membuat KK Baru'},
                      {value: '3', label: 'Nama Kep. Keluarga dan Nomor KK Tetap'},
                    ]}
                    placeholder="Pilih Status Nomor KK yang Pindah"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Rencana Tanggal Pindah</b>
                </Label>
                <div className="col-sm-8">
                  <Datetime
                    inputProps={{ "data-testid": "tgl_pindah", placeholder: 'Tanggal Pindah' }}
                    data-testid="tgl_pindah"
                    inputId="tgl_pindah"
                    name="tgl_pindah"
                    id="tgl_pindah"
                    locale="id-ID"
                    dateFormat="DD/MM/yyyy"
                    timeFormat={false}
                    input={true}
                    closeOnTab={true}
                    closeOnSelect={true}
                    value={this.state.dtPindah.tgl_pindah}
                    initialValue={this.state.dtPindah.tgl_pindah}
                    onChange={e => {
                      this.gantiTgl(e,'tgl_pindah')
                    }}
                  />
                  {/* <Datetime
                    inputProps={{ "data-testid": "tgl_pindah", placeholder: 'Tanggal Pindah', required: true }}
                    inputId="tgl_pindah"
                    name="tgl_pindah"
                    input={true}
                    timeFormat={false}
                    dateFormat="DD/MM/yyyy"
                    closeOnSelect={true}
                    initialValue={this.state.dtPindah.tgl_pindah}
                    value={this.state.dtPindah.tgl_pindah}
                    onChange={(val) => {
                      //console.log(val);
                      this.setState({ dtPindah: {...this.state.dtPindah, tgl_pindah: new Date(val)} })
                    }}
                  /> */}
                </div>
              </FormGroup>
              {this.state.kkStat ? (
                <FormGroup className="row">
                  <Label className="col-sm-12 col-form-label">
                    Daftar Keluarga Yang Ikut
                  </Label>
                  <Col sm="5">
                    <div data-testid="selectNIK3">
                      <Select
                        inputId="nik3"
                        classNamePrefix="select"
                        onChange={e => {
                          this.changeSelectValue(e, "nik3");
                        }}
                        defaultValue={this.state.nik3Stat}
                        value={this.state.nik3Stat}
                        name="NIK3"
                        options={this.state.frmNik3}
                        placeholder="Pilih NIK Anggota Keluarga yang Ikut"
                        isClearable
                      />
                    </div>
                  </Col>
                  <Col sm="4">
                    <div data-testid="selectShdk">
                      <Select
                        inputId="shdk_aw"
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.changeSelectValue(e, 'shdk_aw');
                        }}
                        value={this.state.shdk_aw}
                        id="shdk"
                        options={[
                          { value: '01', label: 'Kep. Keluarga' },
                          { value: '02', label: 'Suami' },
                          { value: '03', label: 'Isteri' },
                          { value: '04', label: 'Anak' },
                          { value: '05', label: 'Menantu' },
                          { value: '06', label: 'Cucu' },
                          { value: '07', label: 'Orang Tua' },
                          { value: '08', label: 'Mertua' },
                          { value: '09', label: 'Famili Lain' },
                          { value: '10', label: 'Lainnya' },
                        ]}
                        placeholder="Hubungan Keluarga"
                      />
                    </div>
                  </Col>
                  <Col sm="3">
                    <button
                      data-testid="btnTambahKeluarga"
                      type="button"
                      className="btn btn-primary-custom"
                      onClick={this.tambahAw}
                    >
                      Tambah
                    </button>
                  </Col>
                  <Col sm="12">
                    <table className="table table-bordered table-stripes table-hovered">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Nama</th>
                          <th>NIK</th>
                          {/* <th>Tempat/Tanggal Lahir</th>
                          <th>Pekerjaan</th>
                          <th>Pendidikan</th> */}
                          <th>Hubungan Keluarga</th>
                          <th>
                            <i className="fa fa-trash"></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{listKeluargaIn}</tbody>
                    </table>
                  </Col>
                </FormGroup>
              ) : ''}

            </div>
          
            <div className="modal-footer" align="center">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
                >
                Tutup
              </button>
              <button
                data-testid="btnSubmitCreate"
                type="submit"
                className="btn btn-primary-custom"
                disabled={this.state.status.btnForm}
                >
                Selesai
              </button>
            </div>
          </Form>
				</Modal>
				
      </div>
    );
  }
}

export default KecamatanPindahDatang;